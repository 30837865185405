import { useRef, useState } from "react";
import styled from "styled-components";
import UserOptions from "./UserOptions";

interface UserIconProps {
  dataTestname: string;
  initials: string;
  setLocallyAuthorized: Function;
  setAppLoading: Function;
}

const UserMenuItem: React.FC<UserIconProps> = ({
  dataTestname,
  initials,
  setLocallyAuthorized,
  setAppLoading,
}) => {
  const [userOptionsDisplayed, setUserOptionsDisplayed] =
    useState<boolean>(false);

  const userIconRef = useRef<any>(null);

  const handleDisplayStateChange = () => {
    setUserOptionsDisplayed(!userOptionsDisplayed);
  };

  return (
    <StyledUserItemContainer data-testname={dataTestname}>
      <StyledUserIconDiv
        hoverable={true}
        onClick={handleDisplayStateChange}
        ref={userIconRef}
      >
        <StyledUserIconText data-testid="clickable-initials">
          {initials}
        </StyledUserIconText>
      </StyledUserIconDiv>
      <UserOptions
        userOptionsDisplayed={userOptionsDisplayed}
        initials={initials}
        setUserOptionsDisplayed={setUserOptionsDisplayed}
        userIconRef={userIconRef}
        setLocallyAuthorized={setLocallyAuthorized}
        setAppLoading={setAppLoading}
      />
    </StyledUserItemContainer>
  );
};

export default UserMenuItem;

const StyledUserItemContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

interface UserIconDivProps {
  hoverable: boolean;
}

const StyledUserIconDiv = styled.div<UserIconDivProps>`
  width: 30px;
  height: 30px;
  color: black;
  background-color: white;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  :hover {
    cursor: ${(props) => (props.hoverable ? "pointer" : "default")};
  }
`;

const StyledUserIconText = styled.p`
  font-size: ${(props) => props.theme.userOptionsMediumText};
  font-weight: 600;
  text-transform: uppercase;
  line-height: 6px;
`;
