import { createContext } from "react";
import { IContactPointContext } from "./types";

const defaultContactPointContext: IContactPointContext = {
  contactPoints: [],
  contactPoint: null,
  setContactPoint: () => null,
  setContactPointsByCompany: () => null,
  loading: true,
};

const ContactPointContext = createContext<IContactPointContext>(
  defaultContactPointContext
);

export default ContactPointContext;
