import React, { useCallback } from "react";
import styled from "styled-components";

// TabTitle Component Instructions
// 1. <TabTitle> </TabTitle> should not be used on its own. It is called within Tabs.
// 2. Here is a list of the props and how each of them are used:
//  - dataTestname: the unique dataTestname to assign to the component that allows Mike to run automated tests
//  - title: the title that will be displayed for the Tab(s)
//  - index: the numbered index of the Tab - necessary to know which to show
//  - selectedTab: the number of the selected Tab currently displayed
//  - setSelectedTab: the function to update which tab is selected
// 3. Please do not touch the styling in this file. If you *do* need to change styling, please talk to TJ.
// 4. If you have any suggestions or need another css property to be supported, please talk to TJ.
// 5. If there are any issues, please talk to TJ.

interface TabTitleProps {
  dataTestname: string;
  title: string;
  index: number;
  setSelectedTab: (index: number) => void;
  selectedTab: number;
}

const TabTitle: React.FC<TabTitleProps> = ({
  dataTestname,
  title,
  setSelectedTab,
  index,
  selectedTab,
}) => {
  const onClick = useCallback(() => {
    setSelectedTab(index);
  }, [setSelectedTab, index]);

  return (
    <StyledTab
      data-testname={dataTestname}
      data-testid="tab-button"
      className="tabButton"
      onClick={onClick}
      selectedTab={selectedTab}
      index={index}
    >
      {title}
    </StyledTab>
  );
};

export default TabTitle;

interface SelectedTabProps {
  selectedTab: number;
  index: number;
}

const StyledTab = styled.button<SelectedTabProps>`
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
  border-width: 1px;
  border-style: solid;
  border-color: #ffffff;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  display: block;
  padding: 0.5rem 1rem;
  cursor: pointer;
  margin: 0 1px;
  outline: none;
  background-color: #ffffff;
  color: ${(props) => (props.selectedTab === props.index ? "black" : "#aaa")};
  font-weight: bold;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    border-color: ${(props) =>
      props.selectedTab === props.index ? "#ffffff #ffffff" : "transparent"};
    background-color: ${(props) =>
      props.selectedTab === props.index ? "#ffffff" : "#eaeaea"};
    color: ${(props) => (props.selectedTab === props.index ? "black" : "#888")};
  }
`;
