import { ICompany } from "../../types/companies";
import { NewIUser } from "../../types/users";
import { ActionTypes, ICompanyState } from "./types";

type Action =
  | { type: ActionTypes.SET_COMPANIES; payload: ICompany[] }
  | { type: ActionTypes.SET_COMPANY; payload: ICompany | null }
  | { type: ActionTypes.SET_USERS; payload: NewIUser[] }
  | {
      type: ActionTypes.SET_COMPANY_BELONGS_TO_USER;
      payload: boolean;
    }
  | {
      type: ActionTypes.SET_USER_BELONGS_TO_USER_COMPANY;

      payload: boolean;
    }
  | { type: ActionTypes.SENDING_REQUEST }
  | { type: ActionTypes.REQUEST_FINISHED };

const reducer = (state: ICompanyState, action: Action) => {
  switch (action.type) {
    case ActionTypes.SET_COMPANIES:
      return {
        ...state,
        companies: action.payload,
      };
    case ActionTypes.SET_COMPANY:
      return {
        ...state,
        company: action.payload,
      };
    case ActionTypes.SET_USERS:
      let usersArray: NewIUser[] = [];
      action.payload.forEach((user) => {
        user.role = user.app_role_title;
        user.name = user.first_name + " " + user.last_name;
        usersArray.push(user);
      });
      return {
        ...state,
        users: usersArray,
      };
    case ActionTypes.SET_COMPANY_BELONGS_TO_USER:
      return {
        ...state,
        companyBelongsToUser: action.payload,
      };
    case ActionTypes.SENDING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.REQUEST_FINISHED:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.SET_USER_BELONGS_TO_USER_COMPANY:
      return {
        ...state,
        userBelongsToUserCompany: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
