import { IControlUnitConfigDTO } from "../../types/controlUnitConfig";

export interface IControlUnitConfigContext {
  currentConfigs: IControlUnitConfigDTO[];
  historicalConfigs: IControlUnitConfigDTO[];
  loading: boolean;
  setCurrentConfigs: Function;
  setHistoricalConfigs: Function;
  children?: React.ReactNode;
}

export interface IControlUnitConfigState {
  currentConfigs: IControlUnitConfigDTO[];
  historicalConfigs: IControlUnitConfigDTO[];
  loading: boolean;
}

export enum ActionTypes {
  SET_CURRENT_CONTROL_UNIT_CONFIGS = "SET_CURRENT_CONTROL_UNIT_CONFIGS",
  SET_HISTORICAL_CONTROL_UNIT_CONFIGS = "SET_HISTORICAL_CONTROL_UNIT_CONFIGS",
  SENDING_REQUEST = "SENDING_REQUEST",
  REQUEST_FINISHED = "REQUEST_FINISHED",
}

export interface ControlUnitConfigAction {
  type: ActionTypes;
  payload: boolean | IControlUnitConfigDTO[];
}
