import { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ProfireButton from "../common/buttons/ProfireButton";
import Dropdown from "../common/formInputs/Dropdown";
import ModalBox from "../common/modals/ModalBox";
import RoleContext from "../../stores/roles/RoleContext";
import UserContext from "../../stores/users/UserContext";
import { IRole } from "../../types/roles";
import { IUser, IUserUpdateDTO } from "../../types/users";
import { validateTextString } from "../../utilities/validationFunctions";
import CommonInput from "../common/formInputs/CommonInput";

interface EditUserProps {
  active: boolean;
  user: IUser;
  onCancel?: () => void;
  onEdit: Function;
  retainedData: IUser | undefined;
  companyId: number;
}

let existingErrors: Boolean = false;

const EditUser: React.FC<EditUserProps> = ({
  active,
  user,
  onCancel,
  onEdit,
  retainedData,
  companyId,
}) => {
  const userContext = useContext(UserContext);
  const userContextRef = useRef(userContext).current;
  const roleContext = useContext(RoleContext);
  const roleContextRef = useRef(roleContext).current;

  useEffect(() => {
    roleContextRef.setRoles();
  }, [roleContextRef, userContextRef, user]);

  const [firstName, setFirstName] = useState<string>(
    retainedData === undefined
      ? user?.first_name === undefined
        ? ""
        : user?.first_name
      : retainedData.first_name
  );
  const [firstNameErrors, setFirstNameErrors] = useState<string>("");

  const handleFirstName = (e: React.FormEvent<HTMLInputElement>) => {
    if (
      !validateTextString(e.currentTarget.value) &&
      e.currentTarget.value !== ""
    ) {
      setFirstName(e.currentTarget.value);
      setFirstNameErrors(
        "First character must be alphanumeric and only alphanumeric, spaces, and the following are supported after: | _ : , . # - / '"
      );
    } else {
      setFirstName(e.currentTarget.value);
      setFirstNameErrors("");
    }
  };

  const [lastName, setLastName] = useState<string>(
    retainedData === undefined
      ? user?.last_name === undefined
        ? ""
        : user?.last_name
      : retainedData.last_name
  );
  const [lastNameErrors, setLastNameErrors] = useState<string>("");

  const handleLastName = (e: React.FormEvent<HTMLInputElement>) => {
    if (
      !validateTextString(e.currentTarget.value) &&
      e.currentTarget.value !== ""
    ) {
      setLastName(e.currentTarget.value);
      setLastNameErrors(
        "First character must be alphanumeric and only alphanumeric, spaces, and the following are supported after: | _ : , . # - / '"
      );
    } else {
      setLastName(e.currentTarget.value);
      setLastNameErrors("");
    }
  };

  const [email, setEmail] = useState<string>(
    retainedData === undefined
      ? user?.email === undefined
        ? ""
        : user?.email
      : retainedData.email
  );
  const [emailErrors, setEmailErrors] = useState<string>("");

  const handleEmail = (e: React.FormEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value);
    setEmailErrors("");
  };

  const [roleId, setRoleId] = useState<number>(
    retainedData === undefined
      ? user?.app_role_id === undefined
        ? 0
        : user?.app_role_id
      : retainedData.app_role_id
  );
  const [roleIdErrors, setRoleIdErrors] = useState<string>("");

  function handleChosenRole(newChosen: string) {
    setRoleId(parseInt(newChosen));
    setRoleIdErrors("");
  }

  let rolesArray: [string, string][] = [];
  rolesArray.push(["Select a role...", "0"]);

  let filteredRoles: IRole[] = roleContext.roles.filter(
    (r) =>
      r.is_internal ===
      (!userContext.permissions?.profire_select_company || companyId !== 1
        ? false
        : true)
  );

  for (let index = 0; index < filteredRoles.length; index++) {
    rolesArray.push([
      filteredRoles[index].description,
      filteredRoles[index].id.toString(),
    ]);
  }

  function handleCancel() {
    existingErrors = false;
    onCancel!();
  }

  function handleExistingErrors(errors: Boolean) {
    existingErrors = errors;
  }

  function clearErrors() {
    setRoleIdErrors("");
    setFirstNameErrors("");
    setLastNameErrors("");
    setEmailErrors("");

    handleExistingErrors(false);
  }

  function ErrorHandler() {
    clearErrors();

    if (roleId === 0 || !roleId) {
      setRoleIdErrors("Role is required.\n");
      handleExistingErrors(true);
    }

    if (firstName === "" || !firstName) {
      setFirstNameErrors("First name is required.\n");
      handleExistingErrors(true);
    }

    if (firstName) {
      if (!validateTextString(firstName)) {
        setFirstNameErrors(
          "Please read the information in the top box to see what characters are allowed."
        );
        handleExistingErrors(true);
      }
    }

    if (lastName === "" || !lastName) {
      setLastNameErrors("Last name is required.\n");
      handleExistingErrors(true);
    }

    if (lastName) {
      if (!validateTextString(lastName)) {
        setLastNameErrors(
          "Please read the information in the top box to see what characters are allowed."
        );
        handleExistingErrors(true);
      }
    }
  }

  const onSubmit = async (e: any) => {
    e.preventDefault();
    // if no errors
    if (!existingErrors) {
      const editedUser: IUserUpdateDTO = {
        azure_id: user.azure_id,
        first_name: firstName,
        last_name: lastName,
        company_id: companyId,
        app_role_id: roleId,
      };

      onEdit(editedUser);
    }
  };

  return (
    <ModalBox
      dataTestname="edit-user-modal-box"
      active={active}
      desktopContainerMinWidth={"765px"}
      mobileContainerMinWidth={"320px"}
      containerTitle="Edit User"
      maxHeight={"50%"}
    >
      <StyledEditUserDiv>
        <StyledMainDiv data-testid="main-div">
          <StyledForm onSubmit={onSubmit}>
            <StyledSectionDiv>
              <CommonInput
                dataTestname="edit-user-first-name-common-input"
                type={"text"}
                labelText={"First Name"}
                required={true}
                readOnly={false}
                value={firstName}
                onChange={handleFirstName}
                maxLength={50}
                placeholder={"First Name"}
                errors={firstNameErrors}
                autoFocus={true}
              />
              <CommonInput
                dataTestname="edit-user-last-name-common-input"
                type={"text"}
                labelText={"Last Name"}
                required={true}
                readOnly={false}
                value={lastName}
                onChange={handleLastName}
                maxLength={50}
                placeholder={"Last Name"}
                errors={lastNameErrors}
              />
              <CommonInput
                dataTestname="edit-user-email-common-input"
                type={"text"}
                labelText={"Email"}
                required={true}
                readOnly={true}
                value={email}
                onChange={handleEmail}
                maxLength={50}
                placeholder={"Email"}
                errors={emailErrors}
              />
              <Dropdown
                dataTestname="edit-user-role-dropdown"
                selected={roleId.toString()}
                onchange={handleChosenRole}
                columns={rolesArray}
                labelText={"Role"}
                required={true}
                errors={roleIdErrors}
              />
              {existingErrors && (
                <StyledErrorMessage data-testid="submit-errors">
                  {"Please fix above errors and resubmit."}
                </StyledErrorMessage>
              )}
              <StyledButtonRow>
                <StyledCancelButtonHolder>
                  <ProfireButton
                    dataTestname="add-site-cancel-button"
                    text="Cancel"
                    onClickFunction={handleCancel}
                  />
                </StyledCancelButtonHolder>
                <StyledSaveButtonHolder>
                  <ProfireButton
                    dataTestname="add-site-save-button"
                    text="Save"
                    onClickFunction={ErrorHandler}
                    defaultButton={true}
                  />
                </StyledSaveButtonHolder>
              </StyledButtonRow>
            </StyledSectionDiv>
          </StyledForm>
        </StyledMainDiv>
      </StyledEditUserDiv>
    </ModalBox>
  );
};

export default EditUser;

const StyledEditUserDiv = styled.div`
  width: calc(100% - 10px);
  height: 100%;
  overflow-y: auto;
  padding-right: 10px;
`;

const StyledMainDiv = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledErrorMessage = styled.div`
  text-align: left;
  color: #9c1b30;
  font-size: ${(props) => props.theme.contentErrorSize};
  font-weight: ${(props) => props.theme.contentErrorWeight};

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
`;

const StyledSectionDiv = styled.div`
  width: Calc(100%);
  background-color: #ffffff;
  border-radius: 10px;
  text-align: left;
`;

const StyledForm = styled.form``;

const StyledButtonRow = styled.div`
  width: 100%;
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

const StyledCancelButtonHolder = styled.div`
  margin-right: 20px;
`;

const StyledSaveButtonHolder = styled.div``;
