import "./App.css";
import { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
// router imports
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
// msal imports
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
// component imports
import Login from "./pages/Login";
import ClientAccounts from "./pages/ClientAccounts";
import PageNotFound from "./pages/PageNotFound";
import Header from "./components/common/headers/Header";
import Loading from "./components/common/modals/Loading";
// context imports
import ContextComposer from "./stores/ContextComposer";
import CompanyProvider from "./stores/companies/CompanyProvider";
import DailyAggregateProvider from "./stores/dailyAggregates/DailyAggregateProvider";
import RoleProvider from "./stores/roles/RoleProvider";
import UserContext from "./stores/users/UserContext";
import MyCompany from "./pages/MyCompany";
import Sites from "./pages/Sites";
import SiteProvider from "./stores/sites/SiteProvider";
import SingleSite from "./pages/SingleSite";
import ProcessUnitProvider from "./stores/processUnits/ProcessUnitProvider";
import MonitorUnitProvider from "./stores/monitorUnits/MonitorUnitProvider";
import SingleProcessUnit from "./pages/SingleProcessUnit";
import SingleControlUnit from "./pages/SingleControlUnit";
import ControlUnitProvider from "./stores/controlUnits/ControlUnitProvider";
import SingleMonitorUnit from "./pages/SingleMonitorUnit";
import LookupProvider from "./stores/lookup/LookupProvider";
import { getToken } from "./utilities/msTokenFunc";
import axios, { AxiosRequestConfig } from "axios";
import { NewIUser } from "./types/users";
import PageUnauthorized from "./pages/PageUnauthorized";
import ControlUnitConfigProvider from "./stores/controlUnitConfig/ControlUnitConfigProvider";
import ContactPointProvider from "./stores/contactPoints/ContactPointProvider";
import Navbar from "./components/common/navigation/Navbar";
import StyleProvider from "./stores/styles/StyleProvider";
import MobileMap from "./pages/MobileMap";
import { InteractionStatus } from "@azure/msal-browser";

const App: React.FC = () => {
  const { accounts, inProgress, instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const userContext = useContext(UserContext);
  const userContextRef = useRef(userContext).current;

  const [loading, setLoading] = useState<boolean>(false);
  const [user, setUser] = useState<NewIUser>();
  const [locallyAuthorized, setLocallyAuthorized] = useState<boolean>(false);

  const getUserInfo = async (azureId: string) => {
    try {
      setLoading(true);
      const token = await getToken();
      const result = await axios.get(
        `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/user/byazureid/${azureId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return result.data;
    } catch (error) {}
  };

  useEffect(() => {
    //after redirect, jump straight to here probably because authenticated/account is set
    if (isAuthenticated && accounts.length > 0) {
      getUserInfo(accounts[0].localAccountId)
        .then((res) => {
          setUser(res);
          if (res === undefined) {
            setLocallyAuthorized(false);
            localStorage.setItem("userId", "N/A");
          } else {
            setLocallyAuthorized(true);
            localStorage.setItem("userId", accounts[0].localAccountId!);
            userContextRef.setUser({
              azure_id: accounts[0].localAccountId,
              azure_name: accounts[0].name!,
              azure_username: accounts[0].username,
              first_name: res.first_name,
              last_name: res.last_name,
            });
            userContextRef.setPermissions(accounts[0].localAccountId);
          }
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
        });
    } else {
      userContextRef.setUser(null);
    }
  }, [isAuthenticated, accounts, userContextRef, instance]);

  axios.interceptors.request.use(
    (request: AxiosRequestConfig) => {
      request.headers = request.headers ?? {};

      //safely set headers...?
      if (accounts[0] !== undefined) {
        request.headers["azureId"] = accounts[0].localAccountId;
      }

      return request;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  if (userContext.loading || loading || inProgress !== InteractionStatus.None) {
    return (
      <div className="fullPageLoadingContainer">
        <Loading dataTestname="app-loading" />
      </div>
    );
  } else {
    if (!isAuthenticated || !locallyAuthorized) {
      return (
        <>
          <Router>
            <Routes>
              {/* this check makes it so that when using the loginPopup, if the user is not a registered user, 
              they are shown the Unauthorized Page without needing to be redirected.
              This is extremely important because our alternatives are:
                1. to use a set href.location.whatever to Unauthorized in App.tsx. Infinite Loop.
                2. change popup to redirect, which has not been cleared with Steve at time of writing.  */}
              {userContext.user === null && isAuthenticated && (
                <Route path="*" element={<PageUnauthorized />} />
              )}
              {/* <Route path="/" element={<PageUnauthorized />} /> */}
              {/* <Route path="/" element={<Login setLoading={setLoading} />} /> */}

              {/* <Route path="/" element={<Login setLoading={setLoading} />} /> */}
              {/* <Route path="*" element={<PageUnauthorized />} /> */}
              <Route path="*" element={<Login setLoading={setLoading} />} />
            </Routes>
          </Router>
        </>
      );
    } else {
      if (userContext.user === null) {
        return <></>;
      } else {
        return (
          <MainContainer className="App">
            <Router>
              <Header
                dataTestname="app-header"
                setLocallyAuthorized={setLocallyAuthorized}
                setAppLoading={setLoading}
              />
              <Navbar
                dataTestname={"app-navbar"}
                sharePointLink={undefined}
                setLocallyAuthorized={setLocallyAuthorized}
                setAppLoading={setLoading}
              />
              <MainContent>
                <ContextComposer
                  components={[
                    CompanyProvider,
                    RoleProvider,
                    DailyAggregateProvider,
                    SiteProvider,
                    ProcessUnitProvider,
                    MonitorUnitProvider,
                    ControlUnitProvider,
                    LookupProvider,
                    ControlUnitConfigProvider,
                    ContactPointProvider,
                    StyleProvider,
                  ]}
                >
                  <Routes>
                    <Route
                      path="/"
                      element={<Navigate replace to="/sites" />}
                    />
                    <Route
                      path="/clientaccounts"
                      element={<ClientAccounts />}
                    />
                    <Route
                      path="/company"
                      element={
                        userContext.permissions ? (
                          <MyCompany companyId={user?.company_id!} />
                        ) : null
                      }
                    />
                    <Route
                      path="/company/:companyId"
                      element={
                        userContext.permissions ? (
                          <MyCompany companyId={user?.company_id!} />
                        ) : null
                      }
                    />
                    <Route
                      path="/sites"
                      element={
                        userContext.permissions ? (
                          <Sites companyId={user?.company_id!} />
                        ) : null
                      }
                    />
                    <Route
                      path="/sites/:companyId"
                      element={
                        userContext.permissions ? (
                          <Sites companyId={user?.company_id!} />
                        ) : null
                      }
                    />
                    <Route
                      path="/sites/map/:companyId"
                      element={
                        userContext.permissions ? (
                          <MobileMap companyId={user?.company_id!} />
                        ) : null
                      }
                    />
                    <Route
                      path="/sites/details/:siteId"
                      element={userContext.permissions ? <SingleSite /> : null}
                    />
                    <Route
                      path="/processunits/details/:processUnitId"
                      element={
                        userContext.permissions ? <SingleProcessUnit /> : null
                      }
                    />
                    <Route
                      path="/monitorunits/details/:monitorUnitId"
                      element={
                        userContext.permissions ? <SingleMonitorUnit /> : null
                      }
                    />
                    <Route
                      path="/controlunits/details/:unitName/:unitId/:controlUnitId"
                      element={
                        userContext.permissions ? <SingleControlUnit /> : null
                      }
                    />
                    <Route path="*" element={<PageNotFound />} />
                  </Routes>
                </ContextComposer>
              </MainContent>
            </Router>
          </MainContainer>
        );
      }
    }
  }
};

export default App;

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
