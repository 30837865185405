import { createContext } from "react";
import { ISiteContext } from "./types";

const defaultSiteContext: ISiteContext = {
  sites: [],
  site: null,
  siteBelongsToUserCompany: null,
  loading: true,
  setSites: () => null,
  setSite: () => null,
  setSitesByCompanyId: () => null,
  setSiteBelongsToUserCompany: () => null,
};

const SiteContext = createContext<ISiteContext>(defaultSiteContext);

export default SiteContext;
