import React, { useReducer } from "react";
import { ActionTypes, IDailyAggregateContext } from "./types";
import DailyAggregateReducer from "./DailyAggregateReducer";
import axios from "axios";
import DailyAggregateContext from "./DailyAggregateContext";
import { getToken } from "../../utilities/msTokenFunc";

const DailyAggregateProvider: React.FC<IDailyAggregateContext> = (props) => {
  const initialState = {
    dailyAggregates: [],
    loading: true,
  };

  const [state, dispatch] = useReducer(DailyAggregateReducer, initialState);

  const setDailyAggregates = async (
    controlUnitId: string,
    month: number,
    year: number
  ) => {
    try {
      dispatch({ type: ActionTypes.SENDING_REQUEST });
      const token: string = await getToken();
      await axios
        .get(
          `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/dailyaggregate/monthly/${controlUnitId}/${month}/${year}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) =>
          dispatch({
            type: ActionTypes.SET_DAILY_AGGREGATES,
            payload: res.data,
          })
        );
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    } catch (error) {
      dispatch({
        type: ActionTypes.SET_DAILY_AGGREGATES,
        payload: [],
      });
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    }
  };

  return (
    <DailyAggregateContext.Provider
      value={{
        dailyAggregates: state.dailyAggregates,
        loading: state.loading,
        setDailyAggregates: setDailyAggregates,
      }}
    >
      {props.children}
    </DailyAggregateContext.Provider>
  );
};

export default DailyAggregateProvider;
