import React, { useReducer } from "react";
import {
  ActionTypes,
  IControlUnitConfigContext,
  IControlUnitConfigState,
} from "./types";
import ControlUnitConfigReducer from "./ControlUnitConfigReducer";
import ControlUnitConfigContext from "./ControlUnitConfigContext";
import axios from "axios";
import { getToken } from "../../utilities/msTokenFunc";

const ControlUnitConfigProvider: React.FC<IControlUnitConfigContext> = (
  props
) => {
  const initialState: IControlUnitConfigState = {
    currentConfigs: [],
    historicalConfigs: [],
    loading: true,
  };

  const [state, dispatch] = useReducer(ControlUnitConfigReducer, initialState);

  const setCurrentControlUnitConfigs = async (id: number) => {
    try {
      dispatch({ type: ActionTypes.SENDING_REQUEST });
      const token: string = await getToken();
      await axios
        .get(
          `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/currentconfig/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) =>
          dispatch({
            type: ActionTypes.SET_CURRENT_CONTROL_UNIT_CONFIGS,
            payload: res.data,
          })
        );
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    } catch (error) {
      dispatch({
        type: ActionTypes.SET_CURRENT_CONTROL_UNIT_CONFIGS,
        payload: [],
      });
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    }
  };

  const setHistoricalControlUnitConfigs = async (id: number) => {
    try {
      dispatch({ type: ActionTypes.SENDING_REQUEST });
      const token: string = await getToken();
      await axios
        .get(
          `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/historicalconfig/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) =>
          dispatch({
            type: ActionTypes.SET_HISTORICAL_CONTROL_UNIT_CONFIGS,
            payload: res.data,
          })
        );
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    } catch (error) {
      dispatch({
        type: ActionTypes.SET_HISTORICAL_CONTROL_UNIT_CONFIGS,
        payload: [],
      });
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    }
  };

  return (
    <ControlUnitConfigContext.Provider
      value={{
        currentConfigs: state.currentConfigs,
        historicalConfigs: state.historicalConfigs,
        loading: state.loading,
        setCurrentConfigs: setCurrentControlUnitConfigs,
        setHistoricalConfigs: setHistoricalControlUnitConfigs,
      }}
    >
      {props.children}
    </ControlUnitConfigContext.Provider>
  );
};

export default ControlUnitConfigProvider;
