import React, { useState } from "react";
import styled from "styled-components";
import ChevronRightIcon from "../../../assets/images/navigation/chevron-right.svg";
import ChevronDownIcon from "../../../assets/images/navigation/chevron-down.svg";

// Collapsible Row Component Instructions
// To use this Collapsible Row, import it into whatever page/component necessary and feed it two things: A title (prop) and children.
// - title: Just a simple string that will be displayed verbatim to the right of the chevron
// - children: any and all HTML elements that need to be shown when the Collapsible Row is opened are passed in like children normally would be.
// - ex: <CollapsibleRow title={"Div Title"}>{this is where your HTML elements go}</CollapsibleRow>
// This component is meant to be as simple and straightforward as possible, which means that at this current time I will not be setting up a CollapsibleRowGroup
// This means that you can have as many CollapsibleRows open at once as you like, until the group is set up.

interface CollapsibleRowProps {
  dataTestname: string;
  title: string;
  type?: string;
  children?: React.ReactNode;
}

const CollapsibleRow: React.FC<CollapsibleRowProps> = ({
  dataTestname,
  title,
  type,
  children,
}) => {
  const [active, setActive] = useState<boolean>(false);
  const updateActive = () => {
    setActive(!active);
  };

  return (
    <StyledMainDiv data-testname={dataTestname} data-testid="main-div">
      {/* collapsible row area */}
      <StyledMainRowDiv
        active={active}
        data-testid="main-row-div"
        onClick={updateActive}
      >
        <StyledMainRowChevronDiv data-testid="main-row-arrow-div">
          <StyledChevron
            active={active}
            data-testid="main-row-arrow"
            src={active ? ChevronDownIcon : ChevronRightIcon}
            alt="overview icon"
          />
        </StyledMainRowChevronDiv>
        <StyledMainRowTitleDiv data-testid="main-row-title-div">
          <StyledTitle data-testid="main-row-title">{title}</StyledTitle>
        </StyledMainRowTitleDiv>
        <StyledMainRowTypeDiv data-testid="main-row-type-div">
          <StyledType data-testid="main-row-type">{type}</StyledType>
        </StyledMainRowTypeDiv>
      </StyledMainRowDiv>
      {active && (
        <>
          {/* children area */}
          {children}
        </>
      )}
    </StyledMainDiv>
  );
};

export default CollapsibleRow;

interface MainRowProps {
  active: boolean;
}

const StyledMainDiv = styled.div`
  width: 100%;

  background-color: white;
  border-radius: 4px 4px 4px 4px;
`;

const StyledMainRowDiv = styled.div<MainRowProps>`
  width: 100%;

  display: flex;
  border-radius: ${(props) => (props.active ? "4px 4px 0px 0px" : "4px")};
  background-color: white;
  cursor: pointer;
  border-bottom: 1px solid #f6f6f6;

  div:first-child {
    width: 46px;
    text-align: left;
    border-top: none;

    @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
      text-align: center;
    }
  }

  div:nth-child(2) {
    width: Calc(100% - 46px);
  }
`;

const StyledMainRowChevronDiv = styled.div`
  text-align: left;
  width: 200px;

  padding-top: 11px;
  padding-bottom: 10px;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    padding-left: 11px;
  }
`;

const StyledChevron = styled.img<MainRowProps>`
  width: ${(props) => (props.active ? "13px" : "7.43px")};
`;

const StyledMainRowTitleDiv = styled.div`
  text-align: left;
  width: 200px;

  padding-top: 11px;
  padding-bottom: 11px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h2 {
    margin: 0;
    font-weight: normal;
  }
`;

const StyledTitle = styled.h2`
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
`;

const StyledMainRowTypeDiv = styled.div`
  text-align: right;
  width: 200px;

  padding-top: 9px;
  padding-bottom: 13px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    padding-right: 20px;
  }

  h2 {
    margin: 0;
    font-weight: normal;
  }
`;

const StyledType = styled.h2`
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
`;
