import React, { useContext } from "react";
import styled from "styled-components";
import { IPF2200SBFields } from "../../../types/controlUnitConfig";
import Checkbox from "./Checkbox";
import UserContext from "../../../stores/users/UserContext";

interface PF2200SBInputAreaProps {
  dataTestname: string;
  liveChartData: IPF2200SBFields[];
  historicalChartData: IPF2200SBFields[];
  handleToggle: Function;
}

const PF2200SBInputArea: React.FC<PF2200SBInputAreaProps> = ({
  dataTestname,
  liveChartData,
  historicalChartData,
  handleToggle,
}) => {
  const userContext = useContext(UserContext);

  return (
    <StyledMainDiv data-testname={dataTestname} data-testid="main-div">
      <StyledInstructionText>
        Select the elements to display on the charts page from the lists below.
      </StyledInstructionText>
      <StyledInstructionSubText>
        Please select at least one option from Live Data and one option from
        Historical Data.
      </StyledInstructionSubText>
      <StyledCheckboxGroupsDiv>
        <StyledCheckboxGroupDiv data-testid="live-div">
          <StyledCheckboxGroupHeader>Live Data</StyledCheckboxGroupHeader>
          {liveChartData.map((liveChartDatum, index) => (
            <Checkbox
              dataTestname="single-control-unit-live-data-checkbox"
              key={"live" + index}
              arrayLetter={"L"}
              handleChange={(e: any) => handleToggle(e, "L")}
              isChecked={liveChartDatum.active}
              label={liveChartDatum.name}
              readOnly={
                !(
                  userContext.permissions?.profire_manage_charts! ||
                  userContext.permissions?.external_manage_charts!
                )
              }
            />
          ))}
        </StyledCheckboxGroupDiv>
        <StyledCheckboxGroupDiv data-testid="historical-div">
          <StyledCheckboxGroupHeader>Historical Data</StyledCheckboxGroupHeader>
          {historicalChartData.map((historicalChartDatum, index) => (
            <Checkbox
              dataTestname="single-control-unit-historical-data-checkbox"
              key={"historical" + index}
              arrayLetter={"H"}
              handleChange={(e: any) => handleToggle(e, "H")}
              isChecked={historicalChartDatum.active}
              label={historicalChartDatum.name}
              readOnly={
                !(
                  userContext.permissions?.profire_manage_charts! ||
                  userContext.permissions?.external_manage_charts!
                )
              }
            />
          ))}
        </StyledCheckboxGroupDiv>
      </StyledCheckboxGroupsDiv>
    </StyledMainDiv>
  );
};

export default PF2200SBInputArea;

const StyledMainDiv = styled.div`
  width: Calc(100%);
  background-color: #ffffff;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    width: Calc(100% - (46px * 2));
    padding: 16px 46px 15px 46px;
  }
`;

const StyledInstructionText = styled.p`
  text-align: left;
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
  margin: 0px;
  width: 100%;
  margin-bottom: 5px;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    margin-bottom: 0px;
  }
`;

const StyledInstructionSubText = styled.p`
  text-align: left;
  font-size: ${(props) => props.theme.contentMapSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
  color: ${(props) => props.theme.subLabelTextColor};
  margin: 0px;
  margin-bottom: 10px;
  width: 100%;
`;

const StyledCheckboxGroupHeader = styled.h2`
  width: 100%;
  text-align: left;
  font-size: ${(props) => props.theme.headingSecondarySize};
  font-weight: ${(props) => props.theme.headingSecondaryWeight};
  margin: 0px;
  margin-bottom: 23px;
`;

const StyledCheckboxGroupsDiv = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    display: flex;
    flex-direction: row;
  }
`;

const StyledCheckboxGroupDiv = styled.div`
  width: 100%;
  margin-bottom: 15px;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    width: 50%;
    margin-bottom: 0px;
  }
`;
