import React, { useContext, useState } from "react";
import styled from "styled-components";
import { ISiteCreateDTO } from "../../types/sites";
import { validateTextString } from "../../utilities/validationFunctions";
import Banner from "../common/headers/Banner";
import UserContext from "../../stores/users/UserContext";
import CommonInput from "../common/formInputs/CommonInput";
import ProfireButton from "../common/buttons/ProfireButton";
import ModalBox from "../common/modals/ModalBox";

interface AddSiteProps {
  active: boolean;
  companyId: number;
  onCancel?: () => void;
  onAdd: Function;
  retainedData: ISiteCreateDTO | undefined;
}

let existingErrors: Boolean = false;

const AddSite: React.FC<AddSiteProps> = ({
  active,
  companyId,
  onCancel,
  onAdd,
  retainedData,
}) => {
  const userContext = useContext(UserContext);

  let headingText: string = "Insufficient Access";
  let text: string =
    "You do not have sufficient access to use this page. If you believe this is an error, please contact technical support.";

  const signedFloatRegex = /^[-]?[0-9]*\.?[0-9]+$/;

  const checkIfAcceptedSignedFloat = (value: string) => {
    const readValue: string = value.charAt(0) === "-" ? value.slice(1) : value;

    if (
      signedFloatRegex.test(readValue) ||
      readValue.slice(-1) === "." ||
      readValue === ""
    ) {
      if (readValue.match(/\./g)?.length! > 1) {
        return false;
      }
      return true;
    }
    return false;
  };

  const [facilityName, setFacilityName] = useState<string>(
    retainedData === undefined ? "" : retainedData.facility_name
  );
  const [facilityNameErrors, setFacilityNameErrors] = useState<string>("");
  const handleFacilityName = (e: React.FormEvent<HTMLInputElement>) => {
    if (
      !validateTextString(e.currentTarget.value) &&
      e.currentTarget.value !== ""
    ) {
      setFacilityName(e.currentTarget.value);
      setFacilityNameErrors(
        "First character must be alphanumeric and only alphanumeric, spaces, and the following are supported after: | _ : , . # - / '"
      );
    } else {
      setFacilityName(e.currentTarget.value);
      setFacilityNameErrors("");
    }
  };

  const [facilityAddress, setFacilityAddress] = useState<string>(
    retainedData === undefined ? "" : retainedData.facility_address
  );
  const [facilityAddressErrors, setFacilityAddressErrors] =
    useState<string>("");
  const handleFacilityAddress = (e: React.FormEvent<HTMLInputElement>) => {
    if (
      !validateTextString(e.currentTarget.value) &&
      e.currentTarget.value !== ""
    ) {
      setFacilityAddress(e.currentTarget.value);
      setFacilityAddressErrors(
        "First character must be alphanumeric and only alphanumeric, spaces, and the following are supported after: | _ : , . # - / '"
      );
    } else {
      setFacilityAddress(e.currentTarget.value);
      setFacilityAddressErrors("");
    }
  };

  const [longitude, setLongitude] = useState<string | null>(
    retainedData === undefined
      ? null
      : retainedData.longitude === null || isNaN(retainedData.longitude)
      ? null
      : retainedData.longitude.toString()
  );
  const [longitudeErrors, setLongitudeErrors] = useState<string>("");

  const handleLongitude = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    if (
      checkIfAcceptedSignedFloat(value) &&
      ((parseFloat(value) <= 180.0 && parseFloat(value) >= -180.0) ||
        value === "-" ||
        value === "")
    ) {
      setLongitude(value === "" ? null : value);
      setLongitudeErrors("");
    } else {
      setLongitudeErrors(
        "Only numbers with decimals are accepted (-180 to 180)."
      );
    }
  };

  const [latitude, setLatitude] = useState<string | null>(
    retainedData === undefined
      ? null
      : retainedData.latitude === null || isNaN(retainedData.latitude)
      ? null
      : retainedData.latitude.toString()
  );
  const [latitudeErrors, setLatitudeErrors] = useState<string>("");

  const handleLatitude = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    if (
      checkIfAcceptedSignedFloat(value) &&
      ((parseFloat(value) <= 90.0 && parseFloat(value) >= -90.0) ||
        value === "-" ||
        value === "")
    ) {
      setLatitude(value === "" ? null : value);
      setLatitudeErrors("");
    } else {
      setLatitudeErrors("Only numbers with decimals are accepted (-90 to 90).");
    }
  };

  function handleCancel() {
    existingErrors = false;
    onCancel!();
  }

  function handleExistingErrors(errors: Boolean) {
    existingErrors = errors;
  }

  function clearErrors() {
    setFacilityNameErrors("");
    setFacilityAddressErrors("");
    setLatitudeErrors("");
    setLongitudeErrors("");

    handleExistingErrors(false);
  }

  function ErrorHandler() {
    clearErrors();

    if (!facilityName) {
      setFacilityNameErrors("Facility Name is required\n");
      handleExistingErrors(true);
    }

    if (facilityName) {
      if (!validateTextString(facilityName)) {
        setFacilityNameErrors(
          "First character must be alphanumeric and only alphanumeric, spaces, and the following are supported after: | _ : , . # - / '"
        );
        handleExistingErrors(true);
      }
    }

    if (!facilityAddress) {
      setFacilityAddressErrors("Facility Address is required\n");
      handleExistingErrors(true);
    }

    if (facilityAddress) {
      if (!validateTextString(facilityAddress)) {
        setFacilityAddressErrors(
          "First character must be alphanumeric and only alphanumeric, spaces, and the following are supported after: | _ : , . # - / '"
        );
        handleExistingErrors(true);
      }
    }

    if (longitude) {
      if (longitude === "-") {
        setLongitudeErrors("Invalid longitude value.");
        handleExistingErrors(true);
      }
    }

    if (latitude) {
      if (latitude === "-") {
        setLatitudeErrors("Invalid latitude value.");
        handleExistingErrors(true);
      }
    }
  }

  function onSubmit(e: any) {
    e.preventDefault();

    if (!existingErrors) {
      const newSite: ISiteCreateDTO = {
        company_id: companyId,
        facility_name: facilityName,
        facility_address: facilityAddress,
        longitude: parseFloat(longitude!),
        latitude: parseFloat(latitude!),
      };
      onAdd(newSite);
    }
  }

  return (
    <ModalBox
      dataTestname="add-site-modal-box"
      active={active}
      desktopContainerMinWidth={"765px"}
      mobileContainerMinWidth={"320px"}
      containerTitle="Add Site"
    >
      <StyledAddSiteDiv>
        {userContext.permissions?.profire_manage_sites ? (
          <StyledMainDiv data-testid="main-div">
            <StyledForm onSubmit={onSubmit}>
              <StyledSectionDiv>
                <CommonInput
                  dataTestname="add-site-facility-name-common-input"
                  type={"text"}
                  labelText={"Facility Name"}
                  required={true}
                  readOnly={false}
                  value={facilityName}
                  onChange={handleFacilityName}
                  maxLength={50}
                  placeholder={"Facility Name"}
                  errors={facilityNameErrors}
                  autoFocus={true}
                />
                <CommonInput
                  dataTestname="add-site-facility-address-common-input"
                  type={"text"}
                  labelText={"Facility Address"}
                  required={true}
                  readOnly={false}
                  value={facilityAddress}
                  onChange={handleFacilityAddress}
                  maxLength={50}
                  placeholder={"Facility Address"}
                  errors={facilityAddressErrors}
                />
                <CommonInput
                  dataTestname="add-site-logitude-common-input"
                  type={"text"}
                  labelText={"Longitude"}
                  required={false}
                  readOnly={false}
                  value={longitude === null ? "" : longitude.toString()}
                  onChange={handleLongitude}
                  placeholder={"Longitude"}
                  errors={longitudeErrors}
                  subLabelText={
                    "Both Latitude and Longitude must be provided for the site to display on the map"
                  }
                />
                <CommonInput
                  dataTestname="add-site-latitude-common-input"
                  type={"text"}
                  labelText={"Latitude"}
                  required={false}
                  readOnly={false}
                  value={latitude === null ? "" : latitude.toString()}
                  onChange={handleLatitude}
                  placeholder={"Latitude"}
                  errors={latitudeErrors}
                  subLabelText={
                    "Both Latitude and Longitude must be provided for the site to display on the map"
                  }
                />
                {existingErrors && (
                  <StyledErrorMessage data-testid="submit-errors">
                    {"Please fix above errors and resubmit."}
                  </StyledErrorMessage>
                )}
                <StyledButtonRow>
                  <StyledCancelButtonHolder>
                    <ProfireButton
                      dataTestname="add-site-cancel-button"
                      text="Cancel"
                      onClickFunction={handleCancel}
                    />
                  </StyledCancelButtonHolder>
                  <StyledSaveButtonHolder>
                    <ProfireButton
                      dataTestname="add-site-save-button"
                      text="Save"
                      onClickFunction={ErrorHandler}
                      defaultButton={true}
                    />
                  </StyledSaveButtonHolder>
                </StyledButtonRow>
              </StyledSectionDiv>
            </StyledForm>
          </StyledMainDiv>
        ) : (
          <StyledMainDiv data-testid="main-div">
            <div data-testid="banner-div">
              <Banner
                dataTestname="add-site-banner"
                headingtext={headingText}
                text={text}
                redBox={true}
              />
            </div>
          </StyledMainDiv>
        )}
      </StyledAddSiteDiv>
    </ModalBox>
  );
};

export default AddSite;

const StyledAddSiteDiv = styled.div`
  width: calc(100% - 10px);
  height: 100%;
  overflow-y: auto;
  padding-right: 10px;
`;

const StyledMainDiv = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledErrorMessage = styled.div`
  text-align: left;
  color: #9c1b30;
  font-size: ${(props) => props.theme.contentErrorSize};
  font-weight: ${(props) => props.theme.contentErrorWeight};

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
`;

const StyledForm = styled.form``;

const StyledSectionDiv = styled.div`
  width: Calc(100%);
  background-color: #ffffff;
  border-radius: 10px;
  text-align: left;
`;

const StyledButtonRow = styled.div`
  width: 100%;
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

const StyledCancelButtonHolder = styled.div`
  margin-right: 20px;
`;

const StyledSaveButtonHolder = styled.div``;
