import { useReducer } from "react";
import { ActionTypes, ILookupContext, ILookupState } from "./types";
import LookupReducer from "./LookupReducer";
import axios from "axios";
import LookupContext from "./LookupContext";
import { getToken } from "../../utilities/msTokenFunc";

const LookupProvider: React.FC<ILookupContext> = (props) => {
  const initialState: ILookupState = {
    controlUnitTypes: [],
    monitorUnitTypes: [],
    fuelGasTypes: [],
    processUnitTypes: [],
    loading: true,
  };

  const [state, dispatch] = useReducer(LookupReducer, initialState);

  const setControlUnitTypes = async () => {
    try {
      dispatch({ type: ActionTypes.SENDING_REQUEST });
      const token: string = await getToken();
      await axios
        .get(
          `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/lookup/controlunittype`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) =>
          dispatch({
            type: ActionTypes.SET_CONTROL_UNIT_TYPES,
            payload: res.data,
          })
        );
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    } catch (error) {
      dispatch({ type: ActionTypes.SET_CONTROL_UNIT_TYPES, payload: [] });
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    }
  };

  const setMonitorUnitTypes = async () => {
    try {
      dispatch({ type: ActionTypes.SENDING_REQUEST });
      const token: string = await getToken();
      await axios
        .get(
          `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/lookup/monitorunittype`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) =>
          dispatch({
            type: ActionTypes.SET_MONITOR_UNIT_TYPES,
            payload: res.data,
          })
        );
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    } catch (error) {
      dispatch({ type: ActionTypes.SET_MONITOR_UNIT_TYPES, payload: [] });
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    }
  };

  const setFuelGasTypes = async () => {
    try {
      dispatch({ type: ActionTypes.SENDING_REQUEST });
      const token: string = await getToken();
      await axios
        .get(
          `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/lookup/fuelgastype`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) =>
          dispatch({ type: ActionTypes.SET_FUEL_GAS_TYPES, payload: res.data })
        );
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    } catch (error) {
      dispatch({ type: ActionTypes.SET_FUEL_GAS_TYPES, payload: [] });
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    }
  };

  const setProcessUnitTypes = async () => {
    try {
      dispatch({ type: ActionTypes.SENDING_REQUEST });
      const token: string = await getToken();
      await axios
        .get(
          `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/lookup/processunittype`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) =>
          dispatch({
            type: ActionTypes.SET_PROCESS_UNIT_TYPES,
            payload: res.data,
          })
        );
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    } catch (error) {
      dispatch({ type: ActionTypes.SET_PROCESS_UNIT_TYPES, payload: [] });
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    }
  };

  return (
    <LookupContext.Provider
      value={{
        controlUnitTypes: state.controlUnitTypes,
        monitorUnitTypes: state.monitorUnitTypes,
        fuelGasTypes: state.fuelGasTypes,
        processUnitTypes: state.processUnitTypes,
        loading: state.loading,
        setControlUnitTypes: setControlUnitTypes,
        setMonitorUnitTypes: setMonitorUnitTypes,
        setFuelGasTypes: setFuelGasTypes,
        setProcessUnitTypes: setProcessUnitTypes,
      }}
    >
      {props.children}
    </LookupContext.Provider>
  );
};

export default LookupProvider;
