import React from "react";
import styled from "styled-components";
import StatusGoodIcon from "../../../assets/images/sites/green-status-circle.svg";
import StatusBadIcon from "../../../assets/images/sites/red-status-circle.svg";

// state prop has the following categories:
// *** if state prop is not provided, the component will default to state Normal
//    Normal
//    Pending
//    Tripped

// this component should be imported as a child of a div, that div should ONLY affect the button positioning.
// *** do not try to change the width or height of this button using that div

interface ProfireButtonProps {
  dataTestname: string;
  onClickFunction?: () => void;
  disabled?: boolean;
  state?: string | null;
  element: string;
}

const ProfireButton: React.FC<ProfireButtonProps> = ({
  dataTestname,
  onClickFunction,
  disabled,
  state,
  element,
}) => {
  return (
    <StyledButtonContainer>
      <StyledStatusDot
        src={state === "Tripped" ? StatusBadIcon : StatusGoodIcon}
        alt="status good icon"
        element={element}
      />
      <StyledButton
        data-testname={dataTestname}
        disabled={disabled}
        className="btn"
        data-testid="reusable-button"
        onClick={onClickFunction}
        state={state ? state : "Normal"}
        element={element}
      >
        <StyledButtonText>{state ? state : "Normal"}</StyledButtonText>
      </StyledButton>
    </StyledButtonContainer>
  );
};

export default ProfireButton;

const StyledButtonContainer = styled.div``;

interface StatusDotProps {
  element: string;
}

const StyledStatusDot = styled.img<StatusDotProps>`
  display: ${(props) => (props.element === "StateMorph" ? "flex" : "none")};

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    display: none;
  }
`;

interface StyledProfireButton {
  state: string;
  element: string;
}

const StyledButton = styled.button<StyledProfireButton>`
  display: ${(props) =>
    props.element === "StateMorph" ? "none" : "inline-block"};

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    display: inline-block;
  }
  background-color: ${(props) =>
    props.state === "Pending"
      ? "#E5AA36"
      : props.state === "Tripped"
      ? "#9C1B30"
      : props.state === "Normal"
      ? "#348343"
      : (props.state === "No Data" || props.state === "Creating") && "#3d71d9"};
  border: ${(props) =>
    props.state === "Pending"
      ? "1px solid #E5AA36"
      : props.state === "Tripped"
      ? "1px solid #9C1B30"
      : props.state === "Normal"
      ? "1px solid #348343"
      : (props.state === "No Data" || props.state === "Creating") &&
        "1px solid #3d71d9"};
  color: white;
  border-radius: 2px;
  padding: 5px 16px 5px 16px;
  height: 30px;
  width: 91px;

  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.headingSecondaryWeight};
`;

const StyledButtonText = styled.strong`
  font-size: ${(props) => props.theme.buttonTextSize};
  font-weight: ${(props) => props.theme.buttonTextWeight};

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    font-size: ${(props) => props.theme.buttonTextSize};
    font-weight: ${(props) => props.theme.buttonTextWeight};
  }
`;
