import { IDailyAggregate } from "../../types/dailyAggregates";
import { ActionTypes, IDailyAggregateState } from "./types";

type Action =
  | { type: ActionTypes.SET_DAILY_AGGREGATES; payload: IDailyAggregate[] }
  | { type: ActionTypes.SENDING_REQUEST }
  | { type: ActionTypes.REQUEST_FINISHED };

const reducer = (state: IDailyAggregateState, action: Action) => {
  switch (action.type) {
    case ActionTypes.SET_DAILY_AGGREGATES:
      return {
        ...state,
        dailyAggregates: action.payload.sort(
          (a, b) =>
            Date.parse(a.date.toString()) - Date.parse(b.date.toString())
        ),
      };
    case ActionTypes.SENDING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.REQUEST_FINISHED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
