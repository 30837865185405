import {
  IControlUnit,
  IControlUnitAllInclusive,
} from "../../types/controlUnits";
import { ActionTypes, IControlUnitState } from "./types";

type Action =
  | { type: ActionTypes.SET_CONTROL_UNITS; payload: IControlUnit[] }
  | {
      type: ActionTypes.SET_CONTROL_UNIT;
      payload: IControlUnitAllInclusive | null;
    }
  | {
      type: ActionTypes.SET_CONTROL_UNIT_BELONGS_TO_USER_COMPANY;
      payload: boolean;
    }
  | { type: ActionTypes.SENDING_REQUEST }
  | { type: ActionTypes.REQUEST_FINISHED };

const reducer = (state: IControlUnitState, action: Action) => {
  switch (action.type) {
    case ActionTypes.SET_CONTROL_UNITS:
      return {
        ...state,
        controlUnits: action.payload,
      };
    case ActionTypes.SET_CONTROL_UNIT:
      return {
        ...state,
        controlUnit: action.payload,
      };
    case ActionTypes.SET_CONTROL_UNIT_BELONGS_TO_USER_COMPANY:
      return {
        ...state,
        controlUnitBelongsToUserCompany: action.payload,
      };
    case ActionTypes.SENDING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.REQUEST_FINISHED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
