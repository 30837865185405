import { createContext } from "react";
import { IRoleContext } from "./types";

const defaultRoleContext: IRoleContext = {
  roles: [],
  loading: true,
  setRoles: () => null,
};

const RoleContext = createContext<IRoleContext>(defaultRoleContext);

export default RoleContext;
