import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Dropdown from "../common/formInputs/Dropdown";
import { IProcessUnitCreateDTO } from "../../types/processUnits";
import LookupContext from "../../stores/lookup/LookupContext";
import { validateTextString } from "../../utilities/validationFunctions";
import UserContext from "../../stores/users/UserContext";
import Banner from "../common/headers/Banner";
import CommonInput from "../common/formInputs/CommonInput";
import ProfireButton from "../common/buttons/ProfireButton";
import ModalBox from "../common/modals/ModalBox";
import { dateToStringNoTime } from "../../utilities/timeFunctions";

interface AddProcessUnitProps {
  active: boolean;
  siteId: number;
  onCancel?: () => void;
  onAdd: Function;
  retainedData: IProcessUnitCreateDTO | undefined;
}

let existingErrors: Boolean = false;

const AddProcessUnit: React.FC<AddProcessUnitProps> = ({
  active,
  siteId,
  onCancel,
  onAdd,
  retainedData,
}) => {
  const userContext = useContext(UserContext);
  const lookupContext = useContext(LookupContext);
  const lookupContextRef = useRef(lookupContext).current;

  let headingText: string = "Insufficient Access";
  let text: string =
    "You do not have sufficient access to use this page. If you believe this is an error, please contact technical support.";

  const integerRegex = /^[0-9\b]+$/;
  const floatRegex = /^[0-9]*\.?[0-9]+$/;

  const checkIfAcceptedFloat = (value: string) => {
    if (floatRegex.test(value) || value.slice(-1) === "." || value === "") {
      if (value.match(/\./g)?.length! > 1) {
        return false;
      }
      return true;
    }
    return false;
  };

  const checkIfAcceptedInteger = (value: string) => {
    return integerRegex.test(value) || value === "" ? true : false;
  };

  useEffect(() => {
    lookupContextRef.setFuelGasTypes();
    lookupContextRef.setProcessUnitTypes();
  }, [lookupContextRef]);

  const [chosenProcessUnitType, setChosenProcessUnitType] = useState<number>(
    retainedData === undefined ? 0 : retainedData.process_unit_type_id
  );
  function updateChosenProcessUnitType(newChosen: string) {
    setChosenProcessUnitType(parseInt(newChosen));
    setChosenProcessUnitTypeErrors("");
  }
  let processUnitTypeArray: [string, string][] = [];
  //populate processUnitTypeArray for dropdown
  processUnitTypeArray.push(["Select an appliance type...", "0"]);
  for (let index = 0; index < lookupContext.processUnitTypes.length; index++) {
    processUnitTypeArray.push([
      lookupContext.processUnitTypes[index].type,
      lookupContext.processUnitTypes[index].id.toString(),
    ]);
  }

  const [chosenFuelGasType, setChosenFuelGasType] = useState<number>(
    retainedData === undefined ? 0 : retainedData.fuel_gas_type_id
  );
  function updateChosenFuelGasType(newChosen: string) {
    setChosenFuelGasType(parseInt(newChosen));
    setChosenFuelGasTypeErrors("");
  }
  let fuelGasTypeArray: [string, string][] = [];
  //populate fuelGasTypeArray for dropdown
  fuelGasTypeArray.push(["Select a fuel gas type...", "0"]);
  for (let index = 0; index < lookupContext.fuelGasTypes.length; index++) {
    fuelGasTypeArray.push([
      lookupContext.fuelGasTypes[index].type,
      lookupContext.fuelGasTypes[index].id.toString(),
    ]);
  }

  const [chosenProcessUnitTypeErrors, setChosenProcessUnitTypeErrors] =
    useState<string>("");

  const [chosenFuelGasTypeErrors, setChosenFuelGasTypeErrors] =
    useState<string>("");

  const [vesselManufacturer, setVesselManufacturer] = useState<string>(
    retainedData === undefined || retainedData.vessel_manufacturer === null
      ? ""
      : retainedData.vessel_manufacturer
  );

  const [vesselManufacturerErrors, setVesselManufacturerErrors] =
    useState<string>("");
  const [vesselModel, setVesselModel] = useState<string>(
    retainedData === undefined || retainedData.vessel_model === null
      ? ""
      : retainedData.vessel_model
  );

  const [vesselModelErrors, setVesselModelErrors] = useState<string>("");

  const [vesselRatedMaxBTU, setVesselRatedMaxBTU] = useState<string | null>(
    retainedData === undefined || retainedData.vessel_rated_max_btu === null
      ? null
      : retainedData.vessel_rated_max_btu.toString()
  );

  const [vesselRatedMaxBTUErrors, setVesselRatedMaxBTUErrors] =
    useState<string>("");

  const [burnerManufacturer, setBurnerManufacturer] = useState<string>(
    retainedData === undefined || retainedData.burner_manufacturer === null
      ? ""
      : retainedData.burner_manufacturer
  );

  const [burnerManufacturerErrors, setBurnerManufacturerErrors] =
    useState<string>("");

  const [burnerModel, setBurnerModel] = useState<string>(
    retainedData === undefined || retainedData.burner_model === null
      ? ""
      : retainedData.burner_model
  );

  const [burnerModelErrors, setBurnerModelErrors] = useState<string>("");

  const [burnerRatedMaxBTU, setBurnerRatedMaxBTU] = useState<string | null>(
    retainedData === undefined || retainedData.burner_rated_max_btu === null
      ? null
      : retainedData.burner_rated_max_btu.toString()
  );

  const [burnerRatedMaxBTUErrors, setBurnerRatedMaxBTUErrors] =
    useState<string>("");

  const [dateLastServiced, setDateLastServiced] = useState<string>(
    retainedData === undefined || retainedData.last_service_date === null
      ? ""
      : dateToStringNoTime(retainedData.last_service_date)
  );

  const [dateLastServicedErrors, setDateLastServicedErrors] =
    useState<string>("");

  const [tag, setTag] = useState<string>(
    retainedData === undefined || retainedData.tag === null
      ? ""
      : retainedData.tag
  );

  const [tagErrors, setTagErrors] = useState<string>("");

  const [fuelGasBTU, setFuelGasBTU] = useState<string | null>(
    retainedData === undefined || retainedData.fuel_gas_btu === null
      ? "1050"
      : retainedData.fuel_gas_btu.toString()
  );

  const [fuelGasBTUErrors, setFuelGasBTUErrors] = useState<string>("");

  const [burnerHighFirePressure, setBurnerHighFirePressure] = useState<
    string | null
  >(
    retainedData === undefined ||
      retainedData.burner_high_fire_pressure === null
      ? null
      : retainedData.burner_high_fire_pressure.toString()
  );

  const [burnerHighFirePressureErrors, setBurnerHighFirePressureErrors] =
    useState<string>("");

  const [burnerLowFirePressure, setBurnerLowFirePressure] = useState<
    string | null
  >(
    retainedData === undefined || retainedData.burner_low_fire_pressure === null
      ? null
      : retainedData.burner_low_fire_pressure.toString()
  );

  const [burnerLowFirePressureErrors, setBurnerLowFirePressureErrors] =
    useState<string>("");

  const [burnerOrificeDiameter, setBurnerOrificeDiameter] = useState<
    string | null
  >(
    retainedData === undefined || retainedData.burner_orifice_diameter === null
      ? null
      : retainedData.burner_orifice_diameter.toString()
  );

  const [burnerOrificeDiameterErrors, setBurnerOrificeDiameterErrors] =
    useState<string>("");

  const handleVesselManufacturer = (e: React.FormEvent<HTMLInputElement>) => {
    if (
      !validateTextString(e.currentTarget.value) &&
      e.currentTarget.value !== ""
    ) {
      setVesselManufacturer(e.currentTarget.value);
      setVesselManufacturerErrors(
        "First character must be alphanumeric and only alphanumeric, spaces, and the following are supported after: | _ : , . # - / '"
      );
    } else {
      setVesselManufacturer(e.currentTarget.value);
      setVesselManufacturerErrors("");
    }
  };

  const handleVesselModel = (e: React.FormEvent<HTMLInputElement>) => {
    if (
      !validateTextString(e.currentTarget.value) &&
      e.currentTarget.value !== ""
    ) {
      setVesselModel(e.currentTarget.value);
      setVesselModelErrors(
        "First character must be alphanumeric and only alphanumeric, spaces, and the following are supported after: | _ : , . # - / '"
      );
    } else {
      setVesselModel(e.currentTarget.value);
      setVesselModelErrors("");
    }
  };

  const handleVesselRatedMaxBTU = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    if (checkIfAcceptedInteger(value)) {
      setVesselRatedMaxBTU(value);
      setVesselRatedMaxBTUErrors("");
    } else {
      setVesselRatedMaxBTUErrors("Only whole numbers are accepted.");
    }
  };

  const handleBurnerManufacturer = (e: React.FormEvent<HTMLInputElement>) => {
    if (
      !validateTextString(e.currentTarget.value) &&
      e.currentTarget.value !== ""
    ) {
      setBurnerManufacturer(e.currentTarget.value);
      setBurnerManufacturerErrors(
        "First character must be alphanumeric and only alphanumeric, spaces, and the following are supported after: | _ : , . # - / '"
      );
    } else {
      setBurnerManufacturer(e.currentTarget.value);
      setBurnerManufacturerErrors("");
    }
  };

  const handleBurnerModel = (e: React.FormEvent<HTMLInputElement>) => {
    if (
      !validateTextString(e.currentTarget.value) &&
      e.currentTarget.value !== ""
    ) {
      setBurnerModel(e.currentTarget.value);
      setBurnerModelErrors(
        "First character must be alphanumeric and only alphanumeric, spaces, and the following are supported after: | _ : , . # - / '"
      );
    } else {
      setBurnerModel(e.currentTarget.value);
      setBurnerModelErrors("");
    }
  };

  const handleBurnerRatedMaxBTU = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    if (checkIfAcceptedInteger(value)) {
      setBurnerRatedMaxBTU(value);
      setBurnerRatedMaxBTUErrors("");
    } else {
      setBurnerRatedMaxBTUErrors("Only whole numbers are accepted.");
    }
  };

  const handleDateLastServiced = (e: React.FormEvent<HTMLInputElement>) => {
    setDateLastServiced(e.currentTarget.value);
    setDateLastServicedErrors("");
  };

  const handleTag = (e: React.FormEvent<HTMLInputElement>) => {
    if (
      !validateTextString(e.currentTarget.value) &&
      e.currentTarget.value !== ""
    ) {
      setTag(e.currentTarget.value);
      setTagErrors(
        "First character must be alphanumeric and only alphanumeric, spaces, and the following are supported after: | _ : , . # - / '"
      );
    } else {
      setTag(e.currentTarget.value);
      setTagErrors("");
    }
  };

  const handleFuelGasBTU = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    if (checkIfAcceptedInteger(value)) {
      setFuelGasBTU(value);
      setFuelGasBTUErrors("");
    } else {
      setFuelGasBTUErrors("Only whole numbers are accepted.");
    }
  };

  const handleBurnerHighFirePressure = (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    const { value } = e.currentTarget;

    if (checkIfAcceptedFloat(value)) {
      setBurnerHighFirePressure(value);
      setBurnerHighFirePressureErrors("");
    } else {
      setBurnerHighFirePressureErrors("Only numbers are accepted.");
    }
  };

  const handleBurnerLowFirePressure = (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    const { value } = e.currentTarget;

    if (checkIfAcceptedFloat(value)) {
      setBurnerLowFirePressure(value);
      setBurnerLowFirePressureErrors("");
    } else {
      setBurnerLowFirePressureErrors("Only numbers are accepted.");
    }
  };

  const handleBurnerOrificeDiameter = (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    const { value } = e.currentTarget;

    if (checkIfAcceptedFloat(value)) {
      setBurnerOrificeDiameter(value);
      setBurnerOrificeDiameterErrors("");
    } else {
      setBurnerOrificeDiameterErrors("Only numbers are accepted.");
    }
  };

  function handleCancel() {
    existingErrors = false;
    onCancel!();
  }

  function handleExistingErrors(errors: Boolean) {
    existingErrors = errors;
  }

  function clearErrors() {
    setChosenProcessUnitTypeErrors("");
    setChosenFuelGasTypeErrors("");
    setVesselManufacturerErrors("");
    setVesselModelErrors("");
    setVesselRatedMaxBTUErrors("");
    setBurnerManufacturerErrors("");
    setBurnerModelErrors("");
    setBurnerRatedMaxBTUErrors("");
    setDateLastServicedErrors("");
    setTagErrors("");
    setBurnerHighFirePressureErrors("");
    setBurnerLowFirePressureErrors("");
    setBurnerOrificeDiameterErrors("");
    setFuelGasBTUErrors("");

    handleExistingErrors(false);
  }

  function ErrorHandler() {
    clearErrors();

    if (!chosenProcessUnitType) {
      setChosenProcessUnitTypeErrors("Appliance Type is required.\n");
      handleExistingErrors(true);
    }
    if (chosenProcessUnitType === 0) {
      setChosenProcessUnitTypeErrors("Appliance Type is required.\n");
      handleExistingErrors(true);
    }
    if (!chosenFuelGasType) {
      setChosenFuelGasTypeErrors("Fuel Gas Type is required.\n");
      handleExistingErrors(true);
    }
    if (chosenFuelGasType === 0) {
      setChosenFuelGasTypeErrors("Fuel Gas Type is required.\n");
      handleExistingErrors(true);
    }
    if (vesselManufacturer) {
      if (!validateTextString(vesselManufacturer)) {
        setVesselManufacturerErrors(
          "First character must be alphanumeric and only alphanumeric, spaces, and the following are supported after: | _ : , . # - / '"
        );
        handleExistingErrors(true);
      }
    }
    if (vesselModel) {
      if (!validateTextString(vesselModel)) {
        setVesselModelErrors(
          "First character must be alphanumeric and only alphanumeric, spaces, and the following are supported after: | _ : , . # - / '"
        );
        handleExistingErrors(true);
      }
    }
    if (burnerManufacturer) {
      if (!validateTextString(burnerManufacturer)) {
        setBurnerManufacturerErrors(
          "First character must be alphanumeric and only alphanumeric, spaces, and the following are supported after: | _ : , . # - / '"
        );
        handleExistingErrors(true);
      }
    }
    if (burnerModel) {
      if (!validateTextString(burnerModel)) {
        setBurnerModelErrors(
          "First character must be alphanumeric and only alphanumeric, spaces, and the following are supported after: | _ : , . # - / '"
        );
        handleExistingErrors(true);
      }
    }
    if (!tag) {
      setTagErrors("Tag is required.\n");
      handleExistingErrors(true);
    }
    if (tag) {
      if (!validateTextString(tag)) {
        setTagErrors(
          "First character must be alphanumeric and only alphanumeric, spaces, and the following are supported after: | _ : , . # - / '"
        );
        handleExistingErrors(true);
      }
    }
  }

  function onSubmit(e: any) {
    e.preventDefault();

    if (!existingErrors) {
      const newProcessUnit: IProcessUnitCreateDTO = {
        site_id: siteId,
        process_unit_type_id: chosenProcessUnitType,
        fuel_gas_type_id: chosenFuelGasType,
        vessel_manufacturer: vesselManufacturer,
        vessel_model: vesselModel,
        vessel_rated_max_btu: parseInt(vesselRatedMaxBTU!),
        burner_manufacturer: burnerManufacturer,
        burner_model: burnerModel,
        burner_rated_max_btu: parseInt(burnerRatedMaxBTU!),
        last_service_date:
          dateLastServiced === "" ? null : new Date(dateLastServiced),
        tag: tag,
        fuel_gas_btu: parseInt(fuelGasBTU!),
        burner_high_fire_pressure: parseFloat(burnerHighFirePressure!),
        burner_low_fire_pressure: parseFloat(burnerLowFirePressure!),
        burner_orifice_diameter: parseFloat(burnerOrificeDiameter!),
      };

      onAdd(newProcessUnit);
    }
  }

  return (
    <ModalBox
      dataTestname="add-process-unit-modal-box"
      active={active}
      desktopContainerMinWidth={"765px"}
      mobileContainerMinWidth={"320px"}
      containerTitle="Add Appliance"
    >
      <StyledAddUnitDiv>
        {userContext.permissions?.profire_manage_process_units ? (
          <StyledMainDiv data-testid="main-div">
            <StyledForm onSubmit={onSubmit}>
              <StyledSectionDiv>
                <Dropdown
                  dataTestname="add-process-unit-appliance-type-dropdown"
                  selected={chosenProcessUnitType.toString()}
                  onchange={updateChosenProcessUnitType}
                  columns={processUnitTypeArray}
                  labelText={"Appliance Type"}
                  required={true}
                  errors={chosenProcessUnitTypeErrors}
                  autoFocus={true}
                />
                <Dropdown
                  dataTestname="add-process-unit-fuel-gas-type-dropdown"
                  selected={chosenFuelGasType.toString()}
                  onchange={updateChosenFuelGasType}
                  columns={fuelGasTypeArray}
                  labelText={"Fuel Gas Type"}
                  required={true}
                  errors={chosenFuelGasTypeErrors}
                />
                <CommonInput
                  dataTestname="add-process-unit-vessel-manufacturer-common-input"
                  type={"text"}
                  labelText={"Vessel Manufacturer"}
                  required={false}
                  readOnly={false}
                  value={vesselManufacturer}
                  onChange={handleVesselManufacturer}
                  maxLength={50}
                  placeholder={"Vessel Manufacturer"}
                  errors={vesselManufacturerErrors}
                />
                <CommonInput
                  dataTestname="add-process-unit-vessel-model-common-input"
                  type={"text"}
                  labelText={"Vessel Model"}
                  required={false}
                  readOnly={false}
                  value={vesselModel}
                  onChange={handleVesselModel}
                  maxLength={50}
                  placeholder={"Vessel Model"}
                  errors={vesselModelErrors}
                />
                <CommonInput
                  dataTestname="add-process-unit-vessel-rated-max-btu-common-input"
                  type="text"
                  labelText={"Vessel Rated Max BTU"}
                  required={false}
                  readOnly={false}
                  value={
                    vesselRatedMaxBTU === null
                      ? ""
                      : vesselRatedMaxBTU.toString()
                  }
                  onChange={handleVesselRatedMaxBTU}
                  placeholder={"Vessel Rated Max BTU"}
                  errors={vesselRatedMaxBTUErrors}
                />
                <CommonInput
                  dataTestname="add-process-unit-burner-manufacturer-common-input"
                  type={"text"}
                  labelText={"Burner Manufacturer"}
                  required={false}
                  readOnly={false}
                  value={burnerManufacturer}
                  onChange={handleBurnerManufacturer}
                  maxLength={50}
                  placeholder={"Burner Manufacturer"}
                  errors={burnerManufacturerErrors}
                />
                <CommonInput
                  dataTestname="add-process-unit-burner-model-common-input"
                  type={"text"}
                  labelText={"Burner Model"}
                  required={false}
                  readOnly={false}
                  value={burnerModel}
                  onChange={handleBurnerModel}
                  maxLength={50}
                  placeholder={"Burner Model"}
                  errors={burnerModelErrors}
                />
                <CommonInput
                  dataTestname="add-process-unit-burner-rated-max-btu-common-input"
                  type="text"
                  labelText={"Burner Rated Max BTU"}
                  required={false}
                  readOnly={false}
                  value={
                    burnerRatedMaxBTU === null
                      ? ""
                      : burnerRatedMaxBTU.toString()
                  }
                  onChange={handleBurnerRatedMaxBTU}
                  placeholder={"Burner Rated Max BTU"}
                  errors={burnerRatedMaxBTUErrors}
                />
                <CommonInput
                  dataTestname="add-process-unit-date-common-input"
                  type={"date"}
                  labelText={"Date Last Serviced"}
                  required={false}
                  readOnly={false}
                  value={dateLastServiced}
                  onChange={handleDateLastServiced}
                  placeholder={"Date Last Serviced"}
                  errors={dateLastServicedErrors}
                />
                <CommonInput
                  dataTestname="add-process-unit-tag-common-input"
                  type={"text"}
                  labelText={"Tag"}
                  required={true}
                  readOnly={false}
                  value={tag}
                  onChange={handleTag}
                  maxLength={50}
                  placeholder={"Tag"}
                  errors={tagErrors}
                />
                <CommonInput
                  dataTestname="add-process-unit-fuel-gas-btu-common-input"
                  type="text"
                  labelText={"Fuel Gas BTU (BTU/SCF)"}
                  required={false}
                  readOnly={false}
                  value={fuelGasBTU === null ? "" : fuelGasBTU.toString()}
                  onChange={handleFuelGasBTU}
                  placeholder={"Fuel Gas BTU"}
                  errors={fuelGasBTUErrors}
                />
                <CommonInput
                  dataTestname="add-process-unit-burner-high-fire-pressure-common-input"
                  type="text"
                  labelText={"Burner High Fire Pressure (psig)"}
                  required={false}
                  readOnly={false}
                  value={
                    burnerHighFirePressure === null
                      ? ""
                      : burnerHighFirePressure.toString()
                  }
                  onChange={handleBurnerHighFirePressure}
                  placeholder={"Burner High Fire Pressure"}
                  errors={burnerHighFirePressureErrors}
                />
                <CommonInput
                  dataTestname="add-process-unit-burner-low-fire-pressure-common-input"
                  type="text"
                  labelText={"Burner Low Fire Pressure (psig)"}
                  required={false}
                  readOnly={false}
                  value={
                    burnerLowFirePressure === null
                      ? ""
                      : burnerLowFirePressure.toString()
                  }
                  onChange={handleBurnerLowFirePressure}
                  placeholder={"Burner Low Fire Pressure"}
                  errors={burnerLowFirePressureErrors}
                />
                <CommonInput
                  dataTestname="add-process-unit-burner-orifice-diameter-common-input"
                  type="text"
                  labelText={"Burner Orifice Diameter (in.)"}
                  required={false}
                  readOnly={false}
                  value={
                    burnerOrificeDiameter === null
                      ? ""
                      : burnerOrificeDiameter.toString()
                  }
                  onChange={handleBurnerOrificeDiameter}
                  placeholder={"Burner Orifice Diameter"}
                  errors={burnerOrificeDiameterErrors}
                />
                {existingErrors && (
                  <StyledErrorMessage data-testid="submit-errors">
                    {"Please fix above errors and resubmit."}
                  </StyledErrorMessage>
                )}
                <StyledButtonRow>
                  <StyledCancelButtonHolder>
                    <ProfireButton
                      dataTestname="add-site-cancel-button"
                      text="Cancel"
                      onClickFunction={handleCancel}
                    />
                  </StyledCancelButtonHolder>
                  <StyledSaveButtonHolder>
                    <ProfireButton
                      dataTestname="add-site-save-button"
                      text="Save"
                      onClickFunction={ErrorHandler}
                      defaultButton={true}
                    />
                  </StyledSaveButtonHolder>
                </StyledButtonRow>
              </StyledSectionDiv>
            </StyledForm>
          </StyledMainDiv>
        ) : (
          <StyledMainDiv>
            <div data-testid="banner-div">
              <Banner
                dataTestname="add-process-unit-banner"
                headingtext={headingText}
                text={text}
                redBox={true}
              />
            </div>
          </StyledMainDiv>
        )}
      </StyledAddUnitDiv>
    </ModalBox>
  );
};

export default AddProcessUnit;

const StyledAddUnitDiv = styled.div`
  width: calc(100% - 10px);
  height: 100%;
  overflow-y: auto;
  padding-right: 10px;
`;

const StyledMainDiv = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledErrorMessage = styled.div`
  text-align: left;
  color: #9c1b30;
  font-size: ${(props) => props.theme.contentErrorSize};
  font-weight: ${(props) => props.theme.contentErrorWeight};

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
`;

const StyledSectionDiv = styled.div`
  width: Calc(100%);
  background-color: #ffffff;
  border-radius: 10px;
  text-align: left;
`;

const StyledForm = styled.form``;

const StyledButtonRow = styled.div`
  width: 100%;
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

const StyledCancelButtonHolder = styled.div`
  margin-right: 20px;
`;

const StyledSaveButtonHolder = styled.div``;
