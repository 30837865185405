import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Banner from "../components/common/headers/Banner";
import Loading from "../components/common/modals/Loading";
import ControlUnitContext from "../stores/controlUnits/ControlUnitContext";
import ProcessUnitContext from "../stores/processUnits/ProcessUnitContext";
import UserContext from "../stores/users/UserContext";
import { validateTextString } from "../utilities/validationFunctions";
import { IProcessUnit, IProcessUnitUpdateDTO } from "../types/processUnits";
import Breadcrumb from "../components/common/navigation/Breadcrumbs";
import { IBreadcrumbItem } from "../types/breadcrumb";
import CommonInput from "../components/common/formInputs/CommonInput";
import DefaultContainer from "../components/common/containers/DefaultContainer";
import InfoDisplay from "../components/common/tables/InfoDisplay";
import EditButton from "../components/common/buttons/EditButton";
import DeleteButton from "../components/common/buttons/DeleteButton";
import ProfireButton from "../components/common/buttons/ProfireButton";
import {
  deleteProcessUnit,
  editProcessUnit,
  editProcessUnitTag,
  setProcessUnitBelongsToUserCompanyFromAPI,
  setProcessUnitFromAPI,
} from "../utilities/apiCalls/processUnits";
import EditProcessUnit from "../components/processUnits/EditProcessUnit";
import { IControlUnit, IControlUnitCreateDTO } from "../types/controlUnits";
import {
  addNewControlUnit,
  setControlUnitsByProcessUnitFromAPI,
} from "../utilities/apiCalls/controlUnits";
import AddControlUnit from "../components/controlUnits/AddControlUnit";
import ConfirmationBox from "../components/common/modals/ConfirmationBox";
import CommonCustomizableTable from "../components/common/tables/CommonCustomizableTable";
import {
  backendErrorCodes,
  blankProcessUnit,
} from "../utilities/staticObjects";

interface SingleProcessUnitProps {}

let existingErrors: Boolean = false;

const SingleProcessUnit: React.FC<SingleProcessUnitProps> = () => {
  const userContext = useContext(UserContext);
  const userContextRef = useRef(userContext).current;
  const processUnitContext = useContext(ProcessUnitContext);
  const processUnitContextRef = useRef(processUnitContext).current;
  const controlUnitContext = useContext(ControlUnitContext);
  const controlUnitContextRef = useRef(controlUnitContext).current;
  const params = useParams();

  const [loading, setLoading] = useState<boolean>(false);
  const [processUnitBelongsToUserCompany, setProcessUnitBelongsToUserCompany] =
    useState<boolean | null>(null);
  const [processUnit, setProcessUnit] = useState<IProcessUnit>();
  const [controlUnits, setControlUnits] = useState<IControlUnit[]>([]);

  let headingText: string = "Invalid Appliance";
  let text: string =
    "This Appliance ID is invalid. If you believe this is an error, please contact technical support.";

  useEffect(() => {
    setProcessUnitBelongsToUserCompanyFromAPI(
      parseInt(params.processUnitId!),
      userContextRef.user?.azure_id!,
      userContextRef.permissions?.profire_select_company!
    ).then((res: any) => {
      if (res.data === true) {
        setProcessUnitBelongsToUserCompany(true);

        setProcessUnitFromAPI(parseInt(params.processUnitId!), setLoading).then(
          (res: any) => {
            if (backendErrorCodes.includes(res.status)) {
              setProcessUnitBelongsToUserCompany(false);
              setProcessUnit(blankProcessUnit);
            } else {
              setProcessUnit(res.data);
              setTag(res.data.tag);
            }
          }
        );

        setControlUnitsByProcessUnitFromAPI(
          parseInt(params.processUnitId!),
          setLoading
        ).then((res: any) => {
          setControlUnits(res.data);
        });
      } else {
        //the MU does not belong to the user's company
        setProcessUnitBelongsToUserCompany(false);
        //fill MU with default data to prevent warnings
        setProcessUnit(blankProcessUnit);
      }
    });
  }, [
    userContextRef,
    processUnitContextRef,
    params.processUnitId,
    controlUnitContextRef,
  ]);

  const crumbs: IBreadcrumbItem[] = userContext.permissions
    ?.profire_select_company
    ? [
        {
          id: processUnit?.site?.company_id!,
          title: processUnit?.site?.company?.name!,
          url: userContext.permissions?.profire_select_company
            ? `/sites/${processUnit?.site?.company_id!}`
            : `/sites`,
          prefix: "Company",
        },

        {
          id: processUnit?.site?.id!,
          title: processUnit?.site?.facility_name!,
          url: `/sites/details/${processUnit?.site?.id}`,
          prefix: "Site",
        },
      ]
    : [
        {
          id: processUnit?.site?.id!,
          title: processUnit?.site?.facility_name!,
          url: `/sites/details/${processUnit?.site?.id}`,
          prefix: "Site",
        },
      ];

  const navigate = useNavigate();

  function handleExistingErrors(errors: Boolean) {
    existingErrors = errors;
  }

  //Edit Tag
  const [showEditTag, setShowEditTag] = useState<boolean>(false);
  const [editTagCBActive, setEditTagCBActive] = useState<boolean>(false);
  const [editTagCBText, setEditTagCBText] = useState<string>("");
  const [editTagCBFinalDisplay, setEditTagCBFinalDisplay] =
    useState<boolean>(false);
  const [tag, setTag] = useState<string>(processUnit?.tag!);
  const [tagErrors, setTagErrors] = useState<string>("");
  const handleTag = (e: React.FormEvent<HTMLInputElement>) => {
    if (
      !validateTextString(e.currentTarget.value) &&
      e.currentTarget.value !== ""
    ) {
      setTag(e.currentTarget.value);
      setTagErrors(
        "First character must be alphanumeric and only alphanumeric, spaces, and the following are supported after: | _ : , . # - / '"
      );
      handleExistingErrors(true);
    } else {
      setTag(e.currentTarget.value);
      setTagErrors("");
      handleExistingErrors(false);
    }
  };

  function handleShowEditTag() {
    setShowEditTag(!showEditTag);
  }

  //This function runs on clicking Save in Edit Tag
  function showEditTagConfirmationBox(e: any) {
    if (!existingErrors) {
      setEditTagCBActive(true);
      setEditTagCBText("Are you sure you want to save these changes?");
    }
  }

  //This function runs on clicking Cancel in Edit Tag
  function hideEditTagConfirmationBox(e: any) {
    setEditTagCBActive(false);
    setEditTagCBText("");
  }

  //This function runs on clicking Yes after clicking Save in Edit Tag
  function handleEditTag() {
    setEditTagCBText("");

    const update: IProcessUnitUpdateDTO = {
      id: processUnit?.id!,
      tag: tag,
      site_id: processUnit?.site_id!,
      process_unit_type_id: processUnit?.process_unit_type_id!,
      fuel_gas_type_id: processUnit?.fuel_gas_type_id!,
      vessel_manufacturer: processUnit?.vessel_manufacturer!,
      vessel_model: processUnit?.vessel_model!,
      vessel_rated_max_btu: processUnit?.vessel_rated_max_btu!,
      burner_manufacturer: processUnit?.burner_manufacturer!,
      burner_model: processUnit?.burner_model!,
      burner_rated_max_btu: processUnit?.burner_rated_max_btu!,
      last_service_date: processUnit?.last_service_date!,
      fuel_gas_btu: processUnit?.fuel_gas_btu!,
      burner_high_fire_pressure: processUnit?.burner_high_fire_pressure!,
      burner_low_fire_pressure: processUnit?.burner_low_fire_pressure!,
      burner_orifice_diameter: processUnit?.burner_orifice_diameter!,
    };

    editProcessUnitTag(update!).then((res) => {
      if (res.status === 200 || res.status === 202) {
        setEditTagCBText(`Tag successfully edited`);
        setProcessUnitAfterEdit();
      } else {
        setEditTagCBText(
          `The following issue was encountered while attempting to edit ${
            processUnit!.tag
          }:\n\n${res.data.message}`
        );
      }
    });
    setEditTagCBFinalDisplay(true);
  }

  //This function runs on clicking Ok after clicking Yes in Edit ProcessUnit
  function completeEditProcessUnitTag(e: any) {
    setEditTagCBActive(false);
    setEditTagCBText("");
    setEditTagCBFinalDisplay(false);
    handleShowEditTag();
  }

  //Edit ProcessUnit
  const [showEditProcessUnit, setShowEditProcessUnit] =
    useState<boolean>(false);
  const [editProcessUnitCBActive, setEditProcessUnitCBActive] =
    useState<boolean>(false);
  const [editProcessUnitCBText, setEditProcessUnitCBText] =
    useState<string>("");
  const [editProcessUnitCBFinalDisplay, setEditProcessUnitCBFinalDisplay] =
    useState<boolean>(false);
  //retainedEditProcessUnit is the state that persists entered information that is used to repopulate the form in the event that the API Call errors out in the Confirmation Box
  const [retainedEditProcessUnit, setRetainedEditProcessUnit] = useState<
    IProcessUnitUpdateDTO | undefined
  >();

  function handleShowEditProcessUnit() {
    setShowEditProcessUnit(!showEditProcessUnit);
  }

  //This function runs on clicking Save in Edit ProcessUnit
  function showEditProcessUnitConfirmationBox(e: any) {
    setShowEditProcessUnit(false);
    setEditProcessUnitCBActive(true);
    setEditProcessUnitCBText("Are you sure you want to save these changes?");
    setRetainedEditProcessUnit(e);
  }

  //This function runs on clicking Cancel in Edit ProcessUnit
  function hideEditProcessUnitConfirmationBox(e: any) {
    setEditProcessUnitCBActive(false);
    setEditProcessUnitCBText("");
    setRetainedEditProcessUnit(undefined);
  }

  //This function runs on clicking Yes after clicking Save in Edit ProcessUnit
  function handleEditProcessUnit() {
    setEditProcessUnitCBText("");

    editProcessUnit(retainedEditProcessUnit!).then((res) => {
      if (res.status === 200 || res.status === 202) {
        setEditProcessUnitCBText(
          `${retainedEditProcessUnit!.tag} successfully edited`
        );
        setProcessUnitAfterEdit();
        setRetainedEditProcessUnit(undefined);
      } else {
        setEditProcessUnitCBText(
          `The following issue was encountered while attempting to edit ${
            retainedEditProcessUnit!.tag
          }:\n\n${res.data.message}`
        );
      }
    });
    setEditProcessUnitCBFinalDisplay(true);
  }

  //This function runs on clicking Ok after clicking Yes in Edit ProcessUnit
  function completeEditProcessUnit(e: any) {
    setEditProcessUnitCBActive(false);
    setEditProcessUnitCBText("");
    setEditProcessUnitCBFinalDisplay(false);

    if (retainedEditProcessUnit !== undefined) {
      setShowEditProcessUnit(true);
    }
  }

  //This function will get the latest information for the Process Unit when called
  function setProcessUnitAfterEdit() {
    setProcessUnitFromAPI(parseInt(params.processUnitId!), setLoading).then(
      (res: any) => {
        setProcessUnit(res.data);
      }
    );
  }

  //Delete ProcessUnit
  const [deleteProcessUnitCBActive, setDeleteProcessUnitCBActive] =
    useState<boolean>(false);
  const [deleteProcessUnitCBText, setDeleteProcessUnitCBText] =
    useState<string>("");
  //finalDisplay is the bool that determines when it is time to show the single button when using a 2 button ConfirmationBox
  const [deleteProcessUnitCBFinalDisplay, setDeleteProcessUnitCBFinalDisplay] =
    useState<boolean>(false);

  //This function runs on clicking Save in Delete ProcessUnit
  function showDeleteProcessUnitConfirmationBox(e: any) {
    setDeleteProcessUnitCBActive(true);
    setDeleteProcessUnitCBText(
      `Are you sure you want to delete ${processUnit?.tag}?`
    );
  }

  //This function runs on clicking Cancel in Delete ProcessUnit
  function hideDeleteProcessUnitConfirmationBox(e: any) {
    setDeleteProcessUnitCBActive(false);
    setDeleteProcessUnitCBText("");
  }

  //This function runs on clicking Yes after clicking Save in Delete ProcessUnit
  function handleDeleteProcessUnit() {
    setDeleteProcessUnitCBText("");

    deleteProcessUnit(processUnit?.id!).then((res) => {
      if (res.status === 200 || res.status === 202) {
        setDeleteProcessUnitCBText(
          `${processUnit?.tag} successfully deleted. You will now be redirected to the Site page.`
        );
      } else {
        setDeleteProcessUnitCBText(
          `The following issue was encountered while attempting to delete ${processUnit?.tag}:\n\n${res.data.message}`
        );
      }
    });
    setDeleteProcessUnitCBFinalDisplay(true);
  }

  //This function runs on clicking Ok after clicking Yes in Edit ProcessUnit
  function completeDeleteProcessUnit(e: any) {
    setDeleteProcessUnitCBActive(false);
    setDeleteProcessUnitCBText("");
    setDeleteProcessUnitCBFinalDisplay(false);
    navigate(`/sites/details/${processUnit?.site?.id}`);
  }

  //Control Unit Table states
  const [controlUnitsElements] = useState<string[]>([
    "Data",
    "Data",
    "Data",
    "Data",
    "RouteButton",
  ]);

  const [controlUnitsElementsInfo] = useState<string[]>([
    "",
    "",
    "",
    "",
    "View",
  ]);

  const [controlUnitsHeaders] = useState<string[]>([
    "tag",
    "control_unit_type.manufacturer",
    "control_unit_type.model",
    "comm_address",
    "",
  ]);

  const [controlUnitsOverwriteHeaders] = useState<string[]>([
    "Tag",
    "Manufacturer",
    "Model",
    "Comm Address",
    "",
  ]);

  const viewControlUnitOnClick = (controlUnit: any) => {
    navigate(
      `/controlunits/details/processunit/${params.processUnitId!}/${
        controlUnit?.id
      }`
    );
  };

  const [controlUnitsElementsOnClick] = useState<any[]>([
    null,
    null,
    null,
    null,
    viewControlUnitOnClick,
  ]);

  //Add ControlUnit
  const [showAddControlUnit, setShowAddControlUnit] = useState<boolean>(false);
  const [addControlUnitCBActive, setAddControlUnitCBActive] =
    useState<boolean>(false);
  const [addControlUnitCBText, setAddControlUnitCBText] = useState<string>("");
  const [retainedNewControlUnit, setRetainedNewControlUnit] = useState<
    IControlUnitCreateDTO | undefined
  >();

  function handleShowAddControlUnit() {
    if (showAddControlUnit) {
      setRetainedNewControlUnit(undefined);
    }
    setShowAddControlUnit(!showAddControlUnit);
  }

  //This function runs on clicking Save in Add ControlUnit
  function handleAddControlUnit(e: IControlUnit) {
    setShowAddControlUnit(false);
    setAddControlUnitCBActive(true);
    addNewControlUnit(e).then((res) => {
      if (res.status === 200 || res.status === 202) {
        setAddControlUnitCBText(`${e.tag} successfully added`);
        setRetainedNewControlUnit(undefined);
        setControlUnitsAfterAdd();
      } else {
        setAddControlUnitCBText(
          `The following issue was encountered while attempting to add ${e.tag}:\n\n${res.data.message}`
        );
        setRetainedNewControlUnit(e);
      }
    });
  }

  //This function runs on clicking OK after clicking Save in Add Alert
  function completeAddControlUnit() {
    setAddControlUnitCBActive(false);
    setAddControlUnitCBText("");

    if (retainedNewControlUnit !== undefined) {
      setShowAddControlUnit(true);
    }
  }

  function setControlUnitsAfterAdd() {
    setControlUnitsByProcessUnitFromAPI(
      parseInt(params.processUnitId!),
      setLoading
    ).then((res: any) => {
      setControlUnits(res.data);
    });
  }

  if (
    loading ||
    userContext.loading ||
    processUnitBelongsToUserCompany === null ||
    processUnit === undefined
  )
    return (
      <div className="fullPageLoadingContainer">
        <Loading dataTestname="single-site-loading" />
      </div>
    );
  return (
    <StyledSingleProcessUnitDiv>
      {processUnitBelongsToUserCompany && (
        <Breadcrumb
          dataTestname="single-process-unit-breadcrumbs"
          breadcrumbs={crumbs}
        />
      )}
      {processUnitBelongsToUserCompany ? (
        <StyledMainDiv data-testid="main-div">
          {processUnit !== null && (
            <DefaultContainer
              dataTestname="single-process-unit-dark-default-container"
              darkBackground={true}
              title={"Appliance"}
            >
              <InfoDisplay
                dataTestname="single-process-unit-info-display"
                datum={processUnit}
                headers={[
                  "tag",
                  "process_unit_type.type",
                  "fuel_gas_type.type",
                  "vessel_manufacturer",
                  "vessel_model",
                  "vessel_rated_max_btu",
                  "burner_manufacturer",
                  "burner_model",
                  "burner_rated_max_btu",
                  "last_service_date",
                ]}
                overwriteHeaders={[
                  "Tag",
                  "Type",
                  "Fuel Gas",
                  "Vessel Manufacturer",
                  "Vessel Model",
                  "Vessel Rated Max BTU",
                  "Burner Manufacturer",
                  "Burner Model",
                  "Burner Rated Max BTU",
                  "Last Service Date",
                ]}
                greyBoxTitle={"Emissions Parameters"}
                greyBoxHeaders={[
                  "fuel_gas_btu",
                  "burner_orifice_diameter",
                  "burner_high_fire_pressure",
                  "burner_low_fire_pressure",
                  "high_fire_net_btu_per_hour",
                  "low_fire_net_btu_per_hour",
                ]}
                greyBoxOverwriteHeaders={[
                  "Fuel Gas BTU (BTU/SCF)",
                  "Burner Orifice Diameter (in.)",
                  "Burner High Fire Pressure (psig)",
                  "Burner Low Fire Pressure (psig)",
                  "High Fire Net BTU Per Hour (mmBTU)",
                  "Low Fire Net BTU Per Hour (mmBTU)",
                ]}
                headerWidth={"31%"}
                colonWidth={"3%"}
                containerWidth={"50%"}
                greyBoxHeaderWidth={"54%"}
                greyBoxColonWidth={"6%"}
                greyBoxInfoWidth={"40%"}
                greyBoxContainerWidth={"50%"}
                rightSpacerWidth={"0%"}
                infoWidth={"Calc(100% - 171px - 42px)"}
                headerMinWidth={"151px"}
                colonMinWidth={"21px"}
                infoMinWidth={"139px"}
                containerMinWidth={"365px"}
                greyBoxContainerMinWidth={"300px"}
                rightSpacerMinWidth={"0%"}
              />

              <StyledButtonsDiv>
                {userContext.permissions?.profire_manage_process_units && (
                  <EditButton
                    dataTestname="single-process-unit-edit-button"
                    onClickFunction={handleShowEditProcessUnit}
                  />
                )}
                {userContext.permissions?.profire_manage_process_units && (
                  <DeleteButton
                    dataTestname="single-process-unit-delete-button"
                    onClickFunction={showDeleteProcessUnitConfirmationBox}
                  />
                )}
                {userContext.permissions?.external_manage_tags && (
                  <StyledEditButtonDiv data-testid="edit-tag-button-div">
                    <ProfireButton
                      dataTestname="single-process-unit-edit-tag-button"
                      text={showEditTag ? "Stop Editing" : "Edit Tag"}
                      onClickFunction={handleShowEditTag}
                      size={4}
                    />
                  </StyledEditButtonDiv>
                )}
              </StyledButtonsDiv>
              <ConfirmationBox
                dataTestname="process-unit-edit-confirmation-box"
                heading={"Information"}
                message={editProcessUnitCBText}
                active={editProcessUnitCBActive}
                onOk={completeEditProcessUnit}
                onYes={handleEditProcessUnit}
                onCancel={hideEditProcessUnitConfirmationBox}
                displayCancel={true}
                finalDisplay={editProcessUnitCBFinalDisplay}
              />
              <ConfirmationBox
                dataTestname="single-process-unit-delete-confirmation-box"
                heading={"Information"}
                message={deleteProcessUnitCBText}
                active={deleteProcessUnitCBActive}
                onOk={completeDeleteProcessUnit}
                onYes={handleDeleteProcessUnit}
                onCancel={hideDeleteProcessUnitConfirmationBox}
                displayCancel={true}
                finalDisplay={deleteProcessUnitCBFinalDisplay}
              />
              <ConfirmationBox
                dataTestname="single-process-unit-edit-tag-confirmation-box"
                heading={"Information"}
                message={editTagCBText}
                active={editTagCBActive}
                onOk={completeEditProcessUnitTag}
                onYes={handleEditTag}
                onCancel={hideEditTagConfirmationBox}
                displayCancel={true}
                finalDisplay={editTagCBFinalDisplay}
              />
              {showEditProcessUnit && (
                <EditProcessUnit
                  active={showEditProcessUnit}
                  processUnit={processUnit!}
                  onCancel={handleShowEditProcessUnit}
                  onEdit={showEditProcessUnitConfirmationBox}
                  retainedData={retainedEditProcessUnit}
                />
              )}
              {showEditTag && (
                <StyledEditTagDiv
                  data-testid="edit-tag-div"
                  className={showEditTag ? "ease" : ""}
                >
                  <CommonInput
                    dataTestname="single-process-unit-tag-common-input"
                    type={"text"}
                    labelText={"Tag"}
                    required={true}
                    readOnly={false}
                    value={tag}
                    onChange={handleTag}
                    maxLength={50}
                    placeholder={"Tag"}
                    errors={tagErrors}
                  />
                  <StyledFormButtonDiv data-testid="save-tag-button-div">
                    <ProfireButton
                      dataTestname="single-process-unit-save-tag-button"
                      text="Save Tag"
                      onClickFunction={showEditTagConfirmationBox}
                      size={4}
                    />
                  </StyledFormButtonDiv>
                </StyledEditTagDiv>
              )}
            </DefaultContainer>
          )}
          <DefaultContainer
            dataTestname="single-process-unit-light-default-container"
            darkBackground={false}
            title="Associated Control Units"
          >
            <ConfirmationBox
              dataTestname="single-site-add-process-unit-confirmation-box"
              heading={"Information"}
              message={addControlUnitCBText}
              active={addControlUnitCBActive}
              onOk={completeAddControlUnit}
              displayCancel={false}
            />
            {userContext.permissions?.profire_manage_control_units && (
              <StyledAddButtonDiv data-testid="add-control-unit-button-div">
                <ProfireButton
                  dataTestname="single-process-unit-add-control-unit-button"
                  onClickFunction={handleShowAddControlUnit}
                  text="Add Control Unit"
                  size={6}
                />
                {showAddControlUnit && (
                  <AddControlUnit
                    active={showAddControlUnit}
                    siteId={processUnit.site!.id}
                    onCancel={handleShowAddControlUnit}
                    onAdd={handleAddControlUnit}
                    fromProcessUnit={true}
                    unit={processUnit}
                    retainedData={retainedNewControlUnit}
                  />
                )}
              </StyledAddButtonDiv>
            )}
            <CommonCustomizableTable
              dataTestname="appliance-control-unit-common-customizable-table"
              usageIdentifier="control-unit"
              data={controlUnits}
              elements={controlUnitsElements}
              elementsInfo={controlUnitsElementsInfo}
              headers={controlUnitsHeaders}
              overwriteHeaders={controlUnitsOverwriteHeaders}
              tableWidth={"100%"}
              tableMinWidth={"100%"}
              tableMaxHeight={"400px"}
              desktopColWidths={["20%", "20%", "20%", "20%", "20%"]}
              desktopColMinWidths={["20%", "20%", "20%", "20%", "20%"]}
              mobileColWidths={["50%", "0px", "50%", "0px", "0px"]}
              mobileColMinWidths={["50%", "0px", "50%", "0px", "0px"]}
              elementsOnClick={controlUnitsElementsOnClick}
              textAlign={["left", "left", "left", "left", "right"]}
              textAlignMobile={["left", "left", "right", "left", "right"]}
              tdHeight={"45px"}
              tdHeightMobile={"33px"}
              tdMinHeight={"45px"}
              tdMinHeightMobile={"33px"}
              addPaddingRightForScroll={true}
              colDisplayOnMobile={[true, false, true, false, false]}
              colJustify={["start", "start", "start", "start", "end"]}
              colFlexDirection={["column", "column", "column", "column", "row"]}
              mobileRowOnClick={viewControlUnitOnClick}
            />
            {userContext.permissions?.profire_manage_control_units && (
              <StyledAddButtonMobileDiv data-testid="add-control-unit-button-div">
                <ProfireButton
                  dataTestname="single-process-unit-add-control-unit-button"
                  onClickFunction={handleShowAddControlUnit}
                  text="Add Control Unit"
                  size={6}
                />
                {showAddControlUnit && (
                  <AddControlUnit
                    active={showAddControlUnit}
                    siteId={processUnit.site!.id}
                    onCancel={handleShowAddControlUnit}
                    onAdd={handleAddControlUnit}
                    fromProcessUnit={true}
                    unit={processUnit}
                    retainedData={retainedNewControlUnit}
                  />
                )}
              </StyledAddButtonMobileDiv>
            )}
          </DefaultContainer>
        </StyledMainDiv>
      ) : (
        <StyledMainDiv>
          <StyledBannerDiv data-testid="banner-div">
            <Banner
              dataTestname="single-process-unit-banner"
              headingtext={headingText}
              text={text}
              redBox={true}
            />
          </StyledBannerDiv>
        </StyledMainDiv>
      )}
    </StyledSingleProcessUnitDiv>
  );
};

export default SingleProcessUnit;

const StyledSingleProcessUnitDiv = styled.div`
  width: Calc(100% - 32px);
  max-width: Calc(1200px - 40px);
  min-width: Calc(320px - 40px);
  margin: 60px 16px 20px 16px;
  height: Calc(100% - 80px);
  background-color: white;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    margin: 60px 20px 20px 20px;
    width: Calc(100% - 40px);
    background-color: ${(props) => props.theme.bgColor};
  }
`;

const StyledMainDiv = styled.div`
  height: calc(100% - 33px);
  width: 100%;
`;

const StyledBannerDiv = styled.div`
  min-width: Calc(300px - 32px);
  width: Calc(100% - 32px);
  padding: 20px 16px;
  margin-bottom: 25px;
  border-radius: 10px;
`;

const StyledAddButtonDiv = styled.div`
  width: 100%;
  margin-bottom: 15px;
  text-align: right;
  display: none;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    display: block;
  }
`;

const StyledAddButtonMobileDiv = styled.div`
  width: 100%;
  margin-top: 12px;
  text-align: right;
  display: block;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    display: none;
  }
`;

const StyledEditButtonDiv = styled.div`
  width: 100%;
  margin-top: 5px;
  text-align: right;
`;

const StyledButtonsDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

const StyledEditTagDiv = styled.div`
  padding-top: 0px;
  padding-bottom: 0px;
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;

  input {
    text-align: left;
    font-size: ${(props) => props.theme.contentMainSize};
    font-weight: ${(props) => props.theme.contentMainWeight};
  }

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    padding-top: 10px;
    padding-bottom: 5px;
  }
`;

const StyledFormButtonDiv = styled.div`
  text-align: left;
  width: 100%;

  button {
    font-size: ${(props) => props.theme.contentMainSize};
    font-weight: ${(props) => props.theme.contentMainWeight};
  }
`;
