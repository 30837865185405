import { createContext } from "react";
import { IMonitorUnitContext } from "./types";

const defaultMonitorUnitContext: IMonitorUnitContext = {
  monitorUnits: [],
  monitorUnit: null,
  monitorUnitBelongsToUserCompany: null,
  loading: true,
  setMonitorUnits: () => null,
  setMonitorUnit: () => null,
  setMonitorUnitsBySiteId: () => null,
  setMonitorUnitBelongsToUserCompany: () => null,
  setMonitorUnitsByCompany: () => null,
};

const MonitorUnitContext = createContext<IMonitorUnitContext>(
  defaultMonitorUnitContext
);

export default MonitorUnitContext;
