import axios from "axios";
import { IUserCreateDTO } from "../../types/users";
import { IUserUpdateDTO } from "../../types/users";
import { getToken } from "../msTokenFunc";

export const getAppUserById = async (userId: number, loadingFunc: Function) => {
  try {
    loadingFunc(true);
    const token = await getToken();
    const result = await axios.get(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/user/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    loadingFunc(false);
    return result;
  } catch (error: any) {
    loadingFunc(false);
    return error.response;
  }
};

export const getAppUserByAzureId = async (
  azureId: string,
  loadingFunc: Function
) => {
  try {
    loadingFunc(true);
    const token = await getToken();
    const result = await axios.get(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/user/byazureid/${azureId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    loadingFunc(false);
    return result;
  } catch (error: any) {
    loadingFunc(false);
    return error.response;
  }
};

export const getUsersByCompanyId = async (
  companyId: number,
  loadingFunc: Function
) => {
  try {
    loadingFunc(true);
    const token = await getToken();
    const result = await axios.get(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/user/bycompany/${companyId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    loadingFunc(false);
    return result;
  } catch (error: any) {
    loadingFunc(false);
    return error.response;
  }
};

export const checkUserRegisterStatus = async (
  email: string,
  loadingFunc: Function
) => {
  try {
    loadingFunc(true);
    const token = await getToken();
    const result = await axios.get(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/user/checkstatus/${email}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    loadingFunc(false);
    return result;
  } catch (error: any) {
    loadingFunc(false);
    return error.response;
  }
};

export const verifySiteBelongsToUserCompany = async (
  siteId: number,
  azureId: string,
  loadingFunc: Function
) => {
  try {
    loadingFunc(true);
    const token = await getToken();
    const result = await axios.get(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/user/verifyusersite/${siteId}/${azureId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    loadingFunc(false);
    return result;
  } catch (error: any) {
    loadingFunc(false);
    return error.response;
  }
};

export const verifyProcessUnitBelongsToUserCompany = async (
  processUnitId: number,
  azureId: string,
  loadingFunc: Function
) => {
  try {
    loadingFunc(true);
    const token = await getToken();
    const result = await axios.get(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/user/verifyuserprocessunit/${processUnitId}/${azureId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    loadingFunc(false);
    return result;
  } catch (error: any) {
    loadingFunc(false);
    return error.response;
  }
};

export const verifyMonitorUnitBelongsToUserCompany = async (
  monitorUnitId: number,
  azureId: string,
  loadingFunc: Function
) => {
  try {
    loadingFunc(true);
    const token = await getToken();
    const result = await axios.get(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/user/verifyusermonitorunit/${monitorUnitId}/${azureId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    loadingFunc(false);
    return result;
  } catch (error: any) {
    loadingFunc(false);
    return error.response;
  }
};

export const verifyControlUnitBelongsToUserCompany = async (
  controlUnitId: number,
  azureId: string,
  loadingFunc: Function
) => {
  try {
    loadingFunc(true);
    const token = await getToken();
    const result = await axios.get(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/user/verifyusercontrolunit/${controlUnitId}/${azureId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    loadingFunc(false);
    return result;
  } catch (error: any) {
    loadingFunc(false);
    return error.response;
  }
};

export const verifyUserBelongsToUserCompany = async (
  userId: number,
  azureId: string,
  loadingFunc: Function
) => {
  try {
    loadingFunc(true);
    const token = await getToken();
    const result = await axios.get(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/user/verifycompanyuser/${userId}/${azureId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    loadingFunc(false);
    return result;
  } catch (error: any) {
    loadingFunc(false);
    return error.response;
  }
};

export const addAppUser = async (newUser: IUserCreateDTO) => {
  try {
    const token = await getToken();
    const result = await axios.post(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/user`,
      newUser,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result;
  } catch (error: any) {
    return error.response;
  }
};

export const updateAppUser = async (updatedUser: IUserUpdateDTO) => {
  try {
    const token = await getToken();
    const result = await axios.put(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/user`,
      updatedUser,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result;
  } catch (error: any) {
    return error.response;
  }
};

export const deleteAppUser = async (userId: number) => {
  try {
    const token = await getToken();
    const result = await axios.delete(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/user/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result;
  } catch (error: any) {
    return error.response;
  }
};
