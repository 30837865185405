import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { handleDateDisplay } from "../../../utilities/timeFunctions";
import { validateDateString } from "../../../utilities/validationFunctions";
import ChevronRightIcon from "../../../assets/images/navigation/chevron-right.svg";
import ChevronDownIcon from "../../../assets/images/navigation/chevron-down.svg";
import ChartIcon from "../../../assets/images/navigation/chart-icon.svg";
import { FormatHeader } from "../../../utilities/tableHeaderFix";
import { PropertyAccess } from "../../../utilities/propertyAccess";
import { openInNewTab } from "../../../utilities/secureNewTab";
import StatusGoodIcon from "../../../assets/images/sites/green-status-circle.svg";
import StatusBadIcon from "../../../assets/images/sites/red-status-circle.svg";
import { IControlUnit } from "../../../types/controlUnits";
import ProfireButton from "../buttons/ProfireButton";

// Collapsible Link Table Header Component Instructions
//  This component is NOT meant to be used on its own. It is included in CollapsibleLinkTable.tsx
//  headers, collapseHeaders, collapseOverwriteHeaders, collapseData, viewButtonUrl, viewButtonUrlAppendProperty, collapseDatumFieldToMatchAppendProperty:
//  all of the above properties are passed down directly from CollapsibleLinkTable.tsx
//  - datum: a single instance of data from the data[] that was passed into CollapsibleLinkTable.tsx, used to display information for a main row
//  - dataIndex: a number passed down from performing data.map to identify this row's index
//  - activeRow: the row number that is currently active or open. when this matches the dataIndex, the collapseData will be displayed.
//  - updateActiveRow: a function passed down from CollapsibleLinkTable.tsx that updates the activeRow prop
//  - grafanaLink: currently an empty function. This will eventually hold the link to view the selected Control Unit in Grafana upon clicking the chart icon.
//  If there are questions about how any of the below logic works, you can do one of two things:
//  1. Ask TJ
//  2. Believe that it works until you observe it not working.
//  Some of the logic is untested for situations other than using siteContext.sites as the data[] and controlUnitContext.controlUnits as the collapseData[].
//  I fully expect there to be some issues if this table is needed in another component, so please document what's going wrong if it is used and let TJ know immediately.
//  If the styling is changed in here, the styling will also likely need to be changed in CollapsibleLinkTableHeader.tsx, especially if it is width adjustment.

interface CollapsibleLinkTableProps {
  dataTestname: string;
  headers: string[];
  datum: any;
  dataIndex: number;
  collapseHeaders: string[];
  collapseOverwriteHeaders?: string[];
  collapseCUs: any[];
  collapseMUs: any[];
  collapsePUs: any[];
  grafanaUrlProperty: string;
  viewButtonUrl: string;
  viewButtonUrlAppendProperty: string;
  collapseDatumFieldToMatchAppendProperty: string[];
}

const CollapsibleLinkTableRow: React.FC<CollapsibleLinkTableProps> = ({
  dataTestname,
  headers,
  datum,
  collapseHeaders,
  collapseOverwriteHeaders,
  collapseCUs,
  collapseMUs,
  collapsePUs,
  grafanaUrlProperty,
  viewButtonUrl,
  viewButtonUrlAppendProperty,
  collapseDatumFieldToMatchAppendProperty,
}) => {
  const [active, setActive] = useState<boolean>(false);
  const updateActive = () => {
    setActive(!active);
  };

  const navigate = useNavigate();
  const handleViewSite = () => {
    navigate(viewButtonUrl + datum[viewButtonUrlAppendProperty]);
  };
  const handleViewSelected = (URL: any) => {
    navigate(`/${URL}`);
  };

  return (
    <>
      {/* main row area */}
      <StyledMainTableRow
        data-testname={dataTestname}
        data-testid="main-tr"
        key={datum.id}
      >
        <StyledMainRowTd key={datum.id + "row"}>
          <StyledMainTableDiv data-testid="main-td-div">
            {headers.map((header, index) =>
              index === 0 ? (
                <StyledZeroDiv key={datum.id + index + "zero div"}>
                  <StyledMainTableInnerDiv
                    data-testid="main-arrow-inner-div"
                    onClick={updateActive}
                    key={header + index}
                  >
                    <StyledChevron
                      active={active}
                      src={active ? ChevronDownIcon : ChevronRightIcon}
                      alt="overview icon"
                      key={datum.id + "Chevron"}
                    />
                  </StyledMainTableInnerDiv>
                  <StyledMainTableStatusDiv
                    data-testid="main-arrow-inner-div"
                    key={header + index + "Status"}
                  >
                    <StyledStatus
                      src={datum.alerting ? StatusBadIcon : StatusGoodIcon}
                      alt="overview icon"
                      key={datum.id + "status dot"}
                    />
                  </StyledMainTableStatusDiv>
                </StyledZeroDiv>
              ) : (
                <StyledMainTableInnerDiv
                  data-testid="main-inner-div"
                  key={header + index}
                  onClick={updateActive}
                >
                  <span key={datum.id + "datespan"}>
                    {header.includes(".")
                      ? datum[header.split(".")[0]][header.split(".")[1]]
                      : validateDateString(datum[header])
                      ? handleDateDisplay(new Date(datum[header].toString()))
                      : datum[header]}
                  </span>
                </StyledMainTableInnerDiv>
              )
            )}

            <StyledMainTableInnerButtonDiv
              data-testid="view-row-button-div"
              key={datum.id + "button"}
            >
              <ProfireButton
                dataTestname="collapsible-link-table-row-view-profire-button"
                text="View"
                disabled={false}
                onClickFunction={handleViewSite}
              />
            </StyledMainTableInnerButtonDiv>
          </StyledMainTableDiv>
        </StyledMainRowTd>
      </StyledMainTableRow>
      {active && (
        <>
          {/* headers area */}
          {collapseOverwriteHeaders ? (
            <StyledCollapsibleHeaderRow
              data-testid="collapse-header-overwrite-tr"
              key={datum.id + "headerrow"}
            >
              <StyledCollapsibleRowTh>
                <StyledCollapsibleHeaderDiv>
                  <StyledCollapsibleTableStatusDiv data-testid="main-arrow-inner-div"></StyledCollapsibleTableStatusDiv>
                  {collapseOverwriteHeaders.map((header, index) =>
                    header.includes(".") ? (
                      <div key={header + index + "collapsezero"}>
                        {index === 0 && (
                          <StyledCollapsibleTableStatusDiv
                            data-testid="main-arrow-inner-div"
                            key={header + index + "Status"}
                          ></StyledCollapsibleTableStatusDiv>
                        )}
                        <StyledCollapsibleInnerDivTh
                          data-testid="overwrite-inner-div-th"
                          key={header + index}
                        >
                          {FormatHeader(header.split(".")[0])}
                        </StyledCollapsibleInnerDivTh>
                      </div>
                    ) : (
                      //I NEED TO MODIFY HERE
                      <div key={header + index + "collapse"}>
                        <StyledCollapsibleInnerDivTh
                          data-testid="overwrite-inner-div-th"
                          key={header + index}
                        >
                          {FormatHeader(header)}
                        </StyledCollapsibleInnerDivTh>
                      </div>
                    )
                  )}
                </StyledCollapsibleHeaderDiv>
              </StyledCollapsibleRowTh>
            </StyledCollapsibleHeaderRow>
          ) : (
            <StyledCollapsibleHeaderRow
              data-testid="collapse-header-tr"
              key={datum.id}
            >
              <StyledCollapsibleRowTh>
                <StyledCollapsibleDiv>
                  {collapseHeaders.map((header, index) =>
                    header.includes(".") ? (
                      <div key={header + index + "collapsezero"}>
                        {index === 0 && (
                          <StyledCollapsibleTableStatusDiv
                            data-testid="main-arrow-inner-div"
                            key={header + index + "Status"}
                          ></StyledCollapsibleTableStatusDiv>
                        )}
                        <StyledCollapsibleInnerDivTh
                          data-testid="inner-div-th"
                          key={header + index}
                        >
                          {FormatHeader(header.split(".")[0])}
                        </StyledCollapsibleInnerDivTh>
                      </div>
                    ) : (
                      <div key={header + index + "collapse"}>
                        {index === 0 && (
                          <StyledCollapsibleTableStatusDiv
                            data-testid="main-arrow-inner-div"
                            key={header + index + "Status"}
                          ></StyledCollapsibleTableStatusDiv>
                        )}
                        <StyledCollapsibleInnerDivTh
                          data-testid="inner-div-th"
                          key={header + index}
                        >
                          {FormatHeader(header)}
                        </StyledCollapsibleInnerDivTh>
                      </div>
                    )
                  )}
                </StyledCollapsibleDiv>
              </StyledCollapsibleRowTh>
            </StyledCollapsibleHeaderRow>
          )}
          {/* data rows area */}
          {collapseCUs.map(
            (collapseDatum: IControlUnit, collapseDataIndex) =>
              collapsePUs !== undefined &&
              collapsePUs.find((pu) => collapseDatum.process_unit_id === pu.id)
                .site_id === datum.id && (
                <StyledCollapsibleDataRow
                  data-testname={`control-unit-collapse-row-${collapseDatum.id}`}
                  data-testid="collapse-data-tr"
                  key={collapseDatum.id + collapseDataIndex}
                >
                  <StyledCollapsibleDataRowTd>
                    <StyledCollapsibleDiv>
                      <>
                        <StyledCollapsibleTableStatusDiv data-testid="main-arrow-inner-div">
                          <StyledStatus
                            src={
                              collapseDatum.alerting
                                ? StatusBadIcon
                                : StatusGoodIcon
                            }
                            alt="overview icon"
                          />
                        </StyledCollapsibleTableStatusDiv>
                        <StyledCollapsibleInnerDivTd data-testid="inner-div-td">
                          <StyledCollapsibleInnerDivTdText
                            onClick={() =>
                              handleViewSelected(
                                `controlunits/details/processunit/${collapseDatum.process_unit_id}/${collapseDatum.id}`
                              )
                            }
                          >
                            {collapseDatum.tag}
                          </StyledCollapsibleInnerDivTdText>
                        </StyledCollapsibleInnerDivTd>
                        <StyledCollapsibleInnerDivTd data-testid="inner-div-td">
                          <StyledCollapsibleInnerDivTdText
                            onClick={() =>
                              handleViewSelected(
                                `processunits/details/${collapseDatum.process_unit_id}`
                              )
                            }
                          >
                            {collapsePUs.find(
                              (pu) => collapseDatum.process_unit_id === pu.id
                            ) === undefined
                              ? "N/A"
                              : collapsePUs.find(
                                  (pu) =>
                                    collapseDatum.process_unit_id === pu.id
                                ).tag}
                          </StyledCollapsibleInnerDivTdText>
                        </StyledCollapsibleInnerDivTd>
                        <StyledCollapsibleInnerDivTd data-testid="inner-div-td">
                          <StyledCollapsibleInnerDivTdText
                            onClick={() =>
                              handleViewSelected(
                                `monitorunits/details/${collapseDatum.monitor_unit_id}`
                              )
                            }
                          >
                            {collapseMUs.find(
                              (mu) => collapseDatum.monitor_unit_id === mu.id
                            ) === undefined
                              ? "N/A"
                              : collapseMUs.find(
                                  (mu) =>
                                    collapseDatum.monitor_unit_id === mu.id
                                ).tag}
                          </StyledCollapsibleInnerDivTdText>
                        </StyledCollapsibleInnerDivTd>
                      </>
                      <StyledCollapsibleInnerDivTd data-testid="chart-inner-div-td">
                        <StyledGrafanaLink
                          onClick={() =>
                            openInNewTab(
                              PropertyAccess(collapseDatum, grafanaUrlProperty)
                            )
                          }
                          src={ChartIcon}
                          alt="chart icon"
                        />
                      </StyledCollapsibleInnerDivTd>
                    </StyledCollapsibleDiv>
                  </StyledCollapsibleDataRowTd>
                </StyledCollapsibleDataRow>
              )
          )}
          <StyledCollapsibleDataRow>
            <StyledCollapsibleDataRowTd>
              <StyledSpacingRow data-testid="spacing-row">
                <StyledCollapsibleInnerDivTd></StyledCollapsibleInnerDivTd>
              </StyledSpacingRow>
            </StyledCollapsibleDataRowTd>
          </StyledCollapsibleDataRow>
        </>
      )}
    </>
  );
};

export default CollapsibleLinkTableRow;

const StyledMainTableRow = styled.tr`
  width: 100%;

  td > div:last-child {
    border-bottom: 1px solid #e5e5e5;
  }

  div {
    font-size: ${(props) => props.theme.contentMainSize};
    font-weight: ${(props) => props.theme.contentMainWeight};
    word-break: break-word;

    button {
      height: 30px;
    }
  }
`;

const StyledMainRowTd = styled.td`
  cursor: pointer;
  padding: 0;
`;

const StyledMainTableDiv = styled.div`
  width: 100%;
  display: flex;

  > div:first-child {
    width: 47px;
    text-align: center;
    border-top: none;

    @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
      width: 67px;
    }
  }

  > div:nth-child(2) {
    width: Calc(100% - 123px);
    padding-right: 10px;

    @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
      width: Calc(23.5% - 10px);
    }
  }

  > div:nth-child(3) {
    width: Calc(76.5% - 163px);
    padding-right: 10px;
    display: none;

    @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
      display: flex;
    }
  }

  > div:last-child {
    width: 66px;

    @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
      width: 86px;
    }
  }
`;

const StyledZeroDiv = styled.div`
  display: flex;
`;

const StyledMainTableInnerDiv = styled.div`
  text-align: left;
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledMainTableStatusDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 13px;
  margin-left: 10px;
  margin-right: 10px;
`;

const StyledCollapsibleTableStatusDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 13px;
  height: 100%;
  margin-right: 12px;
`;

interface ChevronProps {
  active: boolean;
}

const StyledChevron = styled.img<ChevronProps>`
  width: ${(props) => (props.active ? "15px" : "9px")};
  height: 15px;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    margin-left: 20px;
  }
`;

const StyledStatus = styled.img`
  width: 13px;
  height: 13px;
`;

const StyledMainTableInnerButtonDiv = styled.div`
  text-align: left;
  height: auto;
  cursor: default;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  button {
    height: 30px;
    width: 66px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    display: flex;
    height: 100%;
  }
`;

const StyledCollapsibleHeaderRow = styled.tr`
  background-color: #fff;
  div {
    font-size: ${(props) => props.theme.contentMainSize};
    font-weight: ${(props) => props.theme.headingSecondaryWeight};
    word-break: break-word;
  }

  th {
    padding-top: 10px;
  }

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    background-color: #e8e8e8;
  }
`;

const StyledCollapsibleRowTh = styled.th`
  padding: 0;

  > div:first-child {
    border-bottom: 1px solid #d5d5d5;
  }

  > div:last-child {
    border-bottom: 1px solid #d5d5d5;
  }
`;

const StyledCollapsibleHeaderDiv = styled.div`
  margin-left: 25px;
  margin-right: 5.36%;
  width: Calc(100% - 5.36% - 25px);
  height: 22px;
  padding-top: 6px;
  padding-bottom: 7px;
  display: flex;
  flex-direction: row;
  color: #767676;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    margin-left: max(5.36%, 45px);
    margin-right: 5.36%;
    width: Calc(100% - 5.36% - max(5.36%, 45px));
  }

  > div {
    width: Calc(80%);

    @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
      width: Calc(30% - 8.4px);
    }
  }

  > div:first-child {
    text-align: left;
    width: 13px;
    margin-right: 12px;
  }

  > div:nth-child(2) {
    text-align: left;

    @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
      padding-right: 10px;
      width: Calc(30% - 18.4px);
    }
  }

  > div:nth-child(3) {
    text-align: left;
    display: none;

    @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
      padding-right: 10px;
      display: flex;
      width: Calc(30% - 18.4px);
    }
  }

  > div:nth-child(4) {
    text-align: left;
    display: none;

    @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
      padding-right: 10px;
      display: flex;
      width: Calc(30% - 18.4px);
    }
  }

  > div:last-child {
    width: 20%;
    text-align: right;
    display: flex;
    flex-direction: row;
    justify-content: right;

    @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
      display: flex;
      width: 10%;
    }
  }
`;

const StyledCollapsibleDiv = styled.div`
  margin-left: 25px;
  margin-right: 5.36%;
  width: Calc(100% - 5.36% - 25px);
  height: 22px;
  padding-top: 6px;
  padding-bottom: 7px;
  display: flex;
  flex-direction: row;
  color: #767676;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    margin-left: max(5.36%, 45px);
    margin-right: 5.36%;
    width: Calc(100% - 5.36% - max(5.36%, 45px));
  }

  > div {
    width: Calc(80%);
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
      width: Calc(30% - 8.4px);
    }
  }

  > div:first-child {
    text-align: left;
    width: 13px;
    margin-right: 12px;
  }

  > div:nth-child(2) {
    text-align: left;

    @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
      padding-right: 10px;
      width: Calc(30% - 18.4px);
    }
  }

  > div:nth-child(3) {
    text-align: left;
    display: none;

    @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
      padding-right: 10px;
      display: flex;
      width: Calc(30% - 18.4px);
    }
  }

  > div:nth-child(4) {
    text-align: left;
    display: none;

    @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
      padding-right: 10px;
      display: flex;
      width: Calc(30% - 18.4px);
    }
  }

  > div:last-child {
    width: 20%;
    text-align: right;
    display: flex;
    flex-direction: row;
    justify-content: right;

    @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
      display: flex;
      width: 10%;
    }
  }
`;

const StyledCollapsibleInnerDivTh = styled.div``;

const StyledCollapsibleDataRow = styled.tr`
  background-color: #fff;

  div {
    font-size: ${(props) => props.theme.contentMainSize};
    font-weight: ${(props) => props.theme.contentMainWeight};
    word-break: break-word;
  }

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    background-color: #e8e8e8;
  }
`;

const StyledCollapsibleDataRowTd = styled.td`
  padding: 0;

  img {
    color: #5f5f5f;
  }
`;

const StyledCollapsibleInnerDivTd = styled.div`
  color: #848484;
`;

const StyledSpacingRow = styled.div`
  height: 10px;
  width: 100%;
`;

const StyledCollapsibleInnerDivTdText = styled.span`
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

const StyledGrafanaLink = styled.img`
  cursor: pointer;
`;
