import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Banner from "../components/common/headers/Banner";
import Loading from "../components/common/modals/Loading";
import MonitorUnitContext from "../stores/monitorUnits/MonitorUnitContext";
import SiteContext from "../stores/sites/SiteContext";
import UserContext from "../stores/users/UserContext";
import { validateTextString } from "../utilities/validationFunctions";
import { IMonitorUnit } from "../types/monitorUnits";
import Breadcrumb from "../components/common/navigation/Breadcrumbs";
import { IBreadcrumbItem } from "../types/breadcrumb";
import CommonInput from "../components/common/formInputs/CommonInput";
import DefaultContainer from "../components/common/containers/DefaultContainer";
import InfoDisplay from "../components/common/tables/InfoDisplay";
import EditButton from "../components/common/buttons/EditButton";
import DeleteButton from "../components/common/buttons/DeleteButton";
import ProfireButton from "../components/common/buttons/ProfireButton";
import {
  deleteMonitorUnit,
  editMonitorUnit,
  editMonitorUnitTag,
  setMonitorUnitBelongsToUserCompanyFromAPI,
  setMonitorUnitFromAPI,
} from "../utilities/apiCalls/monitorUnits";
import EditMonitorUnit from "../components/monitorUnits/EditMonitorUnit";
import { IControlUnit, IControlUnitCreateDTO } from "../types/controlUnits";
import {
  addNewControlUnit,
  setControlUnitsByMonitorUnitFromAPI,
} from "../utilities/apiCalls/controlUnits";
import AddControlUnit from "../components/controlUnits/AddControlUnit";
import ConfirmationBox from "../components/common/modals/ConfirmationBox";
import CommonCustomizableTable from "../components/common/tables/CommonCustomizableTable";
import {
  backendErrorCodes,
  blankMonitorUnit,
} from "../utilities/staticObjects";

interface SingleMonitorUnitProps {}

let existingErrors: Boolean = false;

const SingleMonitorUnit: React.FC<SingleMonitorUnitProps> = () => {
  const userContext = useContext(UserContext);
  const userContextRef = useRef(userContext).current;
  const monitorUnitContext = useContext(MonitorUnitContext);
  const monitorUnitContextRef = useRef(monitorUnitContext).current;
  const siteContext = useContext(SiteContext);
  const siteContextRef = useRef(siteContext).current;
  const params = useParams();

  const [loading, setLoading] = useState<boolean>(false);
  const [monitorUnitBelongsToUserCompany, setMonitorUnitBelongsToUserCompany] =
    useState<boolean | null>(null);
  const [monitorUnit, setMonitorUnit] = useState<IMonitorUnit>();
  const [controlUnits, setControlUnits] = useState<IControlUnit[]>([]);

  let headingText: string = "Invalid Monitor Unit";
  let text: string =
    "This Monitor Unit ID is invalid. If you believe this is an error, please contact technical support.";

  useEffect(() => {
    setMonitorUnitBelongsToUserCompanyFromAPI(
      parseInt(params.monitorUnitId!),
      userContextRef.user?.azure_id!,
      userContextRef.permissions?.profire_select_company!
    ).then((res: any) => {
      if (res.data === true) {
        setMonitorUnitBelongsToUserCompany(true);

        setMonitorUnitFromAPI(parseInt(params.monitorUnitId!), setLoading).then(
          (res: any) => {
            if (backendErrorCodes.includes(res.status)) {
              setMonitorUnitBelongsToUserCompany(false);
              setMonitorUnit(blankMonitorUnit);
            } else {
              setMonitorUnit(res.data);
              setTag(res.data.tag);
            }
          }
        );

        setControlUnitsByMonitorUnitFromAPI(
          parseInt(params.monitorUnitId!),
          setLoading
        ).then((res: any) => {
          setControlUnits(res.data);
        });
      } else {
        //the MU does not belong to the user's company
        setMonitorUnitBelongsToUserCompany(false);
        //fill MU with default data to prevent warnings
        setMonitorUnit(blankMonitorUnit);
      }
    });
  }, [
    userContextRef,
    monitorUnitContextRef,
    params.monitorUnitId,
    siteContextRef,
  ]);

  const crumbs: IBreadcrumbItem[] = userContext.permissions
    ?.profire_select_company
    ? [
        {
          id: monitorUnit?.site?.company_id!,
          title: monitorUnit?.site?.company?.name!,
          url: userContext.permissions?.profire_select_company
            ? `/sites/${monitorUnit?.site?.company_id!}`
            : `/sites`,
          prefix: "Company",
        },

        {
          id: monitorUnit?.site?.id!,
          title: monitorUnit?.site?.facility_name!,
          url: `/sites/details/${monitorUnit?.site?.id}`,
          prefix: "Site",
        },
      ]
    : [
        {
          id: monitorUnit?.site?.id!,
          title: monitorUnit?.site?.facility_name!,
          url: `/sites/details/${monitorUnit?.site?.id}`,
          prefix: "Site",
        },
      ];

  const navigate = useNavigate();

  function handleExistingErrors(errors: Boolean) {
    existingErrors = errors;
  }

  //Edit Tag
  const [showEditTag, setShowEditTag] = useState<boolean>(false);
  const [editTagCBActive, setEditTagCBActive] = useState<boolean>(false);
  const [editTagCBText, setEditTagCBText] = useState<string>("");
  const [editTagCBFinalDisplay, setEditTagCBFinalDisplay] =
    useState<boolean>(false);
  const [tag, setTag] = useState<string>(monitorUnit?.tag!);
  const [tagErrors, setTagErrors] = useState<string>("");
  const handleTag = (e: React.FormEvent<HTMLInputElement>) => {
    if (
      !validateTextString(e.currentTarget.value) &&
      e.currentTarget.value !== ""
    ) {
      setTag(e.currentTarget.value);
      setTagErrors(
        "First character must be alphanumeric and only alphanumeric, spaces, and the following are supported after: | _ : , . # - / '"
      );
      handleExistingErrors(true);
    } else {
      setTag(e.currentTarget.value);
      setTagErrors("");
      handleExistingErrors(false);
    }
  };

  function handleShowEditTag() {
    setShowEditTag(!showEditTag);
  }

  //This function runs on clicking Save in Edit Tag
  function showEditTagConfirmationBox(e: any) {
    if (!existingErrors) {
      setEditTagCBActive(true);
      setEditTagCBText("Are you sure you want to save these changes?");
    }
  }

  //This function runs on clicking Cancel in Edit Tag
  function hideEditTagConfirmationBox(e: any) {
    setEditTagCBActive(false);
    setEditTagCBText("");
  }

  //This function runs on clicking Yes after clicking Save in Edit Tag
  function handleEditTag() {
    setEditTagCBText("");

    const update: IMonitorUnit = {
      id: monitorUnit?.id!,
      tag: tag,
      site_id: monitorUnit?.site_id!,
      monitor_unit_type_id: monitorUnit?.monitor_unit_type_id!,
    };

    editMonitorUnitTag(update!).then((res) => {
      if (res.status === 200 || res.status === 202) {
        setEditTagCBText(`Tag successfully edited`);
        setMonitorUnitAfterEdit();
      } else {
        setEditTagCBText(
          `The following issue was encountered while attempting to edit ${
            monitorUnit!.tag
          }:\n\n${res.data.message}`
        );
      }
    });
    setEditTagCBFinalDisplay(true);
  }

  //This function runs on clicking Ok after clicking Yes in Edit MonitorUnit
  function completeEditMonitorUnitTag(e: any) {
    setEditTagCBActive(false);
    setEditTagCBText("");
    setEditTagCBFinalDisplay(false);
    handleShowEditTag();
  }

  //Edit MonitorUnit
  const [showEditMonitorUnit, setShowEditMonitorUnit] =
    useState<boolean>(false);
  const [editMonitorUnitCBActive, setEditMonitorUnitCBActive] =
    useState<boolean>(false);
  const [editMonitorUnitCBText, setEditMonitorUnitCBText] =
    useState<string>("");
  const [editMonitorUnitCBFinalDisplay, setEditMonitorUnitCBFinalDisplay] =
    useState<boolean>(false);
  //retainedEditMonitorUnit is the state that persists entered information that is used to repopulate the form in the event that the API Call errors out in the Confirmation Box
  const [retainedEditMonitorUnit, setRetainedEditMonitorUnit] = useState<
    IMonitorUnit | undefined
  >();

  function handleShowEditMonitorUnit() {
    setShowEditMonitorUnit(!showEditMonitorUnit);
  }

  //This function runs on clicking Save in Edit MonitorUnit
  function showEditMonitorUnitConfirmationBox(e: any) {
    setShowEditMonitorUnit(false);
    setEditMonitorUnitCBActive(true);
    setEditMonitorUnitCBText("Are you sure you want to save these changes?");
    setRetainedEditMonitorUnit(e);
  }

  //This function runs on clicking Cancel in Edit MonitorUnit
  function hideEditMonitorUnitConfirmationBox(e: any) {
    setEditMonitorUnitCBActive(false);
    setEditMonitorUnitCBText("");
    setRetainedEditMonitorUnit(undefined);
  }

  //This function runs on clicking Yes after clicking Save in Edit MonitorUnit
  function handleEditMonitorUnit() {
    setEditMonitorUnitCBText("");

    editMonitorUnit(retainedEditMonitorUnit!).then((res) => {
      if (res.status === 200 || res.status === 202) {
        setEditMonitorUnitCBText(
          `${retainedEditMonitorUnit!.tag} successfully edited`
        );
        setMonitorUnitAfterEdit();
        setRetainedEditMonitorUnit(undefined);
      } else {
        setEditMonitorUnitCBText(
          `The following issue was encountered while attempting to edit ${
            retainedEditMonitorUnit!.tag
          }:\n\n${res.data.message}`
        );
      }
    });
    setEditMonitorUnitCBFinalDisplay(true);
  }

  //This function runs on clicking Ok after clicking Yes in Edit MonitorUnit
  function completeEditMonitorUnit(e: any) {
    setEditMonitorUnitCBActive(false);
    setEditMonitorUnitCBText("");
    setEditMonitorUnitCBFinalDisplay(false);

    if (retainedEditMonitorUnit !== undefined) {
      setShowEditMonitorUnit(true);
    }
  }

  //This function will get the latest information for the Process Unit when called
  function setMonitorUnitAfterEdit() {
    setMonitorUnitFromAPI(parseInt(params.monitorUnitId!), setLoading).then(
      (res: any) => {
        setMonitorUnit(res.data);
      }
    );
  }

  //Delete MonitorUnit
  const [deleteMonitorUnitCBActive, setDeleteMonitorUnitCBActive] =
    useState<boolean>(false);
  const [deleteMonitorUnitCBText, setDeleteMonitorUnitCBText] =
    useState<string>("");
  //finalDisplay is the bool that determines when it is time to show the single button when using a 2 button ConfirmationBox
  const [deleteMonitorUnitCBFinalDisplay, setDeleteMonitorUnitCBFinalDisplay] =
    useState<boolean>(false);

  //This function runs on clicking Save in Delete MonitorUnit
  function showDeleteMonitorUnitConfirmationBox(e: any) {
    setDeleteMonitorUnitCBActive(true);
    setDeleteMonitorUnitCBText(
      `Are you sure you want to delete ${monitorUnit?.tag}?`
    );
  }

  //This function runs on clicking Cancel in Delete MonitorUnit
  function hideDeleteMonitorUnitConfirmationBox(e: any) {
    setDeleteMonitorUnitCBActive(false);
    setDeleteMonitorUnitCBText("");
  }

  //This function runs on clicking Yes after clicking Save in Delete MonitorUnit
  function handleDeleteMonitorUnit() {
    setDeleteMonitorUnitCBText("");

    deleteMonitorUnit(monitorUnit?.id!).then((res) => {
      if (res.status === 200 || res.status === 202) {
        setDeleteMonitorUnitCBText(
          `${monitorUnit?.tag} successfully deleted. You will now be redirected to the Site page.`
        );
      } else {
        setDeleteMonitorUnitCBText(
          `The following issue was encountered while attempting to delete ${monitorUnit?.tag}:\n\n${res.data.message}`
        );
      }
    });
    setDeleteMonitorUnitCBFinalDisplay(true);
  }

  //This function runs on clicking Ok after clicking Yes in Delete MonitorUnit
  function completeDeleteMonitorUnit(e: any) {
    setDeleteMonitorUnitCBActive(false);
    setDeleteMonitorUnitCBText("");
    setDeleteMonitorUnitCBFinalDisplay(false);
    navigate(`/sites/details/${monitorUnit?.site?.id}`);
  }

  //Control Unit Table states
  const [controlUnitsElements] = useState<string[]>([
    "Data",
    "Data",
    "Data",
    "Data",
    "RouteButton",
  ]);

  const [controlUnitsElementsInfo] = useState<string[]>([
    "",
    "",
    "",
    "",
    "View",
  ]);

  const [controlUnitsHeaders] = useState<string[]>([
    "tag",
    "control_unit_type.manufacturer",
    "control_unit_type.model",
    "comm_address",
    "",
  ]);

  const [controlUnitsOverwriteHeaders] = useState<string[]>([
    "Tag",
    "Manufacturer",
    "Model",
    "Comm Address",
    "",
  ]);

  const viewControlUnitOnClick = (controlUnit: any) => {
    navigate(
      `/controlunits/details/monitorunit/${params.monitorUnitId!}/${
        controlUnit?.id
      }`
    );
  };

  const [controlUnitsElementsOnClick] = useState<any[]>([
    null,
    null,
    null,
    null,
    viewControlUnitOnClick,
  ]);

  //Add ControlUnit
  const [showAddControlUnit, setShowAddControlUnit] = useState<boolean>(false);
  const [addControlUnitCBActive, setAddControlUnitCBActive] =
    useState<boolean>(false);
  const [addControlUnitCBText, setAddControlUnitCBText] = useState<string>("");
  const [retainedNewControlUnit, setRetainedNewControlUnit] = useState<
    IControlUnitCreateDTO | undefined
  >();

  function handleShowAddControlUnit() {
    if (showAddControlUnit) {
      setRetainedNewControlUnit(undefined);
    }
    setShowAddControlUnit(!showAddControlUnit);
  }

  //This function runs on clicking Save in Add ControlUnit
  function handleAddControlUnit(e: IControlUnit) {
    setShowAddControlUnit(false);
    setAddControlUnitCBActive(true);
    addNewControlUnit(e).then((res) => {
      if (res.status === 200 || res.status === 202) {
        setAddControlUnitCBText(`${e.tag} successfully added`);
        setRetainedNewControlUnit(undefined);
        setControlUnitsAfterAdd();
      } else {
        setAddControlUnitCBText(
          `The following issue was encountered while attempting to add ${e.tag}:\n\n${res.data.message}`
        );
        setRetainedNewControlUnit(e);
      }
    });
  }

  //This function runs on clicking OK after clicking Save in Add Alert
  function completeAddControlUnit() {
    setAddControlUnitCBActive(false);
    setAddControlUnitCBText("");

    if (retainedNewControlUnit !== undefined) {
      setShowAddControlUnit(true);
    }
  }

  function setControlUnitsAfterAdd() {
    setControlUnitsByMonitorUnitFromAPI(
      parseInt(params.monitorUnitId!),
      setLoading
    ).then((res: any) => {
      setControlUnits(res.data);
    });
  }

  if (
    loading ||
    userContext.loading ||
    monitorUnitBelongsToUserCompany === null ||
    monitorUnit === undefined
  ) {
    return (
      <div className="fullPageLoadingContainer">
        <Loading dataTestname="single-monitor-unit-loading" />
      </div>
    );
  } else {
    return (
      <StyledSingleMonitorUnitDiv>
        {monitorUnitBelongsToUserCompany && (
          <Breadcrumb
            dataTestname="single-monitor-unit-breadcrumbs"
            breadcrumbs={crumbs}
          />
        )}
        {monitorUnitBelongsToUserCompany ? (
          <StyledMainDiv data-testid="main-div">
            {monitorUnit !== null && (
              <DefaultContainer
                dataTestname="single-monitor-unit-light-default-container"
                darkBackground={true}
                title={"Monitor Unit"}
              >
                <InfoDisplay
                  dataTestname="single-monitor-unit-info-display"
                  datum={monitorUnit}
                  headers={[
                    "id",
                    "tag",
                    "monitor_unit_type.manufacturer",
                    "monitor_unit_type.model",
                  ]}
                  headerWidth={"7.5%"}
                  colonWidth={"3%"}
                  infoWidth={"Calc(100% - 107px - 42px)"}
                  containerWidth={"100%"}
                  headerMinWidth={"107px"}
                  colonMinWidth={"21px"}
                  infoMinWidth={"120px"}
                  containerMinWidth={"100%"}
                  rightSpacerWidth={"0%"}
                />
                <StyledButtonsDiv data-testid="buttons-div">
                  {userContext.permissions?.profire_manage_monitor_units && (
                    <EditButton
                      dataTestname="single-monitor-unit-edit-button"
                      onClickFunction={handleShowEditMonitorUnit}
                    />
                  )}
                  {userContext.permissions?.profire_manage_monitor_units && (
                    <DeleteButton
                      dataTestname="single-monitor-unit-delete-button"
                      onClickFunction={showDeleteMonitorUnitConfirmationBox}
                    />
                  )}
                  {userContext.permissions?.external_manage_tags && (
                    <StyledEditButtonDiv data-testid="edit-tag-button-div">
                      <ProfireButton
                        dataTestname="single-monitor-unit-edit-tag-button"
                        text={showEditTag ? "Stop Editing" : "Edit Tag"}
                        onClickFunction={handleShowEditTag}
                        size={4}
                      />
                    </StyledEditButtonDiv>
                  )}
                </StyledButtonsDiv>
                <ConfirmationBox
                  dataTestname="single-monitor-unit-edit-confirmation-box"
                  heading={"Information"}
                  message={editMonitorUnitCBText}
                  active={editMonitorUnitCBActive}
                  onOk={completeEditMonitorUnit}
                  onYes={handleEditMonitorUnit}
                  onCancel={hideEditMonitorUnitConfirmationBox}
                  displayCancel={true}
                  finalDisplay={editMonitorUnitCBFinalDisplay}
                />
                <ConfirmationBox
                  dataTestname="single-monitor-unit-delete-confirmation-box"
                  heading={"Information"}
                  message={deleteMonitorUnitCBText}
                  active={deleteMonitorUnitCBActive}
                  onOk={completeDeleteMonitorUnit}
                  onYes={handleDeleteMonitorUnit}
                  onCancel={hideDeleteMonitorUnitConfirmationBox}
                  displayCancel={true}
                  finalDisplay={deleteMonitorUnitCBFinalDisplay}
                />
                <ConfirmationBox
                  dataTestname="single-monitor-unit-edit-tag-confirmation-box"
                  heading={"Information"}
                  message={editTagCBText}
                  active={editTagCBActive}
                  onOk={completeEditMonitorUnitTag}
                  onYes={handleEditTag}
                  onCancel={hideEditTagConfirmationBox}
                  displayCancel={true}
                  finalDisplay={editTagCBFinalDisplay}
                />
                {showEditMonitorUnit && (
                  <EditMonitorUnit
                    active={showEditMonitorUnit}
                    monitorUnit={monitorUnit!}
                    onCancel={handleShowEditMonitorUnit}
                    onEdit={showEditMonitorUnitConfirmationBox}
                    retainedData={retainedEditMonitorUnit}
                  />
                )}
                {showEditTag && (
                  <StyledEditTagDiv
                    data-testid="edit-tag-div"
                    className={showEditTag ? "ease" : ""}
                  >
                    <CommonInput
                      dataTestname="single-monitor-unit-tag-common-input"
                      type={"text"}
                      labelText={"Tag"}
                      required={true}
                      readOnly={false}
                      value={tag}
                      onChange={handleTag}
                      maxLength={50}
                      placeholder={"Tag"}
                      errors={tagErrors}
                    />
                    <StyledFormButtonDiv data-testid="save-tag-button-div">
                      <ProfireButton
                        dataTestname="single-monitor-unit-save-tag-button"
                        text="Save Tag"
                        onClickFunction={showEditTagConfirmationBox}
                        size={4}
                      />
                    </StyledFormButtonDiv>
                  </StyledEditTagDiv>
                )}
              </DefaultContainer>
            )}
            <DefaultContainer
              dataTestname="single-monitor-unit-dark-default-container"
              darkBackground={false}
              title="Associated Control Units"
            >
              <ConfirmationBox
                dataTestname="single-site-add-process-unit-confirmation-box"
                heading={"Information"}
                message={addControlUnitCBText}
                active={addControlUnitCBActive}
                onOk={completeAddControlUnit}
                displayCancel={false}
              />
              {userContext.permissions?.profire_manage_control_units && (
                <StyledAddButtonDiv data-testid="add-control-unit-button-div">
                  <ProfireButton
                    dataTestname="single-monitor-unit-add-control-unit-button"
                    onClickFunction={handleShowAddControlUnit}
                    text="Add Control Unit"
                    size={6}
                  />
                  {showAddControlUnit && (
                    <AddControlUnit
                      active={showAddControlUnit}
                      siteId={monitorUnit.site!.id}
                      onCancel={handleShowAddControlUnit}
                      onAdd={handleAddControlUnit}
                      fromProcessUnit={false}
                      unit={monitorUnit}
                      retainedData={retainedNewControlUnit}
                    />
                  )}
                </StyledAddButtonDiv>
              )}
              <CommonCustomizableTable
                dataTestname="appliance-control-unit-common-customizable-table"
                usageIdentifier="control-unit"
                data={controlUnits}
                elements={controlUnitsElements}
                elementsInfo={controlUnitsElementsInfo}
                headers={controlUnitsHeaders}
                overwriteHeaders={controlUnitsOverwriteHeaders}
                tableWidth={"100%"}
                tableMinWidth={"100%"}
                tableMaxHeight={"400px"}
                desktopColWidths={["20%", "20%", "20%", "20%", "20%"]}
                desktopColMinWidths={["20%", "20%", "20%", "20%", "20%"]}
                mobileColWidths={["50%", "0px", "50%", "0px", "0px"]}
                mobileColMinWidths={["50%", "0px", "50%", "0px", "0px"]}
                elementsOnClick={controlUnitsElementsOnClick}
                textAlign={["left", "left", "left", "left", "right"]}
                textAlignMobile={["left", "left", "right", "left", "right"]}
                tdHeight={"45px"}
                tdHeightMobile={"33px"}
                tdMinHeight={"45px"}
                tdMinHeightMobile={"33px"}
                addPaddingRightForScroll={true}
                colDisplayOnMobile={[true, false, true, false, false]}
                colJustify={["start", "start", "start", "start", "end"]}
                colFlexDirection={[
                  "column",
                  "column",
                  "column",
                  "column",
                  "row",
                ]}
                mobileRowOnClick={viewControlUnitOnClick}
              />
              {userContext.permissions?.profire_manage_control_units && (
                <StyledAddButtonMobileDiv data-testid="add-control-unit-button-div">
                  <ProfireButton
                    dataTestname="single-monitor-unit-add-control-unit-button"
                    onClickFunction={handleShowAddControlUnit}
                    text="Add Control Unit"
                    size={6}
                  />
                  {showAddControlUnit && (
                    <AddControlUnit
                      active={showAddControlUnit}
                      siteId={monitorUnit.site!.id}
                      onCancel={handleShowAddControlUnit}
                      onAdd={handleAddControlUnit}
                      fromProcessUnit={false}
                      unit={monitorUnit}
                      retainedData={retainedNewControlUnit}
                    />
                  )}
                </StyledAddButtonMobileDiv>
              )}
            </DefaultContainer>
          </StyledMainDiv>
        ) : (
          <StyledMainDiv>
            <StyledBannerDiv data-testid="banner-div">
              <Banner
                dataTestname="single-monitor-unit-banner"
                headingtext={headingText}
                text={text}
                redBox={true}
              />
            </StyledBannerDiv>
          </StyledMainDiv>
        )}
      </StyledSingleMonitorUnitDiv>
    );
  }
};

export default SingleMonitorUnit;

const StyledSingleMonitorUnitDiv = styled.div`
  width: Calc(100% - 32px);
  max-width: Calc(1200px - 40px);
  min-width: Calc(320px - 40px);
  margin: 60px 16px 20px 16px;
  height: Calc(100% - 80px);
  background-color: white;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    margin: 60px 20px 20px 20px;
    width: Calc(100% - 40px);
    background-color: ${(props) => props.theme.bgColor};
  }
`;

const StyledMainDiv = styled.div`
  height: calc(100% - 33px);
  width: 100%;
`;

const StyledBannerDiv = styled.div`
  min-width: Calc(300px - 32px);
  width: Calc(100% - 32px);
  padding: 20px 16px;
  margin-bottom: 25px;
  border-radius: 10px;
`;

const StyledAddButtonDiv = styled.div`
  width: 100%;
  margin-bottom: 15px;
  text-align: right;
  display: none;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    display: block;
  }
`;

const StyledAddButtonMobileDiv = styled.div`
  width: 100%;
  margin-top: 12px;
  text-align: right;
  display: block;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    display: none;
  }
`;

const StyledEditButtonDiv = styled.div`
  width: 100%;
  margin-top: 5px;
  text-align: right;
`;

const StyledButtonsDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

const StyledEditTagDiv = styled.div`
  padding-top: 0px;
  padding-bottom: 0px;
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;

  input {
    text-align: left;
    font-size: ${(props) => props.theme.contentMainSize};
    font-weight: ${(props) => props.theme.contentMainWeight};
  }

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    padding-top: 10px;
    padding-bottom: 5px;
  }
`;

const StyledFormButtonDiv = styled.div`
  text-align: left;
  width: 100%;

  button {
    font-size: ${(props) => props.theme.contentMainSize};
    font-weight: ${(props) => props.theme.contentMainWeight};
  }
`;
