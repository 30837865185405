import React, { useReducer } from "react";
import { ActionTypes, ICompanyContext, ICompanyState } from "./types";
import CompanyReducer from "./CompanyReducer";
import CompanyContext from "./CompanyContext";
import axios from "axios";
import { getToken } from "../../utilities/msTokenFunc";

const CompanyProvider: React.FC<ICompanyContext> = (props) => {
  const initialState: ICompanyState = {
    companies: [],
    users: [],
    company: null,
    userBelongsToUserCompany: null,
    loading: true,
    companyBelongsToUser: null,
  };

  const [state, dispatch] = useReducer(CompanyReducer, initialState);

  const setCompanies = async () => {
    try {
      dispatch({ type: ActionTypes.SENDING_REQUEST });
      const token: string = await getToken();
      await axios
        .get(`${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/company/ready`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) =>
          dispatch({ type: ActionTypes.SET_COMPANIES, payload: res.data })
        );
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    } catch (error) {
      dispatch({ type: ActionTypes.SET_COMPANIES, payload: [] });
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    }
  };

  const setCompany = async (id: number) => {
    if (id === 0) {
      dispatch({ type: ActionTypes.SET_COMPANY, payload: null });
    } else {
      try {
        dispatch({ type: ActionTypes.SENDING_REQUEST });
        const token: string = await getToken();
        await axios
          .get(
            `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/company/${id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            dispatch({ type: ActionTypes.SET_COMPANY, payload: res.data });
            dispatch({
              type: ActionTypes.SET_USERS,
              payload: res.data.app_users,
            });
          });
        dispatch({ type: ActionTypes.REQUEST_FINISHED });
      } catch (error) {
        dispatch({ type: ActionTypes.SET_COMPANY, payload: null });
        dispatch({
          type: ActionTypes.SET_USERS,
          payload: [],
        });
        dispatch({ type: ActionTypes.REQUEST_FINISHED });
      }
    }
  };

  const setCompanyByUser = async (userId: string) => {
    try {
      dispatch({ type: ActionTypes.SENDING_REQUEST });
      const token: string = await getToken();
      await axios
        .get(
          `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/company/byuser/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          dispatch({
            type: ActionTypes.SET_COMPANY,
            payload: res.data,
          });
          dispatch({
            type: ActionTypes.SET_USERS,
            payload: res.data.app_users,
          });
        });
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    } catch (error) {
      dispatch({ type: ActionTypes.SET_COMPANY, payload: null });
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    }
  };

  const setCompanyByControlUnit = async (controlUnitId: number) => {
    try {
      dispatch({ type: ActionTypes.SENDING_REQUEST });
      const token: string = await getToken();
      await axios
        .get(
          `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/company/bycontrolunit/${controlUnitId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          dispatch({
            type: ActionTypes.SET_COMPANY,
            payload: res.data,
          });
        });
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    } catch (error) {
      dispatch({ type: ActionTypes.SET_COMPANY, payload: null });
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    }
  };

  const setUsers = async (companyId: number) => {
    try {
      dispatch({ type: ActionTypes.SENDING_REQUEST });
      const token: string = await getToken();
      await axios
        .get(
          `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/user/bycompany/${companyId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          dispatch({ type: ActionTypes.SET_USERS, payload: res.data });
        });
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    } catch (error) {
      dispatch({ type: ActionTypes.SET_USERS, payload: [] });
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    }
  };

  const setCompanyBelongsToUser = async (
    companyId: number,
    azureId: string
  ) => {
    try {
      dispatch({ type: ActionTypes.SENDING_REQUEST });

      const token: string = await getToken();
      await axios
        .get(
          `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/user/verifyusercompany/${companyId}/${azureId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          dispatch({
            type: ActionTypes.SET_COMPANY_BELONGS_TO_USER,
            payload: res.data,
          });
        });

      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    } catch (error) {
      dispatch({
        type: ActionTypes.SET_COMPANY_BELONGS_TO_USER,
        payload: false,
      });
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    }
  };

  const setUserBelongsToUserCompany = async (
    userId: number,
    azureId: string,
    isProfireUser: boolean
  ) => {
    try {
      dispatch({ type: ActionTypes.SENDING_REQUEST });
      if (isProfireUser) {
        dispatch({
          type: ActionTypes.SET_USER_BELONGS_TO_USER_COMPANY,
          payload: true,
        });
      } else {
        const token: string = await getToken();
        await axios
          .get(
            `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/user/verifycompanyuser/${userId}/${azureId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            dispatch({
              type: ActionTypes.SET_USER_BELONGS_TO_USER_COMPANY,
              payload: res.data,
            });
          });
      }
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    } catch (error) {
      dispatch({
        type: ActionTypes.SET_USER_BELONGS_TO_USER_COMPANY,
        payload: false,
      });
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    }
  };

  return (
    <CompanyContext.Provider
      value={{
        companies: state.companies,
        company: state.company,
        users: state.users,
        userBelongsToUserCompany: state.userBelongsToUserCompany,
        loading: state.loading,
        companyBelongsToUser: state.companyBelongsToUser,
        setCompanies: setCompanies,
        setCompany: setCompany,
        setCompanyByUser: setCompanyByUser,
        setCompanyByControlUnit: setCompanyByControlUnit,
        setUsers: setUsers,
        setCompanyBelongsToUser: setCompanyBelongsToUser,
        setUserBelongsToUserCompany: setUserBelongsToUserCompany,
      }}
    >
      {props.children}
    </CompanyContext.Provider>
  );
};

export default CompanyProvider;
