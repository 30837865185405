import React, { useCallback, useContext, useEffect, useRef } from "react";
import styled from "styled-components";
import UserContext from "../../../stores/users/UserContext";
import UserOption from "./UserOption";
import LogoutArrow from "../../../assets/images/header/logout-arrow.svg";
import { useMsal } from "@azure/msal-react";

interface UserOptionsProps {
  userOptionsDisplayed: boolean;
  initials: string;
  setUserOptionsDisplayed: Function;
  userIconRef: any;
  setLocallyAuthorized: Function;
  setAppLoading: Function;
}

const UserOptions: React.FC<UserOptionsProps> = ({
  userOptionsDisplayed,
  initials,
  setUserOptionsDisplayed,
  userIconRef,
  setLocallyAuthorized,
  setAppLoading,
}) => {
  const userOptionsRef = useRef<any>(null);

  const { instance, accounts } = useMsal();

  const handleLogout = async () => {
    await setAppLoading(true);
    await localStorage.clear();
    await setLocallyAuthorized(false);
    await instance.logoutPopup({
      account: accounts[0],
      mainWindowRedirectUri: "/",
      postLogoutRedirectUri: null,
    });
  };

  useEffect(() => {
    if (accounts.length === 0) {
      setAppLoading(false);
    }
  }, [accounts, setAppLoading]);

  const userContext = useContext(UserContext);

  const handleOutsideOfBoxClick = useCallback(
    (e: any) => {
      if (
        userOptionsRef.current &&
        !userOptionsRef.current?.contains(e.target) &&
        !userIconRef.current?.contains(e.target)
      ) {
        setUserOptionsDisplayed(false);
      }
    },
    [setUserOptionsDisplayed, userIconRef]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideOfBoxClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideOfBoxClick);
    };
  }, [handleOutsideOfBoxClick]);

  return (
    <StyledUserOptionsContainer
      userOptionsDisplayed={userOptionsDisplayed}
      data-testid="main-user-options-div"
      ref={userOptionsRef}
    >
      {/* === General user information (no options/clickables) === */}
      <StyledGeneralUserInfoDiv>
        <StyledUserIconDiv hoverable={false}>
          <StyledUserIconText data-testid="initials">
            {initials}
          </StyledUserIconText>
        </StyledUserIconDiv>
        <StyledTextSection>
          <StyledUserName data-testid="user-name">
            {userContext.user?.first_name + " " + userContext.user?.last_name}
          </StyledUserName>
          <StyledUserEmail data-testid="user-email">
            {userContext.user?.azure_username}
          </StyledUserEmail>
        </StyledTextSection>
      </StyledGeneralUserInfoDiv>
      {/* === Options go here === */}
      {/* Logout */}
      <UserOption
        dataTestname="user-options-sign-out-user-option"
        text="Sign Out"
        icon={LogoutArrow}
        onClickFunction={handleLogout}
      />
      {/* Add more options here when needed using the <UserOption /> component */}
    </StyledUserOptionsContainer>
  );
};

export default UserOptions;

interface UserOptionsContainerProps {
  userOptionsDisplayed: boolean;
}

const StyledUserOptionsContainer = styled.div<UserOptionsContainerProps>`
  z-index: 1;
  display: ${(props) => (props.userOptionsDisplayed ? "block" : "none")};
  min-width: 250px;
  box-shadow: 2px 2px 6px #00000029;
  border: 1px solid #e0e4e7;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 1px;
  position: absolute;
  top: 35px;
  right: 0px;
  z-index: 1;
`;

const StyledGeneralUserInfoDiv = styled.div`
  display: flex;
  justify-content: start;
  height: 35px;
  padding: 12px 13px;
`;

const StyledTextSection = styled.div``;

const StyledUserName = styled.p`
  font-size: ${(props) => props.theme.userOptionsMediumText};
  font-weight: ${(props) => props.theme.headingSecondaryWeight};
  color: #1f1f1f;
  margin: 0;
  text-align: left;
  display: block;
`;

const StyledUserEmail = styled.p`
  font-size: ${(props) => props.theme.userOptionsSmallText};
  font-weight: ${(props) => props.theme.contentMainWeight};
  color: #888a8d;
  margin: 0;
  text-align: left;
  display: block;
`;

interface UserIconDivProps {
  hoverable: boolean;
}

const StyledUserIconDiv = styled.div<UserIconDivProps>`
  flex-basis: 46px;
  margin-right: 11px;
  width: 35px;
  max-width: 35px;
  height: 34px;
  color: white;
  background-color: black;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  :hover {
    cursor: ${(props) => (props.hoverable ? "pointer" : "default")};
  }
`;

const StyledUserIconText = styled.p`
  font-weight: 600;
  text-transform: uppercase;
  line-height: 6px;
  font-size: ${(props) => props.theme.userOptionsMediumText};
`;
