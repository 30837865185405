import { useState } from "react";
import styled from "styled-components";
import CopyImage from "../../../assets/images/clickables/copy.svg";
// Reusable Click To Copy Box Instructions
// 1. Encase <ClickToCopyBox valueToDisplayAndCopy={string that you want to display and have copied to the user's clipboard on click} readOnly={boolean to allow the user to change the text to be copied or not} /> in a Styled div}
// 2. Ensure the valueToDisplayAndCopy is passed in as a string. What you pass in is exactly what will be copied.
// 3. You will need to set readOnly to either true or false, depending on the purpose of your page
//    If you need to display a single value and ensure the user can copy it simply by clicking, use true.
//    If you DO need to allow the user to change the input, use false.
//    If the readOnly prop is not given any data, it will default to true, AKA, read only.
// 4. Please do not touch the styling in this file. If you *do* need to change styling, please talk to TJ.
// 5. If there are any issues, please talk to TJ.

interface ClickToCopyBoxProps {
  dataTestname: string;
  valueToDisplayAndCopy: string;
}

const ClickToCopyBox: React.FC<ClickToCopyBoxProps> = ({
  dataTestname,
  valueToDisplayAndCopy,
}) => {
  const [displayToolTip, setDisplayToolTip] = useState<boolean>(false);
  function setTooltipToCopied() {
    var tooltip = document.getElementById(`myTooltip + ${dataTestname}`);
    navigator.clipboard.writeText(valueToDisplayAndCopy);
    setDisplayToolTip(true);
    tooltip!.innerHTML = "Copied!";
  }

  function setTooltipToDefault() {
    var tooltip = document.getElementById(`myTooltip + ${dataTestname}`);
    setDisplayToolTip(false);
    tooltip!.innerHTML = "";
  }

  return (
    <StyledClickToCopyDiv
      data-testname={dataTestname}
      displayToolTip={displayToolTip}
      data-testid="tooltip-div"
    >
      <StyledP>{valueToDisplayAndCopy}</StyledP>

      <StyledImgDiv className="tooltip" onMouseLeave={setTooltipToDefault}>
        <img src={CopyImage} onClick={setTooltipToCopied} alt="copy icon" />

        <span
          className="tooltiptext"
          id={`myTooltip + ${dataTestname}`}
          data-testid="tooltip-text"
        />
      </StyledImgDiv>
    </StyledClickToCopyDiv>
  );
};

export default ClickToCopyBox;

interface DisplayProps {
  displayToolTip: boolean;
}

const StyledClickToCopyDiv = styled.div<DisplayProps>`
  display: flex;

  p {
    font-family: "Roboto Mono", monospace;
    margin: 0;
  }

  span {
    width: 14px;
    display: ${(props) => (props.displayToolTip ? "block" : "none")};
  }
`;

const StyledImgDiv = styled.div`
  img {
    width: 14px;
    height: auto;
    margin-left: 14px;
    :hover {
      cursor: pointer;
    }
  }
`;

const StyledP = styled.p`
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
  word-break: break-all;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    word-break: normal;
  }
`;
