import { ICompany } from "../../types/companies";
import { NewIUser } from "../../types/users";

export interface ICompanyContext {
  companies: ICompany[];
  company: ICompany | null;
  users: NewIUser[];
  userBelongsToUserCompany: boolean | null;
  loading: boolean;
  companyBelongsToUser: boolean | null;
  setCompanies: Function;
  setCompany: Function;
  setCompanyByUser: Function;
  setCompanyByControlUnit: Function;
  setUsers: Function;
  setCompanyBelongsToUser: Function;
  setUserBelongsToUserCompany: Function;
  children?: React.ReactNode;
}

export interface ICompanyState {
  companies: ICompany[];
  company: ICompany | null;
  users: NewIUser[];
  userBelongsToUserCompany: boolean | null;
  loading: boolean;
  companyBelongsToUser: boolean | null;
}

export enum ActionTypes {
  SET_COMPANIES = "SET_COMPANIES",
  SET_COMPANY = "SET_COMPANY",
  SET_USERS = "SET_USERS",
  SET_COMPANY_BELONGS_TO_USER = "SET_COMPANY_BELONGS_TO_USER",
  SET_USER_BELONGS_TO_USER_COMPANY = "SET_USER_BELONGS_TO_USER_COMPANY",
  SENDING_REQUEST = "SENDING_REQUEST",
  REQUEST_FINISHED = "REQUEST_FINISHED",
}

export interface CompanyAction {
  type: ActionTypes;
  payload: number | string | boolean | ICompany | ICompany[] | NewIUser[];
}
