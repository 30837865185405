import React from "react";
import styled from "styled-components";

interface ModalTextAreaProps {
  dataTestname: string;
  value: string;
  rows?: number;
  onChange?: Function;
  labelText?: string;
  subLabelText?: string;
  errors?: string;
  readOnly?: boolean;
  placeholder?: string;
  minLength?: number;
  maxLength?: number;
  hideErrorsDiv?: boolean;
  hintText?: string;
  autoFocus?: boolean;
}

const ModalTextArea: React.FC<ModalTextAreaProps> = ({
  dataTestname,
  value,
  rows,
  onChange,
  labelText,
  subLabelText,
  errors,
  readOnly,
  placeholder,
  minLength,
  maxLength,
  hideErrorsDiv,
  hintText,
  autoFocus = false,
}) => {
  return (
    <StyledTextAreaDiv data-testname={dataTestname}>
      {labelText ? (
        <>
          <StyledTextAreaLabel
            data-testid="input-label"
            subLabelExists={subLabelText === undefined ? false : true}
          >
            {labelText}
          </StyledTextAreaLabel>
          <StyledTextAreaSubLabel>{subLabelText}</StyledTextAreaSubLabel>
        </>
      ) : (
        <></>
      )}
      <StyledP>{hintText}</StyledP>
      <StyledTextArea
        data-testname={dataTestname}
        data-testid="input-area"
        rows={rows}
        placeholder={placeholder ? placeholder : ""}
        readOnly={readOnly ? readOnly : false}
        value={value}
        onChange={onChange && ((e) => onChange!(e))}
        minLength={minLength}
        maxLength={maxLength}
        autoFocus={autoFocus}
      />
      {!hideErrorsDiv ? (
        <StyledErrorMessage data-testid="input-errors">
          {errors}
        </StyledErrorMessage>
      ) : (
        <></>
      )}
    </StyledTextAreaDiv>
  );
};

export default ModalTextArea;

const StyledTextAreaDiv = styled.div`
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`;

interface TextAreaProps {
  readOnly: boolean;
}

interface LabelProps {
  subLabelExists: boolean;
}
const StyledTextAreaLabel = styled.label<LabelProps>`
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
  margin-bottom: ${(props) => (props.subLabelExists ? "0px" : "6px")};
  text-align: left;
  margin: 0;
`;

const StyledTextAreaSubLabel = styled.label`
  display: block;
  text-align: left;
  font-size: ${(props) => props.theme.subLabelTextSize};
  font-weight: ${(props) => props.theme.subLabelTextWeight};
  color: ${(props) => props.theme.subLabelTextColor};
  margin-bottom: 5px;
`;

const StyledTextArea = styled.textarea<TextAreaProps>`
  font-family: "Open Sans";
  resize: none;
  outline: none;
  padding: 12px;
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
  color: #292929;
`;

const StyledP = styled.p`
  color: #888a8d;
  margin: 0;
  margin-top: -7px;
  margin-bottom: 5px;
  text-align: left;
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
`;

const StyledErrorMessage = styled.div`
  text-align: left;
  color: #9c1b30;
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
  padding-bottom: 20px;

  width: 100%;
`;
