import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Loading from "../components/common/modals/Loading";
import CollapsibleLinkTable from "../components/common/tables/CollapsibleLinkTable";
import CommonCompanySelect from "../components/common/formInputs/CommonCompanySelect";
import DefaultContainer from "../components/common/containers/DefaultContainer";
import UserContext from "../stores/users/UserContext";
import Map, {
  FullscreenControl,
  GeolocateControl,
  Marker,
  NavigationControl,
  Popup,
  ScaleControl,
} from "react-map-gl";

import "mapbox-gl/dist/mapbox-gl.css";
import CollapsibleDiv from "../components/common/containers/CollapsibleDiv";
import { IControlUnitWithStatusDTO } from "../types/controlUnits";
import mapboxgl from "mapbox-gl";
import { openInNewTab } from "../utilities/secureNewTab";
import ChartIcon from "../assets/images/navigation/chart-icon.svg";
import StatusGoodIcon from "../assets/images/sites/green-status-circle.svg";
import StatusBadIcon from "../assets/images/sites/red-status-circle.svg";
import { ISite, ISiteCreateDTO, ISiteWithStatusDTO } from "../types/sites";
import { addNewSite, setFlatSitesFromAPI } from "../utilities/apiCalls/sites";
import { IProcessUnitWithStatusDTO } from "../types/processUnits";
import { IMonitorUnitWithStatusDTO } from "../types/monitorUnits";
import ProfireButton from "../components/common/buttons/ProfireButton";
import AddSite from "../components/sites/AddSite";
import ConfirmationBox from "../components/common/modals/ConfirmationBox";
import { ICompany } from "../types/companies";
import { getReadyCompanies } from "../utilities/apiCalls/companies";
import StyleContext from "../stores/styles/StyleContext";

interface SitesProps {
  companyId: number;
}

const Sites: React.FC<SitesProps> = ({ companyId }) => {
  const userContext = useContext(UserContext);
  const userContextRef = useRef(userContext).current;
  const styleContext = useContext(StyleContext);
  const params = useParams();

  const [loading, setLoading] = useState<boolean>(true);
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const [sites, setSites] = useState<ISiteWithStatusDTO[]>();
  const [processUnits, setProcessUnits] = useState<IProcessUnitWithStatusDTO[]>(
    []
  );
  const [monitorUnits, setMonitorUnits] = useState<IMonitorUnitWithStatusDTO[]>(
    []
  );
  const [controlUnits, setControlUnits] = useState<IControlUnitWithStatusDTO[]>(
    []
  );

  const [chosenCompany, setChosenCompany] = useState<number>(
    !userContext.permissions?.profire_select_company
      ? companyId
      : params.companyId === undefined || params.companyId === null
      ? 1
      : parseInt(params.companyId!)
  );

  //on page load
  useEffect(() => {
    //if the user is not a profire user, set company using user's companyId
    if (!userContextRef.permissions?.profire_select_company) {
      setFlatSitesFromAPI(parseInt(companyId.toString()), setLoading).then(
        (res: any) => {
          setSites(res.data.sites);
          setProcessUnits(
            res.data.process_units === undefined ? [] : res.data.process_units
          );
          setMonitorUnits(
            res.data.monitor_units === undefined ? [] : res.data.monitor_units
          );
          setControlUnits(
            res.data.control_units === undefined ? [] : res.data.control_units
          );
        }
      );
      //else if the user is a profire user, set companies
    } else {
      getReadyCompanies(setLoading).then((res: any) => {
        setCompanies(res.data);
      });
    }
  }, [userContextRef, companyId]);

  function updateChosenCompany(newChosen: string) {
    setChosenCompany(parseInt(newChosen));
  }

  //when chosen company is updated
  useEffect(() => {
    if (
      chosenCompany !== undefined &&
      chosenCompany !== null &&
      chosenCompany !== 0
    ) {
      setPopupInfo(null); //closes the open popup
      setSites([]);
      setProcessUnits([]);
      setMonitorUnits([]);
      setControlUnits([]);

      setFlatSitesFromAPI(chosenCompany, setLoading).then((res: any) => {
        setSites(res.data.sites);
        setProcessUnits(
          res.data.process_units === undefined ? [] : res.data.process_units
        );
        setMonitorUnits(
          res.data.monitor_units === undefined ? [] : res.data.monitor_units
        );
        setControlUnits(
          res.data.control_units === undefined ? [] : res.data.control_units
        );

        let assigned: boolean = false;
        if (res.data.sites !== undefined && res.data.sites.length > 0) {
          res.data.sites.forEach((site: ISite) => {
            if (
              site.latitude !== null &&
              site.longitude !== null &&
              assigned === false
            ) {
              setViewState({
                latitude: site.latitude!,
                longitude: site.longitude!,
                zoom: 3,
              });
              assigned = true;
            }
          });
        }
        if (assigned === false) {
          setViewState({
            latitude: 47,
            longitude: -101.36249531152085,
            zoom: 3,
          });
        }
      });
    }
  }, [chosenCompany]);

  let companiesArray: [string, string][] = [];

  if (userContext.permissions?.profire_select_company) {
    //populate companiesArray for dropdown
    for (let index = 0; index < companies.length; index++) {
      companiesArray.push([
        companies[index].name,
        companies[index].id.toString(),
      ]);
    }
  }

  const [mapDivActive, setMapDivActive] = useState<boolean>(true);
  function handleShowMapDiv() {
    setMapDivActive(!mapDivActive);
  }

  //Add Site
  const [showAddSite, setShowAddSite] = useState<boolean>(false);
  const [addSiteCBActive, setAddSiteCBActive] = useState<boolean>(false);
  const [addSiteCBText, setAddSiteCBText] = useState<string>("");
  const [retainedNewSite, setRetainedNewSite] = useState<
    ISiteCreateDTO | undefined
  >();

  //this function toggles whether Add Site modal is shown
  function handleShowAddSite() {
    if (showAddSite) {
      setRetainedNewSite(undefined);
    }
    setShowAddSite(!showAddSite);
  }

  //This function runs on clicking Save in Add Site
  function handleAddSite(e: ISiteCreateDTO) {
    setShowAddSite(false);
    setAddSiteCBActive(true);
    addNewSite(e).then((res) => {
      if (res.status === 200 || res.status === 202) {
        setAddSiteCBText(`${e.facility_name} successfully added`);
        setRetainedNewSite(undefined);
        setSitesAfterAdd();
      } else {
        setAddSiteCBText(
          `The following issue was encountered while attempting to add ${e.facility_name}:\n\n${res.data.message}`
        );
        setRetainedNewSite(e);
      }
    });
  }

  //This function runs on clicking OK after clicking Save in Add Alert
  function completeAddSite() {
    setAddSiteCBActive(false);
    setAddSiteCBText("");

    if (retainedNewSite !== undefined) {
      setShowAddSite(true);
    }
  }

  //this function updates the list of sites after a new one has been added
  function setSitesAfterAdd() {
    setPopupInfo(null); //closes the open popup
    setProcessUnits([]);
    setMonitorUnits([]);
    setControlUnits([]);

    setFlatSitesFromAPI(chosenCompany, setLoading).then((res: any) => {
      setSites(res.data.sites);
      setProcessUnits(
        res.data.process_units === undefined ? [] : res.data.process_units
      );
      setMonitorUnits(
        res.data.monitor_units === undefined ? [] : res.data.monitor_units
      );
      setControlUnits(
        res.data.control_units === undefined ? [] : res.data.control_units
      );

      let assigned: boolean = false;
      if (res.data.sites !== undefined && res.data.sites.length > 0) {
        res.data.sites.forEach((site: ISite) => {
          if (
            site.latitude !== null &&
            site.longitude !== null &&
            assigned === false
          ) {
            setViewState({
              latitude: site.latitude!,
              longitude: site.longitude!,
              zoom: 3,
            });
            assigned = true;
          }
        });
      }
      if (assigned === false) {
        setViewState({
          latitude: 47,
          longitude: -101.36249531152085,
          zoom: 3,
        });
      }
    });
  }

  //MAPBOX STUFF
  //this static lat/long should be swapped once we have the inclusive site object being returned.
  const [viewState, setViewState] = React.useState({
    latitude: 47,
    longitude: -101.36249531152085,
    zoom: 3,
  });

  const mapboxToken =
    "pk.eyJ1Ijoic2R1YmxhbmtvIiwiYSI6ImNsNWE0eTN0NTA0aG8zaXRkb2o3cWF0aG8ifQ.MTU0YF2D1ZOWxxOo_5R7tw";

  interface info {
    latitude: number;
    longitude: number;
    facility_name: string;
    company_id: number;
    control_units: IControlUnitWithStatusDTO[];
    site_id: number;
  }
  const [popupInfo, setPopupInfo] = useState<info | null>(null);
  const pins = useMemo(
    () =>
      sites !== undefined &&
      controlUnits !== undefined &&
      sites.map(
        (site, index) =>
          site.latitude !== undefined &&
          site.latitude !== null &&
          site.longitude !== undefined &&
          site.longitude !== null && (
            <Marker
              key={`marker-${index}`}
              longitude={site.longitude}
              latitude={site.latitude}
              color={site.alerting ? "#9c1b30" : "#00B207"}
              anchor="top"
              offset={new mapboxgl.Point(0, -50 / 2)}
              onClick={(e) => {
                // If we let the click event propagates to the map, it will immediately close the popup
                // with `closeOnClick: true`
                e.originalEvent.stopPropagation();
                setPopupInfo({
                  longitude: site.longitude!,
                  latitude: site.latitude!,
                  facility_name: site.facility_name,
                  company_id: site.company_id,
                  control_units: controlUnits,
                  site_id: site.id,
                });
              }}
            ></Marker>
          )
      ),
    [sites, controlUnits]
  );

  function ctrlScroll(event: any) {
    if (event.originalEvent.ctrlKey) {
      return;
    }

    if (event.originalEvent.metaKey) {
      return;
    }

    if (event.originalEvent.altKey) {
      return;
    }

    event.preventDefault();
  }

  const navigate = useNavigate();
  const viewMapOnClick = () => {
    navigate(`/sites/map/${chosenCompany}`);
  };

  if (loading) {
    return (
      <div className="fullPageLoadingContainer">
        <Loading dataTestname="sites-loading" />
      </div>
    );
  } else {
    return (
      <StyledSitesDiv>
        <StyledMainDiv data-testid="main-div">
          {userContext.permissions?.profire_select_company && (
            <CommonCompanySelect
              dataTestname="sites-common-company-select"
              data={companiesArray}
              selected={chosenCompany.toString()}
              onChange={updateChosenCompany}
              displayAddCompanyButton={false}
            />
          )}
          {chosenCompany > 0 && !styleContext.isMobile && (
            <CollapsibleDiv
              dataTestname="sites-map-collapsible-div"
              title="Map"
              open={mapDivActive}
              toggleFunction={handleShowMapDiv}
            >
              <Map
                {...viewState}
                onMove={(evt) => setViewState(evt.viewState)}
                onWheel={(e) => ctrlScroll(e)}
                style={{
                  width: "100%",
                  height: 600,
                  float: "left",
                  overflow: "clip",
                }}
                mapStyle="mapbox://styles/mapbox/light-v10"
                mapboxAccessToken={mapboxToken}
              >
                <GeolocateControl position="top-left" />
                <FullscreenControl position="top-left" />
                <NavigationControl position="top-left" />
                <ScaleControl />

                {pins}

                {popupInfo && (
                  <Popup
                    anchor="bottom"
                    longitude={Number(popupInfo.longitude)}
                    latitude={Number(popupInfo.latitude)}
                    onClose={() => setPopupInfo(null)}
                    style={{ maxWidth: "unset", whiteSpace: "pre-line" }}
                  >
                    <StyledPopupInnerDiv>
                      <StyledPopupSiteName>{`${popupInfo.facility_name}`}</StyledPopupSiteName>
                      {popupInfo.control_units.map(
                        (unit, index) =>
                          processUnits !== undefined &&
                          processUnits.find(
                            (pu) => unit.process_unit_id === pu.id
                          ) !== undefined &&
                          processUnits.find(
                            (pu) => unit.process_unit_id === pu.id
                          )!.site_id === popupInfo.site_id && (
                            <StyledTagAndLinkDiv key={unit.tag + index}>
                              <div>
                                <StyledStatusDot
                                  src={
                                    unit.alerting
                                      ? StatusBadIcon
                                      : StatusGoodIcon
                                  }
                                  alt="status good icon"
                                />
                                <StyledLink
                                  href={`/controlunits/details/processunit/${unit.process_unit_id}/${unit.id}`}
                                >
                                  {unit.tag}
                                </StyledLink>
                              </div>
                              <StyledGrafanaLink
                                onClick={() =>
                                  openInNewTab(unit.dashboard_url!)
                                }
                                src={ChartIcon}
                                alt="chart icon"
                              />
                            </StyledTagAndLinkDiv>
                          )
                      )}
                    </StyledPopupInnerDiv>
                  </Popup>
                )}
              </Map>
            </CollapsibleDiv>
          )}
          <StyledMobileViewMapDiv>
            <ProfireButton
              dataTestname="mobile-view-map-button"
              text="View Map"
              onClickFunction={viewMapOnClick}
            />
          </StyledMobileViewMapDiv>
          {chosenCompany > 0 && (
            <StyledCommonTableDiv>
              <ConfirmationBox
                dataTestname="sites-add-site-confirm-confirmation-box"
                heading={"Information"}
                message={addSiteCBText}
                active={addSiteCBActive}
                onOk={completeAddSite}
                displayCancel={false}
              />
              <DefaultContainer
                dataTestname="sites-dark-default-container"
                darkBackground={true}
                title="Sites"
                overwritePadding="55px 0px 20px 0px"
                overwriteWidth="100%"
              >
                {userContext.permissions?.profire_manage_sites && (
                  <StyledAddButtonDiv data-testid="add-button-div">
                    <ProfireButton
                      dataTestname="sites-add-site-profire-button"
                      onClickFunction={handleShowAddSite}
                      text="Add Site"
                    />
                  </StyledAddButtonDiv>
                )}
                {showAddSite && (
                  <AddSite
                    active={showAddSite}
                    companyId={chosenCompany}
                    onCancel={handleShowAddSite}
                    onAdd={handleAddSite}
                    retainedData={retainedNewSite}
                  />
                )}
                <CollapsibleLinkTable
                  dataTestname="sites-collapsible-link-table"
                  headers={["", "facility_name", "facility_address"]}
                  overwriteHeaders={["", "Name", "Address"]}
                  data={sites !== undefined ? sites : []}
                  collapseHeaders={["tag", "tag", "tag"]}
                  collapseOverwriteHeaders={[
                    "Control Unit",
                    "Appliance",
                    "Monitor Unit",
                    "Chart",
                  ]}
                  collapseCUs={controlUnits === undefined ? [] : controlUnits}
                  collapseMUs={monitorUnits === undefined ? [] : monitorUnits}
                  collapsePUs={processUnits === undefined ? [] : processUnits}
                  grafanaUrlProperty={"dashboard_url"}
                  viewButtonUrl={`/sites/details/`}
                  viewButtonUrlAppendProperty={"id"}
                  collapseDatumFieldToMatchAppendProperty={[
                    "process_unit",
                    "site_id",
                  ]}
                />
                {userContext.permissions?.profire_manage_sites && (
                  <StyledAddButtonMobileDiv data-testid="add-button-div">
                    <ProfireButton
                      dataTestname="sites-add-site-profire-button"
                      onClickFunction={handleShowAddSite}
                      text="Add Site"
                    />
                  </StyledAddButtonMobileDiv>
                )}
              </DefaultContainer>
            </StyledCommonTableDiv>
          )}
        </StyledMainDiv>
      </StyledSitesDiv>
    );
  }
};

export default Sites;

const StyledSitesDiv = styled.div`
  width: Calc(100% - 32px);
  max-width: Calc(1200px - 40px);
  min-width: Calc(320px - 40px);
  margin: 80px 16px 20px 16px;
  height: Calc(100% - 100px);
  background-color: white;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    margin: 80px 20px 20px 20px;
    width: Calc(100% - 40px);
    background-color: ${(props) => props.theme.bgColor};
  }
`;

const StyledMainDiv = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledCommonTableDiv = styled.div`
  width: 100%;
  background-color: #ffffff;
  border-radius: 4px;
  text-align: left;
  padding-bottom: 20px;
`;

const StyledAddButtonDiv = styled.div`
  width: calc(100% - 30px);
  margin-bottom: 5px;
  text-align: right;
  display: none;
  padding-right: 30px;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    display: block;
  }
`;

const StyledAddButtonMobileDiv = styled.div`
  padding-right: 10px;
  width: Calc(100% - 10px);
  margin-top: 12px;
  text-align: right;
  display: block;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    display: none;
  }
`;

const StyledMobileViewMapDiv = styled.div`
  width: 100%;
  margin-bottom: 20px;
  text-align: left;
  display: inline-flex;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    display: none;
  }
`;

const StyledPopupInnerDiv = styled.div`
  padding: 18px 18px 13px 18px;
  width: max-content;
`;

const StyledStatusDot = styled.img`
  margin-right: 5px;
`;

const StyledLink = styled.a`
  color: #9c1b30;
  text-align: left;

  :visited {
    color: #9c1b30;
  }
`;

const StyledPopupSiteName = styled.h2`
  font-size: ${(props) => props.theme.headingMapSize};
  font-weight: ${(props) => props.theme.headingMapWeight};
  margin: 0;
  margin-bottom: 12px;
`;

const StyledGrafanaLink = styled.img`
  cursor: pointer;
  margin-left: 5px;
`;

const StyledTagAndLinkDiv = styled.div`
  min-width: max-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: ${(props) => props.theme.contentMapSize};
  font-weight: ${(props) => props.theme.contentMapWeight};
`;
