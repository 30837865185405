import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Dropdown from "../common/formInputs/Dropdown";
import SiteContext from "../../stores/sites/SiteContext";
import ProcessUnitContext from "../../stores/processUnits/ProcessUnitContext";
import MonitorUnitContext from "../../stores/monitorUnits/MonitorUnitContext";
import { IControlUnitCreateDTO } from "../../types/controlUnits";
import LookupContext from "../../stores/lookup/LookupContext";
import { validateTextString } from "../../utilities/validationFunctions";
import UserContext from "../../stores/users/UserContext";
import Banner from "../common/headers/Banner";
import CommonInput from "../common/formInputs/CommonInput";
import ProfireButton from "../common/buttons/ProfireButton";
import ModalBox from "../common/modals/ModalBox";
import { IProcessUnit } from "../../types/processUnits";
import { IMonitorUnit } from "../../types/monitorUnits";

interface AddControlUnitProps {
  active: boolean;
  siteId: number;
  onCancel?: () => void;
  onAdd: Function;
  fromProcessUnit: boolean;
  unit: IProcessUnit | IMonitorUnit;
  retainedData: IControlUnitCreateDTO | undefined;
}

let existingErrors: Boolean = false;

const AddControlUnit: React.FC<AddControlUnitProps> = ({
  active,
  siteId,
  onCancel,
  onAdd,
  fromProcessUnit,
  unit,
  retainedData,
}) => {
  const userContext = useContext(UserContext);
  const lookupContext = useContext(LookupContext);
  const lookupContextRef = useRef(lookupContext).current;
  const siteContext = useContext(SiteContext);
  const siteContextRef = useRef(siteContext).current;
  const processUnitContext = useContext(ProcessUnitContext);
  const processUnitContextRef = useRef(processUnitContext).current;
  const monitorUnitContext = useContext(MonitorUnitContext);
  const monitorUnitContextRef = useRef(monitorUnitContext).current;

  let headingText: string = "Insufficient Access";
  let text: string =
    "You do not have sufficient access to use this page. If you believe this is an error, please contact technical support.";
  useEffect(() => {
    siteContextRef.setSite(siteId);
    processUnitContextRef.setProcessUnitsBySiteId(siteId);
    monitorUnitContextRef.setMonitorUnitsBySiteId(siteId);
    lookupContextRef.setControlUnitTypes();
  }, [
    siteContextRef,
    processUnitContextRef,
    monitorUnitContextRef,
    lookupContextRef,
    siteId,
  ]);

  const [chosenProcessUnit, setChosenProcessUnit] = useState<number>(
    retainedData === undefined
      ? fromProcessUnit === true
        ? unit.id
        : 0
      : retainedData.process_unit_id
  );
  function updateChosenProcessUnit(newChosen: string) {
    setChosenProcessUnit(parseInt(newChosen));
    setChosenProcessUnitErrors("");
  }
  let processUnitsArray: [string, string][] = [];
  //populate processUnitsArray for dropdown
  processUnitsArray.push(["Select an appliance...", "0"]);
  for (let index = 0; index < processUnitContext.processUnits.length; index++) {
    processUnitsArray.push([
      processUnitContext.processUnits[index].tag,
      processUnitContext.processUnits[index].id.toString(),
    ]);
  }

  const [chosenMonitorUnit, setChosenMonitorUnit] = useState<number>(
    retainedData === undefined
      ? fromProcessUnit === false
        ? unit.id
        : -1
      : retainedData.monitor_unit_id === null
      ? 0
      : retainedData.monitor_unit_id
  );
  function updateChosenMonitorUnit(newChosen: string) {
    setChosenMonitorUnit(parseInt(newChosen));
    setChosenMonitorUnitErrors("");
  }
  let monitorUnitsArray: [string, string][] = [];
  //populate monitorUnitsArray for dropdown
  monitorUnitsArray.push(["Select a monitor unit...", "-1"]);
  monitorUnitsArray.push(["None", "0"]);
  for (let index = 0; index < monitorUnitContext.monitorUnits.length; index++) {
    monitorUnitsArray.push([
      monitorUnitContext.monitorUnits[index].tag,
      monitorUnitContext.monitorUnits[index].id.toString(),
    ]);
  }

  const [chosenControlUnitType, setChosenControlUnitType] = useState<number>(
    retainedData === undefined ? 0 : retainedData.control_unit_type_id
  );
  function updateChosenControlUnitType(newChosen: string) {
    setChosenControlUnitType(parseInt(newChosen));
    setChosenControlUnitTypeErrors("");
  }
  let controlUnitTypeArray: [string, string][] = [];
  //populate controlUnitTypeArray for dropdown
  controlUnitTypeArray.push(["Select a control unit type...", "0"]);
  for (let index = 0; index < lookupContext.controlUnitTypes.length; index++) {
    controlUnitTypeArray.push([
      lookupContext.controlUnitTypes[index].manufacturer +
        ": " +
        lookupContext.controlUnitTypes[index].model,
      lookupContext.controlUnitTypes[index].id.toString(),
    ]);
  }

  const [chosenProcessUnitErrors, setChosenProcessUnitErrors] =
    useState<string>("");
  const [chosenMonitorUnitErrors, setChosenMonitorUnitErrors] =
    useState<string>("");
  const [chosenControlUnitTypeErrors, setChosenControlUnitTypeErrors] =
    useState<string>("");

  const [tag, setTag] = useState<string>(
    retainedData === undefined ? "" : retainedData.tag
  );
  const [tagErrors, setTagErrors] = useState<string>("");

  const handleTag = (e: React.FormEvent<HTMLInputElement>) => {
    if (
      !validateTextString(e.currentTarget.value) &&
      e.currentTarget.value !== ""
    ) {
      setTag(e.currentTarget.value);
      setTagErrors(
        "First character must be alphanumeric and only alphanumeric, spaces, and the following are supported after: | _ : , . # - / '"
      );
    } else {
      setTag(e.currentTarget.value);
      setTagErrors("");
    }
  };

  const [commAddress, setCommAddress] = useState<string>(
    retainedData === undefined ? "" : retainedData.comm_address
  );
  const [commAddressErrors, setCommAddressErrors] = useState<string>("");

  const handleCommAddress = (e: React.FormEvent<HTMLInputElement>) => {
    if (
      !validateTextString(e.currentTarget.value) &&
      e.currentTarget.value !== ""
    ) {
      setCommAddress(e.currentTarget.value);
      setCommAddressErrors(
        "First character must be alphanumeric and only alphanumeric, spaces, and the following are supported after: | _ : , . # - / '"
      );
    } else {
      setCommAddress(e.currentTarget.value);
      setCommAddressErrors("");
    }
  };

  function handleCancel() {
    existingErrors = false;
    onCancel!();
  }

  function handleExistingErrors(errors: Boolean) {
    existingErrors = errors;
  }

  function clearErrors() {
    setChosenProcessUnitErrors("");
    setChosenMonitorUnitErrors("");
    setChosenControlUnitTypeErrors("");
    setCommAddressErrors("");
    setTagErrors("");

    handleExistingErrors(false);
  }

  function ErrorHandler() {
    clearErrors();

    if (chosenMonitorUnit === -1) {
      setChosenMonitorUnitErrors("Monitor unit is required.\n");
      handleExistingErrors(true);
    }
    if (chosenMonitorUnit > 0 && !commAddress) {
      setCommAddressErrors(
        "Comm Address is required while a Monitor Unit is selected.\n"
      );
      handleExistingErrors(true);
    }
    if (!chosenControlUnitType) {
      setChosenControlUnitTypeErrors("Control Unit Type is required.\n");
      handleExistingErrors(true);
    }
    if (chosenControlUnitType === 0) {
      setChosenControlUnitTypeErrors("Control Unit Type is required.\n");
      handleExistingErrors(true);
    }
    if (commAddress) {
      if (!validateTextString(commAddress)) {
        setCommAddressErrors(
          "First character must be alphanumeric and only alphanumeric, spaces, and the following are supported after: | _ : , . # - / '"
        );
        handleExistingErrors(true);
      }
    }
    if (!tag) {
      setTagErrors("Tag is required.\n");
      handleExistingErrors(true);
    }
    if (tag) {
      if (!validateTextString(tag)) {
        setTagErrors(
          "First character must be alphanumeric and only alphanumeric, spaces, and the following are supported after: | _ : , . # - / '"
        );
        handleExistingErrors(true);
      }
    }
  }

  function onSubmit(e: any) {
    e.preventDefault();

    if (!existingErrors) {
      const newControlUnit: IControlUnitCreateDTO = {
        process_unit_id: chosenProcessUnit,
        monitor_unit_id: chosenMonitorUnit === 0 ? null : chosenMonitorUnit,
        control_unit_type_id: chosenControlUnitType,
        comm_address: commAddress,
        tag: tag,
      };

      onAdd(newControlUnit);
    }
  }

  return (
    <ModalBox
      dataTestname="add-control-unit-modal-box"
      active={active}
      desktopContainerMinWidth={"765px"}
      mobileContainerMinWidth={"320px"}
      containerTitle="Add Control Unit"
      maxHeight={"50%"}
    >
      <StyledAddUnitDiv>
        {userContext.permissions?.profire_manage_control_units ? (
          <StyledMainDiv data-testid="main-div">
            <StyledForm onSubmit={onSubmit}>
              <StyledSectionDiv>
                <Dropdown
                  dataTestname="add-control-unit-appliance-dropdown"
                  selected={chosenProcessUnit.toString()}
                  onchange={updateChosenProcessUnit}
                  columns={processUnitsArray}
                  labelText={"Appliance"}
                  required={true}
                  errors={chosenProcessUnitErrors}
                  autoFocus={true}
                />
                <Dropdown
                  dataTestname="add-control-unit-monitor-unit-dropdown"
                  selected={chosenMonitorUnit.toString()}
                  onchange={updateChosenMonitorUnit}
                  columns={monitorUnitsArray}
                  labelText={"Monitor Unit"}
                  required={true}
                  errors={chosenMonitorUnitErrors}
                />
                <Dropdown
                  dataTestname="add-control-unit-control-unit-type-dropdown"
                  selected={chosenControlUnitType.toString()}
                  onchange={updateChosenControlUnitType}
                  columns={controlUnitTypeArray}
                  labelText={"Control Unit Type"}
                  required={true}
                  errors={chosenControlUnitTypeErrors}
                />
                <CommonInput
                  dataTestname="add-control-unit-comm-address-common-input"
                  type={"text"}
                  labelText={
                    "Comm Address (required if Monitor Unit is chosen)"
                  }
                  required={false}
                  readOnly={false}
                  value={commAddress}
                  onChange={handleCommAddress}
                  maxLength={50}
                  placeholder={"Comm Address"}
                  errors={commAddressErrors}
                />
                <CommonInput
                  dataTestname="add-control-unit-tag-common-input"
                  type={"text"}
                  labelText={"Tag"}
                  required={true}
                  readOnly={false}
                  value={tag}
                  onChange={handleTag}
                  maxLength={50}
                  placeholder={"Tag"}
                  errors={tagErrors}
                />
                {existingErrors && (
                  <StyledErrorMessage data-testid="submit-errors">
                    {"Please fix above errors and resubmit."}
                  </StyledErrorMessage>
                )}
                <StyledButtonRow>
                  <StyledCancelButtonHolder>
                    <ProfireButton
                      dataTestname="add-control-unit-cancel-button"
                      text="Cancel"
                      onClickFunction={handleCancel}
                    />
                  </StyledCancelButtonHolder>
                  <StyledSaveButtonHolder>
                    <ProfireButton
                      dataTestname="add-control-unit-save-button"
                      text="Save"
                      onClickFunction={ErrorHandler}
                      defaultButton={true}
                    />
                  </StyledSaveButtonHolder>
                </StyledButtonRow>
              </StyledSectionDiv>
            </StyledForm>
          </StyledMainDiv>
        ) : (
          <StyledMainDiv>
            <div data-testid="banner-div">
              <Banner
                dataTestname="add-control-unit-banner"
                headingtext={headingText}
                text={text}
                redBox={true}
              />
            </div>
          </StyledMainDiv>
        )}
      </StyledAddUnitDiv>
    </ModalBox>
  );
};

export default AddControlUnit;

const StyledAddUnitDiv = styled.div`
  width: calc(100% - 10px);
  height: 100%;
  overflow-y: auto;
  padding-right: 10px;
`;

const StyledMainDiv = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledErrorMessage = styled.div`
  text-align: left;
  color: #9c1b30;
  font-size: ${(props) => props.theme.contentErrorSize};
  font-weight: ${(props) => props.theme.contentErrorWeight};

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
`;

const StyledSectionDiv = styled.div`
  width: Calc(100%);
  background-color: #ffffff;
  border-radius: 10px;
  text-align: left;
`;

const StyledForm = styled.form``;

const StyledButtonRow = styled.div`
  width: 100%;
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

const StyledCancelButtonHolder = styled.div`
  margin-right: 20px;
`;

const StyledSaveButtonHolder = styled.div``;
