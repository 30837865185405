import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Loading from "../components/common/modals/Loading";
import UserContext from "../stores/users/UserContext";
import Map, {
  GeolocateControl,
  Marker,
  NavigationControl,
  Popup,
  ScaleControl,
} from "react-map-gl";

import "mapbox-gl/dist/mapbox-gl.css";
import { IControlUnitWithStatusDTO } from "../types/controlUnits";
import mapboxgl from "mapbox-gl";
import { openInNewTab } from "../utilities/secureNewTab";
import ChartIcon from "../assets/images/navigation/chart-icon.svg";
import StatusGoodIcon from "../assets/images/sites/green-status-circle.svg";
import StatusBadIcon from "../assets/images/sites/red-status-circle.svg";
import { ISite, ISiteWithStatusDTO } from "../types/sites";
import { setFlatSitesFromAPI } from "../utilities/apiCalls/sites";
import { IProcessUnitWithStatusDTO } from "../types/processUnits";
import { ICompany } from "../types/companies";
import { getReadyCompanies } from "../utilities/apiCalls/companies";

interface MobileMapProps {
  companyId: number;
}

const MobileMap: React.FC<MobileMapProps> = ({ companyId }) => {
  const userContext = useContext(UserContext);
  const userContextRef = useRef(userContext).current;
  const params = useParams();

  const [loading, setLoading] = useState<boolean>(false);
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const [sites, setSites] = useState<ISiteWithStatusDTO[]>();
  const [processUnits, setProcessUnits] = useState<IProcessUnitWithStatusDTO[]>(
    []
  );
  const [controlUnits, setControlUnits] = useState<IControlUnitWithStatusDTO[]>(
    []
  );

  const [chosenCompany] = useState<number>(
    !userContext.permissions?.profire_select_company
      ? companyId
      : params.companyId === undefined || params.companyId === null
      ? 1
      : parseInt(params.companyId!)
  );

  //on page load
  useEffect(() => {
    //if the user is not a profire user, set company using user's companyId
    if (!userContextRef.permissions?.profire_select_company) {
      setFlatSitesFromAPI(parseInt(companyId.toString()), setLoading).then(
        (res: any) => {
          setSites(res.data.sites);
          setProcessUnits(
            res.data.process_units === undefined ? [] : res.data.process_units
          );
          setControlUnits(
            res.data.control_units === undefined ? [] : res.data.control_units
          );
        }
      );
      //else if the user is a profire user, set companies
    } else {
      getReadyCompanies(setLoading).then((res: any) => {
        setCompanies(res.data);
      });
    }
  }, [userContextRef, companyId]);

  //when chosen company is updated
  useEffect(() => {
    if (
      chosenCompany !== undefined &&
      chosenCompany !== null &&
      chosenCompany !== 0
    ) {
      setPopupInfo(null); //closes the open popup
      setSites([]);
      setProcessUnits([]);
      setControlUnits([]);

      setFlatSitesFromAPI(chosenCompany, setLoading).then((res: any) => {
        setSites(res.data.sites);
        setProcessUnits(
          res.data.process_units === undefined ? [] : res.data.process_units
        );
        setControlUnits(
          res.data.control_units === undefined ? [] : res.data.control_units
        );

        let assigned: boolean = false;
        if (res.data.sites !== undefined && res.data.sites.length > 0) {
          res.data.sites.forEach((site: ISite) => {
            if (
              site.latitude !== null &&
              site.longitude !== null &&
              assigned === false
            ) {
              setViewState({
                latitude: site.latitude!,
                longitude: site.longitude!,
                zoom: 3,
              });
              assigned = true;
            }
          });
        }
        if (assigned === false) {
          setViewState({
            latitude: 47,
            longitude: -101.36249531152085,
            zoom: 3,
          });
        }
      });
    }
  }, [chosenCompany]);

  let companiesArray: [string, string][] = [];

  if (userContext.permissions?.profire_select_company) {
    //populate companiesArray for dropdown
    for (let index = 0; index < companies.length; index++) {
      companiesArray.push([
        companies[index].name,
        companies[index].id.toString(),
      ]);
    }
  }

  //MAPBOX STUFF
  //this static lat/long should be swapped once we have the inclusive site object being returned.
  const [viewState, setViewState] = React.useState({
    latitude: 47,
    longitude: -101.36249531152085,
    zoom: 3,
  });

  const mapboxToken =
    "pk.eyJ1Ijoic2R1YmxhbmtvIiwiYSI6ImNsNWE0eTN0NTA0aG8zaXRkb2o3cWF0aG8ifQ.MTU0YF2D1ZOWxxOo_5R7tw";

  interface info {
    latitude: number;
    longitude: number;
    facility_name: string;
    company_id: number;
    control_units: IControlUnitWithStatusDTO[];
    site_id: number;
  }
  const [popupInfo, setPopupInfo] = useState<info | null>(null);
  const pins = useMemo(
    () =>
      sites !== undefined &&
      controlUnits !== undefined &&
      sites.map(
        (site, index) =>
          site.latitude !== undefined &&
          site.latitude !== null &&
          site.longitude !== undefined &&
          site.longitude !== null && (
            <Marker
              key={`marker-${index}`}
              longitude={site.longitude}
              latitude={site.latitude}
              color={site.alerting ? "#9c1b30" : "#00B207"}
              anchor="top"
              offset={new mapboxgl.Point(0, -50 / 2)}
              onClick={(e) => {
                // If we let the click event propagates to the map, it will immediately close the popup
                // with `closeOnClick: true`
                e.originalEvent.stopPropagation();
                setPopupInfo({
                  longitude: site.longitude!,
                  latitude: site.latitude!,
                  facility_name: site.facility_name,
                  company_id: site.company_id,
                  control_units: controlUnits,
                  site_id: site.id,
                });
              }}
            ></Marker>
          )
      ),
    [sites, controlUnits]
  );

  function ctrlScroll(event: any) {
    if (event.originalEvent.ctrlKey) {
      return;
    }

    if (event.originalEvent.metaKey) {
      return;
    }

    if (event.originalEvent.altKey) {
      return;
    }

    event.preventDefault();
  }

  if (loading) {
    return (
      <div className="fullPageLoadingContainer">
        <Loading dataTestname="mobile-map-loading" />
      </div>
    );
  } else {
    return (
      <StyledMapDiv>
        <StyledMainDiv data-testid="main-div">
          {chosenCompany > 0 && (
            <Map
              {...viewState}
              onMove={(evt) => setViewState(evt.viewState)}
              onWheel={(e) => ctrlScroll(e)}
              style={{
                width: "100%",
                height: "100%",
                overflow: "clip",
              }}
              mapStyle="mapbox://styles/mapbox/light-v10"
              mapboxAccessToken={mapboxToken}
            >
              <GeolocateControl position="top-left" />
              <NavigationControl position="top-left" />
              <ScaleControl />

              {pins}

              {popupInfo && (
                <Popup
                  anchor="bottom"
                  longitude={Number(popupInfo.longitude)}
                  latitude={Number(popupInfo.latitude)}
                  onClose={() => setPopupInfo(null)}
                  style={{ maxWidth: "unset", whiteSpace: "pre-line" }}
                >
                  <StyledPopupInnerDiv>
                    <StyledPopupSiteName>{`${popupInfo.facility_name}`}</StyledPopupSiteName>
                    {popupInfo.control_units.map(
                      (unit, index) =>
                        processUnits !== undefined &&
                        processUnits.find(
                          (pu) => unit.process_unit_id === pu.id
                        ) !== undefined &&
                        processUnits.find(
                          (pu) => unit.process_unit_id === pu.id
                        )!.site_id === popupInfo.site_id && (
                          <StyledTagAndLinkDiv key={unit.tag + index}>
                            <div>
                              <StyledStatusDot
                                src={
                                  unit.alerting ? StatusBadIcon : StatusGoodIcon
                                }
                                alt="status good icon"
                              />
                              <StyledLink
                                href={`/controlunits/details/processunit/${unit.process_unit_id}/${unit.id}`}
                              >
                                {unit.tag}
                              </StyledLink>
                            </div>
                            <StyledGrafanaLink
                              onClick={() => openInNewTab(unit.dashboard_url!)}
                              src={ChartIcon}
                              alt="chart icon"
                            />
                          </StyledTagAndLinkDiv>
                        )
                    )}
                  </StyledPopupInnerDiv>
                </Popup>
              )}
            </Map>
          )}
        </StyledMainDiv>
      </StyledMapDiv>
    );
  }
};

export default MobileMap;

const StyledMapDiv = styled.div`
  width: Calc(100%);
  max-width: Calc(100%);
  min-width: Calc(320px);
  margin: 60px 0px 0px 0px;
  height: calc(100vh - 60px);
  height: calc(100dvh - 60px);
  background-color: white;
  display: flex;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    background-color: ${(props) => props.theme.bgColor};
  }
`;

const StyledMainDiv = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledPopupInnerDiv = styled.div`
  padding: 18px 18px 13px 18px;
  width: max-content;
`;

const StyledStatusDot = styled.img`
  margin-right: 5px;
`;

const StyledLink = styled.a`
  color: #9c1b30;
  text-align: left;

  :visited {
    color: #9c1b30;
  }
`;

const StyledPopupSiteName = styled.h2`
  font-size: ${(props) => props.theme.headingMapSize};
  font-weight: ${(props) => props.theme.headingMapWeight};
  margin: 0;
  margin-bottom: 12px;
`;

const StyledGrafanaLink = styled.img`
  cursor: pointer;
  margin-left: 5px;
`;

const StyledTagAndLinkDiv = styled.div`
  min-width: max-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: ${(props) => props.theme.contentMapSize};
  font-weight: ${(props) => props.theme.contentMapWeight};
`;
