import React, { ReactElement, useState } from "react";
import TabTitle from "./TabTitle";
import styled from "styled-components";

// Tabs Component Instructions
// 1. <Tabs> </Tabs> is meant to be used as the outer div to <Tab> </Tab> components. Do not use it on its own.
// 2. Here is a list of the props and how each of them are used:
//  - dataTestname: the unique dataTestname to assign to the component that allows Mike to run automated tests
//  - children: though this looks general, this should only be <Tab></Tab> components
// 3. Please do not touch the styling in this file. If you *do* need to change styling, please talk to TJ.
// 4. If you have any suggestions or need another css property to be supported, please talk to TJ.
// 5. If there are any issues, please talk to TJ.

interface TabsProps {
  dataTestname: string;
  children: ReactElement[];
}

const Tabs: React.FC<TabsProps> = ({ dataTestname, children }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <StyledTabsDiv
      data-testname={dataTestname}
      data-testid="tabs-div"
      count={children.length}
    >
      <StyledTabsContainer count={children.length}>
        {children.map((item, index) => (
          <TabTitle
            data-testname={`tab-${index}`}
            dataTestname="tabs-tab-title"
            key={index}
            title={item.props.title}
            index={index}
            setSelectedTab={setSelectedTab}
            selectedTab={selectedTab}
          />
        ))}
      </StyledTabsContainer>
      {children[selectedTab]}
    </StyledTabsDiv>
  );
};

export default Tabs;

interface CountProps {
  count: number;
}

const StyledTabsContainer = styled.div<CountProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  .tabButton {
    width: Calc(100% / ${(props) => props.count} - 2px);
  }
`;

const StyledTabsDiv = styled.div<CountProps>`
  width: 100%;
  .tabButton {
    width: Calc(100% / ${(props) => props.count} - 2px);
  }
`;
