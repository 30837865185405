import { createContext } from "react";
import { ILookupContext } from "./types";

const defaultLookupContext: ILookupContext = {
  controlUnitTypes: [],
  monitorUnitTypes: [],
  fuelGasTypes: [],
  processUnitTypes: [],
  loading: true,
  setControlUnitTypes: () => null,
  setMonitorUnitTypes: () => null,
  setFuelGasTypes: () => null,
  setProcessUnitTypes: () => null,
};

const LookupContext = createContext<ILookupContext>(defaultLookupContext);

export default LookupContext;
