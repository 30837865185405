import React, { useReducer } from "react";
import { ActionTypes, IControlUnitContext, IControlUnitState } from "./types";
import ControlUnitReducer from "./ControlUnitReducer";
import ControlUnitContext from "./ControlUnitContext";
import axios from "axios";
import { getToken } from "../../utilities/msTokenFunc";

const ControlUnitProvider: React.FC<IControlUnitContext> = (props) => {
  const initialState: IControlUnitState = {
    controlUnits: [],
    controlUnit: null,
    controlUnitBelongsToUserCompany: null,
    loading: true,
  };

  const [state, dispatch] = useReducer(ControlUnitReducer, initialState);

  const setControlUnits = async () => {
    try {
      dispatch({ type: ActionTypes.SENDING_REQUEST });
      const token: string = await getToken();
      await axios
        .get(`${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/controlunit`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) =>
          dispatch({ type: ActionTypes.SET_CONTROL_UNITS, payload: res.data })
        );
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    } catch (error) {
      dispatch({ type: ActionTypes.SET_CONTROL_UNITS, payload: [] });
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    }
  };

  const setControlUnit = async (id: number) => {
    try {
      dispatch({ type: ActionTypes.SENDING_REQUEST });
      const token: string = await getToken();
      await axios
        .get(
          `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/controlunit/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) =>
          dispatch({ type: ActionTypes.SET_CONTROL_UNIT, payload: res.data })
        );
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    } catch (error) {
      dispatch({ type: ActionTypes.SET_CONTROL_UNIT, payload: null });
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    }
  };

  const setControlUnitsByProcessUnitId = async (processUnitId: number) => {
    try {
      dispatch({ type: ActionTypes.SENDING_REQUEST });
      const token: string = await getToken();
      await axios
        .get(
          `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/controlunit/byprocessunit/${processUnitId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) =>
          dispatch({ type: ActionTypes.SET_CONTROL_UNITS, payload: res.data })
        );
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    } catch (error) {
      dispatch({ type: ActionTypes.SET_CONTROL_UNITS, payload: [] });
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    }
  };

  const setControlUnitsByMonitorUnitId = async (monitorUnitId: number) => {
    try {
      dispatch({ type: ActionTypes.SENDING_REQUEST });
      const token: string = await getToken();
      await axios
        .get(
          `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/controlunit/bymonitorunit/${monitorUnitId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) =>
          dispatch({ type: ActionTypes.SET_CONTROL_UNITS, payload: res.data })
        );
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    } catch (error) {
      dispatch({ type: ActionTypes.SET_CONTROL_UNITS, payload: [] });
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    }
  };

  const setControlUnitBelongsToUserCompany = async (
    controlUnitId: number,
    azureId: string,
    isProfireUser: boolean
  ) => {
    try {
      dispatch({ type: ActionTypes.SENDING_REQUEST });

      if (isProfireUser) {
        dispatch({
          type: ActionTypes.SET_CONTROL_UNIT_BELONGS_TO_USER_COMPANY,
          payload: true,
        });
      } else {
        const token: string = await getToken();
        await axios
          .get(
            `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/user/verifyusercontrolunit/${controlUnitId}/${azureId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            dispatch({
              type: ActionTypes.SET_CONTROL_UNIT_BELONGS_TO_USER_COMPANY,
              payload: res.data,
            });
          });
      }

      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    } catch (error) {
      dispatch({
        type: ActionTypes.SET_CONTROL_UNIT_BELONGS_TO_USER_COMPANY,
        payload: false,
      });
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    }
  };

  const setControlUnitsByCompany = async (companyId: number) => {
    try {
      dispatch({ type: ActionTypes.SENDING_REQUEST });
      const token: string = await getToken();
      await axios
        .get(
          `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/controlunit/bycompany/${companyId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) =>
          dispatch({ type: ActionTypes.SET_CONTROL_UNITS, payload: res.data })
        );
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    } catch (error) {
      dispatch({ type: ActionTypes.SET_CONTROL_UNITS, payload: [] });
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    }
  };

  return (
    <ControlUnitContext.Provider
      value={{
        controlUnits: state.controlUnits,
        controlUnit: state.controlUnit,
        controlUnitBelongsToUserCompany: state.controlUnitBelongsToUserCompany,
        loading: state.loading,
        setControlUnits: setControlUnits,
        setControlUnit: setControlUnit,
        setControlUnitsByProcessUnitId: setControlUnitsByProcessUnitId,
        setControlUnitsByMonitorUnitId: setControlUnitsByMonitorUnitId,
        setControlUnitBelongsToUserCompany: setControlUnitBelongsToUserCompany,
        setControlUnitsByCompany: setControlUnitsByCompany,
      }}
    >
      {props.children}
    </ControlUnitContext.Provider>
  );
};

export default ControlUnitProvider;
