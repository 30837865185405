import axios from "axios";
import { ISite, ISiteCreateDTO } from "../../types/sites";
import { getToken } from "../msTokenFunc";

export const getAllSites = async (loadingFunc: Function) => {
  try {
    loadingFunc(true);
    const token = await getToken();
    const result = await axios.get(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/site`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    loadingFunc(false);
    return result;
  } catch (error: any) {
    loadingFunc(false);
    return error.response;
  }
};

export const getSiteById = async (siteId: number, loadingFunc: Function) => {
  try {
    loadingFunc(true);
    const token = await getToken();
    const result = await axios.get(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/site/${siteId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    loadingFunc(false);
    return result;
  } catch (error: any) {
    loadingFunc(false);
    return error.response;
  }
};

export const getSitesByCompanyId = async (
  companyId: number,
  loadingFunc: Function
) => {
  try {
    loadingFunc(true);
    const token = await getToken();
    const result = await axios.get(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/site/bycompany/${companyId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    loadingFunc(false);
    return result;
  } catch (error: any) {
    loadingFunc(false);
    return error.response;
  }
};

export const setFlatSitesFromAPI = async (
  companyId: number,
  loadingFunc: Function
) => {
  try {
    const token = await getToken();
    const result = await axios.get(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/site/flat/bycompany/${companyId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    loadingFunc(false);
    return result;
  } catch (error: any) {
    loadingFunc(false);
    return error.response;
  }
};

export const setSiteFromAPI = async (siteId: number, loadingFunc: Function) => {
  try {
    const token = await getToken();
    const result = await axios.get(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/site/${siteId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    loadingFunc(false);
    return result;
  } catch (error: any) {
    loadingFunc(false);
    return error.response;
  }
};

export const addNewSite = async (newSite: ISiteCreateDTO) => {
  try {
    const token = await getToken();
    const result = await axios.post(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/site`,
      newSite,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result;
  } catch (error: any) {
    return error.response;
  }
};

export const editSite = async (updatedSite: ISite) => {
  try {
    const token = await getToken();
    const result = await axios.put(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/site`,
      updatedSite,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result;
  } catch (error: any) {
    return error.response;
  }
};

export const deleteSite = async (siteId: number) => {
  try {
    const token = await getToken();
    const result = await axios.delete(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/site/${siteId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result;
  } catch (error: any) {
    return error.response;
  }
};

export const setSiteBelongsToUserCompanyFromAPI = async (
  siteId: number,
  azureId: string,
  isProfireUser: boolean) => {
  try {
    if (isProfireUser) {
      return {data: true}
    }

    const token = await getToken();
    const result = await axios.get(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/user/verifyusersite/${siteId}/${azureId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return result;
  } catch (error: any) {
    return error.response;
  }
};