import { IControlUnitType } from "../../types/controlUnits";
import { IMonitorUnitType } from "../../types/monitorUnits";
import { IFuelGasType, IProcessUnitType } from "../../types/processUnits";
import { ActionTypes, ILookupState } from "./types";

type Action =
  | { type: ActionTypes.SET_CONTROL_UNIT_TYPES; payload: IControlUnitType[] }
  | { type: ActionTypes.SET_MONITOR_UNIT_TYPES; payload: IMonitorUnitType[] }
  | { type: ActionTypes.SET_FUEL_GAS_TYPES; payload: IFuelGasType[] }
  | { type: ActionTypes.SET_PROCESS_UNIT_TYPES; payload: IProcessUnitType[] }
  | { type: ActionTypes.SENDING_REQUEST }
  | { type: ActionTypes.REQUEST_FINISHED };

const reducer = (state: ILookupState, action: Action) => {
  switch (action.type) {
    case ActionTypes.SET_CONTROL_UNIT_TYPES:
      return {
        ...state,
        controlUnitTypes: action.payload,
      };
    case ActionTypes.SET_MONITOR_UNIT_TYPES:
      return {
        ...state,
        monitorUnitTypes: action.payload,
      };
    case ActionTypes.SET_FUEL_GAS_TYPES:
      return {
        ...state,
        fuelGasTypes: action.payload,
      };
    case ActionTypes.SET_PROCESS_UNIT_TYPES:
      return {
        ...state,
        processUnitTypes: action.payload,
      };
    case ActionTypes.SENDING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.REQUEST_FINISHED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
