import React from "react";
import styled from "styled-components";

<link
  href="https://fonts.googleapis.com/icon?family=Material+Icons"
  rel="stylesheet"
></link>;

interface BannerProps {
  dataTestname: string;
  headingtext: string;
  text: string;
  redBox?: boolean;
}

const Banner: React.FC<BannerProps> = ({
  dataTestname,
  headingtext,
  text,
  redBox,
}) => {
  return (
    <StyledBannerContainer data-testname={dataTestname} redBox={redBox!}>
      <StyledBanner redBox={redBox!}>
        <h2 data-testid="banner-header">{headingtext}</h2>
        <p data-testid="banner-text">{text}</p>
      </StyledBanner>
    </StyledBannerContainer>
  );
};

export default Banner;

interface RedBoxProps {
  redBox: boolean;
}

const StyledBannerContainer = styled.div<RedBoxProps>`
  border: ${(props) =>
    props.redBox ? "1px solid #ffffff" : "1px solid #9c1b30"};
  min-width: Calc(300px - 32px);
  width: Calc(100% - 32px);
  background-color: ${(props) => (props.redBox ? "#9c1b30" : "#ffffff")};
  padding: 20px 16px;
  margin-bottom: 25px;
  border-radius: 10px;
`;

const StyledBanner = styled.div<RedBoxProps>`
  border-radius: "10px";
  margin-top: "20px";
  margin-bottom: "20px";
  text-align: left;
  color: ${(props) => (props.redBox ? "#ffffff" : "#9c1b30")};

  h2 {
    font-size: ${(props) => props.theme.headingMainSize};
    font-weight: ${(props) => props.theme.headingMainWeight};
    margin-bottom: 3px;
    margin-top: 15px;
  }
  p {
    font-size: ${(props) => props.theme.contentMainSize};
    font-weight: ${(props) => props.theme.contentMainWeight};
    margin-top: 0px;
    white-space: pre-wrap;
  }
`;
