import { IControlUnitConfigDTO } from "../../types/controlUnitConfig";
import { ActionTypes, IControlUnitConfigState } from "./types";

type Action =
  | {
      type: ActionTypes.SET_CURRENT_CONTROL_UNIT_CONFIGS;
      payload: IControlUnitConfigDTO[];
    }
  | {
      type: ActionTypes.SET_HISTORICAL_CONTROL_UNIT_CONFIGS;
      payload: IControlUnitConfigDTO[];
    }
  | { type: ActionTypes.SENDING_REQUEST }
  | { type: ActionTypes.REQUEST_FINISHED };

const reducer = (state: IControlUnitConfigState, action: Action) => {
  switch (action.type) {
    case ActionTypes.SET_CURRENT_CONTROL_UNIT_CONFIGS:
      return {
        ...state,
        currentConfigs: action.payload,
      };
    case ActionTypes.SET_HISTORICAL_CONTROL_UNIT_CONFIGS:
      return {
        ...state,
        historicalConfigs: action.payload,
      };
    case ActionTypes.SENDING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.REQUEST_FINISHED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
