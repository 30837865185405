import { IContactPoint } from "../../types/contactPoints";

export interface IContactPointContext {
  contactPoints: IContactPoint[];
  contactPoint: IContactPoint | null;
  setContactPoint: Function;
  setContactPointsByCompany: Function;
  loading: boolean;
  children?: React.ReactNode;
}

export interface IContactPointState {
  contactPoints: IContactPoint[];
  contactPoint: IContactPoint | null;
  loading: boolean;
}

export enum ActionTypes {
  SET_CONTACT_POINTS_BY_COMPANY = "SET_CONTACT_POINTS_BY_COMPANY",
  SET_CONTACT_POINT = "SET_CONTACT_POINT",
  SENDING_REQUEST = "SENDING_REQUEST",
  REQUEST_FINISHED = "REQUEST_FINISHED",
}

export interface ContactPointAction {
  type: ActionTypes;
  payload: number | string | boolean | IContactPoint | IContactPoint[];
}
