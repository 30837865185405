import { ISite } from "../../types/sites";
import { ActionTypes, ISiteState } from "./types";

type Action =
  | { type: ActionTypes.SET_SITES; payload: ISite[] }
  | { type: ActionTypes.SET_SITE; payload: ISite | null }
  | {
      type: ActionTypes.SET_SITE_BELONGS_TO_USER_COMPANY;
      payload: boolean;
    }
  | { type: ActionTypes.SENDING_REQUEST }
  | { type: ActionTypes.REQUEST_FINISHED };

const reducer = (state: ISiteState, action: Action) => {
  switch (action.type) {
    case ActionTypes.SET_SITES:
      return {
        ...state,
        sites: action.payload,
      };
    case ActionTypes.SET_SITE:
      return {
        ...state,
        site: action.payload,
      };
    case ActionTypes.SENDING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.REQUEST_FINISHED:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.SET_SITE_BELONGS_TO_USER_COMPANY:
      return {
        ...state,
        siteBelongsToUserCompany: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
