import axios from "axios";
import { IGrafanaAlert, IGrafanaAlertCreateDTO } from "../../types/alerts";
import { getToken } from "../msTokenFunc";

export const setGrafanaAlertFieldsFromAPI = async (
  controlUnitId: number,
  loadingFunc: Function
) => {
  try {
    const token = await getToken();
    const result = await axios.get(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/grafanaalert/fields/${controlUnitId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    loadingFunc(false);
    return result;
  } catch (error: any) {
    loadingFunc(false);
    return error.response;
  }
};

export const setGrafanaAlertsFromAPI = async (
  controlUnitId: number,
  loadingFunc: Function
) => {
  try {
    const token = await getToken();
    const result = await axios.get(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/grafanaalert/${controlUnitId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    loadingFunc(false);
    return result;
  } catch (error: any) {
    loadingFunc(false);
    return error.response;
  }
};

export const setContactPointsByControlUnitFromAPI = async (
  controlUnitId: number,
  loadingFunc: Function
) => {
  try {
    const token = await getToken();
    const result = await axios.get(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/contactpoint/bycontrolunit/${controlUnitId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    loadingFunc(false);
    return result;
  } catch (error: any) {
    loadingFunc(false);
    return error.response;
  }
};

export const createNewAlert = async (newAlert: IGrafanaAlertCreateDTO) => {
  try {
    const token = await getToken();
    const result = await axios.post(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/grafanaalert`,
      newAlert,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result;
  } catch (error: any) {
    return error.response;
  }
};

export const setAlertLogsFromAPI = async (
  grafanaAlertId: number,
  loadingFunc: Function
) => {
  try {
    const token = await getToken();
    const result = await axios.get(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/grafanaalert/logs/${grafanaAlertId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    loadingFunc(false);
    return result;
  } catch (error: any) {
    loadingFunc(false);
    return error.response;
  }
};

export const deleteAlert = async (alert_id: number) => {
  try {
    const token = await getToken();
    const result = await axios.delete(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/grafanaalert/${alert_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result;
  } catch (error: any) {
    return error.response;
  }
};

export const editAlert = async (editedAlert: IGrafanaAlert) => {
  try {
    const token = await getToken();
    const result = await axios.put(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/grafanaalert`,
      editedAlert,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return result;
  } catch (error: any) {
    return error.response;
  }
};
