import { IMonitorUnit } from "../../types/monitorUnits";
import { ActionTypes, IMonitorUnitState } from "./types";

type Action =
  | { type: ActionTypes.SET_MONITOR_UNITS; payload: IMonitorUnit[] }
  | { type: ActionTypes.SET_MONITOR_UNIT; payload: IMonitorUnit | null }
  | {
      type: ActionTypes.SET_MONITOR_UNIT_BELONGS_TO_USER_COMPANY;
      payload: boolean;
    }
  | { type: ActionTypes.SENDING_REQUEST }
  | { type: ActionTypes.REQUEST_FINISHED };

const reducer = (state: IMonitorUnitState, action: Action) => {
  switch (action.type) {
    case ActionTypes.SET_MONITOR_UNITS:
      return {
        ...state,
        monitorUnits: action.payload,
      };
    case ActionTypes.SET_MONITOR_UNIT:
      return {
        ...state,
        monitorUnit: action.payload,
      };
    case ActionTypes.SENDING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.REQUEST_FINISHED:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.SET_MONITOR_UNIT_BELONGS_TO_USER_COMPANY:
      return {
        ...state,
        monitorUnitBelongsToUserCompany: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
