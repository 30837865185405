import React from "react";
import { ClipLoader } from "react-spinners";
import styled from "styled-components";

// size prop ranges from 1 to 5 with the following categories:
// *** if size prop is not provided, the component will default to size 3 (medium)
//    1 = very small
//    2 = small
//    3 = medium
//    4 = large
//    5 = very large
//    6 = extra large

// this component should be imported as a child of a div, that div should ONLY affect the button positioning.
// *** do not try to change the width or height of this button using that div, instead use the size prop

interface ProfireButtonProps {
  dataTestname: string;
  text: string;
  onClickFunction?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  loading?: boolean;
  size?: number;
  overrideWidth?: string;
  overrideHeight?: string;
  overrideFontSize?: string;
  overridePadding?: string;
  defaultButton?: boolean;
}

const ProfireButton: React.FC<ProfireButtonProps> = ({
  dataTestname,
  text,
  onClickFunction,
  disabled,
  loading,
  size,
  overrideWidth,
  overrideHeight,
  overrideFontSize,
  overridePadding,
  defaultButton = false,
}) => {
  return (
    <StyledButton
      data-testname={dataTestname}
      disabled={disabled || loading}
      className="btn"
      data-testid="reusable-button"
      onClick={onClickFunction}
      size={size!}
      overrideWidth={overrideWidth!}
      overrideHeight={overrideHeight!}
      overrideFontSize={overrideFontSize!}
      overridePadding={overridePadding!}
      type={defaultButton ? "submit" : "button"}
    >
      {loading ? (
        <StyledClipLoaderDiv data-testid="loader-container">
          <ClipLoader color="#9c1b30" size={15} />
        </StyledClipLoaderDiv>
      ) : (
        <StyledButtonText>{text}</StyledButtonText>
      )}
    </StyledButton>
  );
};

export default ProfireButton;

interface StyledProfireButton {
  size: number;
  overrideWidth?: string;
  overrideHeight?: string;
  overrideFontSize?: string;
  overridePadding?: string;
}

const StyledButton = styled.button<StyledProfireButton>`
  background-color: #9c1b30;
  border: 1px solid #9c1b30;
  color: white;
  border-radius: 3px;
  font-size: ${(props) => props.theme.buttonTextSize};
  font-weight: ${(props) => props.theme.buttonTextWeight};
  padding: 0px;
  padding-bottom: 1px;
  height: 28px;
  width: ${(props) =>
    props.overrideWidth
      ? props.overrideWidth
      : props.size === 1
      ? "66px"
      : props.size === 2
      ? "88px"
      : props.size === 4
      ? "129px"
      : props.size === 5
      ? "145px"
      : props.size === 6
      ? "173px"
      : "105px"};

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    padding: ${(props) => (props.size === 1 ? "2px 12px" : "7px 13px")};
    height: ${(props) => (props.size === 1 ? "30px" : "35px")};
    width: ${(props) =>
      props.size === 1
        ? "66px"
        : props.size === 2
        ? "88px"
        : props.size === 4
        ? "129px"
        : props.size === 5
        ? "145px"
        : props.size === 6
        ? "173px"
        : "105px"};
  }

  :hover {
    cursor: pointer;
    background-color: #711323;
  }

  :disabled {
    background-color: #eee;
    border: 1px solid #999;
    color: #666;
    cursor: default;
  }
`;

const StyledButtonText = styled.strong`
  font-size: ${(props) => props.theme.buttonTextSize};
  font-weight: ${(props) => props.theme.buttonTextWeight};

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    font-size: ${(props) => props.theme.buttonTextSize};
    font-weight: ${(props) => props.theme.buttonTextWeight};
  }

  :hover {
    cursor: pointer;
    background-color: #711323;
  }

  :disabled {
    background-color: #eee;
    border: 1px solid #999;
    color: #666;
    cursor: default;
  }
`;

const StyledClipLoaderDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
