import { IRole } from "../../types/roles";

export interface IRoleContext {
  roles: IRole[],
  loading: boolean,
  setRoles: Function,
  children?: React.ReactNode;
}

export interface IRoleState {
  roles: IRole[],
  loading: boolean
}

export enum ActionTypes {
  SET_ROLES = "SET_ROLES",
  SENDING_REQUEST = "SENDING_REQUEST",
  REQUEST_FINISHED = "REQUEST_FINISHED"
}

export interface RoleAction {
  type: ActionTypes,
  payload: number | string | boolean | IRole[]
}