import { IProcessUnit } from "../../types/processUnits";

export interface IProcessUnitContext {
  processUnits: IProcessUnit[];
  processUnit: IProcessUnit | null;
  processUnitBelongsToUserCompany: boolean | null;
  loading: boolean;
  setProcessUnits: Function;
  setProcessUnit: Function;
  setProcessUnitsBySiteId: Function;
  setProcessUnitBelongsToUserCompany: Function;
  setProcessUnitsByCompany: Function;
  children?: React.ReactNode;
}

export interface IProcessUnitState {
  processUnits: IProcessUnit[];
  processUnit: IProcessUnit | null;
  processUnitBelongsToUserCompany: boolean | null;
  loading: boolean;
}

export enum ActionTypes {
  SET_PROCESS_UNITS = "SET_PROCESS_UNITS",
  SET_PROCESS_UNIT = "SET_PROCESS_UNIT",
  SET_PROCESS_UNIT_BELONGS_TO_USER_COMPANY = "SET_PROCESS_UNIT_BELONGS_TO_USER_COMPANY",
  SENDING_REQUEST = "SENDING_REQUEST",
  REQUEST_FINISHED = "REQUEST_FINISHED",
}

export interface ProcessUnitAction {
  type: ActionTypes;
  payload: number | string | boolean | IProcessUnit | IProcessUnit[];
}
