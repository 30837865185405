import { createContext } from "react";
import { IUserContext } from "./types";

const defaultUserContext: IUserContext = {
  user: null,
  permissions: null,
  loading: false,
  setUser: () => null,
  setPermissions: () => null,
};

const UserContext = createContext<IUserContext>(defaultUserContext);

export default UserContext;
