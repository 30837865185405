import { useReducer } from "react";
import { ActionTypes, IRoleContext, IRoleState } from "./types";
import RoleReducer from "./RoleReducer";
import axios from "axios";
import RoleContext from "./RoleContext";
import { getToken } from "../../utilities/msTokenFunc";

const RoleProvider: React.FC<IRoleContext> = (props) => {
  const initialState: IRoleState = {
    roles: [],
    loading: true,
  };

  const [state, dispatch] = useReducer(RoleReducer, initialState);

  const setRoles = async () => {
    try {
      dispatch({ type: ActionTypes.SENDING_REQUEST });
      const token: string = await getToken();
      await axios
        .get(`${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/role`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          dispatch({ type: ActionTypes.SET_ROLES, payload: res.data });
        });
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    } catch (error) {
      dispatch({ type: ActionTypes.SET_ROLES, payload: [] });
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    }
  };

  return (
    <RoleContext.Provider
      value={{
        roles: state.roles,
        loading: state.loading,
        setRoles: setRoles,
      }}
    >
      {props.children}
    </RoleContext.Provider>
  );
};

export default RoleProvider;
