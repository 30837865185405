import { IUser, IPermission, IPermissions } from "../../types/users";

export interface IUserContext {
  user: IUser | null;
  permissions: IPermissions | null;
  loading: boolean;
  setUser: Function;
  setPermissions: Function;
  children?: React.ReactNode;
}

export interface IUserState {
  user: IUser | null;
  permissions: IPermissions | null;
  loading: boolean;
}

export enum ActionTypes {
  SET_USER = "SET_USER",
  SET_PERMISSIONS = "SET_PERMISSIONS",
  SENDING_REQUEST = "SENDING_REQUEST",
  REQUEST_FINISHED = "REQUEST_FINISHED",
}

export interface UserAction {
  type: ActionTypes;
  payload: number | string | boolean | IUser | IPermission[];
}
