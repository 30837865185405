import { IControlUnitType } from "../../types/controlUnits";
import { IMonitorUnitType } from "../../types/monitorUnits";
import { IFuelGasType, IProcessUnitType } from "../../types/processUnits";

export interface ILookupContext {
  controlUnitTypes: IControlUnitType[];
  monitorUnitTypes: IMonitorUnitType[];
  fuelGasTypes: IFuelGasType[];
  processUnitTypes: IProcessUnitType[];
  loading: boolean;
  setControlUnitTypes: Function;
  setMonitorUnitTypes: Function;
  setFuelGasTypes: Function;
  setProcessUnitTypes: Function;
  children?: React.ReactNode;
}

export interface ILookupState {
  controlUnitTypes: IControlUnitType[];
  monitorUnitTypes: IMonitorUnitType[];
  fuelGasTypes: IFuelGasType[];
  processUnitTypes: IProcessUnitType[];
  loading: boolean;
}

export enum ActionTypes {
  SET_CONTROL_UNIT_TYPES = "SET_CONTROL_UNIT_TYPES",
  SET_MONITOR_UNIT_TYPES = "SET_MONITOR_UNIT_TYPES",
  SET_FUEL_GAS_TYPES = "SET_FUEL_GAS_TYPES",
  SET_PROCESS_UNIT_TYPES = "SET_PROCESS_UNIT_TYPES",
  SENDING_REQUEST = "SENDING_REQUEST",
  REQUEST_FINISHED = "REQUEST_FINISHED",
}

export interface MonitorUnitAction {
  type: ActionTypes;
  payload:
    | boolean
    | IControlUnitType[]
    | IMonitorUnitType[]
    | IFuelGasType[]
    | IProcessUnitType[];
}
