import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Checkbox from "./Checkbox";
import CommonInput from "./CommonInput";
import ConfirmationBox from "../modals/ConfirmationBox";
import Dropdown from "./Dropdown";

interface GenericDeviceInputAreaProps {
  dataTestname: string;
  input_mode: string;
  input_index: number;
  input_name: string;
  input_temperature_units: string;
  input_420_span_max: number;
  input_420_span_min: number;
  input_420_units: string | null;
  is_current_active: boolean;
  is_historical_active: boolean;
  handleChange: Function;
  arrayIndex: number;
  readOnly: boolean;
  generic_modes: string[] | null;
}

const GenericDeviceInputArea: React.FC<GenericDeviceInputAreaProps> = ({
  dataTestname,
  input_mode,
  input_index,
  input_name,
  input_temperature_units,
  input_420_span_max,
  input_420_span_min,
  input_420_units,
  is_current_active,
  is_historical_active,
  handleChange,
  arrayIndex,
  readOnly,
  generic_modes,
}) => {
  //Confirmation Box states
  const [confirmActive, setConfirmActive] = useState<boolean>(false);
  const [confirmText, setConfirmText] = useState<string>("");

  async function handleConfirm() {
    setConfirmText("");
    setConfirmActive(false);
  }

  let modeArray: [string, string][] = [];
  if (generic_modes !== null && generic_modes.length > 0) {
    generic_modes.forEach((mode) => {
      modeArray.push([mode, mode]);
    });
  }

  const [chosenMode, setChosenMode] = useState<string>(input_mode);

  function updateChosenMode(newChosen: string) {
    setChosenMode(newChosen);
    handleChange("input_mode", input_index, newChosen, arrayIndex);

    if (newChosen === "Thermocouple") {
      updateTemperatureChosenUnits(temperatureUnitsArray[0][1]);
    } else if (newChosen === "4-20") {
      setFourTwentyUnits("");
      handleChange("input_420_units", input_index, "", arrayIndex);
    } else if (newChosen === "Digital") {
      setFourTwentyUnits("");
      handleChange("input_420_units", input_index, "", arrayIndex);
      setChosenTemperatureUnits("");
      handleChange("input_temperature_units", input_index, "", arrayIndex);
    } else if (newChosen === "Disabled") {
      setConfirmActive(true);
      setConfirmText(
        'If "Mode" is Saved as Disabled, Alerts that belong to this input will be deleted.'
      );
    }
  }

  const [name, setName] = useState<string>(input_name);
  function updateName(e: React.FormEvent<HTMLInputElement>) {
    setName(e.currentTarget.value);
    handleChange("input_name", input_index, e.currentTarget.value, arrayIndex);
  }

  let temperatureUnitsArray: [string, string][] = [
    ["Celcius", "°C"],
    ["Fahrenheit", "°F"],
  ];
  const [chosenTemperatureUnits, setChosenTemperatureUnits] = useState<string>(
    input_temperature_units
  );
  function updateTemperatureChosenUnits(newChosen: string) {
    setChosenTemperatureUnits(newChosen);
    handleChange("input_temperature_units", input_index, newChosen, arrayIndex);
  }

  const [fourTwentyUnits, setFourTwentyUnits] = useState<string>(
    input_420_units === null ? "" : input_420_units
  );
  function updateFourTwentyUnits(e: React.FormEvent<HTMLInputElement>) {
    setFourTwentyUnits(e.currentTarget.value);
    handleChange(
      "input_420_units",
      input_index,
      e.currentTarget.value,
      arrayIndex
    );
  }

  const [spanMin, setSpanMin] = useState<number>(input_420_span_min);
  function updateSpanMin(e: React.FormEvent<HTMLInputElement>) {
    setSpanMin(e.currentTarget.valueAsNumber);
    handleChange(
      "input_420_span_min",
      input_index,
      e.currentTarget.valueAsNumber,
      arrayIndex
    );
  }

  const [spanMax, setSpanMax] = useState<number>(input_420_span_max);
  function updateSpanMax(e: React.FormEvent<HTMLInputElement>) {
    setSpanMax(e.currentTarget.valueAsNumber);
    handleChange(
      "input_420_span_max",
      input_index,
      e.currentTarget.valueAsNumber,
      arrayIndex
    );
  }

  const [showLiveData, setShowLiveData] = useState<boolean>(is_current_active);
  const [showHistoricalData, setShowHistoricalData] =
    useState<boolean>(is_historical_active);

  function handleLiveDataToggle() {
    if (!readOnly) {
      setShowLiveData(!showLiveData);
      handleChange("is_current_active", input_index, !showLiveData, arrayIndex);
    }
  }
  function handleHistoricalDataToggle(e: React.FormEvent<HTMLInputElement>) {
    if (!readOnly) {
      setShowHistoricalData(!showHistoricalData);
      handleChange(
        "is_historical_active",
        input_index,
        !showHistoricalData,
        arrayIndex
      );
    }
  }

  useEffect(() => {
    setShowLiveData(is_current_active);
    setShowHistoricalData(is_historical_active);
  }, [is_current_active, is_historical_active]);

  return (
    <StyledMainDiv data-testname={dataTestname} data-testid="main-div">
      <ConfirmationBox
        dataTestname="generic-device-input-confirmation-box"
        heading={"Information"}
        message={confirmText}
        active={confirmActive}
        onOk={handleConfirm}
        displayCancel={false}
      />
      {chosenMode === "Thermocouple" ? (
        <>
          <StyledInputRow>
            <StyledInputLabel>Mode</StyledInputLabel>
            <StyledInputCenteringDiv>
              <StyledInput>
                <Dropdown
                  dataTestname="generic-device-input-mode-dropdown"
                  columns={modeArray}
                  labelText=""
                  hideErrorsDiv={true}
                  height={"34px"}
                  border={"1px solid #8E8E8E"}
                  borderRadius={"2px"}
                  textColor={"#767676"}
                  selected={chosenMode}
                  onchange={updateChosenMode}
                  readOnly={readOnly}
                />
              </StyledInput>
            </StyledInputCenteringDiv>
          </StyledInputRow>
          <StyledInputRow>
            <StyledInputLabel>Name</StyledInputLabel>
            <StyledInputCenteringDiv>
              <StyledInput>
                <CommonInput
                  dataTestname="generic-device-input-thermocouple-name-common-input"
                  type="text"
                  value={name}
                  hideErrorsDiv={true}
                  labelText=""
                  height={"34px"}
                  border={"1px solid #8E8E8E"}
                  borderRadius={"2px"}
                  textColor={"#767676"}
                  onChange={updateName}
                  readOnly={readOnly}
                />
              </StyledInput>
            </StyledInputCenteringDiv>
          </StyledInputRow>
          <StyledInputRow>
            <StyledInputLabel>Units</StyledInputLabel>
            <StyledInputCenteringDiv>
              <StyledInput>
                <Dropdown
                  dataTestname="generic-device-input-thermocouple-units-dropdown"
                  columns={temperatureUnitsArray}
                  labelText=""
                  hideErrorsDiv={true}
                  height={"34px"}
                  border={"1px solid #8E8E8E"}
                  borderRadius={"2px"}
                  textColor={"#767676"}
                  selected={chosenTemperatureUnits}
                  onchange={updateTemperatureChosenUnits}
                  readOnly={readOnly}
                />
              </StyledInput>
            </StyledInputCenteringDiv>
          </StyledInputRow>
          <StyledInputRow>
            <StyledInputLabel>Show Live Data</StyledInputLabel>
            <StyledInputCenteringDiv>
              <StyledInput>
                <Checkbox
                  dataTestname="generic-device-input-thermocouple-live-data-checkbox"
                  arrayLetter={`L${arrayIndex}`}
                  isChecked={showLiveData}
                  label={""}
                  handleChange={handleLiveDataToggle}
                  alignment="right"
                  readOnly={readOnly}
                />
              </StyledInput>
            </StyledInputCenteringDiv>
          </StyledInputRow>
          <StyledInputRow>
            <StyledInputLabel>Show Historical Data</StyledInputLabel>
            <StyledInputCenteringDiv>
              <StyledInput>
                <Checkbox
                  dataTestname="generic-device-input-thermocouple-historical-data-checkbox"
                  arrayLetter={`H${arrayIndex}`}
                  isChecked={showHistoricalData}
                  label={""}
                  handleChange={handleHistoricalDataToggle}
                  alignment="right"
                  readOnly={readOnly}
                />
              </StyledInput>
            </StyledInputCenteringDiv>
          </StyledInputRow>
        </>
      ) : chosenMode === "4-20" ? (
        <>
          <StyledInputRow>
            <StyledInputLabel>Mode</StyledInputLabel>
            <StyledInputCenteringDiv>
              <StyledInput>
                <Dropdown
                  dataTestname="generic-device-input-420-mode-dropdown"
                  columns={modeArray}
                  labelText=""
                  hideErrorsDiv={true}
                  height={"34px"}
                  border={"1px solid #8E8E8E"}
                  borderRadius={"2px"}
                  textColor={"#767676"}
                  selected={chosenMode}
                  onchange={updateChosenMode}
                  readOnly={readOnly}
                />
              </StyledInput>
            </StyledInputCenteringDiv>
          </StyledInputRow>
          <StyledInputRow>
            <StyledInputLabel>Name</StyledInputLabel>
            <StyledInputCenteringDiv>
              <StyledInput>
                <CommonInput
                  dataTestname="generic-device-input-420-name-common-input"
                  type="text"
                  value={name}
                  hideErrorsDiv={true}
                  labelText=""
                  height={"34px"}
                  border={"1px solid #8E8E8E"}
                  borderRadius={"2px"}
                  textColor={"#767676"}
                  onChange={updateName}
                  readOnly={readOnly}
                />
              </StyledInput>
            </StyledInputCenteringDiv>
          </StyledInputRow>
          <StyledInputRow>
            <StyledInputLabel>Units</StyledInputLabel>
            <StyledInputCenteringDiv>
              <StyledInput>
                <CommonInput
                  dataTestname="generic-device-input-units-common-input"
                  type="text"
                  value={fourTwentyUnits}
                  hideErrorsDiv={true}
                  labelText=""
                  height={"34px"}
                  border={"1px solid #8E8E8E"}
                  borderRadius={"2px"}
                  textColor={"#767676"}
                  onChange={updateFourTwentyUnits}
                  readOnly={readOnly}
                />
              </StyledInput>
            </StyledInputCenteringDiv>
          </StyledInputRow>
          <StyledInputRow>
            <StyledInputLabel>Span Min</StyledInputLabel>
            <StyledInputCenteringDiv>
              <StyledInput>
                <CommonInput
                  dataTestname="generic-device-input-span-min-common-input"
                  type="number"
                  value={spanMin.toString()}
                  hideErrorsDiv={true}
                  labelText=""
                  height={"34px"}
                  border={"1px solid #8E8E8E"}
                  borderRadius={"2px"}
                  textColor={"#767676"}
                  onChange={updateSpanMin}
                  readOnly={readOnly}
                />
              </StyledInput>
            </StyledInputCenteringDiv>
          </StyledInputRow>
          <StyledInputRow>
            <StyledInputLabel>Span Max</StyledInputLabel>
            <StyledInputCenteringDiv>
              <StyledInput>
                <CommonInput
                  dataTestname="generic-device-input-span-max-common-input"
                  type="number"
                  value={spanMax.toString()}
                  hideErrorsDiv={true}
                  labelText=""
                  height={"34px"}
                  border={"1px solid #8E8E8E"}
                  borderRadius={"2px"}
                  textColor={"#767676"}
                  onChange={updateSpanMax}
                  readOnly={readOnly}
                />
              </StyledInput>
            </StyledInputCenteringDiv>
          </StyledInputRow>
          <StyledInputRow>
            <StyledInputLabel>Show Live Data</StyledInputLabel>
            <StyledInputCenteringDiv>
              <StyledInput>
                <Checkbox
                  dataTestname="generic-device-input-420-live-data-checkbox"
                  arrayLetter={`L${arrayIndex}`}
                  isChecked={showLiveData}
                  label={""}
                  handleChange={handleLiveDataToggle}
                  alignment="right"
                  readOnly={readOnly}
                />
              </StyledInput>
            </StyledInputCenteringDiv>
          </StyledInputRow>
          <StyledInputRow>
            <StyledInputLabel>Show Historical Data</StyledInputLabel>
            <StyledInputCenteringDiv>
              <StyledInput>
                <Checkbox
                  dataTestname="generic-device-input-420-historical-data-checkbox"
                  arrayLetter={`H${arrayIndex}`}
                  isChecked={showHistoricalData}
                  label={""}
                  handleChange={handleHistoricalDataToggle}
                  alignment="right"
                  readOnly={readOnly}
                />
              </StyledInput>
            </StyledInputCenteringDiv>
          </StyledInputRow>
        </>
      ) : chosenMode === "Digital" ? (
        <>
          <StyledInputRow>
            <StyledInputLabel>Mode</StyledInputLabel>
            <StyledInputCenteringDiv>
              <StyledInput>
                <Dropdown
                  dataTestname="generic-device-input-mode-dropdown"
                  columns={modeArray}
                  labelText=""
                  hideErrorsDiv={true}
                  height={"34px"}
                  border={"1px solid #8E8E8E"}
                  borderRadius={"2px"}
                  textColor={"#767676"}
                  selected={chosenMode}
                  onchange={updateChosenMode}
                  readOnly={readOnly}
                />
              </StyledInput>
            </StyledInputCenteringDiv>
          </StyledInputRow>
          <StyledInputRow>
            <StyledInputLabel>Name</StyledInputLabel>
            <StyledInputCenteringDiv>
              <StyledInput>
                <CommonInput
                  dataTestname="generic-device-input-name-common-input"
                  type="text"
                  value={name}
                  hideErrorsDiv={true}
                  labelText=""
                  height={"34px"}
                  border={"1px solid #8E8E8E"}
                  borderRadius={"2px"}
                  textColor={"#767676"}
                  onChange={updateName}
                  readOnly={readOnly}
                />
              </StyledInput>
            </StyledInputCenteringDiv>
          </StyledInputRow>
          <StyledInputRow>
            <StyledInputLabel>Show Live Data</StyledInputLabel>
            <StyledInputCenteringDiv>
              <StyledInput>
                <Checkbox
                  dataTestname="generic-device-input-live-data-checkbox"
                  arrayLetter={`L${arrayIndex}`}
                  isChecked={showLiveData}
                  label={""}
                  handleChange={handleLiveDataToggle}
                  alignment="right"
                  readOnly={readOnly}
                />
              </StyledInput>
            </StyledInputCenteringDiv>
          </StyledInputRow>
          <StyledInputRow>
            <StyledInputLabel>Show Historical Data</StyledInputLabel>
            <StyledInputCenteringDiv>
              <StyledInput>
                <Checkbox
                  dataTestname="generic-device-input-historical-data-checkbox"
                  arrayLetter={`H${arrayIndex}`}
                  isChecked={showHistoricalData}
                  label={""}
                  handleChange={handleHistoricalDataToggle}
                  alignment="right"
                  readOnly={readOnly}
                />
              </StyledInput>
            </StyledInputCenteringDiv>
          </StyledInputRow>
        </>
      ) : (
        chosenMode === "Disabled" && (
          <>
            <StyledInputRow>
              <StyledInputLabel>Mode</StyledInputLabel>
              <StyledInputCenteringDiv>
                <StyledInput>
                  <Dropdown
                    dataTestname="generic-device-input-disabled-mode-dropdown"
                    columns={modeArray}
                    labelText=""
                    hideErrorsDiv={true}
                    height={"34px"}
                    border={"1px solid #8E8E8E"}
                    borderRadius={"2px"}
                    textColor={"#767676"}
                    selected={chosenMode}
                    onchange={updateChosenMode}
                    readOnly={readOnly}
                  />
                </StyledInput>
              </StyledInputCenteringDiv>
            </StyledInputRow>
          </>
        )
      )}
    </StyledMainDiv>
  );
};

export default GenericDeviceInputArea;

const StyledMainDiv = styled.div`
  width: Calc(100% - 46px);

  background-color: #e8e8e8;
  padding: 16px 10px 15px 36px;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    width: Calc(100% - (102px * 2));

    padding: 16px 102px 15px 102px;
  }
`;

const StyledInputRow = styled.div`
  width: 100%;
  height: 34px;

  display: flex;
  flex-direction: row;
  justify-content: start;
  margin-bottom: 10px;
`;

const StyledInputLabel = styled.label`
  width: 45%;

  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
  color: #767676;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    width: 35.7%;
  }
`;

const StyledInput = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: end;

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
  }

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    width: 27.425%;
    min-width: 193.2px;

    display: flex;
    flex-direction: row;
    justify-content: end;
  }
`;

const StyledInputCenteringDiv = styled.div`
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
