import { ActionTypes, IStyleState } from "./types";

type Action =
  | { type: ActionTypes.SENDING_REQUEST }
  | { type: ActionTypes.REQUEST_FINISHED };

const reducer = (state: IStyleState, action: Action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default reducer;
