import { IUser, IPermission } from "../../types/users";
import { ActionTypes, IUserState } from "./types";

type Action =
  | { type: ActionTypes.SET_USER; payload: IUser }
  | { type: ActionTypes.SET_PERMISSIONS; payload: IPermission[] }
  | { type: ActionTypes.SENDING_REQUEST }
  | { type: ActionTypes.REQUEST_FINISHED };

const reducer = (state: IUserState, action: Action) => {
  switch (action.type) {
    case ActionTypes.SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case ActionTypes.SET_PERMISSIONS:
      let permsObj: any = {
        external_document_read: false,
        external_document_read_write: false,
        external_grafana_access: false,
        external_manage_api_key: false,
        external_manage_tags: false,
        external_manage_users: false,
        profire_document_read_write: false,
        profire_grafana_access: false,
        profire_manage_api_key: false,
        profire_manage_company: false,
        profire_manage_control_units: false,
        profire_manage_monitor_units: false,
        profire_manage_process_units: false,
        profire_manage_sites: false,
        profire_manage_users: false,
        profire_select_company: false,
      };
      let fieldName: string = "";

      action.payload.forEach((permission) => {
        fieldName = permission.permission.toString();
        permsObj[fieldName] = true;
      });

      return {
        ...state,
        permissions: permsObj,
      };
    case ActionTypes.SENDING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.REQUEST_FINISHED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
