import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { PropertyAccess } from "../../../utilities/propertyAccess";
import { openInNewTab } from "../../../utilities/secureNewTab";
import { FormatHeader } from "../../../utilities/tableHeaderFix";
import {
  handleDateDisplay,
  handleDurationDisplay,
  handleTimestampDisplay,
} from "../../../utilities/timeFunctions";
import { validateDateString } from "../../../utilities/validationFunctions";
import ChartIcon from "../../../assets/images/navigation/chart-icon.svg";
import ProfireButton from "../buttons/ProfireButton";
import StateButton from "../buttons/StateButton";
import EditButton from "../buttons/EditButton";
import DeleteButton from "../buttons/DeleteButton";
import HistoryButton from "../buttons/HistoryButton";
import LinkButton from "../buttons/LinkButton";
import InfoButton from "../buttons/InfoButton";

// Common Button Table Component Instructions
// 1. Encase <CommonButtonTable headers={string array of headers} data={array of desired data to display} /> in a Styled div}
// 2. Here is a list of the props and how each of them are used:
//  - data: the data you wish to display in a Table-ized format
//  - headers: the properties on the data that you wish to display, must match case-wise
//  - overwriteHeaders: the Headings shown at the top of the table. If you do not enter anything here, your headers will be shown by default with the first letter of each word capitalized, and each _ turned into a space.
//  - elements: the type of element that you wish to display. Please find the list of accepted elements below:
//    - "Button": this will insert a <ProfireButton />
//    - "State": this will insert a <StateButton />
//    - "Chart": this will insert a Chart Icon
//    - "Edit": this will insert an <EditButton />
//    - "Delete": this will insert a <DeleteButton />
//    - "History": this will insert a <HistoryButton />
//    - "Space": this will insert a non-breaking space or nbsp; leaving that cell blank
//    - "Timestamp": this will display as yyyy-mm-dd hh:mm:ss
//    - "Duration": this will display as "xx Days yy hrs zz mins"
//    - "SplitData": this will display data on multiple lines, split by whatever is entered in the elementInfo slot
//    - Anything else will simply display whatever data is found from data[header]
//  - elementsInfo: currently only used by "Button" and "Chart", see below:
//    - "Button" uses elementsInfo for the ButtonText
//    - "Chart" uses the elementsInfo for the URL to navigate to in a new tab
//      - for now, "Chart" is only really usable with dashboard_url on Control Unit
//    - "SplitData": uses the elementsInfo to split the data by whatever was entered (ex: entering ";" will split the data received on every ";" encountered)
//  - elementsOnClick: functions that will occur when an element is clicked on.
//    - "Button", "State", "Edit", "Delete", "History" all support elementsOnClick
//  - desktopColWidths: a string[] that determines how wide to make each column
//    - % and px are both supported
//  - textAlign: a string[] that determines the column's text alignment
//    - "left", "right", "center" are supported
// 3. Please do not touch the styling in this file. If you *do* need to change styling, please talk to TJ.
// 4. If you have any suggestions or need another css property to be supported, please talk to TJ.
// 5. If there are any issues, please talk to TJ.

interface CommonCustomizableTableProps {
  dataTestname: string;
  data: any[];
  headers: string[];
  overwriteHeaders?: string[];
  elements: string[];
  elementsInfo: string[];
  elementsOnClick: any[];
  tableWidth: string;
  tableMinWidth: string;
  tableMaxHeight: string;
  desktopColWidths: string[];
  desktopColMinWidths: string[];
  mobileColWidths: string[];
  mobileColMinWidths: string[];
  textAlign: string[];
  textAlignMobile?: string[];
  tdHeight: string;
  tdMinHeight?: string;
  tdHeightMobile: string;
  tdMinHeightMobile?: string;
  addPaddingRightForScroll?: boolean;
  usageIdentifier: string;
  colDisplayOnMobile: boolean[];
  colJustify: string[];
  colJustifyMobile?: string[];
  colFlexDirection: string[];
  mobileRowOnClick?: Function | undefined;
  infoButtonFunction?: React.MouseEventHandler<HTMLDivElement>;
}

const CommonCustomizableTable: React.FC<CommonCustomizableTableProps> = ({
  dataTestname,
  headers,
  overwriteHeaders,
  data,
  elements,
  elementsInfo,
  elementsOnClick,
  tableWidth,
  tableMinWidth,
  tableMaxHeight,
  desktopColWidths,
  desktopColMinWidths,
  mobileColWidths,
  mobileColMinWidths,
  textAlign,
  textAlignMobile,
  tdHeight,
  tdMinHeight = tdHeight,
  tdHeightMobile,
  tdMinHeightMobile = tdHeightMobile,
  addPaddingRightForScroll,
  usageIdentifier,
  colDisplayOnMobile,
  colJustify,
  colJustifyMobile,
  colFlexDirection,
  mobileRowOnClick,
  infoButtonFunction = undefined,
}) => {
  const [width, setWidth] = useState<number>(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const isMobile = width <= 768;

  function rowOnClick(e: any) {
    if (isMobile && mobileRowOnClick !== undefined) {
      mobileRowOnClick(e);
    }
  }

  return (
    <StyledCommonTableDiv
      data-testname={dataTestname}
      tableWidth={tableWidth}
      tableMinWidth={tableMinWidth}
      tableMaxHeight={tableMaxHeight}
      addPaddingRightForScroll={
        addPaddingRightForScroll === undefined
          ? false
          : addPaddingRightForScroll
      }
    >
      <StyledCommonTable data-testid="common-table" className="table">
        <thead className="headBg">
          <StyledCommonTableHeader data-testid="common-table-header">
            <StyledTh>
              {overwriteHeaders
                ? overwriteHeaders.map((header, index) =>
                    header === "InfoButton" ? (
                      <StyledHeaderDiv
                        data-testid="header-div"
                        desktopColWidth={desktopColWidths[index]}
                        desktopColMinWidth={desktopColMinWidths[index]}
                        mobileColWidth={mobileColWidths[index]}
                        mobileColMinWidth={mobileColMinWidths[index]}
                        textAlign={textAlign[index]}
                        textAlignMobile={
                          textAlignMobile ? textAlignMobile[index] : undefined
                        }
                        key={header + index}
                        element={elements[index]}
                        colDisplayOnMobile={colDisplayOnMobile[index]}
                      >
                        <StyledCenteringDiv
                          colJustify={colJustify[index]}
                          colJustifyMobile={
                            colJustifyMobile === undefined
                              ? undefined
                              : colJustifyMobile[index]
                          }
                          colFlexDirection={colFlexDirection[index]}
                        >
                          <StyledHeaderSpan>
                            <InfoButton
                              dataTestname={`common-customizable-table-${usageIdentifier}-info-button-${index}`}
                              onClickFunction={infoButtonFunction!}
                              hoverWidthsMobile={["0px", "0px", "0px"]}
                              hoverPositionMobile={["0px", "0px", "0px", "0px"]}
                            />
                          </StyledHeaderSpan>
                        </StyledCenteringDiv>
                      </StyledHeaderDiv>
                    ) : (
                      <StyledHeaderDiv
                        data-testid="header-div"
                        desktopColWidth={desktopColWidths[index]}
                        desktopColMinWidth={desktopColMinWidths[index]}
                        mobileColWidth={mobileColWidths[index]}
                        mobileColMinWidth={mobileColMinWidths[index]}
                        textAlign={textAlign[index]}
                        textAlignMobile={
                          textAlignMobile ? textAlignMobile[index] : undefined
                        }
                        key={header + index}
                        element={elements[index]}
                        colDisplayOnMobile={colDisplayOnMobile[index]}
                      >
                        <StyledCenteringDiv
                          colJustify={colJustify[index]}
                          colJustifyMobile={
                            colJustifyMobile === undefined
                              ? undefined
                              : colJustifyMobile[index]
                          }
                          colFlexDirection={colFlexDirection[index]}
                        >
                          <StyledHeaderSpan>
                            {FormatHeader(header)}
                          </StyledHeaderSpan>
                        </StyledCenteringDiv>
                      </StyledHeaderDiv>
                    )
                  )
                : headers.map((header, index) =>
                    header === "InfoButton" ? (
                      <StyledHeaderDiv
                        data-testid="header-div"
                        desktopColWidth={desktopColWidths[index]}
                        desktopColMinWidth={desktopColMinWidths[index]}
                        mobileColWidth={mobileColWidths[index]}
                        mobileColMinWidth={mobileColMinWidths[index]}
                        textAlign={textAlign[index]}
                        textAlignMobile={
                          textAlignMobile ? textAlignMobile[index] : undefined
                        }
                        key={header + index}
                        element={elements[index]}
                        colDisplayOnMobile={colDisplayOnMobile[index]}
                      >
                        <StyledCenteringDiv
                          colJustify={colJustify[index]}
                          colJustifyMobile={
                            colJustifyMobile === undefined
                              ? undefined
                              : colJustifyMobile[index]
                          }
                          colFlexDirection={colFlexDirection[index]}
                        >
                          <StyledHeaderSpan>
                            <InfoButton
                              dataTestname={`common-customizable-table-${usageIdentifier}-info-button-${index}`}
                              onClickFunction={infoButtonFunction!}
                              hoverWidthsMobile={["0px", "0px", "0px"]}
                              hoverPositionMobile={["0px", "0px", "0px", "0px"]}
                            />
                          </StyledHeaderSpan>
                        </StyledCenteringDiv>
                      </StyledHeaderDiv>
                    ) : header.includes(".") ? (
                      <StyledHeaderDiv
                        data-testid="header-div"
                        desktopColWidth={desktopColWidths[index]}
                        desktopColMinWidth={desktopColMinWidths[index]}
                        mobileColWidth={mobileColWidths[index]}
                        mobileColMinWidth={mobileColMinWidths[index]}
                        textAlign={textAlign[index]}
                        textAlignMobile={
                          textAlignMobile ? textAlignMobile[index] : undefined
                        }
                        key={header.split(".")[1] + index}
                        element={elements[index]}
                        colDisplayOnMobile={colDisplayOnMobile[index]}
                      >
                        <StyledCenteringDiv
                          colJustify={colJustify[index]}
                          colJustifyMobile={
                            colJustifyMobile === undefined
                              ? undefined
                              : colJustifyMobile[index]
                          }
                          colFlexDirection={colFlexDirection[index]}
                        >
                          <StyledHeaderSpan>
                            {FormatHeader(header.split(".")[1])}
                          </StyledHeaderSpan>
                        </StyledCenteringDiv>
                      </StyledHeaderDiv>
                    ) : (
                      <StyledHeaderDiv
                        data-testid="header-div"
                        desktopColWidth={desktopColWidths[index]}
                        desktopColMinWidth={desktopColMinWidths[index]}
                        mobileColWidth={mobileColWidths[index]}
                        mobileColMinWidth={mobileColMinWidths[index]}
                        textAlign={textAlign[index]}
                        textAlignMobile={
                          textAlignMobile ? textAlignMobile[index] : undefined
                        }
                        key={header + index}
                        element={elements[index]}
                        colDisplayOnMobile={colDisplayOnMobile[index]}
                      >
                        <StyledCenteringDiv
                          colJustify={colJustify[index]}
                          colJustifyMobile={
                            colJustifyMobile === undefined
                              ? undefined
                              : colJustifyMobile[index]
                          }
                          colFlexDirection={colFlexDirection[index]}
                        >
                          <StyledHeaderSpan>
                            {FormatHeader(header)}
                          </StyledHeaderSpan>
                        </StyledCenteringDiv>
                      </StyledHeaderDiv>
                    )
                  )}
            </StyledTh>
          </StyledCommonTableHeader>
        </thead>
        <tbody>
          {data.map((datum, index) => (
            <StyledCommonTableRow key={"row" + index}>
              <StyledTd
                height={tdHeight ? tdHeight : "initial"}
                minHeight={tdMinHeight ? tdMinHeight : tdHeight}
                heightMobile={tdHeightMobile ? tdHeightMobile : "initial"}
                minHeightMobile={
                  tdMinHeightMobile ? tdMinHeightMobile : tdHeightMobile
                }
                element={elements[index]}
                onClick={() => rowOnClick(datum)!}
                mobileRowOnClickExists={
                  mobileRowOnClick === undefined ? false : true
                }
              >
                {headers.map((header, index) =>
                  elements[index] === "Button" ? (
                    <StyledElementDiv
                      key={index}
                      desktopColWidth={desktopColWidths[index]}
                      desktopColMinWidth={desktopColMinWidths[index]}
                      mobileColWidth={mobileColWidths[index]}
                      mobileColMinWidth={mobileColMinWidths[index]}
                      textAlign={textAlign[index]}
                      textAlignMobile={
                        textAlignMobile ? textAlignMobile[index] : undefined
                      }
                      element={elements[index]}
                      colDisplayOnMobile={colDisplayOnMobile[index]}
                    >
                      <StyledCenteringDiv
                        colJustify={colJustify[index]}
                        colJustifyMobile={
                          colJustifyMobile === undefined
                            ? undefined
                            : colJustifyMobile[index]
                        }
                        colFlexDirection={colFlexDirection[index]}
                      >
                        <ProfireButton
                          dataTestname={`common-customizable-table-${usageIdentifier}-button-${
                            datum.alert_rule_id === undefined
                              ? datum.id
                              : datum.alert_rule_id
                          }`}
                          onClickFunction={elementsOnClick[index]}
                          text={elementsInfo[index]}
                        />
                      </StyledCenteringDiv>
                    </StyledElementDiv>
                  ) : elements[index] === "RouteButton" ? (
                    <StyledElementDiv
                      key={index}
                      desktopColWidth={desktopColWidths[index]}
                      desktopColMinWidth={desktopColMinWidths[index]}
                      mobileColWidth={mobileColWidths[index]}
                      mobileColMinWidth={mobileColMinWidths[index]}
                      textAlign={textAlign[index]}
                      textAlignMobile={
                        textAlignMobile ? textAlignMobile[index] : undefined
                      }
                      element={elements[index]}
                      colDisplayOnMobile={colDisplayOnMobile[index]}
                    >
                      <StyledCenteringDiv
                        colJustify={colJustify[index]}
                        colJustifyMobile={
                          colJustifyMobile === undefined
                            ? undefined
                            : colJustifyMobile[index]
                        }
                        colFlexDirection={colFlexDirection[index]}
                      >
                        <ProfireButton
                          dataTestname={`common-customizable-table-${usageIdentifier}-route-button-${
                            datum.alert_rule_id === undefined
                              ? datum.id
                              : datum.alert_rule_id
                          }`}
                          onClickFunction={() => elementsOnClick[index](datum)}
                          text={elementsInfo[index]}
                        />
                      </StyledCenteringDiv>
                    </StyledElementDiv>
                  ) : elements[index] === "State" ||
                    elements[index] === "StateMorph" ? (
                    <StyledElementDiv
                      key={index}
                      desktopColWidth={desktopColWidths[index]}
                      desktopColMinWidth={desktopColMinWidths[index]}
                      mobileColWidth={mobileColWidths[index]}
                      mobileColMinWidth={mobileColMinWidths[index]}
                      textAlign={textAlign[index]}
                      textAlignMobile={
                        textAlignMobile ? textAlignMobile[index] : undefined
                      }
                      element={elements[index]}
                      colDisplayOnMobile={colDisplayOnMobile[index]}
                    >
                      <StyledCenteringDiv
                        colJustify={colJustify[index]}
                        colJustifyMobile={
                          colJustifyMobile === undefined
                            ? undefined
                            : colJustifyMobile[index]
                        }
                        colFlexDirection={colFlexDirection[index]}
                      >
                        <StateButton
                          dataTestname={`common-customizable-table-${usageIdentifier}-state-button-${
                            datum.alert_rule_id === undefined
                              ? datum.id
                              : datum.alert_rule_id
                          }`}
                          onClickFunction={elementsOnClick[index]}
                          state={datum.alert_state}
                          element={elements[index]}
                        />
                      </StyledCenteringDiv>
                    </StyledElementDiv>
                  ) : elements[index] === "Chart" ? (
                    <StyledElementDiv
                      key={index}
                      desktopColWidth={desktopColWidths[index]}
                      desktopColMinWidth={desktopColMinWidths[index]}
                      mobileColWidth={mobileColWidths[index]}
                      mobileColMinWidth={mobileColMinWidths[index]}
                      textAlign={textAlign[index]}
                      textAlignMobile={
                        textAlignMobile ? textAlignMobile[index] : undefined
                      }
                      element={elements[index]}
                      colDisplayOnMobile={colDisplayOnMobile[index]}
                    >
                      <StyledCenteringDiv
                        colJustify={colJustify[index]}
                        colJustifyMobile={
                          colJustifyMobile === undefined
                            ? undefined
                            : colJustifyMobile[index]
                        }
                        colFlexDirection={colFlexDirection[index]}
                      >
                        <StyledGrafanaLink
                          onClick={() => openInNewTab(elementsInfo[index])}
                          src={ChartIcon}
                          alt="chart icon"
                        />
                      </StyledCenteringDiv>
                    </StyledElementDiv>
                  ) : elements[index] === "Edit" ? (
                    <StyledElementDiv
                      key={index}
                      desktopColWidth={desktopColWidths[index]}
                      desktopColMinWidth={desktopColMinWidths[index]}
                      mobileColWidth={mobileColWidths[index]}
                      mobileColMinWidth={mobileColMinWidths[index]}
                      textAlign={textAlign[index]}
                      textAlignMobile={
                        textAlignMobile ? textAlignMobile[index] : undefined
                      }
                      element={elements[index]}
                      colDisplayOnMobile={colDisplayOnMobile[index]}
                    >
                      <StyledCenteringDiv
                        colJustify={colJustify[index]}
                        colJustifyMobile={
                          colJustifyMobile === undefined
                            ? undefined
                            : colJustifyMobile[index]
                        }
                        colFlexDirection={colFlexDirection[index]}
                      >
                        <EditButton
                          dataTestname={`common-customizable-table-${usageIdentifier}-edit-button-${
                            datum.alert_rule_id === undefined
                              ? datum.id
                              : datum.alert_rule_id
                          }`}
                          onClickFunction={(e: any) =>
                            elementsOnClick[index](datum)
                          }
                        />
                      </StyledCenteringDiv>
                    </StyledElementDiv>
                  ) : elements[index] === "Delete" ? (
                    <StyledElementDiv
                      key={index}
                      desktopColWidth={desktopColWidths[index]}
                      desktopColMinWidth={desktopColMinWidths[index]}
                      mobileColWidth={mobileColWidths[index]}
                      mobileColMinWidth={mobileColMinWidths[index]}
                      textAlign={textAlign[index]}
                      textAlignMobile={
                        textAlignMobile ? textAlignMobile[index] : undefined
                      }
                      element={elements[index]}
                      colDisplayOnMobile={colDisplayOnMobile[index]}
                    >
                      <StyledCenteringDiv
                        colJustify={colJustify[index]}
                        colJustifyMobile={
                          colJustifyMobile === undefined
                            ? undefined
                            : colJustifyMobile[index]
                        }
                        colFlexDirection={colFlexDirection[index]}
                      >
                        <DeleteButton
                          dataTestname={`common-customizable-table-${usageIdentifier}-delete-button-${
                            datum.alert_rule_id === undefined
                              ? datum.id
                              : datum.alert_rule_id
                          }`}
                          onClickFunction={(e: any) =>
                            elementsOnClick[index](datum)
                          }
                        />
                      </StyledCenteringDiv>
                    </StyledElementDiv>
                  ) : elements[index] === "History" ? (
                    <StyledElementDiv
                      key={index}
                      desktopColWidth={desktopColWidths[index]}
                      desktopColMinWidth={desktopColMinWidths[index]}
                      mobileColWidth={mobileColWidths[index]}
                      mobileColMinWidth={mobileColMinWidths[index]}
                      textAlign={textAlign[index]}
                      textAlignMobile={
                        textAlignMobile ? textAlignMobile[index] : undefined
                      }
                      element={elements[index]}
                      colDisplayOnMobile={colDisplayOnMobile[index]}
                    >
                      <StyledCenteringDiv
                        colJustify={colJustify[index]}
                        colJustifyMobile={
                          colJustifyMobile === undefined
                            ? undefined
                            : colJustifyMobile[index]
                        }
                        colFlexDirection={colFlexDirection[index]}
                      >
                        <HistoryButton
                          dataTestname={`common-customizable-table-${usageIdentifier}-history-button-${
                            datum.alert_rule_id === undefined
                              ? datum.id
                              : datum.alert_rule_id
                          }`}
                          onClickFunction={(e: any) =>
                            elementsOnClick[index](datum)
                          }
                        />
                      </StyledCenteringDiv>
                    </StyledElementDiv>
                  ) : elements[index] === "Link" ? (
                    <StyledElementDiv
                      key={index}
                      desktopColWidth={desktopColWidths[index]}
                      desktopColMinWidth={desktopColMinWidths[index]}
                      mobileColWidth={mobileColWidths[index]}
                      mobileColMinWidth={mobileColMinWidths[index]}
                      textAlign={textAlign[index]}
                      textAlignMobile={
                        textAlignMobile ? textAlignMobile[index] : undefined
                      }
                      element={elements[index]}
                      colDisplayOnMobile={colDisplayOnMobile[index]}
                    >
                      <StyledCenteringDiv
                        colJustify={colJustify[index]}
                        colJustifyMobile={
                          colJustifyMobile === undefined
                            ? undefined
                            : colJustifyMobile[index]
                        }
                        colFlexDirection={colFlexDirection[index]}
                      >
                        <LinkButton
                          dataTestname={`common-customizable-table-${usageIdentifier}-link-button-${
                            datum.alert_rule_id === undefined
                              ? datum.id
                              : datum.alert_rule_id
                          }`}
                          onClickFunction={(e: any) =>
                            elementsOnClick[index](datum)
                          }
                        />
                      </StyledCenteringDiv>
                    </StyledElementDiv>
                  ) : elements[index] === "Space" ? (
                    <StyledElementDiv
                      key={index}
                      desktopColWidth={desktopColWidths[index]}
                      desktopColMinWidth={desktopColMinWidths[index]}
                      mobileColWidth={mobileColWidths[index]}
                      mobileColMinWidth={mobileColMinWidths[index]}
                      textAlign={textAlign[index]}
                      textAlignMobile={
                        textAlignMobile ? textAlignMobile[index] : undefined
                      }
                      element={elements[index]}
                      colDisplayOnMobile={colDisplayOnMobile[index]}
                    >
                      <StyledCenteringDiv
                        colJustify={colJustify[index]}
                        colJustifyMobile={
                          colJustifyMobile === undefined
                            ? undefined
                            : colJustifyMobile[index]
                        }
                        colFlexDirection={colFlexDirection[index]}
                      >
                        &nbsp;
                      </StyledCenteringDiv>
                    </StyledElementDiv>
                  ) : elements[index] === "Timestamp" ? (
                    <StyledElementDiv
                      key={index}
                      desktopColWidth={desktopColWidths[index]}
                      desktopColMinWidth={desktopColMinWidths[index]}
                      mobileColWidth={mobileColWidths[index]}
                      mobileColMinWidth={mobileColMinWidths[index]}
                      textAlign={textAlign[index]}
                      textAlignMobile={
                        textAlignMobile ? textAlignMobile[index] : undefined
                      }
                      element={elements[index]}
                      colDisplayOnMobile={colDisplayOnMobile[index]}
                    >
                      <StyledCenteringDiv
                        colJustify={colJustify[index]}
                        colJustifyMobile={
                          colJustifyMobile === undefined
                            ? undefined
                            : colJustifyMobile[index]
                        }
                        colFlexDirection={colFlexDirection[index]}
                      >
                        {handleTimestampDisplay(datum[header])}
                      </StyledCenteringDiv>
                    </StyledElementDiv>
                  ) : elements[index] === "Duration" ? (
                    <StyledElementDiv
                      key={index}
                      desktopColWidth={desktopColWidths[index]}
                      desktopColMinWidth={desktopColMinWidths[index]}
                      mobileColWidth={mobileColWidths[index]}
                      mobileColMinWidth={mobileColMinWidths[index]}
                      textAlign={textAlign[index]}
                      textAlignMobile={
                        textAlignMobile ? textAlignMobile[index] : undefined
                      }
                      element={elements[index]}
                      colDisplayOnMobile={colDisplayOnMobile[index]}
                    >
                      <StyledCenteringDiv
                        colJustify={colJustify[index]}
                        colJustifyMobile={
                          colJustifyMobile === undefined
                            ? undefined
                            : colJustifyMobile[index]
                        }
                        colFlexDirection={colFlexDirection[index]}
                      >
                        {handleDurationDisplay(datum[header])}
                      </StyledCenteringDiv>
                    </StyledElementDiv>
                  ) : elements[index] === "SplitData" ? (
                    <StyledElementDiv
                      key={index}
                      desktopColWidth={desktopColWidths[index]}
                      desktopColMinWidth={desktopColMinWidths[index]}
                      mobileColWidth={mobileColWidths[index]}
                      mobileColMinWidth={mobileColMinWidths[index]}
                      textAlign={textAlign[index]}
                      textAlignMobile={
                        textAlignMobile ? textAlignMobile[index] : undefined
                      }
                      element={elements[index]}
                      colDisplayOnMobile={colDisplayOnMobile[index]}
                    >
                      <StyledCenteringDiv
                        colJustify={colJustify[index]}
                        colJustifyMobile={
                          colJustifyMobile === undefined
                            ? undefined
                            : colJustifyMobile[index]
                        }
                        colFlexDirection={colFlexDirection[index]}
                      >
                        {datum[header] &&
                          datum[header]
                            .split(elementsInfo[index])
                            .map((mail: string) => (
                              <StyledMultiLineSplitData key={mail}>
                                {mail}
                              </StyledMultiLineSplitData>
                            ))}
                      </StyledCenteringDiv>
                    </StyledElementDiv>
                  ) : header.includes(".") ? (
                    datum[header.split(".")[0]] === null ? (
                      <StyledElementDiv
                        key={index}
                        data-testid="data-div"
                        desktopColWidth={desktopColWidths[index]}
                        desktopColMinWidth={desktopColMinWidths[index]}
                        mobileColWidth={mobileColWidths[index]}
                        mobileColMinWidth={mobileColMinWidths[index]}
                        textAlign={textAlign[index]}
                        textAlignMobile={
                          textAlignMobile ? textAlignMobile[index] : undefined
                        }
                        element={elements[index]}
                        colDisplayOnMobile={colDisplayOnMobile[index]}
                      >
                        <StyledCenteringDiv
                          colJustify={colJustify[index]}
                          colJustifyMobile={
                            colJustifyMobile === undefined
                              ? undefined
                              : colJustifyMobile[index]
                          }
                          colFlexDirection={colFlexDirection[index]}
                        >
                          <StyledDataSpan textDecoration={elementsInfo[index]}>
                            N/A
                          </StyledDataSpan>
                        </StyledCenteringDiv>
                      </StyledElementDiv>
                    ) : (
                      <StyledElementDiv
                        key={index}
                        data-testid="data-div"
                        desktopColWidth={desktopColWidths[index]}
                        desktopColMinWidth={desktopColMinWidths[index]}
                        mobileColWidth={mobileColWidths[index]}
                        mobileColMinWidth={mobileColMinWidths[index]}
                        textAlign={textAlign[index]}
                        textAlignMobile={
                          textAlignMobile ? textAlignMobile[index] : undefined
                        }
                        element={elements[index]}
                        colDisplayOnMobile={colDisplayOnMobile[index]}
                      >
                        <StyledCenteringDiv
                          colJustify={colJustify[index]}
                          colJustifyMobile={
                            colJustifyMobile === undefined
                              ? undefined
                              : colJustifyMobile[index]
                          }
                          colFlexDirection={colFlexDirection[index]}
                        >
                          <StyledDataSpan textDecoration={elementsInfo[index]}>
                            {PropertyAccess(datum, header)}
                          </StyledDataSpan>
                        </StyledCenteringDiv>
                      </StyledElementDiv>
                    )
                  ) : validateDateString(datum[header]) ? (
                    <StyledElementDiv
                      key={index}
                      data-testid="data-div"
                      desktopColWidth={desktopColWidths[index]}
                      desktopColMinWidth={desktopColMinWidths[index]}
                      mobileColWidth={mobileColWidths[index]}
                      mobileColMinWidth={mobileColMinWidths[index]}
                      textAlign={textAlign[index]}
                      textAlignMobile={
                        textAlignMobile ? textAlignMobile[index] : undefined
                      }
                      element={elements[index]}
                      colDisplayOnMobile={colDisplayOnMobile[index]}
                    >
                      <StyledCenteringDiv
                        colJustify={colJustify[index]}
                        colJustifyMobile={
                          colJustifyMobile === undefined
                            ? undefined
                            : colJustifyMobile[index]
                        }
                        colFlexDirection={colFlexDirection[index]}
                      >
                        <StyledDataSpan textDecoration={elementsInfo[index]}>
                          {handleDateDisplay(
                            new Date(datum[header].toString())
                          )}
                        </StyledDataSpan>
                      </StyledCenteringDiv>
                    </StyledElementDiv>
                  ) : datum[header] === null ? (
                    <StyledElementDiv
                      key={index}
                      data-testid="data-div"
                      desktopColWidth={desktopColWidths[index]}
                      desktopColMinWidth={desktopColMinWidths[index]}
                      mobileColWidth={mobileColWidths[index]}
                      mobileColMinWidth={mobileColMinWidths[index]}
                      textAlign={textAlign[index]}
                      textAlignMobile={
                        textAlignMobile ? textAlignMobile[index] : undefined
                      }
                      element={elements[index]}
                      colDisplayOnMobile={colDisplayOnMobile[index]}
                    >
                      <StyledCenteringDiv
                        colJustify={colJustify[index]}
                        colJustifyMobile={
                          colJustifyMobile === undefined
                            ? undefined
                            : colJustifyMobile[index]
                        }
                        colFlexDirection={colFlexDirection[index]}
                      >
                        <StyledDataSpan textDecoration={elementsInfo[index]}>
                          N/A
                        </StyledDataSpan>
                      </StyledCenteringDiv>
                    </StyledElementDiv>
                  ) : (
                    <StyledElementDiv
                      key={index}
                      data-testid="data-div"
                      desktopColWidth={desktopColWidths[index]}
                      desktopColMinWidth={desktopColMinWidths[index]}
                      mobileColWidth={mobileColWidths[index]}
                      mobileColMinWidth={mobileColMinWidths[index]}
                      textAlign={textAlign[index]}
                      textAlignMobile={
                        textAlignMobile ? textAlignMobile[index] : undefined
                      }
                      element={elements[index]}
                      colDisplayOnMobile={colDisplayOnMobile[index]}
                    >
                      <StyledCenteringDiv
                        colJustify={colJustify[index]}
                        colJustifyMobile={
                          colJustifyMobile === undefined
                            ? undefined
                            : colJustifyMobile[index]
                        }
                        colFlexDirection={colFlexDirection[index]}
                      >
                        <StyledDataSpan textDecoration={elementsInfo[index]}>
                          <StyledMultiLineSplitData>
                            {datum[header]}
                          </StyledMultiLineSplitData>
                        </StyledDataSpan>
                      </StyledCenteringDiv>
                    </StyledElementDiv>
                  )
                )}
              </StyledTd>
            </StyledCommonTableRow>
          ))}
        </tbody>
      </StyledCommonTable>
    </StyledCommonTableDiv>
  );
};

export default CommonCustomizableTable;

interface TableDivProps {
  tableWidth: string;
  tableMinWidth: string;
  tableMaxHeight: string;
  addPaddingRightForScroll: boolean;
}

const StyledCommonTableDiv = styled.div<TableDivProps>`
  text-align: left;
  overflow: auto;
  max-width: 300px;
  max-height: 60vh;
  width: ${(props) => props.tableWidth};
  min-width: ${(props) => props.tableMinWidth};
  background-color: white;
  height: 100%;
  padding-right: ${(props) =>
    props.addPaddingRightForScroll ? "10px" : "0px"};

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    max-height: ${(props) => props.tableMaxHeight};
  }
`;

const StyledCommonTable = styled.table`
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;

  thead {
    position: sticky;
    top: 0;
    z-index: 2;
    background: white;
  }

  thead th {
    position: sticky;
    top: 0;
    z-index: 1;
    background: white;
  }

  tbody th {
    position: sticky;
  }

  thead th:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
  }

  tbody th {
    position: sticky;
    left: 0;
    background: white;
    z-index: 1;
  }

  th,
  td {
    padding: 0;
    display: flex;
  }
`;

const StyledCommonTableHeader = styled.tr``;

const textElementsArray = [
  "Data",
  "SplitData",
  "Space",
  "Timestamp",
  "Duration",
];

interface DivProps {
  desktopColWidth: string;
  desktopColMinWidth: string;
  mobileColWidth: string;
  mobileColMinWidth: string;
  textAlign: string;
  textAlignMobile?: string;
  element: string;
  colDisplayOnMobile: boolean;
}

const StyledHeaderDiv = styled.div<DivProps>`
  width: ${(props) =>
    textElementsArray.includes(props.element)
      ? `Calc(${props.mobileColWidth} - 10px)`
      : `Calc(${props.mobileColWidth})`};
  min-width: ${(props) =>
    textElementsArray.includes(props.element)
      ? `Calc(${props.mobileColMinWidth} - 10px)`
      : `Calc(${props.mobileColMinWidth})`};
  text-align: ${(props) =>
    props.textAlignMobile === undefined
      ? props.textAlign
      : props.textAlignMobile};
  display: ${(props) => (props.colDisplayOnMobile ? "flex" : "none")};

  padding-bottom: 4.5px;
  padding-right: ${(props) =>
    textElementsArray.includes(props.element) ? "10px" : "0px"};
  border-bottom: 1px solid #e5e5e5;
  background-color: white;

  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.headingSecondaryWeight};

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    display: flex;
    width: ${(props) =>
      textElementsArray.includes(props.element)
        ? `Calc(${props.desktopColWidth} - 10px)`
        : `Calc(${props.desktopColWidth})`};
    min-width: ${(props) =>
      textElementsArray.includes(props.element)
        ? `Calc(${props.desktopColMinWidth} - 10px)`
        : `Calc(${props.desktopColMinWidth})`};
    text-align: ${(props) => props.textAlign};
  }
`;

const StyledElementDiv = styled.div<DivProps>`
  width: ${(props) =>
    textElementsArray.includes(props.element)
      ? `Calc(${props.mobileColWidth} - 10px)`
      : `Calc(${props.mobileColWidth})`};
  min-width: ${(props) =>
    textElementsArray.includes(props.element)
      ? `Calc(${props.mobileColMinWidth} - 10px)`
      : `Calc(${props.mobileColMinWidth})`};
  text-align: ${(props) =>
    props.textAlignMobile === undefined
      ? props.textAlign
      : props.textAlignMobile};
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid #f2f2f2;
  display: ${(props) => (props.colDisplayOnMobile ? "flex" : "none")};

  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
  padding-top: ${(props) =>
    props.element === "SplitData" || props.element === "Data" ? "7px" : ""};
  padding-bottom: ${(props) =>
    props.element === "SplitData" || props.element === "Data" ? "7px" : ""};
  padding-right: ${(props) =>
    textElementsArray.includes(props.element) ? "10px" : "0px"};

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: ${(props) =>
      textElementsArray.includes(props.element)
        ? `Calc(${props.desktopColWidth} - 10px)`
        : `Calc(${props.desktopColWidth})`};
    min-width: ${(props) =>
      textElementsArray.includes(props.element)
        ? `Calc(${props.desktopColMinWidth} - 10px)`
        : `Calc(${props.desktopColMinWidth})`};
    padding-top: ${(props) =>
      props.element === "SplitData" || props.element === "Data" ? "13px" : ""};
    padding-bottom: ${(props) =>
      props.element === "SplitData" || props.element === "Data" ? "13px" : ""};
    padding-right: ${(props) =>
      textElementsArray.includes(props.element) ? "10px" : "0px"};
    text-align: ${(props) => props.textAlign};
  }
`;

interface CenteringDivProps {
  colJustify: string;
  colJustifyMobile?: string;
  colFlexDirection: string;
}

const StyledCenteringDiv = styled.div<CenteringDivProps>`
  display: flex;
  //this was previously row from pf-web-tools, but why? what was I trying to fix?
  flex-direction: ${(props) =>
    props.colJustify ? props.colFlexDirection : "column"};
  justify-content: ${(props) =>
    props.colJustifyMobile ? props.colJustifyMobile : "center"};
  width: 100%;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    justify-content: ${(props) =>
      props.colJustify ? props.colJustify : "start"};
  }
`;

const StyledCommonTableRow = styled.tr`
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
  border-spacing: 0px 10px;
`;

interface TdProps {
  height: string;
  minHeight: string;
  heightMobile: string;
  minHeightMobile: string;
  element: string;
  mobileRowOnClickExists: boolean;
}

const StyledTd = styled.td<TdProps>`
  cursor: ${(props) => (props.mobileRowOnClickExists ? "pointer" : "default")};
  height: fit-content;
  min-height: ${(props) =>
    props.minHeightMobile ? props.minHeightMobile : props.heightMobile};

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    cursor: default;
    height: ${(props) =>
      props.element === "SplitData" || props.element === "Data"
        ? "auto"
        : props.height};
    min-height: ${(props) =>
      props.minHeight ? props.minHeight : props.height};
  }
`;

interface ThProps {}

const StyledTh = styled.th<ThProps>`
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.headingSecondaryWeight};
`;

const StyledGrafanaLink = styled.img`
  cursor: pointer;
`;

const StyledMultiLineSplitData = styled.p`
  white-space: pre-wrap;
  margin: 0;
`;

interface DataSpanProps {
  textDecoration: string;
}

const StyledDataSpan = styled.span<DataSpanProps>`
  width: 100%;
  font-size: ${(props) => props.textDecoration};
`;

const StyledHeaderSpan = styled.span`
  width: 100%;
`;
