import React from "react";
import { IGrafanaAlertLog } from "../../types/alerts";
import CommonCustomizableTable from "../common/tables/CommonCustomizableTable";
import ModalBox from "../common/modals/ModalBox";

interface AlertHistoryProps {
  dataTestname: string;
  active: boolean;
  data: IGrafanaAlertLog[];
  headers: string[];
  overwriteHeaders?: string[];
  alertName: string;
  showX?: boolean;
  xFunction?: React.MouseEventHandler<HTMLImageElement>;
  overflow?: string;
  maxHeight?: string;
}

const AlertHistory: React.FC<AlertHistoryProps> = ({
  dataTestname,
  active,
  data,
  headers,
  overwriteHeaders,
  alertName,
  showX,
  xFunction,
  overflow,
  maxHeight,
}) => {
  interface formattedData {
    timestamp: Date;
    id: number;
    alert_rule_id: number;
    alert_state: string;
  }

  let dataWithFormattedDate: formattedData[] = [];
  if (data.length > 0) {
    data.forEach((element) => {
      dataWithFormattedDate.push({
        alert_state: element.state,
        timestamp: new Date(element.date),
        alert_rule_id: element.alert_rule_id,
        id: element.id,
      });
    });
  }

  return (
    <ModalBox
      dataTestname="alert-history-modal-box"
      containerTitle={`${alertName} - Trip History`}
      active={active}
      showX={showX}
      xFunction={xFunction}
      desktopContainerMinWidth={"533px"}
      mobileContainerMinWidth={"320px"}
    >
      <CommonCustomizableTable
        dataTestname="alert-history-common-customizable-table"
        usageIdentifier="history"
        data-testname={dataTestname}
        data={dataWithFormattedDate}
        headers={headers}
        overwriteHeaders={overwriteHeaders}
        elementsOnClick={[null, null]}
        elements={["State", "Timestamp"]}
        elementsInfo={["", ""]}
        tableWidth={"100%"}
        tableMinWidth={"100%"}
        tableMaxHeight={"400px"}
        desktopColWidths={["50%", "50%"]}
        desktopColMinWidths={["100px", "100px"]}
        mobileColWidths={["50%", "50%"]}
        mobileColMinWidths={["100px", "100px"]}
        textAlign={["left", "right"]}
        tdHeight={"45px"}
        tdHeightMobile={"33px"}
        tdMinHeight={"45px"}
        tdMinHeightMobile={"33px"}
        addPaddingRightForScroll={true}
        colDisplayOnMobile={[true, true, true, true, true, true]}
        colJustify={[]}
        colFlexDirection={[]}
      />
    </ModalBox>
  );
};

export default AlertHistory;
