import React from "react";
import styled from "styled-components";

// Tab Component Instructions
// 1. <Tab> </Tab> is meant to be used as the outer div to any content you want to display inside a Tab (see: SingleSite page)
// 2. Here is a list of the props and how each of them are used:
//  - dataTestname: the unique dataTestname to assign to the component that allows Mike to run automated tests
//  - title: it might look strange here as it isn't used in this component, but it will display the entered Title as the name of the Tab in the Tabs component
//  - children: not exclusively stated, any content that is placed between <Tab> </Tab> will be displayed in the Tab
// 3. Please do not touch the styling in this file. If you *do* need to change styling, please talk to TJ.
// 4. If you have any suggestions or need another css property to be supported, please talk to TJ.
// 5. If there are any issues, please talk to TJ.

type TabProps = {
  dataTestname: string;
  title: string;
  children?: React.ReactNode;
};

const Tab: React.FC<TabProps> = ({ dataTestname, children }) => {
  return (
    <StyledTabDiv data-testname={dataTestname} data-testid="tab-div">
      {children}
    </StyledTabDiv>
  );
};

export default Tab;

const StyledTabDiv = styled.div`
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
  width: 100%;
  margin-bottom: 25px;
`;
