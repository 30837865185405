import React from "react";
import styled from "styled-components";
import ProfireButton from "../buttons/ProfireButton";
import ChevronDownIcon from "../../../assets/images/navigation/chevron-down.svg";
import AddCompany from "../../companies/AddCompany";
import { ICompanyCreateDTO } from "../../../types/companies";

interface CommonCompanySelectProps {
  dataTestname: string;
  data: [string, string][];
  onChange?: Function;
  selected: string;
  displayAddCompanyButton: boolean;
  onAdd?: Function;
  handleShowAddCompany?: Function;
  showAddCompany?: boolean;
  handleAddCompany?: Function;
  retainedNewCompany?: ICompanyCreateDTO | undefined;
}

const CommonCompanySelect: React.FC<CommonCompanySelectProps> = ({
  dataTestname,
  onChange,
  data,
  selected,
  displayAddCompanyButton,
  handleShowAddCompany,
  handleAddCompany,
  showAddCompany,
  retainedNewCompany,
}) => {
  function runHandleShowAddCompany() {
    handleShowAddCompany!();
  }

  function runHandleAddCompany(e: ICompanyCreateDTO) {
    handleAddCompany!(e);
  }

  return (
    <StyledCommonCompanySelectDiv data-testname={dataTestname}>
      <StyledCompanySelectLabel data-testid="input-label">
        Select Company
      </StyledCompanySelectLabel>
      <StyledSelectAddDiv>
        <StyledSelect
          data-testid="dd-select"
          key={data.length === 0 ? "null" : data[0][0]?.toString()}
          onChange={(e) => onChange!(e.target.value)}
          value={selected}
        >
          {data!.map((column, index) => (
            <option
              data-testname={`company-select-element-company-${data[index][1]}`}
              data-testid="dd-option"
              key={column[0] + index}
              value={column[1].toString()}
            >
              {column[0]?.toString()}
            </option>
          ))}
        </StyledSelect>
        {displayAddCompanyButton && (
          <StyledAddCompanyButtonDiv data-testid="add-company-button-div">
            <ProfireButton
              dataTestname="common-company-select-add-company-profire-button"
              text="Add Company"
              onClickFunction={runHandleShowAddCompany}
              size={5}
              overrideWidth={"118px"}
              overrideHeight={"28px"}
            />
          </StyledAddCompanyButtonDiv>
        )}
      </StyledSelectAddDiv>
      {showAddCompany && (
        <AddCompany
          active={showAddCompany}
          onCancel={runHandleShowAddCompany!}
          onAdd={runHandleAddCompany!}
          retainedData={retainedNewCompany}
        />
      )}
    </StyledCommonCompanySelectDiv>
  );
};

export default CommonCompanySelect;

const StyledCommonCompanySelectDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: Calc(100%);
  text-align: left;
  border-radius: 4px;
  margin-bottom: 20px;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    padding: 10px 20px 19px 20px;
    background-color: #e8e8e8;
    border: 1px solid #d3d3d3;
    width: Calc(100% - 42px);
  }
`;

const StyledCompanySelectLabel = styled.label`
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
  width: Calc(100%);
  text-align: left;
  color: black;
  margin-bottom: 8px;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    color: #a7a7a7;
  }
`;

const StyledSelect = styled.select`
  background-color: white;
  border: 1px solid #8e8e8e;
  border-radius: 2px;
  padding: 13px 12px 12px 15px;
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
  height: 37px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: Calc(100%);

  appearance: none;
  background: url(${ChevronDownIcon}) no-repeat right;
  -webkit-appearance: none;
  background-position-x: Calc(100% - 15px);
  background-color: white;

  :focus,
  :active {
    box-shadow: none;
    outline: none;
    border: 1px solid #9c1b30;
    border-radius: 2px;
  }

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    width: Calc(50%);
    height: 47px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

const StyledAddCompanyButtonDiv = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: start;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    margin-top: 6px;
  }
`;

const StyledSelectAddDiv = styled.div`
  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;
