import React from "react";
import styled from "styled-components";
import { onClickUrl } from "../../../utilities/secureNewTab";

interface SafeHyperlinkProps {
  dataTestname: string;
  url: string;
  displayText: string;
  afterDisplayText?: string;
}
const SafeHyperlink: React.FC<SafeHyperlinkProps> = ({
  dataTestname,
  url,
  displayText,
  afterDisplayText,
}) => {
  return (
    <>
      <StyledText data-testname={dataTestname} onClick={onClickUrl(url)}>
        {displayText}
      </StyledText>
      <StyledAfterText>{afterDisplayText}</StyledAfterText>
    </>
  );
};

export default SafeHyperlink;

const StyledText = styled.p`
  color: #9c1b30;
  text-decoration: none;
  margin: 0;
  width: fit-content;
  display: inline;

  :hover {
    text-decoration: underline;
    cursor: pointer;
  }

  span {
    font-style: normal;
  }
`;

const StyledAfterText = styled.p`
  margin: 0;
  width: fit-content;
  display: inline;
`;
