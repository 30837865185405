import { IDailyAggregate } from "../../types/dailyAggregates";

export interface IDailyAggregateContext {
  dailyAggregates: IDailyAggregate[];
  loading: boolean;
  setDailyAggregates: Function;
  children?: React.ReactNode;
}

export interface IDailyAggregateState {
  dailyAggregates: IDailyAggregate[];
  loading: boolean;
}

export enum ActionTypes {
  SET_DAILY_AGGREGATES = "SET_DAILY_AGGREGATES",
  SENDING_REQUEST = "SENDING_REQUEST",
  REQUEST_FINISHED = "REQUEST_FINISHED",
}

export interface DailyAggregateActions {
  type: ActionTypes;
  payload: number | string | boolean | IDailyAggregate | IDailyAggregate[];
}
