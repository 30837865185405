import { IMonitorUnit } from "../../types/monitorUnits";

export interface IMonitorUnitContext {
  monitorUnits: IMonitorUnit[];
  monitorUnit: IMonitorUnit | null;
  monitorUnitBelongsToUserCompany: boolean | null;
  loading: boolean;
  setMonitorUnits: Function;
  setMonitorUnit: Function;
  setMonitorUnitsBySiteId: Function;
  setMonitorUnitBelongsToUserCompany: Function;
  setMonitorUnitsByCompany: Function;
  children?: React.ReactNode;
}

export interface IMonitorUnitState {
  monitorUnits: IMonitorUnit[];
  monitorUnit: IMonitorUnit | null;
  monitorUnitBelongsToUserCompany: boolean | null;
  loading: boolean;
}

export enum ActionTypes {
  SET_MONITOR_UNITS = "SET_MONITOR_UNITS",
  SET_MONITOR_UNIT = "SET_MONITOR_UNIT",
  SET_MONITOR_UNIT_BELONGS_TO_USER_COMPANY = "SET_MONITOR_UNIT_BELONGS_TO_USER_COMPANY",
  SENDING_REQUEST = "SENDING_REQUEST",
  REQUEST_FINISHED = "REQUEST_FINISHED",
}

export interface MonitorUnitAction {
  type: ActionTypes;
  payload: number | string | boolean | IMonitorUnit | IMonitorUnit[];
}
