import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Checkbox from "./Checkbox";
import ConfirmationBox from "../modals/ConfirmationBox";
import Dropdown from "./Dropdown";
import { updatePF2100UnitsArray } from "../../../utilities/staticObjects";
import InfoButton from "../buttons/InfoButton";

interface PF2100InputAreaProps {
  dataTestname: string;
  input_name: string;
  unit_of_measure: string;
  current_active: boolean;
  historical_active: boolean;
  handleChange: Function;
  arrayIndex: number;
  readOnly: boolean;
}

const PF2100InputArea: React.FC<PF2100InputAreaProps> = ({
  dataTestname,
  input_name,
  unit_of_measure,
  current_active,
  historical_active,
  handleChange,
  arrayIndex,
  readOnly,
}) => {
  const pressureInfoHoverText: string =
    "Pressure units should match those configured on the PF2100.";
  const levelInfoHoverText: string =
    "Level units should match those configured on the PF2100.";

  //Confirmation Box states
  const [confirmActive, setConfirmActive] = useState<boolean>(false);
  const [confirmText, setConfirmText] = useState<string>("");

  async function handleConfirm() {
    setConfirmText("");
    setConfirmActive(false);
  }

  let unitsArray: [string, string][] = updatePF2100UnitsArray(input_name);
  const [chosenUnits, setChosenUnits] = useState<string>(unit_of_measure);
  function updateChosenUnits(newChosen: string) {
    setChosenUnits(newChosen);
    handleChange("unit_of_measure", 0, newChosen, arrayIndex);
  }

  const [showLiveData, setShowLiveData] = useState<boolean>(current_active);
  const [showHistoricalData, setShowHistoricalData] =
    useState<boolean>(historical_active);

  function handleLiveDataToggle() {
    if (!readOnly) {
      setShowLiveData(!showLiveData);
      handleChange("current_active", 0, !showLiveData, arrayIndex);
    }
  }
  function handleHistoricalDataToggle(e: React.FormEvent<HTMLInputElement>) {
    if (!readOnly) {
      setShowHistoricalData(!showHistoricalData);
      handleChange("historical_active", 0, !showHistoricalData, arrayIndex);
    }
  }

  const [mobileUnitsTextActive, setMobileUnitsTextActive] =
    useState<boolean>(false);

  function showUnitsText() {
    setMobileUnitsTextActive(!mobileUnitsTextActive);
  }

  const unitsRef = useRef<any>(null);
  const infoButtonRef = useRef<any>(null);

  const handleOutsideOfPressureBoxClick = useCallback(
    (e: any) => {
      if (
        unitsRef.current &&
        !unitsRef.current?.contains(e.target) &&
        !infoButtonRef.current?.contains(e.target)
      ) {
        setMobileUnitsTextActive(false);
      }
    },
    [setMobileUnitsTextActive, unitsRef, infoButtonRef]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideOfPressureBoxClick);

    return () => {
      document.removeEventListener(
        "mousedown",
        handleOutsideOfPressureBoxClick
      );
    };
  }, [handleOutsideOfPressureBoxClick]);

  return (
    <StyledMainDiv data-testname={dataTestname} data-testid="main-div">
      <ConfirmationBox
        dataTestname="pf2100-confirmation-box"
        heading={"Information"}
        message={confirmText}
        active={confirmActive}
        onOk={handleConfirm}
        displayCancel={false}
      />
      <StyledInputRow>
        <StyledLabelInfoDiv>
          <StyledInputLabelInfo>Units</StyledInputLabelInfo>
          {(input_name === "4-20 Pressure" || input_name === "4-20 Level") && (
            <StyledInfo>
              <InfoButton
                dataTestname="pf2100-info-button"
                hoverText={
                  input_name === "4-20 Pressure"
                    ? pressureInfoHoverText
                    : input_name === "4-20 Level"
                    ? levelInfoHoverText
                    : ""
                }
                hoverPositionMobile={
                  input_name === "4-20 Pressure"
                    ? ["28px", "unset", "unset", "-48px"]
                    : input_name === "4-20 Level"
                    ? ["28px", "unset", "unset", "-48px"]
                    : ["28px", "unset", "unset", "0px"]
                }
                hoverWidthsMobile={
                  input_name === "4-20 Pressure"
                    ? ["150px", "212px", "340px"]
                    : input_name === "4-20 Level"
                    ? ["150px", "203px", "340px"]
                    : ["150px", "0px", "340px"]
                }
                onClickFunction={showUnitsText}
                mobileHoverTextRef={infoButtonRef}
              />
              {mobileUnitsTextActive && (
                <StyledInfoButtonDiv
                  hoverWidthsMobile={["150px", "212px", "340px"]}
                  hoverPositionMobile={
                    input_name === "4-20 Pressure"
                      ? ["224px", "unset", "unset", "95px"]
                      : input_name === "4-20 Level"
                      ? ["431px", "unset", "unset", "95px"]
                      : ["28px", "unset", "unset", "0px"]
                  }
                  ref={unitsRef}
                >
                  {input_name === "4-20 Pressure"
                    ? pressureInfoHoverText
                    : input_name === "4-20 Level"
                    ? levelInfoHoverText
                    : ""}
                </StyledInfoButtonDiv>
              )}
            </StyledInfo>
          )}
        </StyledLabelInfoDiv>
        <StyledInputCenteringDiv>
          <StyledInput>
            <Dropdown
              dataTestname="pf2100-units-dropdown"
              columns={unitsArray}
              labelText=""
              hideErrorsDiv={true}
              height={"34px"}
              border={"1px solid #8E8E8E"}
              borderRadius={"2px"}
              textColor={"#767676"}
              selected={chosenUnits}
              onchange={updateChosenUnits}
              readOnly={readOnly}
            />
          </StyledInput>
        </StyledInputCenteringDiv>
      </StyledInputRow>
      <StyledInputRow>
        <StyledInputLabel>Show Live Data</StyledInputLabel>
        <StyledInputCenteringDiv>
          <StyledInput>
            <Checkbox
              dataTestname="pf2100-live-data-checkbox"
              arrayLetter={`L${arrayIndex}`}
              isChecked={showLiveData}
              label={""}
              handleChange={handleLiveDataToggle}
              alignment="right"
              readOnly={readOnly}
            />
          </StyledInput>
        </StyledInputCenteringDiv>
      </StyledInputRow>
      <StyledInputRow>
        <StyledInputLabel>Show Historical Data</StyledInputLabel>
        <StyledInputCenteringDiv>
          <StyledInput>
            <Checkbox
              dataTestname="pf2100-historical-data-checkbox"
              arrayLetter={`H${arrayIndex}`}
              isChecked={showHistoricalData}
              label={""}
              handleChange={handleHistoricalDataToggle}
              alignment="right"
              readOnly={readOnly}
            />
          </StyledInput>
        </StyledInputCenteringDiv>
      </StyledInputRow>
    </StyledMainDiv>
  );
};

export default PF2100InputArea;

const StyledMainDiv = styled.div`
  width: Calc(100% - 46px);

  background-color: #e8e8e8;
  padding: 16px 10px 15px 36px;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    width: Calc(100% - (102px * 2));

    padding: 16px 102px 15px 102px;
  }
`;

const StyledInputRow = styled.div`
  width: 100%;
  height: 34px;

  display: flex;
  flex-direction: row;
  justify-content: start;
  margin-bottom: 10px;
`;

const StyledInputLabel = styled.label`
  width: 45%;

  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
  color: #767676;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    width: 35.7%;
  }
`;

const StyledInput = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: end;

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
  }

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    width: 27.425%;
    min-width: 193.2px;

    display: flex;
    flex-direction: row;
    justify-content: end;
  }
`;

const StyledInputCenteringDiv = styled.div`
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledInfo = styled.div`
  margin-left: 15px;
  width: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledLabelInfoDiv = styled.div`
  width: 45%;
  display: flex;
  flex-direction: row;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    width: 35.7%;
  }
`;

const StyledInputLabelInfo = styled.label`
  width: fit-content;
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
  color: #767676;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
`;

interface MobileInfoButtonProps {
  hoverText?: string;
  hoverPositionMobile: string[];
  hoverWidthsMobile: string[];
}

const StyledInfoButtonDiv = styled.div<MobileInfoButtonProps>`
  content: "${(props) => props.hoverText}";
  visibility: visible;
  opacity: 1;
  min-width: ${(props) => props.hoverWidthsMobile[0]};
  width: ${(props) => props.hoverWidthsMobile[1]};
  background-color: #aaa;
  color: #fff;
  text-align: center;
  border-radius: 3px;
  padding: 10px;
  transition: opacity 0.5s ease-in-out;
  text-align: left;
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};

  position: absolute;
  margin-top: 90px;
  z-index: 130;
`;
