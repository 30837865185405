import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import CompanyContext from "../stores/companies/CompanyContext";
import UserContext from "../stores/users/UserContext";
import Loading from "../components/common/modals/Loading";
import ClickToCopyBox from "../components/common/formInputs/ClickToCopyBox";
import CommonCompanySelect from "../components/common/formInputs/CommonCompanySelect";
import DefaultContainer from "../components/common/containers/DefaultContainer";
import ProfireButton from "../components/common/buttons/ProfireButton";
import EditButton from "../components/common/buttons/EditButton";
import DeleteButton from "../components/common/buttons/DeleteButton";
import InfoDisplay from "../components/common/tables/InfoDisplay";
import CollapsibleDiv from "../components/common/containers/CollapsibleDiv";
import LinkIcon from "../assets/images/clickables/link-icon.svg";
import {
  updateCompany,
  deleteCompany,
  setCompanyByIdFromAPI,
  getReadyCompanies,
  addCompany,
  UpdateCompanyAPIKey,
} from "../utilities/apiCalls/companies";
import EditCompany from "../components/companies/EditCompany";
import CommonCustomizableTable from "../components/common/tables/CommonCustomizableTable";
import { IUser, IUserCreateDTO } from "../types/users";
import {
  addAppUser,
  deleteAppUser,
  updateAppUser,
} from "../utilities/apiCalls/appUsers";
import EditUser from "../components/users/EditUser";
import ConfirmationBox from "../components/common/modals/ConfirmationBox";
import {
  ICompany,
  ICompanyCreateDTO,
  ICompanyEditDTO,
} from "../types/companies";
import AddUser from "../components/users/AddUser";
import { IContactPoint, IContactPointCreateDTO } from "../types/contactPoints";
import {
  addContactPoint,
  deleteContactPoint,
  editContactPoint,
} from "../utilities/apiCalls/contactPoints";
import EditContactPoint from "../components/contactPoints/EditContactPoint";
import AddContactPoint from "../components/contactPoints/AddContactPoint";
import StyleContext from "../stores/styles/StyleContext";

interface MyContactsProps {
  companyId: number;
}

const MyCompany: React.FC<MyContactsProps> = ({ companyId }) => {
  const userContext = useContext(UserContext);
  const userContextRef = useRef(userContext).current;
  const companyContext = useContext(CompanyContext);
  const companyContextRef = useRef(companyContext).current;
  const styleContext = useContext(StyleContext);

  const params = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [company, setCompany] = useState<ICompany>();
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const [companySuccessfullyAdded, setCompanySuccessfullyAdded] =
    useState<boolean>(false);
  const [companySuccessfullyDeleted, setCompanySuccessfullyDeleted] =
    useState<boolean>(false);
  const [chosenCompany, setChosenCompany] = useState<number>(
    userContext.permissions?.profire_select_company === false
      ? companyId
      : params.companyId === undefined
      ? 1
      : parseInt(params.companyId!)
  );

  function updateChosenCompany(newChosen: string) {
    setChosenCompany(parseInt(newChosen));
  }

  useEffect(() => {
    if (userContextRef.permissions?.profire_select_company) {
      getReadyCompanies(setLoading).then((res: any) => {
        setCompanies(res.data);
      });
    }

    companyContextRef.setCompanyBelongsToUser(
      chosenCompany,
      userContextRef.user?.azure_id,
      userContextRef.permissions?.profire_select_company
    );

    setCompanyByIdFromAPI(chosenCompany, setLoading).then((res: any) => {
      setCompany(res.data);
    });
  }, [companyContextRef, userContextRef, chosenCompany]);

  //Check that Company exists, then set AppUsers and ContactPoints
  useEffect(() => {
    if (company !== null && company !== undefined) {
      if (company!.app_users !== null && company!.app_users !== undefined) {
        setAppUsers(company.app_users);
      }
      if (
        company!.alert_contact_points !== null &&
        company.alert_contact_points !== undefined
      ) {
        setContactPoints(company.alert_contact_points);
      }
    }
  }, [company, params, userContextRef]);

  //Add Company
  const [showAddCompany, setShowAddCompany] = useState<boolean>(false);
  const [addCompanyCBActive, setAddCompanyCBActive] = useState<boolean>(false);
  const [addCompanyCBText, setAddCompanyCBText] = useState<string>("");
  //retainedEditCompany is the state that persists entered information that is used to repopulate the form in the event that the API Call errors out in the Confirmation Box
  const [retainedNewCompany, setRetainedNewCompany] = useState<
    ICompanyCreateDTO | undefined
  >();

  //this function toggles whether Add Company modal is shown
  function handleShowAddCompany() {
    if (showAddCompany) {
      setRetainedNewCompany(undefined);
    }
    setShowAddCompany(!showAddCompany);
  }

  //This function runs on clicking Save in Add Company
  function handleAddCompany(e: ICompanyCreateDTO) {
    setShowAddCompany(false);
    setAddCompanyCBActive!(true);
    addCompany(e).then((res) => {
      if (res.status === 200 || res.status === 202) {
        setAddCompanyCBText!(
          `Success. \nIt will take a minute to set up ${e.name} in the Database and Active Directory. \n${e.name} will appear in the Company Select dropdown when completed.`
        );
        setRetainedNewCompany(undefined);
        setCompanySuccessfullyAdded(true);
      } else {
        setAddCompanyCBText!(
          `The following issue was encountered while attempting to add ${e.name}:\n\n${res.data.message}`
        );
        setRetainedNewCompany(e);
        setCompanySuccessfullyAdded(false);
      }
    });
  }

  //This function runs on clicking OK after clicking Save in Add Company
  function completeAddCompany() {
    setAddCompanyCBActive(false);
    setAddCompanyCBText("");
    if (!companySuccessfullyAdded) {
      setShowAddCompany(true);
    }
  }

  //Edit Company
  const [showEditCompany, setShowEditCompany] = useState<boolean>(false);
  const [editCompanyCBActive, setEditCompanyCBActive] =
    useState<boolean>(false);
  const [editCompanyCBText, setEditCompanyCBText] = useState<string>("");
  const [editCompanyCBFinalDisplay, setEditCompanyCBFinalDisplay] =
    useState<boolean>(false);
  //retainedEditCompany is the state that persists entered information that is used to repopulate the form in the event that the API Call errors out in the Confirmation Box
  const [retainedEditCompany, setRetainedEditCompany] = useState<
    ICompanyEditDTO | undefined
  >();

  function handleShowEditCompany() {
    if (showEditCompany) {
      setShowEditCompany(false);
      setRetainedEditCompany(undefined);
    } else {
      setShowEditCompany(!showEditCompany);
    }
    setShowEditCompany(!showEditCompany);
  }

  //This function runs on clicking Save in Edit Company
  function showEditCompanyConfirmationBox(e: any) {
    setShowEditCompany(false);
    setEditCompanyCBActive(true);
    setEditCompanyCBText("Are you sure you want to save these changes?");
    setRetainedEditCompany(e);
  }

  //This function runs on clicking Cancel in Edit Company
  function hideEditCompanyConfirmationBox(e: any) {
    setEditCompanyCBActive(false);
    setEditCompanyCBText("");
    setRetainedEditCompany(undefined);
  }

  //This function runs on clicking Yes after clicking Save in Edit Company
  function handleEditCompany() {
    setEditCompanyCBText("");

    updateCompany(retainedEditCompany!).then((res) => {
      if (res.status === 200 || res.status === 202) {
        setEditCompanyCBText(
          `${retainedEditCompany!.name} successfully edited`
        );
        setCompanyAfterEdit();
        setRetainedEditCompany(undefined);
      } else {
        setEditCompanyCBText(
          `The following issue was encountered while attempting to edit ${
            retainedEditCompany!.name
          }:\n\n${res.data.message}`
        );
      }
    });
    setEditCompanyCBFinalDisplay(true);
  }

  //This function runs on clicking Ok after clicking Yes in Edit Company
  function completeEditCompany(e: any) {
    setEditCompanyCBActive(false);
    setEditCompanyCBText("");
    setEditCompanyCBFinalDisplay(false);

    if (retainedEditCompany !== undefined) {
      setShowEditCompany(true);
    }
  }

  //This function will get the latest information for the Company when called
  function setCompanyAfterEdit() {
    setCompanyByIdFromAPI(chosenCompany, setLoading).then((res: any) => {
      setCompany(res.data);
    });
  }

  //Delete Company
  const [deleteCompanyCBActive, setDeleteCompanyCBActive] =
    useState<boolean>(false);
  const [deleteCompanyCBText, setDeleteCompanyCBText] = useState<string>("");
  //finalDisplay is the bool that determines when it is time to show the single button when using a 2 button ConfirmationBox
  const [deleteCompanyCBFinalDisplay, setDeleteCompanyCBFinalDisplay] =
    useState<boolean>(false);

  //This function runs on clicking Save in Delete Company
  function showDeleteCompanyConfirmationBox(e: any) {
    setDeleteCompanyCBActive(true);
    setDeleteCompanyCBText(`Are you sure you want to delete ${company?.name}?`);
  }

  //This function runs on clicking Cancel in Delete Company
  function hideDeleteCompanyConfirmationBox(e: any) {
    setDeleteCompanyCBActive(false);
    setDeleteCompanyCBText("");
  }

  //This function runs on clicking Yes after clicking Save in Delete Company
  function handleDeleteCompany() {
    setDeleteCompanyCBText("");

    deleteCompany(company?.id!).then((res) => {
      if (res.status === 200 || res.status === 202) {
        setDeleteCompanyCBText(`${company?.name} successfully deleted.`);
        setCompanySuccessfullyDeleted(true);
      } else {
        setDeleteCompanyCBText(
          `The following issue was encountered while attempting to delete ${company?.name}:\n\n${res.data.message}`
        );
        setCompanySuccessfullyDeleted(false);
      }
    });
    setDeleteCompanyCBFinalDisplay(true);
  }

  //This function runs on clicking Ok after clicking Yes in Edit Company
  function completeDeleteCompany(e: any) {
    setDeleteCompanyCBActive(false);
    setDeleteCompanyCBText("");
    setDeleteCompanyCBFinalDisplay(false);
    if (companySuccessfullyDeleted) {
      setChosenCompany(1); //only Profire can delete Companies, so after deletion we set chosen to Profire
    }
  }

  let companiesArray: [string, string][] = [];

  if (userContext.permissions?.profire_select_company) {
    //populate companiesArray for dropdown
    for (let index = 0; index < companies.length; index++) {
      companiesArray.push([
        companies[index].name,
        companies[index].id.toString(),
      ]);
    }
  }

  //Generate API Key
  const [generateApiKeyCBActive, setGenerateApiKeyCBActive] =
    useState<boolean>(false);
  const [generateApiKeyCBText, setGenerateApiKeyCBText] = useState<string>("");
  //finalDisplay is the bool that determines when it is time to show the single button when using a 2 button ConfirmationBox
  const [generateApiKeyCBFinalDisplay, setGenerateApiKeyCBFinalDisplay] =
    useState<boolean>(false);

  //This function runs on clicking Save in Generate API Key
  function showGenerateApiKeyConfirmationBox(e: any) {
    setGenerateApiKeyCBActive(true);
    setGenerateApiKeyCBText(`Are you sure you want to generate a new API Key?`);
  }

  //This function runs on clicking Cancel in Generate API Key
  function hideGenerateApiKeyConfirmationBox(e: any) {
    setGenerateApiKeyCBActive(false);
    setGenerateApiKeyCBText("");
  }

  //This function runs on clicking Yes after clicking Save in Generate API Key
  function handleGenerateApiKey() {
    setGenerateApiKeyCBText("");

    UpdateCompanyAPIKey(chosenCompany).then((res) => {
      if (res.status === 200 || res.status === 202) {
        setGenerateApiKeyCBText(`New API Key successfully generated`);
      } else {
        setGenerateApiKeyCBText(
          `The following issue was encountered while attempting to generate a new API Key:\n\n${res.data.message}`
        );
      }
      setUsers();
    });
    setGenerateApiKeyCBFinalDisplay(true);
  }

  //This function runs on clicking Ok after clicking Yes in Generate API Key
  function completeGenerateApiKey(e: any) {
    setGenerateApiKeyCBActive(false);
    setGenerateApiKeyCBText("");
    setGenerateApiKeyCBFinalDisplay(false);
  }

  //Users states
  const [appUsers, setAppUsers] = useState<IUser[]>([]);

  //User Functions
  function setUsers() {
    setCompanyByIdFromAPI(chosenCompany, setLoading).then((res: any) => {
      setCompany(res.data);
    });
  }

  //Add User
  const [showAddUser, setShowAddUser] = useState<boolean>(false);
  const [addUserCBActive, setAddUserCBActive] = useState<boolean>(false);
  const [addUserCBText, setAddUserCBText] = useState<string>("");
  const [retainedNewUser, setRetainedNewUser] = useState<
    IUserCreateDTO | undefined
  >();

  function handleShowAddUser() {
    if (showAddUser) {
      setRetainedNewUser(undefined);
    }
    setShowAddUser(!showAddUser);
  }

  //This function runs on clicking Save in Add User
  function handleAddUser(e: IUserCreateDTO) {
    setShowAddUser(false);
    setAddUserCBActive(true);
    addAppUser(e).then((res) => {
      if (res.status === 200 || res.status === 202) {
        setAddUserCBText(`${e.first_name} ${e.last_name} successfully added`);
        setRetainedNewUser(undefined);
        setUsers();
      } else {
        setAddUserCBText(
          `The following issue was encountered while attempting to add ${e.first_name} ${e.last_name}:\n\n${res.data.message}`
        );
        setRetainedNewUser(e);
        setUsers();
      }
    });
  }

  //This function runs on clicking OK after clicking Save in Add User
  function completeAddUser() {
    setAddUserCBActive(false);
    setAddUserCBText("");

    if (retainedNewUser !== undefined) {
      setShowAddUser(true);
    }
  }

  //Edit User
  const [showEditUser, setShowEditUser] = useState<boolean>(false);
  const [editUserCBActive, setEditUserCBActive] = useState<boolean>(false);
  const [editUserCBText, setEditUserCBText] = useState<string>("");
  const [editUserCBFinalDisplay, setEditUserCBFinalDisplay] =
    useState<boolean>(false);
  //retainedEditUser is the state that persists entered information that is used to repopulate the form in the event that the API Call errors out in the Confirmation Box
  const [retainedEditUser, setRetainedEditUser] = useState<IUser | undefined>();
  const [chosenEditUser, setChosenEditUser] = useState<IUser>();

  function handleShowEditUser(e: any = undefined) {
    if (showEditUser) {
      setShowEditUser(false);
      setChosenEditUser(undefined);
    } else {
      setShowEditUser(!showEditUser);
      setChosenEditUser(e);
    }
  }

  //This function runs on clicking Save in Edit User
  function showEditUserConfirmationBox(e: IUser) {
    setShowEditUser(false);
    setEditUserCBActive(true);
    setEditUserCBText("Are you sure you want to save these changes?");
    setRetainedEditUser(e);
  }

  //This function runs on clicking Cancel in Edit User
  function hideEditUserConfirmationBox() {
    setShowEditUser(false);
    setEditUserCBActive(false);
    setEditUserCBText("");
    setRetainedEditUser(undefined);
  }

  //This function runs on clicking Yes after clicking Save in Edit User
  function handleEditUser() {
    setEditUserCBText("");

    updateAppUser(retainedEditUser!).then((res) => {
      if (res.status === 200 || res.status === 202) {
        setEditUserCBText(
          `${retainedEditUser?.first_name} ${retainedEditUser?.last_name} successfully updated.`
        );
        setUsers();
        setRetainedEditUser(undefined);
      } else {
        setEditUserCBText(
          `The following issue was encountered while attempting to edit ${chosenEditUser?.first_name} ${retainedEditUser?.last_name}:\n\n${res.data.message}`
        );
        setUsers();
      }
    });
    setEditUserCBFinalDisplay(true);
  }

  //This function runs on clicking OK after clicking Yes after clicking Save in Edit User
  function completeEditUser() {
    setEditUserCBActive(false);
    setEditUserCBText("");
    setEditUserCBFinalDisplay(false);
    setChosenEditUser(undefined);

    if (retainedEditUser !== undefined) {
      setShowEditUser(true);
    }
  }

  //Delete User
  const [deleteUserCBActive, setDeleteUserCBActive] = useState<boolean>(false);
  const [deleteUserCBText, setDeleteUserCBText] = useState<string>("");
  //finalDisplay is the bool that determines when it is time to show the single button when using a 2 button ConfirmationBox
  const [deleteUserCBFinalDisplay, setDeleteUserCBFinalDisplay] =
    useState<boolean>(false);
  const [chosenDeleteUser, setChosenDeleteUser] = useState<IUser>();

  //This function runs on clicking Save in Delete User
  function showDeleteUserConfirmationBox(e: any) {
    setChosenDeleteUser(e);
    setDeleteUserCBActive(true);
    setDeleteUserCBText(
      `Are you sure you want to delete ${e.first_name} ${e.last_name}?`
    );
  }

  //This function runs on clicking Cancel in Delete User
  function hideDeleteUserConfirmationBox(e: any) {
    setDeleteUserCBActive(false);
    setDeleteUserCBText("");
  }

  //This function runs on clicking Yes after clicking Save in Delete User
  function handleDeleteUser() {
    setDeleteUserCBText("");

    deleteAppUser(chosenDeleteUser?.id!).then((res) => {
      if (res.status === 200 || res.status === 202) {
        setDeleteUserCBText(
          `${chosenDeleteUser?.first_name} ${chosenDeleteUser?.last_name} successfully deleted.`
        );
        setUsers();
      } else {
        setDeleteUserCBText(
          `The following issue was encountered while attempting to delete ${chosenDeleteUser?.first_name} ${retainedEditUser?.last_name}:\n\n${res.data.message}`
        );
        setUsers();
      }
    });
    setDeleteUserCBFinalDisplay(true);
  }

  //This function runs on clicking Ok after clicking Yes in Delete User
  function completeDeleteUser(e: any) {
    setDeleteUserCBActive(false);
    setDeleteUserCBText("");
    setDeleteUserCBFinalDisplay(false);
    setChosenDeleteUser(undefined);
  }

  //Users Table states
  const [usersElements] = useState<string[]>(
    userContext.permissions?.profire_manage_users ||
      userContext.permissions?.external_manage_users
      ? ["Data", "Data", "Data", "Space", "Edit", "Delete"]
      : ["Data", "Data", "Data", "Space", "Space", "Space"]
  );

  const [usersElementsInfo] = useState<string[]>([
    "Normal",
    "",
    "",
    "",
    "",
    "",
  ]);

  const [usersHeaders] = useState<string[]>([
    "full_name",
    "app_role_title",
    "email",
    "",
    "",
    "",
  ]);

  const [usersOverwriteHeaders] = useState<string[]>([
    "Name",
    "Role",
    "Email",
    "",
    "",
    "",
  ]);

  const [usersElementsOnClick] = useState<any[]>([
    null,
    null,
    null,
    null,
    handleShowEditUser,
    showDeleteUserConfirmationBox,
  ]);

  //Contact Point states
  const [contactPoints, setContactPoints] = useState<IContactPoint[]>([]);

  //Contact Points Functions
  function setCompanyContactPoints() {
    setCompanyByIdFromAPI(chosenCompany, setLoading).then((res: any) => {
      setCompany(res.data);
    });
  }

  //Add ContactPoint
  const [showAddContactPoint, setShowAddContactPoint] =
    useState<boolean>(false);
  const [addContactPointCBActive, setAddContactPointCBActive] =
    useState<boolean>(false);
  const [addContactPointCBText, setAddContactPointCBText] =
    useState<string>("");
  const [retainedNewContactPoint, setRetainedNewContactPoint] = useState<
    IContactPointCreateDTO | undefined
  >();

  function handleShowAddContactPoint() {
    if (showAddContactPoint) {
      setRetainedNewContactPoint(undefined);
    }
    setShowAddContactPoint(!showAddContactPoint);
  }

  //This function runs on clicking Save in Add ContactPoint
  function handleAddContactPoint(e: IContactPointCreateDTO) {
    setShowAddContactPoint(false);
    setAddContactPointCBActive(true);
    addContactPoint(e).then((res) => {
      if (res.status === 200 || res.status === 202) {
        setAddContactPointCBText(`${e.name} successfully added`);
        setRetainedNewContactPoint(undefined);
        setCompanyContactPoints();
      } else {
        setAddContactPointCBText(
          `The following issue was encountered while attempting to add ${e.name}:\n\n${res.data.message}`
        );
        setRetainedNewContactPoint(e);
        setCompanyContactPoints();
      }
    });
  }

  //This function runs on clicking OK after clicking Save in Add ContactPoint
  function completeAddContactPoint() {
    setAddContactPointCBActive(false);
    setAddContactPointCBText("");

    if (retainedNewContactPoint !== undefined) {
      setShowAddContactPoint(true);
    }
  }

  //Edit ContactPoint
  const [showEditContactPoint, setShowEditContactPoint] =
    useState<boolean>(false);
  const [editContactPointCBActive, setEditContactPointCBActive] =
    useState<boolean>(false);
  const [editContactPointCBText, setEditContactPointCBText] =
    useState<string>("");
  const [editContactPointCBFinalDisplay, setEditContactPointCBFinalDisplay] =
    useState<boolean>(false);
  //retainedEditContactPoint is the state that persists entered information that is used to repopulate the form in the event that the API Call errors out in the Confirmation Box
  const [retainedEditContactPoint, setRetainedEditContactPoint] = useState<
    IContactPoint | undefined
  >();
  const [chosenEditContactPoint, setChosenEditContactPoint] =
    useState<IContactPoint>();

  function handleShowEditContactPoint(e: any = undefined) {
    if (showEditContactPoint) {
      setShowEditContactPoint(false);
      setChosenEditContactPoint(undefined);
    } else {
      setShowEditContactPoint(!showEditContactPoint);
      setChosenEditContactPoint(e);
    }
  }

  //This function runs on clicking Save in Edit ContactPoint
  function showEditContactPointConfirmationBox(e: IContactPoint) {
    setShowEditContactPoint(false);
    setEditContactPointCBActive(true);
    setEditContactPointCBText("Are you sure you want to save these changes?");
    setRetainedEditContactPoint(e);
  }

  //This function runs on clicking Cancel in Edit ContactPoint
  function hideEditContactPointConfirmationBox() {
    setShowEditContactPoint(false);
    setEditContactPointCBActive(false);
    setEditContactPointCBText("");
    setRetainedEditContactPoint(undefined);
  }

  //This function runs on clicking Yes after clicking Save in Edit ContactPoint
  function handleEditContactPoint() {
    setEditContactPointCBText("");

    editContactPoint(retainedEditContactPoint!).then((res) => {
      if (res.status === 200 || res.status === 202) {
        setEditContactPointCBText(
          `${retainedEditContactPoint!.name} successfully updated.`
        );
        setCompanyContactPoints();
        setRetainedEditContactPoint(undefined);
      } else {
        setEditContactPointCBText(
          `The following issue was encountered while attempting to edit ${chosenEditContactPoint?.name}:\n\n${res.data.message}`
        );
        setCompanyContactPoints();
      }
    });
    setEditContactPointCBFinalDisplay(true);
  }

  //This function runs on clicking OK after clicking Yes after clicking Save in Edit ContactPoint
  function completeEditContactPoint() {
    setEditContactPointCBActive(false);
    setEditContactPointCBText("");
    setEditContactPointCBFinalDisplay(false);
    setChosenEditContactPoint(undefined);

    if (retainedEditContactPoint !== undefined) {
      setShowEditContactPoint(true);
    }
  }

  //Delete ContactPoint
  const [deleteContactPointCBActive, setDeleteContactPointCBActive] =
    useState<boolean>(false);
  const [deleteContactPointCBText, setDeleteContactPointCBText] =
    useState<string>("");
  //finalDisplay is the bool that determines when it is time to show the single button when using a 2 button ConfirmationBox
  const [
    deleteContactPointCBFinalDisplay,
    setDeleteContactPointCBFinalDisplay,
  ] = useState<boolean>(false);
  const [chosenDeleteContactPoint, setChosenDeleteContactPoint] =
    useState<IContactPoint>();

  //This function runs on clicking Save in Delete ContactPoint
  function showDeleteContactPointConfirmationBox(e: IContactPoint) {
    setChosenDeleteContactPoint(e);
    setDeleteContactPointCBActive(true);
    setDeleteContactPointCBText(`Are you sure you want to delete ${e.name}?`);
  }

  //This function runs on clicking Cancel in Delete ContactPoint
  function hideDeleteContactPointConfirmationBox(e: any) {
    setDeleteContactPointCBActive(false);
    setDeleteContactPointCBText("");
  }

  //This function runs on clicking Yes after clicking Save in Delete ContactPoint
  function handleDeleteContactPoint() {
    setDeleteContactPointCBText("");

    deleteContactPoint(chosenDeleteContactPoint?.id!).then((res) => {
      if (res.status === 200 || res.status === 202) {
        setDeleteContactPointCBText(
          `${chosenDeleteContactPoint?.name} successfully deleted.`
        );
        setCompanyContactPoints();
      } else {
        setDeleteContactPointCBText(
          `The following issue was encountered while attempting to delete ${chosenDeleteContactPoint?.name}:\n\n${res.data.message}`
        );
        setCompanyContactPoints();
      }
    });
    setDeleteContactPointCBFinalDisplay(true);
  }

  //This function runs on clicking Ok after clicking Yes in Delete ContactPoint
  function completeDeleteContactPoint(e: any) {
    setDeleteContactPointCBActive(false);
    setDeleteContactPointCBText("");
    setDeleteContactPointCBFinalDisplay(false);
    setChosenDeleteContactPoint(undefined);
  }

  //Contact Points Table states
  const [contactPointsElements] = useState<string[]>(
    userContext.permissions?.profire_manage_alerts ||
      userContext.permissions?.external_manage_alerts
      ? ["Data", "SplitData", "SplitData", "Space", "Edit", "Delete"]
      : ["Data", "SplitData", "SplitData", "Space", "Space", "Space"]
  );

  const [contactPointsElementsInfo] = useState<string[]>([
    "Normal",
    ";",
    ";",
    "",
    "",
    "",
  ]);

  const [contactPointsHeaders] = useState<string[]>([
    "name",
    "email",
    "phone_number",
    "",
    "",
    "",
  ]);

  const [contactPointsOverwriteHeaders] = useState<string[]>([
    "Name",
    "Email Addresses",
    "Phone Numbers",
    "",
    "",
    "",
  ]);

  const [contactPointsElementsOnClick] = useState<any[]>([
    null,
    null,
    null,
    null,
    handleShowEditContactPoint,
    showDeleteContactPointConfirmationBox,
  ]);

  if (
    company === undefined ||
    companyContext.loading ||
    loading ||
    appUsers === undefined
  )
    return (
      <div className="fullPageLoadingContainer">
        <Loading dataTestname="my-company-loading" />
      </div>
    );

  return (
    <StyledMyCompanyDiv>
      <ConfirmationBox
        dataTestname="single-control-unit-edit-confirmation-box"
        heading={"Information"}
        message={editCompanyCBText}
        active={editCompanyCBActive}
        onOk={completeEditCompany}
        onYes={handleEditCompany}
        onCancel={hideEditCompanyConfirmationBox}
        displayCancel={true}
        finalDisplay={editCompanyCBFinalDisplay}
      />
      <ConfirmationBox
        dataTestname="single-control-unit-delete-confirmation-box"
        heading={"Information"}
        message={deleteCompanyCBText}
        active={deleteCompanyCBActive}
        onOk={completeDeleteCompany}
        onYes={handleDeleteCompany}
        onCancel={hideDeleteCompanyConfirmationBox}
        displayCancel={true}
        finalDisplay={deleteCompanyCBFinalDisplay}
      />
      {showEditCompany && (
        <EditCompany
          active={showEditCompany}
          company={company!}
          onCancel={handleShowEditCompany}
          onEdit={showEditCompanyConfirmationBox}
          retainedData={retainedEditCompany}
        />
      )}
      <StyledMainDiv>
        <ConfirmationBox
          dataTestname="my-company-add-company-confirmation-box"
          heading={"Information"}
          message={addCompanyCBText}
          active={addCompanyCBActive}
          onOk={completeAddCompany}
          displayCancel={false}
        />
        <ConfirmationBox
          dataTestname="company-add-user-confirmation-box"
          heading={"Information"}
          message={addUserCBText}
          active={addUserCBActive}
          onOk={completeAddUser}
          displayCancel={false}
        />
        <ConfirmationBox
          dataTestname="company-edit-user-confirmation-box"
          heading={"Information"}
          message={editUserCBText}
          active={editUserCBActive}
          onOk={completeEditUser}
          onYes={handleEditUser}
          onCancel={hideEditUserConfirmationBox}
          displayCancel={true}
          finalDisplay={editUserCBFinalDisplay}
        />
        <ConfirmationBox
          dataTestname="company-delete-user-confirmation-box"
          heading={"Information"}
          message={deleteUserCBText}
          active={deleteUserCBActive}
          onOk={completeDeleteUser}
          onYes={handleDeleteUser}
          onCancel={hideDeleteUserConfirmationBox}
          displayCancel={true}
          finalDisplay={deleteUserCBFinalDisplay}
        />
        <ConfirmationBox
          dataTestname="company-add-contact-points-confirmation-box"
          heading={"Information"}
          message={addContactPointCBText}
          active={addContactPointCBActive}
          onOk={completeAddContactPoint}
          displayCancel={false}
        />
        <ConfirmationBox
          dataTestname="company-edit-contact-points-confirmation-box"
          heading={"Information"}
          message={editContactPointCBText}
          active={editContactPointCBActive}
          onOk={completeEditContactPoint}
          onYes={handleEditContactPoint}
          onCancel={hideEditContactPointConfirmationBox}
          displayCancel={true}
          finalDisplay={editContactPointCBFinalDisplay}
        />
        <ConfirmationBox
          dataTestname="company-delete-contact-points-confirmation-box"
          heading={"Information"}
          message={deleteContactPointCBText}
          active={deleteContactPointCBActive}
          onOk={completeDeleteContactPoint}
          onYes={handleDeleteContactPoint}
          onCancel={hideDeleteContactPointConfirmationBox}
          displayCancel={true}
          finalDisplay={deleteContactPointCBFinalDisplay}
        />
        {userContext.permissions?.profire_select_company && (
          <div data-testid="dropdown-div">
            <CommonCompanySelect
              dataTestname="my-company-common-company-select"
              data={companiesArray}
              selected={chosenCompany.toString()}
              onChange={updateChosenCompany}
              displayAddCompanyButton={
                userContext.permissions?.profire_manage_company
              }
              handleAddCompany={handleAddCompany}
              handleShowAddCompany={handleShowAddCompany}
              retainedNewCompany={retainedNewCompany}
              showAddCompany={showAddCompany}
            />
          </div>
        )}
        {company !== null && (
          <>
            <DefaultContainer
              dataTestname="my-company-company-default-container"
              darkBackground={true}
              title="Company"
              mobileRedLine={true}
              insideModal={false}
            >
              <div data-testid="company-div">
                <InfoDisplay
                  dataTestname="my-company-info-display"
                  datum={company}
                  headers={["name", "address", "phone", "date_joined"]}
                  overwriteHeaders={[
                    "Name",
                    "Address",
                    "Phone Number",
                    "Date joined",
                  ]}
                  headerWidth={"8.5%"}
                  colonWidth={"3%"}
                  infoWidth={"Calc(100% - 119px - 42px)"}
                  containerWidth={"100%"}
                  headerMinWidth={"119px"}
                  colonMinWidth={"21px"}
                  infoMinWidth={"120px"}
                  containerMinWidth={"100%"}
                  rightSpacerWidth={"0%"}
                />
              </div>

              {userContext.permissions?.profire_manage_company && (
                <StyledButtonsDiv data-testid="edit-delete-button">
                  <EditButton
                    dataTestname="my-company-edit-button"
                    onClickFunction={handleShowEditCompany}
                  />
                  <DeleteButton
                    dataTestname="my-company-delete-button"
                    onClickFunction={showDeleteCompanyConfirmationBox}
                  />
                </StyledButtonsDiv>
              )}
            </DefaultContainer>
            {((userContext.permissions?.profire_select_company &&
              userContext.permissions?.profire_manage_api_key) ||
              (!userContext.permissions?.profire_select_company &&
                userContext.permissions?.external_manage_api_key)) && (
              <DefaultContainer
                dataTestname="my-company-api-default-container"
                darkBackground={false}
                title="API"
                insideModal={false}
              >
                <ConfirmationBox
                  dataTestname="my-company-generate-new-api-key-confirmation-box"
                  heading={"Information"}
                  message={generateApiKeyCBText}
                  active={generateApiKeyCBActive}
                  onOk={completeGenerateApiKey}
                  onYes={handleGenerateApiKey}
                  onCancel={hideGenerateApiKeyConfirmationBox}
                  displayCancel={true}
                  finalDisplay={generateApiKeyCBFinalDisplay}
                />
                <StyledApiDiv data-testid="api-div">
                  <StyledApiKeyDiv>
                    <StyledAPITitle>API Key</StyledAPITitle>
                    <StyledAPITitleColon>:</StyledAPITitleColon>
                    <ClickToCopyBox
                      dataTestname="my-company-api-key-copy-box"
                      valueToDisplayAndCopy={company.api_key!}
                    />
                  </StyledApiKeyDiv>
                  <StyledApiKeyDiv>
                    <StyledAPITitle>Endpoint</StyledAPITitle>
                    <StyledAPITitleColon>:</StyledAPITitleColon>
                    <ClickToCopyBox
                      dataTestname="my-company-endpoint-copy-box"
                      valueToDisplayAndCopy="api.pfconnect.profireenergy.com"
                    />
                  </StyledApiKeyDiv>
                  <StyledApiKeyDiv>
                    <StyledAPITitle>
                      {styleContext.isMobile ? "Docs" : "Documentation"}
                    </StyledAPITitle>
                    <StyledAPITitleColon>:</StyledAPITitleColon>
                    <StyledP>
                      <StyledA
                        href="http://api.pfconnect.profireenergy.com/swagger"
                        target={"_blank"}
                      >
                        API Docs <StyledIconDiv src={LinkIcon}></StyledIconDiv>
                      </StyledA>
                    </StyledP>
                  </StyledApiKeyDiv>
                </StyledApiDiv>
                <StyledApiButtonDiv>
                  <ProfireButton
                    dataTestname="my-company-generate-key-button"
                    text="Generate Key"
                    size={4}
                    onClickFunction={showGenerateApiKeyConfirmationBox}
                    overrideWidth={"118px"}
                  />
                </StyledApiButtonDiv>
              </DefaultContainer>
            )}

            <CollapsibleDiv
              dataTestname="my-company-user-list-collapsible-div"
              title="Users"
            >
              {showEditUser && (
                <EditUser
                  active={showEditUser}
                  user={chosenEditUser!}
                  onCancel={handleShowEditUser}
                  onEdit={showEditUserConfirmationBox}
                  retainedData={retainedEditUser}
                  companyId={chosenCompany}
                />
              )}
              <StyledCommonTableDiv>
                {(userContext.permissions?.profire_manage_users ||
                  userContext.permissions?.external_manage_users) && (
                  <StyledAddButtonDiv data-testid="add-user-button-div">
                    <ProfireButton
                      dataTestname="company-add-user-button"
                      onClickFunction={handleShowAddUser}
                      text="Add User"
                      size={3}
                    />
                  </StyledAddButtonDiv>
                )}
                {showAddUser && (
                  <AddUser
                    active={showAddUser}
                    onCancel={handleShowAddUser}
                    onAdd={handleAddUser}
                    retainedData={retainedNewUser}
                    companyId={chosenCompany}
                  />
                )}
                <CommonCustomizableTable
                  dataTestname="my-company-user-common-customizable-table"
                  usageIdentifier="user"
                  data={appUsers}
                  elements={usersElements}
                  elementsInfo={usersElementsInfo}
                  headers={usersHeaders}
                  overwriteHeaders={usersOverwriteHeaders}
                  tableWidth={"100%"}
                  tableMinWidth={"100%"}
                  tableMaxHeight={"400px"}
                  desktopColWidths={[
                    "33.33% - 33px",
                    "16.33% - 33px",
                    "50.33% - 33px",
                    "3.33%",
                    "3.33%",
                    "3.33%",
                  ]}
                  desktopColMinWidths={[
                    "33.33% - 33px",
                    "16.33% - 33px",
                    "50.33% - 33px",
                    "33px",
                    "33px",
                    "33px",
                  ]}
                  mobileColWidths={[
                    "100% - 33px - 33px",
                    "0px",
                    "0px",
                    "0px",
                    "3.33%",
                    "3.33%",
                  ]}
                  mobileColMinWidths={[
                    "100% - 33px - 33px",
                    "0px",
                    "0px",
                    "0px",
                    "33px",
                    "33px",
                  ]}
                  elementsOnClick={usersElementsOnClick}
                  textAlign={["left", "left", "left", "left", "left", "left"]}
                  tdHeight={"45px"}
                  tdHeightMobile={"33px"}
                  tdMinHeight={"45px"}
                  tdMinHeightMobile={"33px"}
                  addPaddingRightForScroll={true}
                  colDisplayOnMobile={[true, false, false, false, true, true]}
                  colJustify={[]}
                  colFlexDirection={[]}
                />
              </StyledCommonTableDiv>
              {(userContext.permissions?.profire_manage_users ||
                userContext.permissions?.external_manage_users) && (
                <StyledAddButtonMobileDiv data-testid="add-user-button-div">
                  <ProfireButton
                    dataTestname="company-add-user-button"
                    onClickFunction={handleShowAddUser}
                    text="Add User"
                    size={3}
                  />
                </StyledAddButtonMobileDiv>
              )}
            </CollapsibleDiv>
            <CollapsibleDiv
              dataTestname="my-company-contact-point-list-collapsible-div"
              title="Contact Points"
            >
              {showEditContactPoint && (
                <EditContactPoint
                  active={showEditContactPoint}
                  contactPoint={chosenEditContactPoint!}
                  onCancel={handleShowEditContactPoint}
                  onEdit={showEditContactPointConfirmationBox}
                  retainedData={retainedEditContactPoint}
                  companyId={chosenCompany}
                  contactPoints={contactPoints}
                />
              )}
              <StyledCommonTableDiv>
                {(userContext.permissions?.profire_manage_alerts ||
                  userContext.permissions?.external_manage_alerts) && (
                  <StyledAddButtonDiv data-testid="add-contactPoint-button-div">
                    <ProfireButton
                      dataTestname="company-add-contactPoint-button"
                      onClickFunction={handleShowAddContactPoint}
                      text="Add Contact Point"
                      size={6}
                    />
                  </StyledAddButtonDiv>
                )}
                {showAddContactPoint && (
                  <AddContactPoint
                    active={showAddContactPoint}
                    onCancel={handleShowAddContactPoint}
                    onAdd={handleAddContactPoint}
                    retainedData={retainedNewContactPoint}
                    companyId={chosenCompany}
                    contactPoints={contactPoints}
                  />
                )}
                <CommonCustomizableTable
                  dataTestname="my-company-contact-point-common-customizable-table"
                  usageIdentifier="contact-point"
                  data={contactPoints}
                  elements={contactPointsElements}
                  elementsInfo={contactPointsElementsInfo}
                  headers={contactPointsHeaders}
                  overwriteHeaders={contactPointsOverwriteHeaders}
                  tableWidth={"100%"}
                  tableMinWidth={"100%"}
                  tableMaxHeight={"400px"}
                  desktopColWidths={[
                    "33.33% - 33px",
                    "33.33% - 33px",
                    "33.33% - 33px",
                    "3.33%",
                    "3.33%",
                    "3.33%",
                  ]}
                  desktopColMinWidths={[
                    "33.33% - 33px",
                    "33.33% - 33px",
                    "33.33% - 33px",
                    "33px",
                    "33px",
                    "33px",
                  ]}
                  mobileColWidths={[
                    "100% - 33px - 33px",
                    "0px",
                    "0px",
                    "0px",
                    "3.33%",
                    "3.33%",
                  ]}
                  mobileColMinWidths={[
                    "100% - 33px - 33px",
                    "0px",
                    "0px",
                    "0px",
                    "33px",
                    "33px",
                  ]}
                  elementsOnClick={contactPointsElementsOnClick}
                  textAlign={["left", "left", "left", "left", "left", "left"]}
                  tdHeight={"100%"}
                  tdHeightMobile={"33px"}
                  tdMinHeight={"45px"}
                  tdMinHeightMobile={"33px"}
                  addPaddingRightForScroll={true}
                  colDisplayOnMobile={[true, false, false, false, true, true]}
                  colJustify={[]}
                  colFlexDirection={[]}
                />
              </StyledCommonTableDiv>
              {(userContext.permissions?.profire_manage_alerts ||
                userContext.permissions?.external_manage_alerts) && (
                <StyledAddButtonMobileDiv data-testid="add-user-button-div">
                  <ProfireButton
                    dataTestname="company-add-contactPoint-button"
                    onClickFunction={handleShowAddContactPoint}
                    text="Add Contact Point"
                    size={6}
                  />
                </StyledAddButtonMobileDiv>
              )}
            </CollapsibleDiv>
          </>
        )}
      </StyledMainDiv>
    </StyledMyCompanyDiv>
  );
};

export default MyCompany;

const StyledMyCompanyDiv = styled.div`
  width: Calc(100% - 32px);
  max-width: Calc(1200px - 40px);
  min-width: Calc(320px - 40px);
  margin: 80px 16px 20px 16px;
  height: Calc(100% - 100px);
  background-color: white;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    margin: 80px 20px 20px 20px;
    width: Calc(100% - 40px);
    background-color: ${(props) => props.theme.bgColor};
  }
`;

const StyledMainDiv = styled.div`
  height: Calc(Max(100%, 100vh));
  height: Calc(Max(100%, 100dvh));
  width: 100%;
  display: flex;
  justify-content: start;
  flex-direction: column;
  margin-bottom: 20px;
`;

const StyledApiDiv = styled.div`
  width: 100%;

  background-color: white;
  border-radius: 10px;

  text-align: left;
  display: flex;
  flex-direction: column;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const StyledApiKeyDiv = styled.div`
  flex-basis: calc(100%);
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;

  h4 {
    margin: 0;

    @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
      margin-top: 13px;
      margin-bottom: 13px;
    }
  }

  p {
    font-family: "Roboto Mono", monospace;
  }

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    flex-basis: calc((100% - 50px) / 3);
    flex-direction: column;
  }
`;

const StyledP = styled.p`
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
  margin: 0;
`;

const StyledA = styled.a`
  text-decoration: none;
  color: #292929;
  :hover {
    cursor: pointer;
  }
  :visited {
    color: inherit;
  }
`;

const StyledApiButtonDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  margin-top: 10px;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    margin-top: 15px;
  }
`;

const StyledButtonsDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-basis: 10%;
`;

const StyledAPITitle = styled.h4`
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.headingSecondaryWeight};
  min-width: 65px;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    width: 100%;
  }
`;

const StyledAPITitleColon = styled.h4`
  padding-left: 8px;
  padding-right: 8px;
  font-weight: ${(props) => props.theme.contentMainWeight};

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    display: none;
    font-size: ${(props) => props.theme.contentMainSize};
    font-weight: ${(props) => props.theme.headingSecondaryWeight};
  }
`;

const StyledIconDiv = styled.img`
  width: 14px;
  height: 14px;
  margin-left: 5px;
  margin-bottom: -1.8px;
  :hover {
    cursor: pointer;
  }
`;

const StyledCommonTableDiv = styled.div`
  width: Calc(100%);
  background-color: #ffffff;
  padding: 0px;
  border-radius: 0px 0px 4px 4px;
  text-align: left;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    width: Calc(100% - 40px);
    padding: 0px 20px 21px 20px;
  }
`;

const StyledAddButtonDiv = styled.div`
  width: 100%;
  margin-bottom: 15px;
  text-align: right;
  display: none;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    display: block;
  }
`;

const StyledAddButtonMobileDiv = styled.div`
  width: 100%;
  margin-top: 12px;
  text-align: right;
  display: block;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    display: none;
  }
`;
