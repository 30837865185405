import axios from "axios";
import {
  IControlUnit,
  IControlUnitCreateDTO,
} from "../../types/controlUnits";
import { IExportDataRequest } from "../../types/export";
import { getToken } from "../msTokenFunc";

export const getAllControlUnits = async (loadingFunc: Function) => {
  try {
    loadingFunc(true);
    const token = await getToken();
    const result = await axios.get(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/controlunit`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    loadingFunc(false);
    return result;
  } catch (error: any) {
    loadingFunc(false);
    return error.response;
  }
};

export const getControlUnitById = async (
  controlUnitId: number,
  loadingFunc: Function
) => {
  try {
    loadingFunc(true);
    const token = await getToken();
    const result = await axios.get(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/controlunit/${controlUnitId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    loadingFunc(false);
    return result;
  } catch (error: any) {
    loadingFunc(false);
    return error.response;
  }
};

export const getControlUnitsByProcessUnitId = async (
  processUnitId: number,
  loadingFunc: Function
) => {
  try {
    loadingFunc(true);
    const token = await getToken();
    const result = await axios.get(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/controlunit/byprocessunit/${processUnitId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    loadingFunc(false);
    return result;
  } catch (error: any) {
    loadingFunc(false);
    return error.response;
  }
};

export const getControlUnitsByMonitorUnitId = async (
  monitorUnitId: number,
  loadingFunc: Function
) => {
  try {
    loadingFunc(true);
    const token = await getToken();
    const result = await axios.get(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/controlunit/bymonitorunit/${monitorUnitId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    loadingFunc(false);
    return result;
  } catch (error: any) {
    loadingFunc(false);
    return error.response;
  }
};

export const setControlUnitFromAPI = async (
  controlUnitId: number,
  loadingFunc: Function
) => {
  try {
    const token = await getToken();
    const result = await axios.get(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/controlunit/${controlUnitId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    loadingFunc(false);
    return result;
  } catch (error: any) {
    loadingFunc(false);
    return error.response;
  }
};

export const setSupportedTypesFromAPI = async (loadingFunc: Function) => {
  try {
    const token = await getToken();
    const result = await axios.get(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/lookup/controlunittype`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    loadingFunc(false);
    return result;
  } catch (error: any) {
    loadingFunc(false);
    return error.response;
  }
};

export const setControlUnitsByProcessUnitFromAPI = async (
  processUnitId: number,
  loadingFunc: Function
) => {
  try {
    const token = await getToken();
    const result = await axios.get(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/controlunit/byprocessunit/${processUnitId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    loadingFunc(false);
    return result;
  } catch (error: any) {
    loadingFunc(false);
    return error.response;
  }
};

export const setControlUnitsByMonitorUnitFromAPI = async (
  monitorUnitId: number,
  loadingFunc: Function
) => {
  try {
    const token = await getToken();
    const result = await axios.get(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/controlunit/bymonitorunit/${monitorUnitId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    loadingFunc(false);
    return result;
  } catch (error: any) {
    loadingFunc(false);
    return error.response;
  }
};

export const addNewControlUnit = async (
  newControlUnit: IControlUnitCreateDTO
) => {
  try {
    const token = await getToken();
    const result = await axios.post(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/controlunit`,
      newControlUnit,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result;
  } catch (error: any) {
    return error.response;
  }
};

export const editControlUnitTag = async (
  updatedControlUnit: IControlUnit
) => {
  try {
    const token = await getToken();
    const result = await axios.put(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/controlunit`,
      updatedControlUnit,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result;
  } catch (error: any) {
    return error.response;
  }
};

export const editControlUnit = async (updatedControlUnit: IControlUnit) => {
  try {
    const token = await getToken();
    const result = await axios.put(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/controlunit`,
      updatedControlUnit,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result;
  } catch (error: any) {
    return error.response;
  }
};

export const deleteControlUnit = async (controlUnitId: number) => {
  try {
    const token = await getToken();
    const result = await axios.delete(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/controlunit/${controlUnitId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result;
  } catch (error: any) {
    return error.response;
  }
};

export const getRawDataByControlUnit = async (
  exportRequest: IExportDataRequest, 
  model: string,
  genericTypes: string[]
) => {
  let endpoint = genericTypes.includes(model) ? "generic" : model.toLowerCase()
  try {
    const token = await getToken();
    const result = await axios.post(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/exportdata/${endpoint}`,
      exportRequest,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result;
  } catch (error: any) {
    return error.response;
  }
};

export const setControlUnitBelongsToUserCompanyFromAPI = async (
  controlUnitId: number,
  azureId: string,
  isProfireUser: boolean) => {
  try {
    if (isProfireUser) {
      return {data: true}
    }

    const token = await getToken();
    const result = await axios
    .get(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/user/verifyusercontrolunit/${controlUnitId}/${azureId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return result;
  } catch (error: any) {
    return error.response;
  }
};