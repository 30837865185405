import React, { useState } from "react";
import styled from "styled-components";
import ChevronRightIcon from "../../../assets/images/navigation/chevron-right.svg";
import ChevronDownIcon from "../../../assets/images/navigation/chevron-down.svg";
import DefaultContainer from "./DefaultContainer";

// Collapsible Div Component Instructions
// To use this Collapsible Div, import it into whatever page/component necessary and feed it two things: A title (prop) and children.
// - title: Just a simple string that will be displayed verbatim to the right of the chevron
// - children: any and all HTML elements that need to be shown when the collapsible div is opened are passed in like children normally would be.
// - ex: <CollapsibleDiv title={"Div Title"}>{this is where your HTML elements go}</CollapsibleDiv>
// This component is meant to be as simple and straightforward as possible, which means that at this current time I will not be setting up a CollapsibleDivGroup
// This means that you can have as many CollapsibleDivs open at once as you like, until the group is set up.

interface CollapsibleDivProps {
  dataTestname: string;
  title: string;
  open?: boolean;
  toggleFunction?: React.MouseEventHandler<HTMLDivElement>;
  children?: React.ReactNode;
}

const CollapsibleDiv: React.FC<CollapsibleDivProps> = ({
  dataTestname,
  title,
  open = false,
  toggleFunction,
  children,
}) => {
  const [active, setActive] = useState<boolean>(false);
  const updateActive = () => {
    setActive(!active);
  };

  return (
    <div>
      <StyledMainDiv data-testname={dataTestname} data-testid="main-div">
        {/* collapsible row area */}
        <StyledMainRowDiv
          active={toggleFunction ? open : active}
          data-testid="main-row-div"
          onClick={toggleFunction ? toggleFunction : updateActive}
        >
          <StyledMainRowChevronDiv data-testid="main-row-arrow-div">
            <img
              data-testid="main-row-arrow"
              src={
                toggleFunction
                  ? open
                    ? ChevronDownIcon
                    : ChevronRightIcon
                  : active
                  ? ChevronDownIcon
                  : ChevronRightIcon
              }
              alt="overview icon"
            />
          </StyledMainRowChevronDiv>
          <StyledMainRowTitleDiv data-testid="main-row-title-div">
            <StyledTitle data-testid="main-row-title">{title}</StyledTitle>
          </StyledMainRowTitleDiv>
        </StyledMainRowDiv>
        {toggleFunction
          ? open && (
              <>
                {/* children area */}
                {children}
              </>
            )
          : active && (
              <>
                {/* children area */}
                {children}
              </>
            )}
      </StyledMainDiv>
      <StyledMobileDiv>
        <DefaultContainer
          dataTestname={`${title}-collapsible-div-default-container`}
          darkBackground={true}
          title={title}
        >
          {children}
        </DefaultContainer>
      </StyledMobileDiv>
    </div>
  );
};

export default CollapsibleDiv;

interface MainRowProps {
  active: boolean;
}

const StyledMainDiv = styled.div`
  width: 100%;
  background-color: white;
  margin-bottom: 20px;
  border-radius: 4px 4px 4px 4px;
  display: none;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    display: flow-root;
  }
`;

const StyledMobileDiv = styled.div`
  display: block;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    display: none;
  }
`;

const StyledMainRowDiv = styled.div<MainRowProps>`
  width: 100%;
  display: flex;
  height: 45px;
  border-radius: ${(props) => (props.active ? "4px 4px 0px 0px" : "4px")};
  background-color: white;
  cursor: pointer;

  div:first-child {
    width: 46px;
    text-align: center;
    border-top: none;
  }

  div:nth-child(2) {
    width: Calc(100% - 46px);
  }
`;

const StyledMainRowChevronDiv = styled.div`
  text-align: left;
  width: 200px;
  padding-top: 14px;
  padding-bottom: 15px;
`;

const StyledMainRowTitleDiv = styled.div`
  text-align: left;
  width: 200px;
  padding-top: 11px;
  padding-bottom: 15px;

  h2 {
    margin: 0;
  }
`;

const StyledTitle = styled.h2`
  font-size: ${(props) => props.theme.headingMainSize};
  font-weight: ${(props) => props.theme.headingMainWeight};
`;
