import React, { useReducer } from "react";
import { ActionTypes, IProcessUnitContext, IProcessUnitState } from "./types";
import ProcessUnitReducer from "./ProcessUnitReducer";
import ProcessUnitContext from "./ProcessUnitContext";
import axios from "axios";
import { getToken } from "../../utilities/msTokenFunc";

const ProcessUnitProvider: React.FC<IProcessUnitContext> = (props) => {
  const initialState: IProcessUnitState = {
    processUnits: [],
    processUnit: null,
    processUnitBelongsToUserCompany: null,
    loading: true,
  };

  const [state, dispatch] = useReducer(ProcessUnitReducer, initialState);

  const setProcessUnits = async () => {
    try {
      dispatch({ type: ActionTypes.SENDING_REQUEST });
      const token: string = await getToken();
      await axios
        .get(`${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/processunit`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) =>
          dispatch({ type: ActionTypes.SET_PROCESS_UNITS, payload: res.data })
        );
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    } catch (error) {
      dispatch({ type: ActionTypes.SET_PROCESS_UNITS, payload: [] });
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    }
  };

  const setProcessUnit = async (id: number) => {
    try {
      dispatch({ type: ActionTypes.SENDING_REQUEST });
      const token: string = await getToken();
      await axios
        .get(
          `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/processunit/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) =>
          dispatch({ type: ActionTypes.SET_PROCESS_UNIT, payload: res.data })
        );
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    } catch (error) {
      dispatch({ type: ActionTypes.SET_PROCESS_UNIT, payload: null });
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    }
  };

  const setProcessUnitsBySiteId = async (siteId: number) => {
    try {
      dispatch({ type: ActionTypes.SENDING_REQUEST });
      const token: string = await getToken();
      await axios
        .get(
          `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/processunit/bysite/${siteId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) =>
          dispatch({ type: ActionTypes.SET_PROCESS_UNITS, payload: res.data })
        );
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    } catch (error) {
      dispatch({ type: ActionTypes.SET_PROCESS_UNITS, payload: [] });
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    }
  };

  const setProcessUnitBelongsToUserCompany = async (
    processUnitId: number,
    azureId: string,
    isProfireUser: boolean
  ) => {
    try {
      dispatch({ type: ActionTypes.SENDING_REQUEST });

      if (isProfireUser) {
        dispatch({
          type: ActionTypes.SET_PROCESS_UNIT_BELONGS_TO_USER_COMPANY,
          payload: true,
        });
      } else {
        const token: string = await getToken();
        await axios
          .get(
            `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/user/verifyuserprocessunit/${processUnitId}/${azureId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            dispatch({
              type: ActionTypes.SET_PROCESS_UNIT_BELONGS_TO_USER_COMPANY,
              payload: res.data,
            });
          });
      }

      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    } catch (error) {
      dispatch({
        type: ActionTypes.SET_PROCESS_UNIT_BELONGS_TO_USER_COMPANY,
        payload: false,
      });
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    }
  };

  const setProcessUnitsByCompany = async (companyId: number) => {
    try {
      dispatch({ type: ActionTypes.SENDING_REQUEST });
      const token: string = await getToken();
      await axios
        .get(
          `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/processunit/bycompany/${companyId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) =>
          dispatch({ type: ActionTypes.SET_PROCESS_UNITS, payload: res.data })
        );
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    } catch (error) {
      dispatch({ type: ActionTypes.SET_PROCESS_UNITS, payload: [] });
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    }
  };

  return (
    <ProcessUnitContext.Provider
      value={{
        processUnits: state.processUnits,
        processUnit: state.processUnit,
        processUnitBelongsToUserCompany: state.processUnitBelongsToUserCompany,
        loading: state.loading,
        setProcessUnits: setProcessUnits,
        setProcessUnit: setProcessUnit,
        setProcessUnitsBySiteId: setProcessUnitsBySiteId,
        setProcessUnitBelongsToUserCompany: setProcessUnitBelongsToUserCompany,
        setProcessUnitsByCompany: setProcessUnitsByCompany,
      }}
    >
      {props.children}
    </ProcessUnitContext.Provider>
  );
};

export default ProcessUnitProvider;
