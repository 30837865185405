import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Dropdown from "../common/formInputs/Dropdown";
import { IMonitorUnit } from "../../types/monitorUnits";
import LookupContext from "../../stores/lookup/LookupContext";
import UserContext from "../../stores/users/UserContext";
import Banner from "../common/headers/Banner";
import { validateTextString } from "../../utilities/validationFunctions";
import CommonInput from "../common/formInputs/CommonInput";
import ProfireButton from "../common/buttons/ProfireButton";
import ModalBox from "../common/modals/ModalBox";

interface EditMonitorUnitProps {
  active: boolean;
  monitorUnit: IMonitorUnit;
  onCancel?: () => void;
  onEdit: Function;
  retainedData: IMonitorUnit | undefined;
}

let existingErrors: Boolean = false;

const EditMonitorUnit: React.FC<EditMonitorUnitProps> = ({
  active,
  monitorUnit,
  onCancel,
  onEdit,
  retainedData,
}) => {
  const userContext = useContext(UserContext);
  const lookupContext = useContext(LookupContext);
  const lookupContextRef = useRef(lookupContext).current;

  let headingText: string = "Insufficient Access";
  let text: string =
    "You do not have sufficient access to use this page. If you believe this is an error, please contact technical support.";

  useEffect(() => {
    lookupContextRef.setMonitorUnitTypes();
  }, [lookupContextRef]);

  const [chosenSite] = useState<string>(monitorUnit.site?.facility_name!);

  const [chosenMonitorUnitType, setChosenMonitorUnitType] = useState<number>(
    retainedData === undefined
      ? monitorUnit?.monitor_unit_type_id === undefined
        ? 0
        : monitorUnit?.monitor_unit_type_id
      : retainedData?.monitor_unit_type_id
  );
  function updateChosenMonitorUnitType(newChosen: string) {
    setChosenMonitorUnitType(parseInt(newChosen));
    setChosenMonitorUnitTypeErrors("");
  }
  let monitorUnitTypeArray: [string, string][] = [];
  //populate monitorUnitTypeArray for dropdown
  monitorUnitTypeArray.push(["Select a monitor unit type...", "0"]);
  for (let index = 0; index < lookupContext.monitorUnitTypes.length; index++) {
    monitorUnitTypeArray.push([
      lookupContext.monitorUnitTypes[index].manufacturer +
        ": " +
        lookupContext.monitorUnitTypes[index].model,
      lookupContext.monitorUnitTypes[index].id.toString(),
    ]);
  }

  const [chosenMonitorUnitTypeErrors, setChosenMonitorUnitTypeErrors] =
    useState<string>("");

  const [tag, setTag] = useState<string>(
    retainedData === undefined
      ? monitorUnit?.tag === undefined || monitorUnit.tag === null
        ? ""
        : monitorUnit?.tag
      : retainedData.tag === null
      ? ""
      : retainedData.tag
  );
  const [tagErrors, setTagErrors] = useState<string>("");

  const handleTag = (e: React.FormEvent<HTMLInputElement>) => {
    if (
      !validateTextString(e.currentTarget.value) &&
      e.currentTarget.value !== ""
    ) {
      setTag(e.currentTarget.value);
      setTagErrors(
        "First character must be alphanumeric and only alphanumeric, spaces, and the following are supported after: | _ : , . # - / '"
      );
    } else {
      setTag(e.currentTarget.value);
      setTagErrors("");
    }
  };

  function handleCancel() {
    existingErrors = false;
    onCancel!();
  }

  function handleExistingErrors(errors: Boolean) {
    existingErrors = errors;
  }

  function clearErrors() {
    setChosenMonitorUnitTypeErrors("");

    handleExistingErrors(false);
  }

  function ErrorHandler() {
    clearErrors();

    if (!chosenMonitorUnitType) {
      setChosenMonitorUnitTypeErrors("Monitor Unit Type is required.\n");
      handleExistingErrors(true);
    }
    if (chosenMonitorUnitType === 0) {
      setChosenMonitorUnitTypeErrors("Monitor Unit Type is required.\n");
      handleExistingErrors(true);
    }
    if (!tag) {
      setTagErrors("Tag is required.\n");
      handleExistingErrors(true);
    }
    if (tag) {
      if (!validateTextString(tag)) {
        setTagErrors(
          "First character must be alphanumeric and only alphanumeric, spaces, and the following are supported after: | _ : , . # - / '"
        );
        handleExistingErrors(true);
      }
    }
  }

  function onSubmit(e: any) {
    e.preventDefault();

    if (!existingErrors) {
      const updatedMonitorUnit: IMonitorUnit = {
        id: monitorUnit?.id!,
        site_id: monitorUnit?.site_id!,
        monitor_unit_type_id: chosenMonitorUnitType,
        tag: tag,
      };

      onEdit(updatedMonitorUnit);
    }
  }

  return (
    <ModalBox
      dataTestname="edit-monitor-unit-modal-box"
      active={active}
      desktopContainerMinWidth={"765px"}
      mobileContainerMinWidth={"320px"}
      containerTitle="Edit Monitor Unit"
      maxHeight={"50%"}
    >
      <StyledEditMonitorUnitDiv>
        {userContext.permissions?.profire_manage_monitor_units ? (
          <StyledMainDiv data-testid="main-div">
            <StyledForm onSubmit={onSubmit}>
              <StyledSectionDiv>
                <CommonInput
                  dataTestname="edit-monitor-unit-site-common-input"
                  type={"text"}
                  labelText={"Site"}
                  required={false}
                  readOnly={true}
                  value={chosenSite}
                  placeholder={"Site Name"}
                />
                <Dropdown
                  dataTestname="edit-monitor-unit-monitor-unit-type-dropdown"
                  selected={chosenMonitorUnitType.toString()}
                  onchange={updateChosenMonitorUnitType}
                  columns={monitorUnitTypeArray}
                  labelText={"Monitor Unit Type"}
                  required={true}
                  errors={chosenMonitorUnitTypeErrors}
                  autoFocus={true}
                />
                <CommonInput
                  dataTestname="edit-monitor-unit-tag-common-input"
                  type={"text"}
                  labelText={"Tag"}
                  required={true}
                  readOnly={false}
                  value={tag}
                  onChange={handleTag}
                  maxLength={50}
                  placeholder={"Tag"}
                  errors={tagErrors}
                />
                {existingErrors && (
                  <StyledErrorMessage data-testid="submit-errors">
                    {"Please fix above errors and resubmit."}
                  </StyledErrorMessage>
                )}
                <StyledButtonRow>
                  <StyledCancelButtonHolder>
                    <ProfireButton
                      dataTestname="add-site-cancel-button"
                      text="Cancel"
                      onClickFunction={handleCancel}
                    />
                  </StyledCancelButtonHolder>
                  <StyledSaveButtonHolder>
                    <ProfireButton
                      dataTestname="add-site-save-button"
                      text="Save"
                      onClickFunction={ErrorHandler}
                      defaultButton={true}
                    />
                  </StyledSaveButtonHolder>
                </StyledButtonRow>
              </StyledSectionDiv>
            </StyledForm>
          </StyledMainDiv>
        ) : (
          <StyledMainDiv>
            <StyledBannerDiv
              allowed={userContext.permissions?.profire_manage_monitor_units!}
              data-testid="banner-div"
            >
              <Banner
                dataTestname="edit-monitor-unit-banner"
                headingtext={headingText}
                text={text}
              />
            </StyledBannerDiv>
          </StyledMainDiv>
        )}
      </StyledEditMonitorUnitDiv>
    </ModalBox>
  );
};

export default EditMonitorUnit;

const StyledEditMonitorUnitDiv = styled.div`
  width: calc(100% - 10px);
  height: 100%;
  overflow-y: auto;
  padding-right: 10px;
`;

const StyledMainDiv = styled.div`
  height: 100%;
  width: 100%;
`;

interface AllowedProps {
  allowed: boolean;
}

const StyledBannerDiv = styled.div<AllowedProps>`
  min-width: Calc(300px - 32px);
  width: Calc(100% - 32px);
  background-color: ${(props) => (props.allowed ? "#ffffff" : "#9c1b30")};
  padding: 20px 16px;
  margin-bottom: 25px;
  border-radius: 10px;
`;

const StyledErrorMessage = styled.div`
  text-align: left;
  color: #9c1b30;
  font-size: ${(props) => props.theme.contentErrorSize};
  font-weight: ${(props) => props.theme.contentErrorWeight};

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
`;

const StyledSectionDiv = styled.div`
  width: Calc(100%);
  background-color: #ffffff;
  border-radius: 10px;
  text-align: left;
`;

const StyledForm = styled.form``;

const StyledButtonRow = styled.div`
  width: 100%;
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

const StyledCancelButtonHolder = styled.div`
  margin-right: 20px;
`;

const StyledSaveButtonHolder = styled.div``;
