import {
  IControlUnit,
  IControlUnitAllInclusive,
} from "../../types/controlUnits";

export interface IControlUnitContext {
  controlUnits: IControlUnit[];
  controlUnit: IControlUnitAllInclusive | null;
  controlUnitBelongsToUserCompany: boolean | null;
  loading: boolean;
  setControlUnits: Function;
  setControlUnit: Function;
  setControlUnitsByProcessUnitId: Function;
  setControlUnitsByMonitorUnitId: Function;
  setControlUnitBelongsToUserCompany: Function;
  setControlUnitsByCompany: Function;
  children?: React.ReactNode;
}

export interface IControlUnitState {
  controlUnits: IControlUnit[];
  controlUnit: IControlUnitAllInclusive | null;
  controlUnitBelongsToUserCompany: boolean | null;
  loading: boolean;
}

export enum ActionTypes {
  SET_CONTROL_UNITS = "SET_CONTROL_UNITS",
  SET_CONTROL_UNIT = "SET_CONTROL_UNIT",
  SET_CONTROL_UNIT_BELONGS_TO_USER_COMPANY = "SET_CONTROL_UNIT_BELONGS_TO_USER_COMPANY",
  SENDING_REQUEST = "SENDING_REQUEST",
  REQUEST_FINISHED = "REQUEST_FINISHED",
}

export interface ControlUnitAction {
  type: ActionTypes;
  payload:
    | number
    | string
    | boolean
    | IControlUnit
    | IControlUnit[]
    | IControlUnitAllInclusive;
}
