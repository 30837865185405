import { createContext } from "react";
import { IDailyAggregateContext } from "./types";

const defaultDailyAggregateContext: IDailyAggregateContext = {
  dailyAggregates: [],
  loading: true,
  setDailyAggregates: () => null,
};

const DailyAggregateContext = createContext<IDailyAggregateContext>(
  defaultDailyAggregateContext
);

export default DailyAggregateContext;
