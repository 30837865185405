import { IContactPoint } from "../../types/contactPoints";
import { ActionTypes, IContactPointState } from "./types";

type Action =
  | {
      type: ActionTypes.SET_CONTACT_POINT;
      payload: IContactPoint | null;
    }
  | {
      type: ActionTypes.SET_CONTACT_POINTS_BY_COMPANY;
      payload: IContactPoint[];
    }
  | { type: ActionTypes.SENDING_REQUEST }
  | { type: ActionTypes.REQUEST_FINISHED };

const reducer = (state: IContactPointState, action: Action) => {
  switch (action.type) {
    case ActionTypes.SENDING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.REQUEST_FINISHED:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.SET_CONTACT_POINT:
      return {
        ...state,
        contactPoint: action.payload,
      };
    case ActionTypes.SET_CONTACT_POINTS_BY_COMPANY:
      const sortedContactPoints: IContactPoint[] = action.payload.sort(
        (a, b) => a.id - b.id
      );
      return {
        ...state,
        contactPoints: sortedContactPoints,
      };
    default:
      return state;
  }
};

export default reducer;
