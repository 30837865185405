import { createContext } from "react";
import { ICompanyContext } from "./types";

const defaultCompanyContext: ICompanyContext = {
  companies: [],
  company: null,
  users: [],
  userBelongsToUserCompany: null,
  loading: true,
  companyBelongsToUser: null,
  setCompanies: () => null,
  setCompany: () => null,
  setCompanyByUser: () => null,
  setCompanyByControlUnit: () => null,
  setUsers: () => null,
  setCompanyBelongsToUser: () => null,
  setUserBelongsToUserCompany: () => null,
};

const CompanyContext = createContext<ICompanyContext>(defaultCompanyContext);

export default CompanyContext;
