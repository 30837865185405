import React, { useEffect, useReducer, useState } from "react";
import { IStyleContext, IStyleState } from "./types";
import StyleReducer from "./StyleReducer";
import StyleContext from "./StyleContext";

const StyleProvider: React.FC<IStyleContext> = (props) => {
  const initialState: IStyleState = {
    isMobile: true,
    loading: true,
  };

  const [state] = useReducer(StyleReducer, initialState);
  const [width, setWidth] = useState<number>(window.innerWidth);
  const isMobile = width <= 768;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <StyleContext.Provider
      value={{
        isMobile: isMobile,
        loading: state.loading,
      }}
    >
      {props.children}
    </StyleContext.Provider>
  );
};

export default StyleProvider;
