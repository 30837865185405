import { useReducer } from "react";
import { IUser } from "../../types/users";
import { getToken } from "../../utilities/msTokenFunc";
import { ActionTypes, IUserContext, IUserState } from "./types";
import UserContext from "./UserContext";
import UserReducer from "./UserReducer";
import axios from "axios";

const UserProvider: React.FC<IUserContext> = (props) => {
  const initialState: IUserState = {
    user: null,
    permissions: null,
    loading: false,
  };

  const [state, dispatch] = useReducer(UserReducer, initialState);

  const setUser = async (user: IUser) => {
    dispatch({ type: ActionTypes.SENDING_REQUEST });
    dispatch({ type: ActionTypes.SET_USER, payload: user });
    dispatch({ type: ActionTypes.REQUEST_FINISHED });
  };

  const setPermissions = async (azureId: string) => {
    try {
      dispatch({ type: ActionTypes.SENDING_REQUEST });

      const token: string = await getToken();
      await axios
        .get(
          `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/permission/${azureId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          dispatch({
            type: ActionTypes.SET_PERMISSIONS,
            payload: res.data,
          });
        });

      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    } catch (error) {
      dispatch({
        type: ActionTypes.SET_PERMISSIONS,
        payload: [],
      });
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    }
  };

  return (
    <UserContext.Provider
      value={{
        user: state.user,
        permissions: state.permissions,
        loading: state.loading,
        setUser: setUser,
        setPermissions: setPermissions,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserProvider;
