import axios from "axios";
import {
  IProcessUnitCreateDTO,
  IProcessUnitUpdateDTO,
} from "../../types/processUnits";
import { getToken } from "../msTokenFunc";

export const setProcessUnitsBySiteFromAPI = async (
  siteId: number,
  loadingFunc: Function
) => {
  try {
    const token = await getToken();
    const result = await axios.get(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/processunit/bysite/${siteId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    loadingFunc(false);
    return result;
  } catch (error: any) {
    loadingFunc(false);
    return error.response;
  }
};

export const setProcessUnitFromAPI = async (
  processUnitId: number,
  loadingFunc: Function
) => {
  try {
    const token = await getToken();
    const result = await axios.get(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/processunit/${processUnitId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    loadingFunc(false);
    return result;
  } catch (error: any) {
    loadingFunc(false);
    return error.response;
  }
};

export const addNewProcessUnit = async (
  newProcessUnit: IProcessUnitCreateDTO
) => {
  try {
    const token = await getToken();
    const result = await axios.post(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/processunit`,
      newProcessUnit,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result;
  } catch (error: any) {
    return error.response;
  }
};

export const editProcessUnitTag = async (
  updatedProcessUnit: IProcessUnitUpdateDTO
) => {
  try {
    const token = await getToken();
    const result = await axios.put(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/processunit`,
      updatedProcessUnit,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result;
  } catch (error: any) {
    return error.response;
  }
};

export const editProcessUnit = async (updatedProcessUnit: IProcessUnitUpdateDTO) => {
  try {
    const token = await getToken();
    const result = await axios.put(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/processunit`,
      updatedProcessUnit,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result;
  } catch (error: any) {
    return error.response;
  }
};

export const deleteProcessUnit = async (processUnitId: number) => {
  try {
    const token = await getToken();
    const result = await axios.delete(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/processunit/${processUnitId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result;
  } catch (error: any) {
    return error.response;
  }
};

export const setProcessUnitBelongsToUserCompanyFromAPI = async (
  processUnitId: number,
  azureId: string,
  isProfireUser: boolean) => {
  try {
    if (isProfireUser) {
      return {data: true}
    }

    const token = await getToken();
    const result = await axios
    .get(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/user/verifyuserprocessunit/${processUnitId}/${azureId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return result;
  } catch (error: any) {
    return error.response;
  }
};
