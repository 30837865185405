import { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import RoleContext from "../../stores/roles/RoleContext";
import {
  validateEmailString,
  validateTextString,
} from "../../utilities/validationFunctions";
import ProfireButton from "../common/buttons/ProfireButton";
import ModalBox from "../common/modals/ModalBox";
import CommonInput from "../common/formInputs/CommonInput";
import UserContext from "../../stores/users/UserContext";
import {
  IContactPoint,
  IContactPointCreateDTO,
} from "../../types/contactPoints";
import ModalTextArea from "../common/formInputs/ModalTextArea";

interface AddContactPointProps {
  active: boolean;
  onCancel?: () => void;
  onAdd: Function;
  retainedData: IContactPointCreateDTO | undefined;
  companyId: number;
  contactPoints: IContactPoint[];
}

let existingErrors: Boolean = false;

const AddContactPoint: React.FC<AddContactPointProps> = ({
  active,
  onCancel,
  onAdd,
  retainedData,
  companyId,
  contactPoints,
}) => {
  const userContext = useContext(UserContext);
  const roleContext = useContext(RoleContext);
  const roleContextRef = useRef(roleContext).current;

  useEffect(() => {
    roleContextRef.setRoles();
  }, [roleContextRef, companyId, userContext.user?.azure_id]);

  const [contactPointName, setContactPointName] = useState<string>(
    retainedData === undefined ? "" : retainedData.name!
  );
  const [contactPointNameErrors, setContactPointNameErrors] =
    useState<string>("");
  const handleContactPointName = (e: React.FormEvent<HTMLInputElement>) => {
    if (!validateTextString(e.currentTarget.value)) {
      setContactPointName(e.currentTarget.value);
      setContactPointNameErrors(
        "First character must be alphanumeric and only alphanumeric, spaces, and the following are supported after: | _ : , . # - / '"
      );
    } else {
      setContactPointName(e.currentTarget.value);
      setContactPointNameErrors("");
    }
    handleExistingErrors(false);
  };

  const [emails, setEmails] = useState<string>(
    retainedData === undefined ? "" : retainedData.email!
  );
  const [emailsErrors, setEmailsErrors] = useState<string>("");
  const handleEmails = (e: React.FormEvent<HTMLTextAreaElement>) => {
    setEmails(e.currentTarget.value.replace(/\s+/g, ""));
    setEmailsErrors("");
    handleExistingErrors(false);
  };

  const [phoneNumbers, setPhoneNumbers] = useState<string>(
    retainedData === undefined ? "" : retainedData.phone_number!
  );
  const [phoneNumbersErrors, setPhoneNumbersErrors] = useState<string>("");
  const handlePhoneNumbers = (e: React.FormEvent<HTMLTextAreaElement>) => {
    setPhoneNumbers(e.currentTarget.value.replace(/\s+/g, ""));
    setPhoneNumbersErrors("");
    handleExistingErrors(false);
  };

  function validateEmails(emails: string[]) {
    let validEmail = true;
    let invalidEmail: string = "";

    emails.forEach((email) => {
      if (!validateEmailString(email)) {
        validEmail = false;
        invalidEmail += email + ";";
      }
    });

    if (!validEmail) {
      setEmailsErrors(`Invalid emails: ${invalidEmail}`);
      handleExistingErrors(true);
    }
    return validEmail;
  }

  function validatePhoneNumbers(phoneNumbers: string[]) {
    let validPhoneNumber = true;
    let invalidPhoneNumber: string = "";
    const validPhoneNumberText = new RegExp(`^[0-9]{10}$`);

    phoneNumbers.forEach((phoneNumber) => {
      let valid = validPhoneNumberText.test(phoneNumber.replace(/\s+/g, ""));

      if (!valid) {
        validPhoneNumber = false;
        invalidPhoneNumber += phoneNumber + ";";
      }
    });

    if (!validPhoneNumber) {
      setPhoneNumbersErrors(`Invalid phone numbers: ${invalidPhoneNumber}`);
      handleExistingErrors(true);
    }
    return validPhoneNumber;
  }

  function handleCancel() {
    existingErrors = false;
    onCancel!();
  }

  function handleExistingErrors(errors: Boolean) {
    existingErrors = errors;
  }

  function clearErrors() {
    setContactPointNameErrors("");
    setEmailsErrors("");
    setPhoneNumbersErrors("");

    handleExistingErrors(false);
  }

  function ErrorHandler() {
    clearErrors();

    if (!contactPointName) {
      setContactPointNameErrors("Contact Point Name is required.\n");
      handleExistingErrors(true);
    }

    if (contactPointName) {
      if (!validateTextString(contactPointName)) {
        setContactPointNameErrors("Contact Point Name is invalid.");
        handleExistingErrors(true);
      }

      if (
        contactPoints.some(
          (contactPoint) =>
            contactPoint.name.toLowerCase() === contactPointName.toLowerCase()
        )
      ) {
        setContactPointNameErrors("Contact Point Name already exists.");
        handleExistingErrors(true);
      }
    }

    if (!emails && !phoneNumbers) {
      setEmailsErrors("At least one email or one phone number is required.");
      setPhoneNumbersErrors(
        "At least one email or one phone number is required."
      );
      handleExistingErrors(true);
    }

    if (emails) {
      const emailArray = emails.split(";");
      if (!validateEmails(emailArray)) {
        handleExistingErrors(true);
      }

      let duplicateEmails = false;
      duplicateEmails = emailArray.some(
        (x) => emailArray.indexOf(x) !== emailArray.lastIndexOf(x)
      );
      if (duplicateEmails) {
        setEmailsErrors("Please remove duplicate emails.");
        handleExistingErrors(true);
      }
    }

    if (phoneNumbers) {
      const phoneNumberArray = phoneNumbers.split(";");
      if (!validatePhoneNumbers(phoneNumberArray)) {
        handleExistingErrors(true);
      }

      let duplicatePhoneNumbers = false;
      duplicatePhoneNumbers = phoneNumberArray.some(
        (x) => phoneNumberArray.indexOf(x) !== phoneNumberArray.lastIndexOf(x)
      );
      if (duplicatePhoneNumbers) {
        setPhoneNumbersErrors("Please remove duplicate phone numbers.");
        handleExistingErrors(true);
      }
    }
  }

  async function onSubmit(e: any) {
    e.preventDefault();

    // if no errors
    if (!existingErrors) {
      const newContactPoint: IContactPointCreateDTO = {
        name: contactPointName,
        email: emails,
        phone_number: phoneNumbers,
        company_id: companyId,
      };

      onAdd(newContactPoint);
    }
  }
  return (
    <ModalBox
      dataTestname="add-contact-point-modal-box"
      active={active}
      desktopContainerMinWidth={"765px"}
      mobileContainerMinWidth={"320px"}
      containerTitle="Add Contact Point"
      maxHeight={"50%"}
    >
      <StyledAddContactPointDiv>
        <StyledMainDiv data-testid="main-div">
          <StyledForm onSubmit={onSubmit}>
            <StyledSectionDiv>
              <CommonInput
                dataTestname="add-contact-point-name-common-input"
                type={"text"}
                labelText={"Name"}
                required={true}
                readOnly={false}
                value={contactPointName}
                onChange={handleContactPointName}
                maxLength={50}
                placeholder={""}
                errors={contactPointNameErrors}
                autoFocus={true}
              />
              <ModalTextArea
                dataTestname="add-contact-point-emails-common-input"
                labelText={"E-mail Addresses"}
                readOnly={false}
                value={emails}
                onChange={handleEmails}
                placeholder={""}
                errors={emailsErrors}
                subLabelText="You can enter multiple email addresses using a ; separator"
              />
              <ModalTextArea
                dataTestname="add-contact-point-phone-numbers-common-input"
                labelText={"Phone Numbers"}
                subLabelText={
                  "You can enter multiple phone numbers using a ; separator. Phone numbers should be 10 digits without spaces or dashes (i.e., 1234567890)"
                }
                readOnly={false}
                value={phoneNumbers}
                onChange={handlePhoneNumbers}
                placeholder={""}
                errors={phoneNumbersErrors}
              />
              {existingErrors && (
                <StyledErrorMessage data-testid="submit-errors">
                  {"Please fix above errors and resubmit."}
                </StyledErrorMessage>
              )}
              <StyledButtonRow>
                <StyledCancelButtonHolder>
                  <ProfireButton
                    dataTestname="add-contact-point-cancel-button"
                    text="Cancel"
                    onClickFunction={handleCancel}
                  />
                </StyledCancelButtonHolder>
                <StyledSaveButtonHolder>
                  <ProfireButton
                    dataTestname="add-contact-point-save-button"
                    text="Save"
                    onClickFunction={ErrorHandler}
                    defaultButton={true}
                  />
                </StyledSaveButtonHolder>
              </StyledButtonRow>
            </StyledSectionDiv>
          </StyledForm>
        </StyledMainDiv>
      </StyledAddContactPointDiv>
    </ModalBox>
  );
};

export default AddContactPoint;

const StyledAddContactPointDiv = styled.div`
  width: calc(100% - 10px);
  height: 100%;
  overflow-y: auto;
  padding-right: 10px;
`;

const StyledMainDiv = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledErrorMessage = styled.div`
  text-align: left;
  color: #9c1b30;
  font-size: ${(props) => props.theme.contentErrorSize};
  font-weight: ${(props) => props.theme.contentErrorWeight};

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
`;

const StyledSectionDiv = styled.div`
  width: Calc(100%);
  background-color: #ffffff;
  border-radius: 10px;
  text-align: left;
`;

const StyledForm = styled.form``;

const StyledButtonRow = styled.div`
  width: 100%;
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

const StyledCancelButtonHolder = styled.div`
  margin-right: 20px;
`;

const StyledSaveButtonHolder = styled.div``;
