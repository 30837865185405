import axios from "axios";
import {
  IMonitorUnit,
  IMonitorUnitCreateDTO,
} from "../../types/monitorUnits";
import { getToken } from "../msTokenFunc";

export const setMonitorUnitsBySiteFromAPI = async (
  siteId: number,
  loadingFunc: Function
) => {
  try {
    const token = await getToken();
    const result = await axios.get(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/monitorunit/bysite/${siteId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    loadingFunc(false);
    return result;
  } catch (error: any) {
    loadingFunc(false);
    return error.response;
  }
};

export const setMonitorUnitFromAPI = async (
  monitorUnitId: number,
  loadingFunc: Function
) => {
  try {
    const token = await getToken();
    const result = await axios.get(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/monitorunit/${monitorUnitId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    loadingFunc(false);
    return result;
  } catch (error: any) {
    loadingFunc(false);
    return error.response;
  }
};

export const addNewMonitorUnit = async (
  newMonitorUnit: IMonitorUnitCreateDTO
) => {
  try {
    const token = await getToken();
    const result = await axios.post(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/monitorunit`,
      newMonitorUnit,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result;
  } catch (error: any) {
    return error.response;
  }
};

export const editMonitorUnitTag = async (
  updatedMonitorUnit: IMonitorUnit
) => {
  try {
    const token = await getToken();
    const result = await axios.put(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/monitorunit`,
      updatedMonitorUnit,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result;
  } catch (error: any) {
    return error.response;
  }
};

export const editMonitorUnit = async (updatedMonitorUnit: IMonitorUnit) => {
  try {
    const token = await getToken();
    const result = await axios.put(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/monitorunit`,
      updatedMonitorUnit,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result;
  } catch (error: any) {
    return error.response;
  }
};

export const deleteMonitorUnit = async (monitorUnitId: number) => {
  try {
    const token = await getToken();
    const result = await axios.delete(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/monitorunit/${monitorUnitId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result;
  } catch (error: any) {
    return error.response;
  }
};

export const setMonitorUnitBelongsToUserCompanyFromAPI = async (
  monitorUnitId: number,
  azureId: string,
  isProfireUser: boolean) => {
  try {
    if (isProfireUser) {
      return {data: true}
    }

    const token = await getToken();
    const result = await axios
    .get(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/user/verifyusermonitorunit/${monitorUnitId}/${azureId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return result;
  } catch (error: any) {
    return error.response;
  }
};