import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import CommonInput from "./CommonInput";
import ConfirmationBox from "../modals/ConfirmationBox";
import Dropdown from "./Dropdown";
import {
  IPF2200SBFields,
  ISWARMSettingsDTO,
} from "../../../types/controlUnitConfig";
import Checkbox from "./Checkbox";
import { validateWholeNumberString } from "../../../utilities/validationFunctions";
import {
  fourTwentyLevelUnitsArray,
  fourTwentyPressureUnitsArray,
} from "../../../utilities/staticObjects";
import InfoButton from "../buttons/InfoButton";
import StyleContext from "../../../stores/styles/StyleContext";

interface SWARMInputAreaProps {
  dataTestname: string;
  settings: ISWARMSettingsDTO;
  current_configs: IPF2200SBFields[];
  historical_configs: IPF2200SBFields[];
  handleChange: Function;
  handleCheckbox: Function;
  handleExistingErrors: Function;
  readOnly: boolean;
  model: string;
}

const SWARMInputArea: React.FC<SWARMInputAreaProps> = ({
  dataTestname,
  settings,
  current_configs,
  historical_configs,
  handleChange,
  handleCheckbox,
  handleExistingErrors,
  readOnly,
  model,
}) => {
  const pressureInfoHoverText: string =
    "Pressure units should match those configured on the PF2100.";
  const levelInfoHoverText: string =
    "Level units should match those configured on the PF2100.";

  const styleContext = useContext(StyleContext);

  //Confirmation Box states
  const [confirmActive, setConfirmActive] = useState<boolean>(false);
  const [confirmText, setConfirmText] = useState<string>("");

  async function handleConfirm() {
    setConfirmText("");
    setConfirmActive(false);
  }

  const [input1Units, setInput1Units] = useState<string>(
    settings.input_1_units === null ? "" : settings.input_1_units
  );
  function updateInput1Units(e: React.FormEvent<HTMLInputElement>) {
    setInput1Units(e.currentTarget.value);
    handleChange("input_1_units", e.currentTarget.value);
  }

  const [input1SpanMin, setInput1SpanMin] = useState<string>(
    settings.input_1_span_min.toString()
  );
  function updateInput1SpanMin(e: React.FormEvent<HTMLInputElement>) {
    setInput1SpanMin(e.currentTarget.value);
    handleChange("input_1_span_min", e.currentTarget.value);
    ErrorHandler("input_1_span_min", e.currentTarget.value);
  }
  const [input1SpanMinErrors, setInput1SpanMinErrors] = useState<string>("");

  const [input1SpanMax, setInput1SpanMax] = useState<string>(
    settings.input_1_span_max.toString()
  );
  function updateInput1SpanMax(e: React.FormEvent<HTMLInputElement>) {
    setInput1SpanMax(e.currentTarget.value);
    handleChange("input_1_span_max", e.currentTarget.value);
    ErrorHandler("input_1_span_max", e.currentTarget.value);
  }
  const [input1SpanMaxErrors, setInput1SpanMaxErrors] = useState<string>("");

  const [input2Units, setInput2Units] = useState<string>(
    settings.input_2_units === null ? "" : settings.input_2_units
  );
  function updateInput2Units(e: React.FormEvent<HTMLInputElement>) {
    setInput2Units(e.currentTarget.value);
    handleChange("input_2_units", e.currentTarget.value);
  }

  const [input2SpanMin, setInput2SpanMin] = useState<string>(
    settings.input_2_span_min.toString()
  );
  function updateInput2SpanMin(e: React.FormEvent<HTMLInputElement>) {
    setInput2SpanMin(e.currentTarget.value);
    handleChange("input_2_span_min", e.currentTarget.value);
    ErrorHandler("input_2_span_min", e.currentTarget.value);
  }
  const [input2SpanMinErrors, setInput2SpanMinErrors] = useState<string>("");

  const [input2SpanMax, setInput2SpanMax] = useState<string>(
    settings.input_2_span_max.toString()
  );
  function updateInput2SpanMax(e: React.FormEvent<HTMLInputElement>) {
    setInput2SpanMax(e.currentTarget.value);
    handleChange("input_2_span_max", e.currentTarget.value);
    ErrorHandler("input_2_span_max", e.currentTarget.value);
  }
  const [input2SpanMaxErrors, setInput2SpanMaxErrors] = useState<string>("");

  let temperatureUnitsArray: [string, string][] = [
    ["Celcius", "°C"],
    ["Fahrenheit", "°F"],
  ];
  const [chosenTemperatureUnits, setChosenTemperatureUnits] = useState<string>(
    settings.temp_units
  );
  function updateTemperatureChosenUnits(newChosen: string) {
    setChosenTemperatureUnits(newChosen);
    handleChange("temp_units", newChosen);
  }

  let pressureUnitsArray: [string, string][] = fourTwentyPressureUnitsArray;
  const [chosenPressureUnits, setChosenPressureUnits] = useState<string>(
    settings.pressure_units!
  );
  function updateChosenPressureUnits(newChosen: string) {
    setChosenPressureUnits(newChosen);
    handleChange("pressure_units", newChosen);
  }

  let levelUnitsArray: [string, string][] = fourTwentyLevelUnitsArray;
  const [chosenLevelUnits, setChosenLevelUnits] = useState<string>(
    settings.level_units!
  );
  function updateChosenLevelUnits(newChosen: string) {
    setChosenLevelUnits(newChosen);
    handleChange("level_units", newChosen);
  }

  function ErrorHandler(input: string, newValue: string) {
    handleExistingErrors(false);
    setInput1SpanMinErrors("");
    setInput1SpanMaxErrors("");
    setInput2SpanMinErrors("");
    setInput2SpanMaxErrors("");

    switch (input) {
      case "input_1_span_min":
        if (!validateWholeNumberString(newValue)) {
          setInput1SpanMinErrors("Input 1 Span Min must be a whole number");
          handleExistingErrors(true);
        }

        if (parseInt(newValue) > 100000 || parseInt(newValue) < -100000) {
          setInput1SpanMinErrors(
            "Input 1 Span Min must be between -100000 and 100000"
          );
          handleExistingErrors(true);
        }

        if (
          parseInt(input1SpanMax) > 100000 ||
          parseInt(input1SpanMax) < -100000
        ) {
          setInput1SpanMaxErrors(
            "Input 1 Span Max must be between -100000 and 100000"
          );
          handleExistingErrors(true);
        }

        if (
          parseInt(input2SpanMin) > 100000 ||
          parseInt(input2SpanMin) < -100000
        ) {
          setInput2SpanMinErrors(
            "Input 2 Span Min must be between -100000 and 100000"
          );
          handleExistingErrors(true);
        }

        if (
          parseInt(input2SpanMax) > 100000 ||
          parseInt(input2SpanMax) < -100000
        ) {
          setInput2SpanMaxErrors(
            "Input 2 Span Max must be between -100000 and 100000"
          );
          handleExistingErrors(true);
        }
        break;

      case "input_1_span_max":
        if (!validateWholeNumberString(newValue)) {
          setInput1SpanMaxErrors("Input 1 Span Max must be a whole number");
          handleExistingErrors(true);
        }

        if (
          parseInt(input1SpanMin) > 100000 ||
          parseInt(input1SpanMin) < -100000
        ) {
          setInput1SpanMinErrors(
            "Input 1 Span Min must be between -100000 and 100000"
          );
          handleExistingErrors(true);
        }

        if (parseInt(newValue) > 100000 || parseInt(newValue) < -100000) {
          setInput1SpanMaxErrors(
            "Input 1 Span Max must be between -100000 and 100000"
          );
          handleExistingErrors(true);
        }

        if (
          parseInt(input2SpanMin) > 100000 ||
          parseInt(input2SpanMin) < -100000
        ) {
          setInput2SpanMinErrors(
            "Input 2 Span Min must be between -100000 and 100000"
          );
          handleExistingErrors(true);
        }

        if (
          parseInt(input2SpanMax) > 100000 ||
          parseInt(input2SpanMax) < -100000
        ) {
          setInput2SpanMaxErrors(
            "Input 2 Span Max must be between -100000 and 100000"
          );
          handleExistingErrors(true);
        }
        break;
      case "input_2_span_min":
        if (!validateWholeNumberString(newValue)) {
          setInput2SpanMinErrors("Input 2 Span Min must be a whole number");
          handleExistingErrors(true);
        }

        if (
          parseInt(input1SpanMin) > 100000 ||
          parseInt(input1SpanMin) < -100000
        ) {
          setInput1SpanMinErrors(
            "Input 1 Span Min must be between -100000 and 100000"
          );
          handleExistingErrors(true);
        }

        if (
          parseInt(input1SpanMax) > 100000 ||
          parseInt(input1SpanMax) < -100000
        ) {
          setInput1SpanMaxErrors(
            "Input 1 Span Max must be between -100000 and 100000"
          );
          handleExistingErrors(true);
        }

        if (parseInt(newValue) > 100000 || parseInt(newValue) < -100000) {
          setInput2SpanMinErrors(
            "Input 2 Span Min must be between -100000 and 100000"
          );
          handleExistingErrors(true);
        }

        if (
          parseInt(input2SpanMax) > 100000 ||
          parseInt(input2SpanMax) < -100000
        ) {
          setInput2SpanMaxErrors(
            "Input 2 Span Max must be between -100000 and 100000"
          );
          handleExistingErrors(true);
        }
        break;
      case "input_2_span_max":
        if (!validateWholeNumberString(newValue)) {
          setInput2SpanMaxErrors("Input 2 Span Max must be a whole number");
          handleExistingErrors(true);
        }

        if (
          parseInt(input1SpanMin) > 100000 ||
          parseInt(input1SpanMin) < -100000
        ) {
          setInput1SpanMinErrors(
            "Input 1 Span Min must be between -100000 and 100000"
          );
          handleExistingErrors(true);
        }

        if (
          parseInt(input1SpanMax) > 100000 ||
          parseInt(input1SpanMax) < -100000
        ) {
          setInput1SpanMaxErrors(
            "Input 1 Span Max must be between -100000 and 100000"
          );
          handleExistingErrors(true);
        }

        if (
          parseInt(input2SpanMin) > 100000 ||
          parseInt(input2SpanMin) < -100000
        ) {
          setInput2SpanMinErrors(
            "Input 2 Span Min must be between -100000 and 100000"
          );
          handleExistingErrors(true);
        }

        if (parseInt(newValue) > 100000 || parseInt(newValue) < -100000) {
          setInput2SpanMaxErrors(
            "Input 2 Span Max must be between -100000 and 100000"
          );
          handleExistingErrors(true);
        }
        break;
      default:
        break;
    }
  }

  const [mobilePressureTextActive, setMobilePressureTextActive] =
    useState<boolean>(false);
  const [mobileLevelTextActive, setMobileLevelTextActive] =
    useState<boolean>(false);

  function showPressureText() {
    setMobilePressureTextActive(!mobilePressureTextActive);
  }

  function showLevelText() {
    setMobileLevelTextActive(!mobileLevelTextActive);
  }

  const pressureUnitsRef = useRef<any>(null);
  const levelUnitsRef = useRef<any>(null);
  const pressureInfoButtonRef = useRef<any>(null);
  const levelInfoButtonRef = useRef<any>(null);

  const handleOutsideOfBoxClick = useCallback(
    (e: any) => {
      if (
        pressureUnitsRef.current &&
        !pressureUnitsRef.current?.contains(e.target) &&
        !pressureInfoButtonRef.current?.contains(e.target)
      ) {
        setMobilePressureTextActive(false);
      } else if (
        levelUnitsRef.current &&
        !levelUnitsRef.current?.contains(e.target) &&
        !levelInfoButtonRef.current?.contains(e.target)
      ) {
        setMobileLevelTextActive(false);
      }
    },
    [
      setMobilePressureTextActive,
      pressureUnitsRef,
      pressureInfoButtonRef,
      levelUnitsRef,
      levelInfoButtonRef,
    ]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideOfBoxClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideOfBoxClick);
    };
  }, [handleOutsideOfBoxClick]);

  return (
    <StyledMainDiv data-testname={dataTestname} data-testid="main-div">
      <ConfirmationBox
        dataTestname="swarm-confirmation-box"
        heading={"Information"}
        message={confirmText}
        active={confirmActive}
        onOk={handleConfirm}
        displayCancel={false}
      />
      <StyledConfigurationLabel>Configuration</StyledConfigurationLabel>
      <StyledInputRow>
        <StyledInputLabel>Input 1 Units</StyledInputLabel>
        <StyledInput>
          <CommonInput
            dataTestname="swarm-input-1-units-common-input"
            type="text"
            value={input1Units}
            hideErrorsDiv={true}
            labelText=""
            height={"34px"}
            border={"1px solid #8E8E8E"}
            borderRadius={"2px"}
            textColor={"#767676"}
            onChange={updateInput1Units}
            readOnly={readOnly}
            tabIndex={1}
          />
        </StyledInput>
        {!styleContext.isMobile && (
          <>
            <StyledInputLabel>Temperature Units</StyledInputLabel>
            <StyledInput>
              <Dropdown
                dataTestname="swarm-temperature-units-dropdown"
                columns={temperatureUnitsArray}
                labelText=""
                hideErrorsDiv={true}
                height={"36px"}
                border={"1px solid #8E8E8E"}
                borderRadius={"2px"}
                textColor={"#767676"}
                selected={chosenTemperatureUnits}
                onchange={updateTemperatureChosenUnits}
                readOnly={readOnly}
                tabIndex={7}
              />
            </StyledInput>
          </>
        )}
      </StyledInputRow>
      <StyledInputRow>
        <StyledInputLabel>Input 1 Span Min</StyledInputLabel>
        <StyledInput>
          <CommonInput
            dataTestname="swarm-input-1-span-min-common-input"
            type="text"
            value={input1SpanMin.toString()}
            hideErrorsDiv={true}
            labelText=""
            height={"34px"}
            border={"1px solid #8E8E8E"}
            borderRadius={"2px"}
            textColor={"#767676"}
            onChange={updateInput1SpanMin}
            readOnly={readOnly}
            tabIndex={2}
          />
        </StyledInput>
        {styleContext.isMobile && (
          <StyledErrorMessageRow
            show={input1SpanMinErrors === "" ? false : true}
          >
            <StyledErrorMessage>{input1SpanMinErrors}</StyledErrorMessage>
          </StyledErrorMessageRow>
        )}
        {model === "PFCON-SAT-0001" ? (
          <>
            {!styleContext.isMobile && (
              <>
                <StyledLabelInfoDiv>
                  <StyledInputLabelInfo>Pressure Units</StyledInputLabelInfo>
                  <StyledInfo>
                    <InfoButton
                      dataTestname="swarm-pressure-units-info-button"
                      hoverText={pressureInfoHoverText}
                      hoverWidthsMobile={["150px", "212px", "340px"]}
                      hoverPositionMobile={["28px", "-67px", "unset", "unset"]}
                    />
                  </StyledInfo>
                </StyledLabelInfoDiv>
                <StyledInput>
                  <Dropdown
                    dataTestname="swarm-pressure-units-dropdown"
                    columns={pressureUnitsArray}
                    labelText=""
                    hideErrorsDiv={true}
                    height={"36px"}
                    border={"1px solid #8E8E8E"}
                    borderRadius={"2px"}
                    textColor={"#767676"}
                    selected={chosenPressureUnits}
                    onchange={updateChosenPressureUnits}
                    readOnly={readOnly}
                  />
                </StyledInput>
              </>
            )}
          </>
        ) : (
          !styleContext.isMobile && (
            <>
              <StyledInputLabel></StyledInputLabel>
              <StyledInput></StyledInput>
            </>
          )
        )}
      </StyledInputRow>
      {!styleContext.isMobile && (
        <StyledErrorMessageRow show={input1SpanMinErrors === "" ? false : true}>
          <StyledInputLabel></StyledInputLabel>
          <StyledInput>
            <StyledErrorMessage>{input1SpanMinErrors}</StyledErrorMessage>
          </StyledInput>
          <StyledInputLabel></StyledInputLabel>
          <StyledInput></StyledInput>
        </StyledErrorMessageRow>
      )}
      <StyledInputRow>
        <StyledInputLabel>Input 1 Span Max</StyledInputLabel>
        <StyledInput>
          <CommonInput
            dataTestname="swarm-input-1-span-max-common-input"
            type="text"
            value={input1SpanMax.toString()}
            hideErrorsDiv={true}
            labelText=""
            height={"34px"}
            border={"1px solid #8E8E8E"}
            borderRadius={"2px"}
            textColor={"#767676"}
            onChange={updateInput1SpanMax}
            readOnly={readOnly}
            tabIndex={3}
          />
        </StyledInput>
        {styleContext.isMobile && (
          <StyledErrorMessageRow
            show={input1SpanMaxErrors === "" ? false : true}
          >
            <StyledErrorMessage>{input1SpanMaxErrors}</StyledErrorMessage>
          </StyledErrorMessageRow>
        )}
        {model === "PFCON-SAT-0001" ? (
          <>
            {!styleContext.isMobile && (
              <>
                <StyledLabelInfoDiv>
                  <StyledInputLabelInfo>Level Units</StyledInputLabelInfo>
                  <StyledInfo>
                    <InfoButton
                      dataTestname="swarm-level-units-info-button"
                      hoverText={levelInfoHoverText}
                      hoverWidthsMobile={["150px", "203px", "340px"]}
                      hoverPositionMobile={["28px", "-91px", "unset", "unset"]}
                    />
                  </StyledInfo>
                </StyledLabelInfoDiv>
                <StyledInput>
                  <Dropdown
                    dataTestname="swarm-level-units-dropdown"
                    columns={levelUnitsArray}
                    labelText=""
                    hideErrorsDiv={true}
                    height={"36px"}
                    border={"1px solid #8E8E8E"}
                    borderRadius={"2px"}
                    textColor={"#767676"}
                    selected={chosenLevelUnits}
                    onchange={updateChosenLevelUnits}
                    readOnly={readOnly}
                  />
                </StyledInput>
              </>
            )}
          </>
        ) : (
          !styleContext.isMobile && (
            <>
              <StyledInputLabel></StyledInputLabel>
              <StyledInput></StyledInput>
            </>
          )
        )}
        {styleContext.isMobile && (
          <>
            <StyledInputLabel>Temperature Units</StyledInputLabel>
            <StyledInput>
              <Dropdown
                dataTestname="swarm-temperature-units-dropdown"
                columns={temperatureUnitsArray}
                labelText=""
                hideErrorsDiv={true}
                height={"36px"}
                border={"1px solid #8E8E8E"}
                borderRadius={"2px"}
                textColor={"#767676"}
                selected={chosenTemperatureUnits}
                onchange={updateTemperatureChosenUnits}
                readOnly={readOnly}
                tabIndex={7}
              />
            </StyledInput>
          </>
        )}
        {styleContext.isMobile && model === "PFCON-SAT-0001" && (
          <>
            <StyledLabelInfoDiv>
              <StyledInputLabelInfo>Pressure Units</StyledInputLabelInfo>
              <StyledInfo>
                {mobilePressureTextActive && (
                  <StyledInfoButtonDiv
                    hoverWidthsMobile={["150px", "212px", "340px"]}
                    hoverPositionMobile={["366px", "0px", "unset", "86px"]}
                    ref={pressureUnitsRef}
                  >
                    {pressureInfoHoverText}
                  </StyledInfoButtonDiv>
                )}
                <InfoButton
                  dataTestname="swarm-pressure-units-info-button"
                  hoverText={pressureInfoHoverText}
                  hoverWidthsMobile={["150px", "212px", "340px"]}
                  hoverPositionMobile={["20px", "0px", "unset", "-24px"]}
                  hoverPositionDesktop={["28px", "-67px", "unset", "unset"]}
                  onClickFunction={showPressureText}
                  mobileHoverTextRef={pressureInfoButtonRef}
                ></InfoButton>
              </StyledInfo>
            </StyledLabelInfoDiv>
            <StyledInput>
              <Dropdown
                dataTestname="swarm-pressure-units-dropdown"
                columns={pressureUnitsArray}
                labelText=""
                hideErrorsDiv={true}
                height={"36px"}
                border={"1px solid #8E8E8E"}
                borderRadius={"2px"}
                textColor={"#767676"}
                selected={chosenPressureUnits}
                onchange={updateChosenPressureUnits}
                readOnly={readOnly}
              />
            </StyledInput>
          </>
        )}
        {styleContext.isMobile && model === "PFCON-SAT-0001" && (
          <>
            <StyledLabelInfoDiv>
              <StyledInputLabelInfo>Level Units</StyledInputLabelInfo>
              <StyledInfo>
                {mobileLevelTextActive && (
                  <StyledInfoButtonDiv
                    hoverWidthsMobile={["150px", "203px", "340px"]}
                    hoverPositionMobile={["432px", "0px", "unset", "95px"]}
                    ref={levelUnitsRef}
                  >
                    {levelInfoHoverText}
                  </StyledInfoButtonDiv>
                )}
                <InfoButton
                  dataTestname="swarm-level-units-info-button"
                  hoverText={levelInfoHoverText}
                  hoverWidthsMobile={["150px", "203px", "340px"]}
                  hoverPositionMobile={["20px", "0px", "unset", "0px"]}
                  hoverPositionDesktop={["28px", "-91px", "unset", "unset"]}
                  onClickFunction={showLevelText}
                  mobileHoverTextRef={levelInfoButtonRef}
                />
              </StyledInfo>
            </StyledLabelInfoDiv>
            <StyledInput>
              <Dropdown
                dataTestname="swarm-level-units-dropdown"
                columns={levelUnitsArray}
                labelText=""
                hideErrorsDiv={true}
                height={"36px"}
                border={"1px solid #8E8E8E"}
                borderRadius={"2px"}
                textColor={"#767676"}
                selected={chosenLevelUnits}
                onchange={updateChosenLevelUnits}
                readOnly={readOnly}
              />
            </StyledInput>
          </>
        )}
      </StyledInputRow>
      {!styleContext.isMobile && (
        <>
          <StyledErrorMessageRow
            show={input1SpanMaxErrors === "" ? false : true}
          >
            <StyledInputLabel></StyledInputLabel>
            <StyledInput>
              <StyledErrorMessage>{input1SpanMaxErrors}</StyledErrorMessage>
            </StyledInput>
            <StyledInputLabel></StyledInputLabel>
            <StyledInput></StyledInput>
          </StyledErrorMessageRow>
          <StyledSpacerRow></StyledSpacerRow>
        </>
      )}
      <StyledInputRow>
        <StyledInputLabel>Input 2 Units</StyledInputLabel>
        <StyledInput>
          <CommonInput
            dataTestname="swarm-input-2-units-common-input"
            type="text"
            value={input2Units}
            hideErrorsDiv={true}
            labelText=""
            height={"34px"}
            border={"1px solid #8E8E8E"}
            borderRadius={"2px"}
            textColor={"#767676"}
            onChange={updateInput2Units}
            readOnly={readOnly}
            tabIndex={4}
          />
        </StyledInput>
        {!styleContext.isMobile && (
          <>
            <StyledInputLabel></StyledInputLabel>
            <StyledInput></StyledInput>
          </>
        )}
      </StyledInputRow>
      <StyledInputRow>
        <StyledInputLabel>Input 2 Span Min</StyledInputLabel>
        <StyledInput>
          <CommonInput
            dataTestname="swarm-input-2-span-min-common-input"
            type="text"
            value={input2SpanMin.toString()}
            hideErrorsDiv={true}
            labelText=""
            height={"34px"}
            border={"1px solid #8E8E8E"}
            borderRadius={"2px"}
            textColor={"#767676"}
            onChange={updateInput2SpanMin}
            readOnly={readOnly}
            tabIndex={5}
          />
        </StyledInput>
        {!styleContext.isMobile && (
          <>
            <StyledInputLabel></StyledInputLabel>
            <StyledInput></StyledInput>
          </>
        )}
      </StyledInputRow>
      {styleContext.isMobile && (
        <>
          <StyledErrorMessageRow
            show={input2SpanMinErrors === "" ? false : true}
          >
            <StyledErrorMessage>{input2SpanMinErrors}</StyledErrorMessage>
          </StyledErrorMessageRow>
        </>
      )}
      {!styleContext.isMobile && (
        <>
          <StyledErrorMessageRow
            show={input2SpanMinErrors === "" ? false : true}
          >
            <StyledInputLabel></StyledInputLabel>
            <StyledInput>
              <StyledErrorMessage>{input2SpanMinErrors}</StyledErrorMessage>
            </StyledInput>
            <StyledInputLabel></StyledInputLabel>
            <StyledInput></StyledInput>
          </StyledErrorMessageRow>
        </>
      )}
      <StyledInputRow>
        <StyledInputLabel>Input 2 Span Max</StyledInputLabel>
        <StyledInput>
          <CommonInput
            dataTestname="swarm-input-2-span-max-common-input"
            type="text"
            value={input2SpanMax.toString()}
            hideErrorsDiv={true}
            labelText=""
            height={"34px"}
            border={"1px solid #8E8E8E"}
            borderRadius={"2px"}
            textColor={"#767676"}
            onChange={updateInput2SpanMax}
            readOnly={readOnly}
            tabIndex={6}
          />
        </StyledInput>
        {!styleContext.isMobile && (
          <>
            <StyledInputLabel></StyledInputLabel>
            <StyledInput></StyledInput>
          </>
        )}
      </StyledInputRow>
      {styleContext.isMobile && (
        <>
          <StyledErrorMessageRow
            show={input2SpanMaxErrors === "" ? false : true}
          >
            <StyledErrorMessage>{input2SpanMaxErrors}</StyledErrorMessage>
          </StyledErrorMessageRow>
        </>
      )}
      {!styleContext.isMobile && (
        <>
          <StyledErrorMessageRow
            show={input2SpanMaxErrors === "" ? false : true}
          >
            <StyledInputLabel></StyledInputLabel>
            <StyledInput>
              <StyledErrorMessage>{input2SpanMaxErrors}</StyledErrorMessage>
            </StyledInput>
            <StyledInputLabel></StyledInputLabel>
            <StyledInput></StyledInput>
          </StyledErrorMessageRow>
          <StyledSpacerRow />
        </>
      )}
      <StyledCheckboxArea>
        <StyledPF2200SBInnerDiv>
          <StyledInstructionText>
            Select the elements to display on the charts page from the lists
            below.
          </StyledInstructionText>
          <StyledInstructionSubText>
            Please select at least one option from Live Data and one option from
            Historical Data.
          </StyledInstructionSubText>
          <StyledCheckboxGroupsDiv>
            <StyledCheckboxGroupDiv data-testid="live-div">
              <StyledCheckboxGroupHeader>Live Data</StyledCheckboxGroupHeader>
              {current_configs.map((liveChartDatum, index) => (
                <Checkbox
                  dataTestname="swarm-live-data-checkbox"
                  key={"live" + index}
                  arrayLetter={"L"}
                  handleChange={(e: any) => handleCheckbox(e, "L")}
                  isChecked={liveChartDatum.active}
                  label={liveChartDatum.name}
                  readOnly={readOnly}
                />
              ))}
            </StyledCheckboxGroupDiv>
            <StyledCheckboxGroupDiv data-testid="historical-div">
              <StyledCheckboxGroupHeader>
                Historical Data
              </StyledCheckboxGroupHeader>
              {historical_configs.map((historicalChartDatum, index) => (
                <Checkbox
                  dataTestname="swarm-historical-data-checkbox"
                  key={"historical" + index}
                  arrayLetter={"H"}
                  handleChange={(e: any) => handleCheckbox(e, "H")}
                  isChecked={historicalChartDatum.active}
                  label={historicalChartDatum.name}
                  readOnly={readOnly}
                />
              ))}
            </StyledCheckboxGroupDiv>
          </StyledCheckboxGroupsDiv>
        </StyledPF2200SBInnerDiv>
      </StyledCheckboxArea>
    </StyledMainDiv>
  );
};

export default SWARMInputArea;

const StyledMainDiv = styled.div`
  width: Calc(100%);
  background-color: #ffffff;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    width: Calc(100% - (46px * 2));
    padding: 16px 46px 15px 46px;
  }
`;

const StyledConfigurationLabel = styled.label`
  font-size: ${(props) => props.theme.headingMainSize};
  font-weight: ${(props) => props.theme.headingMainWeight};
  color: #292929;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  margin-bottom: 19px;
`;

const StyledInputRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    flex-direction: row;
    margin-bottom: 10px;
  }
`;

interface ErrorMessageRowProps {
  show: boolean;
}

const StyledErrorMessageRow = styled.div<ErrorMessageRowProps>`
  width: 100%;
  display: ${(props) => (props.show === true ? "flex" : "none")};
  flex-direction: row;
  justify-content: start;
  margin-bottom: 10px;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    height: 34px;
  }
`;

const StyledInputLabel = styled.label`
  width: 100%;
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
  color: #767676;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    width: 27.727%;
  }
`;

const StyledInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
  }

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    width: 28.67%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 131px;
    margin-bottom: 0px;
  }
`;

const StyledSpacerRow = styled.div`
  width: 100%;
  height: 35px;
  display: flex;
  flex-direction: row;
  justify-content: start;
`;

const StyledCheckboxArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin-top: 10px;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    margin-top: 0px;
    margin-bottom: 10px;
  }
`;

const StyledPF2200SBInnerDiv = styled.div`
  width: Calc(100%);
  border-radius: 0px 0px 4px 4px;
`;

const StyledInstructionText = styled.p`
  text-align: left;
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
  margin: 0px;
  width: 100%;
  margin-bottom: 5px;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    margin-bottom: 0px;
  }
`;

const StyledInstructionSubText = styled.p`
  text-align: left;
  font-size: ${(props) => props.theme.contentMapSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
  color: ${(props) => props.theme.subLabelTextColor};
  margin: 0px;
  margin-bottom: 10px;
  width: 100%;
`;

const StyledCheckboxGroupHeader = styled.h2`
  width: 100%;
  text-align: left;
  font-size: ${(props) => props.theme.headingSecondarySize};
  font-weight: ${(props) => props.theme.headingSecondaryWeight};
  margin: 0px;
  margin-bottom: 23px;
`;

const StyledCheckboxGroupsDiv = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    display: flex;
    flex-direction: row;
  }
`;

const StyledCheckboxGroupDiv = styled.div`
  width: 100%;
  margin-bottom: 15px;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    width: 50%;
    margin-bottom: 0px;
  }
`;

const StyledErrorMessage = styled.div`
  text-align: left;
  color: #9c1b30;
  font-size: ${(props) => props.theme.contentErrorSize};
  font-weight: ${(props) => props.theme.contentErrorWeight};
  width: 100%;
`;

const StyledInfo = styled.div`
  margin-left: 15px;
  width: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledInputLabelInfo = styled.label`
  width: fit-content;
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
  color: #767676;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
`;

const StyledLabelInfoDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    width: 27.727%;
  }
`;

interface MobileInfoButtonProps {
  hoverText?: string;
  hoverPositionMobile: string[];
  hoverWidthsMobile: string[];
}

const StyledInfoButtonDiv = styled.div<MobileInfoButtonProps>`
  content: "${(props) => props.hoverText}";
  visibility: visible;
  opacity: 1;
  min-width: ${(props) => props.hoverWidthsMobile[0]};
  width: ${(props) => props.hoverWidthsMobile[1]};
  background-color: #aaa;
  color: #fff;
  text-align: center;
  border-radius: 3px;
  padding: 10px;
  transition: opacity 0.5s ease-in-out;
  text-align: left;
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};

  position: absolute;
  z-index: 130;
  margin-top: 78px;
  left: 10px;
`;
