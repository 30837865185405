import { Configuration, PopupRequest, RedirectRequest } from "@azure/msal-browser";

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID!,
    authority:
    process.env.REACT_APP_AUTHORITY!,
    redirectUri: process.env.REACT_APP_PORTAL_URL,
    postLogoutRedirectUri: process.env.REACT_APP_PORTAL_URL,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

export const loginRequest: PopupRequest = {
  scopes: [process.env.REACT_APP_SCOPE!],
};

export const loginRedirectRequest: RedirectRequest = {
  scopes: [process.env.REACT_APP_SCOPE!], redirectStartPage: "/sites"
};

// this is used to access files that pertain to the user
// export const apiRequest : PopupRequest = {
//   scopes: [""]
// }

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
