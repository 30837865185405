import React, { useReducer } from "react";
import { ActionTypes, ISiteContext, ISiteState } from "./types";
import SiteReducer from "./SiteReducer";
import SiteContext from "./SiteContext";
import axios from "axios";
import { getToken } from "../../utilities/msTokenFunc";

const SiteProvider: React.FC<ISiteContext> = (props) => {
  const initialState: ISiteState = {
    sites: [],
    site: null,
    siteBelongsToUserCompany: null,
    loading: true,
  };

  const [state, dispatch] = useReducer(SiteReducer, initialState);

  const setSites = async () => {
    try {
      dispatch({ type: ActionTypes.SENDING_REQUEST });
      const token: string = await getToken();
      await axios
        .get(`${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/site`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) =>
          dispatch({ type: ActionTypes.SET_SITES, payload: res.data })
        );
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    } catch (error) {
      dispatch({ type: ActionTypes.SET_SITES, payload: [] });
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    }
  };

  const setSite = async (id: number) => {
    try {
      dispatch({ type: ActionTypes.SENDING_REQUEST });
      const token: string = await getToken();
      await axios
        .get(`${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/site/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) =>
          dispatch({ type: ActionTypes.SET_SITE, payload: res.data })
        );
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    } catch (error) {
      dispatch({ type: ActionTypes.SET_SITE, payload: null });
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    }
  };

  const setSitesByCompanyId = async (companyId: number) => {
    try {
      dispatch({ type: ActionTypes.SENDING_REQUEST });
      const token: string = await getToken();
      await axios
        .get(
          `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/site/bycompany/${companyId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) =>
          dispatch({ type: ActionTypes.SET_SITES, payload: res.data })
        );
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    } catch (error) {
      dispatch({ type: ActionTypes.SET_SITES, payload: [] });
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    }
  };

  const setSiteBelongsToUserCompany = async (
    siteId: number,
    azureId: string,
    isProfireUser: boolean
  ) => {
    try {
      dispatch({ type: ActionTypes.SENDING_REQUEST });

      if (isProfireUser) {
        dispatch({
          type: ActionTypes.SET_SITE_BELONGS_TO_USER_COMPANY,
          payload: true,
        });
      } else {
        const token: string = await getToken();
        await axios
          .get(
            `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/user/verifyusersite/${siteId}/${azureId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            dispatch({
              type: ActionTypes.SET_SITE_BELONGS_TO_USER_COMPANY,
              payload: res.data,
            });
          });
      }

      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    } catch (error) {
      dispatch({
        type: ActionTypes.SET_SITE_BELONGS_TO_USER_COMPANY,
        payload: false,
      });
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    }
  };

  return (
    <SiteContext.Provider
      value={{
        sites: state.sites,
        site: state.site,
        siteBelongsToUserCompany: state.siteBelongsToUserCompany,
        loading: state.loading,
        setSites: setSites,
        setSite: setSite,
        setSitesByCompanyId: setSitesByCompanyId,
        setSiteBelongsToUserCompany: setSiteBelongsToUserCompany,
      }}
    >
      {props.children}
    </SiteContext.Provider>
  );
};

export default SiteProvider;
