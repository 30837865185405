import { createContext } from "react";
import { IControlUnitContext } from "./types";

const defaultControlUnitContext: IControlUnitContext = {
  controlUnits: [],
  controlUnit: null,
  controlUnitBelongsToUserCompany: null,
  loading: true,
  setControlUnits: () => null,
  setControlUnit: () => null,
  setControlUnitsByProcessUnitId: () => null,
  setControlUnitsByMonitorUnitId: () => null,
  setControlUnitBelongsToUserCompany: () => null,
  setControlUnitsByCompany: () => null,
};

const ControlUnitContext = createContext<IControlUnitContext>(
  defaultControlUnitContext
);

export default ControlUnitContext;
