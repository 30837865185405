import React, { useReducer } from "react";
import { ActionTypes, IContactPointContext, IContactPointState } from "./types";
import ContactPointReducer from "./ContactPointReducer";
import axios from "axios";
import { getToken } from "../../utilities/msTokenFunc";
import ContactPointContext from "./ContactPointContext";

const ContactPointProvider: React.FC<IContactPointContext> = (props) => {
  const initialState: IContactPointState = {
    contactPoint: null,
    contactPoints: [],
    loading: true,
  };

  const [state, dispatch] = useReducer(ContactPointReducer, initialState);

  const setContactPoint = async (id: number) => {
    if (id === 0) {
      dispatch({ type: ActionTypes.SET_CONTACT_POINT, payload: null });
    } else {
      try {
        dispatch({ type: ActionTypes.SENDING_REQUEST });
        const token: string = await getToken();
        await axios
          .get(
            `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/contactpoint/${id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            dispatch({
              type: ActionTypes.SET_CONTACT_POINT,
              payload: res.data,
            });
          });
        dispatch({ type: ActionTypes.REQUEST_FINISHED });
      } catch (error) {
        dispatch({ type: ActionTypes.SET_CONTACT_POINT, payload: null });

        dispatch({ type: ActionTypes.REQUEST_FINISHED });
      }
    }
  };

  const setContactPointsByCompany = async (companyId: number) => {
    try {
      dispatch({ type: ActionTypes.SENDING_REQUEST });
      const token: string = await getToken();
      await axios
        .get(
          `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/contactpoint/bycompany/${companyId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) =>
          dispatch({
            type: ActionTypes.SET_CONTACT_POINTS_BY_COMPANY,
            payload: res.data,
          })
        );
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    } catch (error) {
      dispatch({
        type: ActionTypes.SET_CONTACT_POINTS_BY_COMPANY,
        payload: [],
      });
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    }
  };

  return (
    <ContactPointContext.Provider
      value={{
        contactPoint: state.contactPoint,
        contactPoints: state.contactPoints,
        loading: state.loading,
        setContactPoint: setContactPoint,
        setContactPointsByCompany: setContactPointsByCompany,
      }}
    >
      {props.children}
    </ContactPointContext.Provider>
  );
};

export default ContactPointProvider;
