import React, { useContext, useState } from "react";
import styled from "styled-components";
import Banner from "../common/headers/Banner";
import { ICompanyCreateDTO } from "../../types/companies";
import UserContext from "../../stores/users/UserContext";
import { validatePhoneString } from "../../utilities/validationFunctions";
import { validateTextString } from "../../utilities/validationFunctions";
import CommonInput from "../common/formInputs/CommonInput";
import ProfireButton from "../common/buttons/ProfireButton";
import ModalBox from "../common/modals/ModalBox";
import { dateToStringNoTime } from "../../utilities/timeFunctions";

interface AddCompanyProps {
  active: boolean;
  onCancel?: () => void;
  onAdd: Function;
  retainedData: ICompanyCreateDTO | undefined;
}

let existingErrors: Boolean = false;

const AddCompany: React.FC<AddCompanyProps> = ({
  active,
  onCancel,
  onAdd,
  retainedData,
}) => {
  const userContext = useContext(UserContext);

  let headingText: string = "Invalid Operation";
  let text: string =
    "You do not have sufficient privilages to add a new company.";

  const [name, setName] = useState<string>(
    retainedData === undefined ? "" : retainedData.name
  );
  const [nameErrors, setNameErrors] = useState<string>("");
  const handleName = (e: React.FormEvent<HTMLInputElement>) => {
    if (
      !validateTextString(e.currentTarget.value) &&
      e.currentTarget.value !== ""
    ) {
      setName(e.currentTarget.value);
      setNameErrors(
        "First character must be alphanumeric and only alphanumeric, spaces, and the following are supported after: | _ : , . # - / '"
      );
    } else {
      setName(e.currentTarget.value);
      setNameErrors("");
    }
  };

  const [dateJoined, setDateJoined] = useState<string>(
    retainedData === undefined
      ? dateToStringNoTime(new Date().toString())
      : dateToStringNoTime(retainedData.date_joined)
  );
  const [dateJoinedErrors, setDateJoinedErrors] = useState<string>("");
  const handleDateJoined = (e: React.FormEvent<HTMLInputElement>) => {
    setDateJoined(e.currentTarget.value);
    setDateJoinedErrors("");
  };

  const [address, setAddress] = useState<string>(
    retainedData === undefined ? "" : retainedData.address
  );
  const [addressErrors, setAddressErrors] = useState<string>("");
  const handleAddress = (e: React.FormEvent<HTMLInputElement>) => {
    if (
      !validateTextString(e.currentTarget.value) &&
      e.currentTarget.value !== ""
    ) {
      setAddress(e.currentTarget.value);
      setAddressErrors(
        "First character must be alphanumeric and only alphanumeric, spaces, and the following are supported after: | _ : , . # - / '"
      );
    } else {
      setAddress(e.currentTarget.value);
      setAddressErrors("");
    }
  };

  const [phone, setPhone] = useState<string>(
    retainedData === undefined ? "" : retainedData.phone
  );
  const [phoneErrors, setPhoneErrors] = useState<string>("");
  const handlePhone = (e: React.FormEvent<HTMLInputElement>) => {
    setPhone(e.currentTarget.value);
    setPhoneErrors("");
  };

  function handleCancel() {
    existingErrors = false;
    onCancel!();
  }

  function handleExistingErrors(errors: Boolean) {
    existingErrors = errors;
  }

  function clearErrors() {
    setNameErrors("");
    setDateJoinedErrors("");
    setAddressErrors("");
    setPhoneErrors("");

    handleExistingErrors(false);
  }

  function ErrorHandler() {
    clearErrors();

    if (!validateTextString(name)) {
      setNameErrors("Company Name is required.\n");
      handleExistingErrors(true);
    }
    if (name !== "") {
      if (!validateTextString(name)) {
        setNameErrors(
          "First character must be alphanumeric and only alphanumeric, spaces, and the following are supported after: | _ : , . # - / '"
        );
        handleExistingErrors(true);
      }
    }
    if (!dateJoined) {
      setDateJoinedErrors("Date Joined is required.\n");
      handleExistingErrors(true);
    }
    if (new Date(dateJoined) > new Date()) {
      setDateJoinedErrors("Date Joined must be today or before today.\n");
      handleExistingErrors(true);
    }
    if (address !== "") {
      if (!validateTextString(address)) {
        setAddressErrors(
          "First character must be alphanumeric and only alphanumeric, spaces, and the following are supported after: | _ : , . # - / '"
        );
        handleExistingErrors(true);
      }
    }
    if (!phone) {
      setPhoneErrors("Company phone is required.\n");
      handleExistingErrors(true);
    }

    if (phone) {
      if (!validatePhoneString(phone)) {
        setPhoneErrors("Please enter a valid 10 digit phone number.");
        handleExistingErrors(true);
      }
    }
  }

  function onSubmit(e: any) {
    e.preventDefault();
    if (!existingErrors) {
      const newCompany: ICompanyCreateDTO = {
        name: name,
        date_joined: new Date(dateJoined),
        address: address,
        phone: phone,
      };
      onAdd(newCompany);
    }
  }

  return (
    <ModalBox
      dataTestname="add-company-modal-box"
      active={active}
      desktopContainerMinWidth={"765px"}
      mobileContainerMinWidth={"320px"}
      containerTitle="Add Company"
    >
      <StyledAddCompanyDiv>
        {!userContext.permissions?.profire_manage_company && (
          <div data-testid="banner-div">
            <Banner
              dataTestname="add-company-banner"
              headingtext={headingText}
              text={text}
              redBox={true}
            />
          </div>
        )}
        {userContext.permissions?.profire_manage_company && (
          <StyledMainDiv data-testid="main-div">
            <StyledForm onSubmit={onSubmit}>
              <StyledSectionDiv>
                <CommonInput
                  dataTestname="add-company-name-common-input"
                  type={"text"}
                  labelText={"Name"}
                  required={true}
                  readOnly={false}
                  value={name}
                  onChange={handleName}
                  maxLength={50}
                  placeholder={"Company Name"}
                  errors={nameErrors}
                  autoFocus={true}
                />
                <CommonInput
                  dataTestname="add-company-date-common-input"
                  type={"date"}
                  labelText={"Date Joined"}
                  required={true}
                  readOnly={false}
                  value={dateJoined}
                  onChange={handleDateJoined}
                  placeholder={"Date Joined"}
                  errors={dateJoinedErrors}
                />
                <CommonInput
                  dataTestname="add-company-address-common-input"
                  type={"text"}
                  labelText={"Address"}
                  required={false}
                  readOnly={false}
                  value={address}
                  onChange={handleAddress}
                  maxLength={50}
                  placeholder={"Company Address"}
                  errors={addressErrors}
                />
                <CommonInput
                  dataTestname="add-company-phone-common-input"
                  type={"text"}
                  labelText={"Phone"}
                  subLabelText={
                    "Phone numbers should be 10 digits without spaces or dashes (i.e., 1234567890)"
                  }
                  required={true}
                  readOnly={false}
                  value={phone}
                  onChange={handlePhone}
                  maxLength={50}
                  placeholder={"Company Phone"}
                  errors={phoneErrors}
                />
                {existingErrors && (
                  <StyledErrorMessage data-testid="submit-errors">
                    {"Please fix above errors and resubmit."}
                  </StyledErrorMessage>
                )}
                <StyledButtonRow>
                  <StyledCancelButtonHolder>
                    <ProfireButton
                      dataTestname="add-company-cancel-button"
                      text="Cancel"
                      size={3}
                      onClickFunction={handleCancel}
                    />
                  </StyledCancelButtonHolder>
                  <StyledSaveButtonHolder>
                    <ProfireButton
                      dataTestname="add-company-save-button"
                      text="Save"
                      size={3}
                      onClickFunction={ErrorHandler}
                      defaultButton={true}
                    />
                  </StyledSaveButtonHolder>
                </StyledButtonRow>
              </StyledSectionDiv>
            </StyledForm>
          </StyledMainDiv>
        )}
      </StyledAddCompanyDiv>
    </ModalBox>
  );
};

export default AddCompany;

const StyledAddCompanyDiv = styled.div`
  width: calc(100% - 10px);
  height: 100%;
  overflow-y: auto;
  padding-right: 10px;
`;

const StyledMainDiv = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledForm = styled.form``;

const StyledErrorMessage = styled.div`
  text-align: left;
  color: #9c1b30;
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
`;

const StyledSectionDiv = styled.div`
  width: Calc(100%);
  background-color: #ffffff;
  border-radius: 10px;
  text-align: left;
`;

const StyledButtonRow = styled.div`
  width: 100%;
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

const StyledCancelButtonHolder = styled.div`
  margin-right: 20px;
`;

const StyledSaveButtonHolder = styled.div``;
