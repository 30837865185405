import React from "react";
import styled from "styled-components";
import { FormatHeader } from "../../../utilities/tableHeaderFix";

//  Collapsible Link Table Header Component Instructions
//  This component is NOT meant to be used on its own. It is included in CollapsibleLinkTable.tsx
//  This component takes the headers and overwriteHeaders passed into the Table component and creates a header row using them.
//  If the styling is changed in here, the styling will also likely need to be changed in CollapsibleLinkTableRow.tsx, especially if it is width adjustment.

interface CollapsibleLinkTableHeaderProps {
  dataTestname: string;
  headers: string[];
  overwriteHeaders?: string[];
}

const CollapsibleLinkTableHeader: React.FC<CollapsibleLinkTableHeaderProps> = ({
  dataTestname,
  headers,
  overwriteHeaders,
}) => {
  return (
    <StyledCollapsibleLinkTableHeader
      data-testname={dataTestname}
      data-testid="collapsible-table-header"
      key={"tableheader"}
    >
      <StyledRowTh data-testid="header-th" key={"header-row-th"}>
        <StyledDiv data-testid="header-th-div" key={"header-div"}>
          {headers.map((header, index) =>
            index === 0 ? (
              <div key={header + index + "zero div"}>
                <StyledInnerDivTh
                  data-testid="inner-div"
                  key={header + index + "StatusSpacer"}
                >
                  &nbsp;
                </StyledInnerDivTh>
                <StyledMainTableStatusDiv
                  data-testid="main-arrow-inner-div"
                  key={header + index + "Status"}
                >
                  <StyledStatus key={"status"}></StyledStatus>
                </StyledMainTableStatusDiv>
              </div>
            ) : header.includes(".") ? (
              <StyledInnerDivTh
                data-testid="inner-div"
                key={header + index + "property"}
              >
                {overwriteHeaders
                  ? FormatHeader(overwriteHeaders[index])
                  : FormatHeader(header.split(".")[1])}
              </StyledInnerDivTh>
            ) : (
              <StyledInnerDivTh data-testid="inner-div" key={header + index}>
                {overwriteHeaders
                  ? FormatHeader(overwriteHeaders[index])
                  : FormatHeader(header)}
              </StyledInnerDivTh>
            )
          )}
          <StyledInnerDivTh key={"spacing"} data-testid="spacing-inner-div">
            &nbsp;
          </StyledInnerDivTh>
        </StyledDiv>
      </StyledRowTh>
    </StyledCollapsibleLinkTableHeader>
  );
};

export default CollapsibleLinkTableHeader;

const StyledCollapsibleLinkTableHeader = styled.tr`
  width: 100%;
  text-align: left;

  div {
    font-size: ${(props) => props.theme.contentMainSize};
    font-weight: ${(props) => props.theme.headingSecondaryWeight};
    word-break: break-word;

    button {
      height: 30px;
    }
  }
`;

const StyledRowTh = styled.th`
  width: 100%;
  padding: 0;
`;

const StyledDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  padding-bottom: 5px;
  border-bottom: 1px solid #e5e5e5;

  > div:first-child {
    display: flex;
    width: 47px;

    @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
      width: 67px;
    }
  }

  > div:nth-child(2) {
    padding-right: 10px;
    width: Calc(100% - 123px);

    @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
      width: Calc(23.5% - 10px);
    }
  }

  > div:nth-child(3) {
    width: Calc(76.5% - 163px);
    padding-right: 10px;
    display: none;

    @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
      display: flex;
    }
  }

  > div:last-child {
    width: 66px;
    display: none;

    @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
      display: flex;
      width: 86px;
    }
  }
`;

const StyledInnerDivTh = styled.div`
  text-align: left;
`;

const StyledMainTableStatusDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 10px;
  margin-left: 10px;
  margin-right: 10px;
`;

const StyledStatus = styled.span`
  width: 10px;
  height: 10px;
`;
