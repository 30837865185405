import axios from "axios";
import { ExportToCsv } from "export-to-csv";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Banner from "../components/common/headers/Banner";
import Dropdown from "../components/common/formInputs/Dropdown";
import Loading from "../components/common/modals/Loading";
import DailyAggregateContext from "../stores/dailyAggregates/DailyAggregateContext";
import UserContext from "../stores/users/UserContext";
import { IControlUnit, IControlUnitAllInclusive } from "../types/controlUnits";
import {
  IPF2200SBFields,
  IControlUnitConfigUpdateDTO,
  IGenericConfigDTO,
  IGenericConfigUpdateDTO,
  IPF2100ConfigDTO,
  IPF2100ConfigUpdateDTO,
  ISWARMConfigDTO,
  ISWARMSettingsDTO,
  ISWARMConfigUpdateDTO,
} from "../types/controlUnitConfig";
import {
  IDailyAggregate,
  IDailyAggregateNoIDs,
} from "../types/dailyAggregates";
import { getToken } from "../utilities/msTokenFunc";
import { validateTextString } from "../utilities/validationFunctions";
import Breadcrumb from "../components/common/navigation/Breadcrumbs";
import { IBreadcrumbItem } from "../types/breadcrumb";
import CommonInput from "../components/common/formInputs/CommonInput";
import DefaultContainer from "../components/common/containers/DefaultContainer";
import DeleteButton from "../components/common/buttons/DeleteButton";
import EditButton from "../components/common/buttons/EditButton";
import InfoDisplay from "../components/common/tables/InfoDisplay";
import ProfireButton from "../components/common/buttons/ProfireButton";
import CollapsibleDiv from "../components/common/containers/CollapsibleDiv";
import { openInNewTab } from "../utilities/secureNewTab";
import CommonCustomizableTable from "../components/common/tables/CommonCustomizableTable";
import AddAlert from "../components/alerts/AddAlert";
import AlertHistory from "../components/alerts/AlertHistory";
import CollapsibleRow from "../components/common/containers/CollapsibleRow";
import GenericDeviceInputArea from "../components/common/formInputs/GenericDeviceInputArea";
import {
  createNewAlert,
  deleteAlert,
  editAlert,
  setAlertLogsFromAPI,
  setGrafanaAlertFieldsFromAPI,
  setGrafanaAlertsFromAPI,
} from "../utilities/apiCalls/controlUnitAlerts";
import {
  IGrafanaAlert,
  IGrafanaAlertCreateDTO,
  IGrafanaAlertFields,
  IGrafanaAlertLog,
} from "../types/alerts";
import EditAlert from "../components/alerts/EditAlert";
import {
  deleteControlUnit,
  editControlUnit,
  editControlUnitTag,
  getRawDataByControlUnit,
  setControlUnitBelongsToUserCompanyFromAPI,
  setControlUnitFromAPI,
  // setSupportedTypesFromAPI,
} from "../utilities/apiCalls/controlUnits";
import EditControlUnit from "../components/controlUnits/EditControlUnit";
import ConfirmationBox from "../components/common/modals/ConfirmationBox";
import PF2100InputArea from "../components/common/formInputs/PF2100InputArea";
import SWARMInputArea from "../components/common/formInputs/SWARMInputArea";
import { dateToStringNoTime } from "../utilities/timeFunctions";
import { IExportDataRequest } from "../types/export";
import EmissionsInfo from "../components/controlUnits/EmissionsInfo";
import PF2200SBInputArea from "../components/common/formInputs/PF2200SBInputArea";
import {
  backendErrorCodes,
  blankControlUnitAllInclusive,
} from "../utilities/staticObjects";

interface SingleControlUnitProps {}

let existingErrors: Boolean = false;

const SingleControlUnit: React.FC<SingleControlUnitProps> = () => {
  const dailyAggregateContext = useContext(DailyAggregateContext);
  const dailyAggregateContextRef = useRef(dailyAggregateContext).current;
  const userContext = useContext(UserContext);
  const userContextRef = useRef(userContext).current;
  const params = useParams();

  let headingText: string = "Invalid Control Unit";
  let text: string =
    "This Control Unit ID is invalid. If you believe this is an error, please contact technical support.";

  const [loading, setLoading] = useState<boolean>(false);
  const [controlUnitBelongsToUserCompany, setControlUnitBelongsToUserCompany] =
    useState<boolean | null>(null);
  const [useEffectLoading, setUseEffectLoading] = useState<boolean>(true);
  const [controlUnit, setControlUnit] = useState<IControlUnitAllInclusive>();
  const navigate = useNavigate();
  const [supportedTypes] = useState<string[]>([
    "ADAM-4118",
    "PF2200-SB",
    "DAT3018",
    "FX30S",
    "PF2100",
    "PFCON-SAT-0000",
    "PFCON-SAT-0001",
    "PFCON-SAT-0002",
  ]);
  const [genericTypes] = useState<string[]>(["ADAM-4118", "DAT3018", "FX30S"]);
  const [SWARMTypes] = useState<string[]>([
    "PFCON-SAT-0000",
    "PFCON-SAT-0001",
    "PFCON-SAT-0002",
  ]);

  //Breadcrumbs states
  const [crumbs, setCrumbs] = useState<IBreadcrumbItem[]>([]);

  //Charts states
  const [liveChartData, setLiveChartData] = useState<IPF2200SBFields[]>([]);
  const [historicalChartData, setHistoricalChartData] = useState<
    IPF2200SBFields[]
  >([]);
  const [genericChartData, setGenericChartData] = useState<IGenericConfigDTO[]>(
    []
  );
  const [pf2100ChartData, setPf2100ChartData] = useState<IPF2100ConfigDTO[]>(
    []
  );
  const [SWARMChartData, setSWARMChartData] = useState<ISWARMConfigDTO>();

  //Alerts states
  const [grafanaAlerts, setGrafanaAlerts] = useState<IGrafanaAlert[]>([]);
  const [grafanaAlertFields, setGrafanaAlertFields] = useState<
    IGrafanaAlertFields[]
  >([]);
  const [grafanaAlertFieldsInvalid, setGrafanaAlertFieldsInvalid] =
    useState<boolean>(false);
  const [grafanaAlertFieldsErrors, setGrafanaAlertFieldsErrors] =
    useState<string>("");

  const [chosenAlertHistory, setChosenAlertHistory] = useState<IGrafanaAlert>();
  const [grafanaAlertHistory, setGrafanaAlertHistory] = useState<
    IGrafanaAlertLog[]
  >([]);
  const [showAlertHistory, setShowAlertHistory] = useState<boolean>(false);

  const [allInputsDisabled, setAllInputsDisabled] = useState<boolean>(true);

  //Emissions Reports states
  const [chosenYear, setChosenYear] = useState<string>(
    new Date().getFullYear().toString()
  );
  const [chosenMonth, setChosenMonth] = useState<string>(
    (new Date().getMonth() + 1).toString()
  );

  //Populate Control Unit state
  useEffect(() => {
    setUseEffectLoading(true);
    setControlUnitBelongsToUserCompanyFromAPI(
      parseInt(params.controlUnitId!),
      userContextRef.user?.azure_id!,
      userContextRef.permissions?.profire_select_company!
    ).then((res: any) => {
      if (res.data === true) {
        setControlUnitBelongsToUserCompany(true);

        setControlUnitFromAPI(parseInt(params.controlUnitId!), setLoading)
          .then((res: any) => {
            if (backendErrorCodes.includes(res.status)) {
              setControlUnitBelongsToUserCompany(false);
              setControlUnit(blankControlUnitAllInclusive);
            } else {
              setControlUnit(res.data);
            }
          })
          .catch(() => {
            setControlUnitBelongsToUserCompany(false);
            setControlUnit(blankControlUnitAllInclusive);
          });
      } else {
        //the CU does not belong to the user's company
        setControlUnitBelongsToUserCompany(false);
        //fill CU with default data to prevent warnings
        setControlUnit(blankControlUnitAllInclusive);
        setUseEffectLoading(false);
      }
    });

    //CURRENTLY THIS RETURNS 2100, 2200-FD, 2200-DB, 3100, 2200-SB, ADAM-4118, DAT3018, FX30S
    //WE DO NOT CURRENTLY SUPPORT ALL OF THESE TYPES, SO I CANNOT USE THIS SOLUTION YET.
    // setSupportedTypesFromAPI(setLoading).then((res) => {
    //   console.log(res);
    // });
  }, [params.controlUnitId, userContextRef]);

  //Check that Control Unit exists, then set Crumbs, Edit Tag, Charts, Alerts, Daily Aggregate states
  useEffect(() => {
    if (
      controlUnit !== null &&
      controlUnit !== undefined &&
      controlUnitBelongsToUserCompany === true
    ) {
      setTag(controlUnit.tag);
      setCrumbs(
        userContextRef.permissions?.profire_select_company
          ? [
              {
                id: controlUnit!.process_unit?.site?.company?.id!,
                title: controlUnit!.process_unit?.site?.company?.name!,
                url: userContextRef.permissions?.profire_select_company
                  ? `/sites/${controlUnit!.process_unit?.site?.company?.id!}`
                  : `/sites`,
                prefix: "Company",
              },
              {
                id: controlUnit!.process_unit?.site?.id!,
                title: controlUnit!.process_unit?.site?.facility_name!,
                url: `/sites/details/${controlUnit!.process_unit?.site?.id}`,
                prefix: "Site",
              },
              {
                id:
                  params.unitName === "processunit"
                    ? controlUnit!.process_unit?.id!
                    : controlUnit?.monitor_unit === null ||
                      controlUnit?.monitor_unit === undefined
                    ? controlUnit!.process_unit?.id!
                    : controlUnit!.monitor_unit?.id!,
                title:
                  params.unitName === "processunit"
                    ? controlUnit!.process_unit?.tag!
                    : controlUnit?.monitor_unit === null ||
                      controlUnit?.monitor_unit === undefined
                    ? controlUnit!.process_unit?.tag!
                    : controlUnit!.monitor_unit?.tag!,
                url:
                  params.unitName === "processunit"
                    ? `/processunits/details/${controlUnit?.process_unit?.id}`
                    : controlUnit?.monitor_unit === null ||
                      controlUnit?.monitor_unit === undefined
                    ? `/processunits/details/${controlUnit?.process_unit?.id}`
                    : `/monitorunits/details/${controlUnit?.monitor_unit?.id}`,
                prefix:
                  params.unitName === "processunit"
                    ? "Appliance"
                    : controlUnit?.monitor_unit === null ||
                      controlUnit?.monitor_unit === undefined
                    ? "Appliance"
                    : "Monitor Unit",
              },
            ]
          : [
              {
                id: controlUnit!.process_unit?.site?.id!,
                title: controlUnit!.process_unit?.site?.facility_name!,
                url: `/sites/details/${controlUnit!.process_unit?.site?.id}`,
                prefix: "Site",
              },
              {
                id:
                  params.unitName === "processunit"
                    ? controlUnit!.process_unit?.id!
                    : controlUnit?.monitor_unit === null ||
                      controlUnit?.monitor_unit === undefined
                    ? controlUnit!.process_unit?.id!
                    : controlUnit!.monitor_unit?.id!,
                title:
                  params.unitName === "processunit"
                    ? controlUnit!.process_unit?.tag!
                    : controlUnit?.monitor_unit === null ||
                      controlUnit?.monitor_unit === undefined
                    ? controlUnit!.process_unit?.tag!
                    : controlUnit!.monitor_unit?.tag!,
                url:
                  params.unitName === "processunit"
                    ? `/processunits/details/${controlUnit?.process_unit?.id}`
                    : controlUnit?.monitor_unit === null ||
                      controlUnit?.monitor_unit === undefined
                    ? `/processunits/details/${controlUnit?.process_unit?.id}`
                    : `/monitorunits/details/${controlUnit?.monitor_unit?.id}`,
                prefix:
                  params.unitName === "processunit"
                    ? "Appliance"
                    : controlUnit?.monitor_unit === null ||
                      controlUnit?.monitor_unit === undefined
                    ? "Appliance"
                    : "Monitor Unit",
              },
            ]
      );

      if (
        controlUnit!.control_unit_configs?.generic_configs !== null &&
        controlUnit!.control_unit_configs?.generic_configs !== undefined
      ) {
        setAllInputsDisabled(true);
        setGenericChartData(
          JSON.parse(
            JSON.stringify(controlUnit!.control_unit_configs.generic_configs)
          )
        );
        controlUnit.control_unit_configs.generic_configs.forEach(
          (input) =>
            input.input_mode !== "Disabled" && setAllInputsDisabled(false)
        );
      } else if (
        controlUnit!.control_unit_configs?.pf2200sb_configs !== null &&
        controlUnit!.control_unit_configs?.pf2200sb_configs !== undefined
      ) {
        setLiveChartData(
          controlUnit!.control_unit_configs?.pf2200sb_configs?.current_configs!
        );
        setHistoricalChartData(
          controlUnit!.control_unit_configs?.pf2200sb_configs
            ?.historical_configs!
        );
      } else if (
        controlUnit.control_unit_configs?.pf2100_configs !== null &&
        controlUnit.control_unit_configs?.pf2100_configs !== undefined
      ) {
        setPf2100ChartData(controlUnit.control_unit_configs.pf2100_configs);
      } else if (
        controlUnit.control_unit_configs?.sat_configs !== null &&
        controlUnit.control_unit_configs?.sat_configs !== undefined
      ) {
        setSWARMChartData(controlUnit.control_unit_configs.sat_configs);
      }

      setGrafanaAlertsFromAPI(parseInt(params.controlUnitId!), setLoading).then(
        (res: any) => {
          setGrafanaAlerts(res.data);
        }
      );

      setGrafanaAlertFieldsFromAPI(
        parseInt(params.controlUnitId!),
        setLoading
      ).then((res: any) => {
        if (res.status === 404) {
          setGrafanaAlertFieldsInvalid(true);
          setGrafanaAlertFieldsErrors(res.data.message.split("Error: ")[1]);
        } else {
          setGrafanaAlertFields(res.data);
        }
      });
    }
  }, [
    controlUnit,
    params.controlUnitId,
    params.unitName,
    userContextRef,
    controlUnitBelongsToUserCompany,
  ]);

  useEffect(() => {
    //here is the breakdown of the following if statement
    //controlUnit local state must not be null or undefined
    //AND
    //one of the following must also not be null or undefined:
    //live + historical ChartData (this is PF2200-SB and needs a refactor into its own input area in the future)
    //pf2100ChartData (PF2100)
    //genericChartData (includes ADAM-4118, FX30S, DAT3018)
    //SWARMChartData (includes PFCON-SAT-0000, 0001, 0002)
    //the one issue I can see with this is if somehow a unit does not have chart data
    if (
      controlUnit !== null &&
      controlUnit !== undefined &&
      ((liveChartData !== null &&
        liveChartData !== undefined &&
        historicalChartData !== null &&
        historicalChartData !== undefined) ||
        (pf2100ChartData !== null && PF2100InputArea !== undefined) ||
        (genericChartData !== null && genericChartData !== undefined) ||
        (SWARMChartData !== null && SWARMChartData !== undefined))
    ) {
      setUseEffectLoading(false);
    }
  }, [
    liveChartData,
    pf2100ChartData,
    genericChartData,
    historicalChartData,
    SWARMChartData,
    controlUnit,
  ]);

  //Existing Errors functions
  function handleExistingErrors(errors: Boolean) {
    existingErrors = errors;
  }

  //Edit Tag
  const [showEditTag, setShowEditTag] = useState<boolean>(false);
  const [editTagCBActive, setEditTagCBActive] = useState<boolean>(false);
  const [editTagCBText, setEditTagCBText] = useState<string>("");
  const [editTagCBFinalDisplay, setEditTagCBFinalDisplay] =
    useState<boolean>(false);
  const [tag, setTag] = useState<string>(controlUnit?.tag!);
  const [tagErrors, setTagErrors] = useState<string>("");
  const handleTag = (e: React.FormEvent<HTMLInputElement>) => {
    if (
      !validateTextString(e.currentTarget.value) &&
      e.currentTarget.value !== ""
    ) {
      setTag(e.currentTarget.value);
      setTagErrors(
        "First character must be alphanumeric and only alphanumeric, spaces, and the following are supported after: | _ : , . # - / '"
      );
      handleExistingErrors(true);
    } else {
      setTag(e.currentTarget.value);
      setTagErrors("");
      handleExistingErrors(false);
    }
  };

  function handleShowEditTag() {
    setShowEditTag(!showEditTag);
  }

  //This function runs on clicking Save in Edit Tag
  function showEditTagConfirmationBox(e: any) {
    if (!existingErrors) {
      setEditTagCBActive(true);
      setEditTagCBText("Are you sure you want to save these changes?");
    }
  }

  //This function runs on clicking Cancel in Edit Tag
  function hideEditTagConfirmationBox(e: any) {
    setEditTagCBActive(false);
    setEditTagCBText("");
  }

  //This function runs on clicking Yes after clicking Save in Edit Tag
  function handleEditTag() {
    setEditTagCBText("");

    const update: IControlUnit = {
      id: controlUnit?.id!,
      tag: tag,
      comm_address: controlUnit?.comm_address!,
      control_unit_type_id: controlUnit?.control_unit_type_id!,
      monitor_unit_id: controlUnit?.monitor_unit_id!,
      process_unit_id: controlUnit?.process_unit_id!,
    };

    editControlUnitTag(update!).then((res) => {
      if (res.status === 200 || res.status === 202) {
        setEditTagCBText(`Tag successfully edited`);
        setControlUnitAfterEdit();
      } else {
        setEditTagCBText(
          `The following issue was encountered while attempting to edit ${
            controlUnit!.tag
          }:\n\n${res.data.message}`
        );
      }
    });
    setEditTagCBFinalDisplay(true);
  }

  //This function runs on clicking Ok after clicking Yes in Edit Tag
  function completeEditControlUnitTag(e: any) {
    setEditTagCBActive(false);
    setEditTagCBText("");
    setEditTagCBFinalDisplay(false);
    handleShowEditTag();
  }

  //Edit ControlUnit
  const [showEditControlUnit, setShowEditControlUnit] =
    useState<boolean>(false);
  const [editControlUnitCBActive, setEditControlUnitCBActive] =
    useState<boolean>(false);
  const [editControlUnitCBText, setEditControlUnitCBText] =
    useState<string>("");
  const [editControlUnitCBFinalDisplay, setEditControlUnitCBFinalDisplay] =
    useState<boolean>(false);
  //retainedEditControlUnit is the state that persists entered information that is used to repopulate the form in the event that the API Call errors out in the Confirmation Box
  const [retainedEditControlUnit, setRetainedEditControlUnit] = useState<
    IControlUnit | undefined
  >();

  function handleShowEditControlUnit() {
    setShowEditControlUnit(!showEditControlUnit);
  }

  //This function runs on clicking Save in Edit ControlUnit
  function showEditControlUnitConfirmationBox(e: any) {
    setShowEditControlUnit(false);
    setEditControlUnitCBActive(true);
    setEditControlUnitCBText("Are you sure you want to save these changes?");
    setRetainedEditControlUnit(e);
  }

  //This function runs on clicking Cancel in Edit ControlUnit
  function hideEditControlUnitConfirmationBox(e: any) {
    setEditControlUnitCBActive(false);
    setEditControlUnitCBText("");
    setRetainedEditControlUnit(undefined);
  }

  //This function runs on clicking Yes after clicking Save in Edit ControlUnit
  function handleEditControlUnit() {
    setEditControlUnitCBText("");

    editControlUnit(retainedEditControlUnit!).then((res) => {
      if (res.status === 200 || res.status === 202) {
        setEditControlUnitCBText(
          `${retainedEditControlUnit!.tag} successfully edited`
        );
        setControlUnitAfterEdit();
        setRetainedEditControlUnit(undefined);
      } else {
        setEditControlUnitCBText(
          `The following issue was encountered while attempting to edit ${
            retainedEditControlUnit!.tag
          }:\n\n${res.data.message}`
        );
      }
    });
    setEditControlUnitCBFinalDisplay(true);
  }

  //This function runs on clicking Ok after clicking Yes in Edit ControlUnit
  function completeEditControlUnit(e: any) {
    setEditControlUnitCBActive(false);
    setEditControlUnitCBText("");
    setEditControlUnitCBFinalDisplay(false);

    if (retainedEditControlUnit !== undefined) {
      setShowEditControlUnit(true);
    }
  }

  //This function will get the latest information for the Control Unit when called
  function setControlUnitAfterEdit() {
    setControlUnitFromAPI(parseInt(params.controlUnitId!), setLoading).then(
      (res: any) => {
        setControlUnit(res.data);
      }
    );
  }

  //Delete ControlUnit
  const [deleteControlUnitCBActive, setDeleteControlUnitCBActive] =
    useState<boolean>(false);
  const [deleteControlUnitCBText, setDeleteControlUnitCBText] =
    useState<string>("");
  //finalDisplay is the bool that determines when it is time to show the single button when using a 2 button ConfirmationBox
  const [deleteControlUnitCBFinalDisplay, setDeleteControlUnitCBFinalDisplay] =
    useState<boolean>(false);

  //This function runs on clicking Save in Delete ControlUnit
  function showDeleteControlUnitConfirmationBox(e: any) {
    setDeleteControlUnitCBActive(true);
    setDeleteControlUnitCBText(
      `Are you sure you want to delete ${controlUnit?.tag}?`
    );
  }

  //This function runs on clicking Cancel in Delete ControlUnit
  function hideDeleteControlUnitConfirmationBox(e: any) {
    setDeleteControlUnitCBActive(false);
    setDeleteControlUnitCBText("");
  }

  //This function runs on clicking Yes after clicking Save in Delete ControlUnit
  function handleDeleteControlUnit() {
    setDeleteControlUnitCBText("");

    deleteControlUnit(controlUnit?.id!).then((res) => {
      if (res.status === 200 || res.status === 202) {
        setDeleteControlUnitCBText(
          `${
            controlUnit?.tag
          } successfully deleted. You will now be redirected to the ${
            params.unitName === "processunit"
              ? "Appliance page."
              : controlUnit?.monitor_unit === null ||
                controlUnit?.monitor_unit === undefined
              ? "Appliance page."
              : "Monitor Unit page."
          }`
        );
      } else {
        setDeleteControlUnitCBText(
          `The following issue was encountered while attempting to delete ${controlUnit?.tag}:\n\n${res.data.message}`
        );
      }
    });
    setDeleteControlUnitCBFinalDisplay(true);
  }

  //This function runs on clicking Ok after clicking Yes in Edit ControlUnit
  function completeDeleteControlUnit(e: any) {
    setDeleteControlUnitCBActive(false);
    setDeleteControlUnitCBText("");
    setDeleteControlUnitCBFinalDisplay(false);
    navigate(
      params.unitName === "processunit"
        ? `/processunits/details/${controlUnit?.process_unit?.id}`
        : controlUnit?.monitor_unit === null ||
          controlUnit?.monitor_unit === undefined
        ? `/processunits/details/${controlUnit?.process_unit?.id}`
        : `/monitorunits/details/${controlUnit?.monitor_unit?.id}`
    );
  }

  //Edit Charts
  const [editChartCBActive, setEditChartCBActive] = useState<boolean>(false);
  const [editChartCBText, setEditChartCBText] = useState<string>("");
  const [editChartCBFinalDisplay, setEditChartCBFinalDisplay] =
    useState<boolean>(false);
  const [editChartExistingErrors, setEditChartExistingErrors] =
    useState<boolean>(false);

  //This function runs on clicking Save in Edit Chart
  function showEditChartConfirmationBox() {
    setEditChartCBActive(true);
    setEditChartCBText("Are you sure you want to save these changes?");
  }

  //This function runs on clicking Cancel in Edit Chart
  function hideEditChartConfirmationBox() {
    setEditChartCBActive(false);
    setEditChartCBText("");
  }

  //This function runs on clicking Yes after clicking Save in Edit Chart
  async function handleEditChart() {
    setEditChartCBText("");

    existingErrors = false;

    if (genericTypes.includes(controlUnit!.control_unit_type?.model!)) {
      const genericUpdate: IGenericConfigUpdateDTO = {
        control_unit_id: controlUnit!.id,
        config_list: genericChartData,
      };
      try {
        const token: string = await getToken();
        await axios
          .put(
            `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/config/generic`,
            genericUpdate,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            setEditChartCBText("The Control Unit Configs have been updated.");
          })
          .catch((err) => {
            setEditChartCBText(`${err.response.data.message}`);
            existingErrors = true;
          });
        setAlerts();
      } catch (error) {
        setEditChartCBText(
          "There was an issue while updating the Control Unit Configs."
        );
        existingErrors = true;
      }

      setEditChartCBFinalDisplay(true);
    } else if (SWARMTypes.includes(controlUnit!.control_unit_type?.model!)) {
      let liveUpdateString: string = "";
      let historicalUpdateString: string = "";

      SWARMChartData?.current_configs.forEach((datum) => {
        if (datum.active) {
          if (liveUpdateString === "") {
            liveUpdateString += datum.name;
          } else {
            liveUpdateString += `,${datum.name}`;
          }
        }
      });

      SWARMChartData?.historical_configs.forEach((datum) => {
        if (datum.active) {
          if (historicalUpdateString === "") {
            historicalUpdateString += datum.name;
          } else {
            historicalUpdateString += `,${datum.name}`;
          }
        }
      });

      const SWARMUpdate: ISWARMConfigUpdateDTO = {
        control_unit_id: controlUnit!.id,
        settings: SWARMChartData?.settings!,
        active_current_configs: liveUpdateString!,
        active_historical_configs: historicalUpdateString,
      };
      try {
        const token: string = await getToken();
        await axios
          .put(
            `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/config/pfcon-sat`,
            SWARMUpdate,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            setEditChartCBText("The Control Unit Configs have been updated.");
          })
          .catch((err) => {
            setEditChartCBText(`${err.response.data.message}`);
            existingErrors = true;
          });
        setAlerts();
      } catch (error) {
        setEditChartCBText(
          "There was an issue while updating the Control Unit Configs."
        );
        existingErrors = true;
      }

      setEditChartCBFinalDisplay(true);
    } else if (controlUnit!.control_unit_type?.model === "PF2100") {
      const pf2100Update: IPF2100ConfigUpdateDTO = {
        control_unit_id: controlUnit!.id,
        config_list: pf2100ChartData,
      };

      try {
        const token: string = await getToken();
        await axios
          .put(
            `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/config/${controlUnit?.control_unit_type?.model}`,
            pf2100Update,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            setEditChartCBText("The Control Unit Configs have been updated.");
          })
          .catch((err) => {
            setEditChartCBText(`${err.response.data.message}`);
            existingErrors = true;
          });
        setAlerts();
      } catch (error) {
        setEditChartCBText(
          "There was an issue while updating the Control Unit Configs."
        );
        existingErrors = true;
      }

      setEditChartCBFinalDisplay(true);
    } else {
      let liveUpdateString: string = "";
      let historicalUpdateString: string = "";

      liveChartData.forEach((datum) => {
        if (datum.active) {
          if (liveUpdateString === "") {
            liveUpdateString += datum.name;
          } else {
            liveUpdateString += `,${datum.name}`;
          }
        }
      });

      historicalChartData.forEach((datum) => {
        if (datum.active) {
          if (historicalUpdateString === "") {
            historicalUpdateString += datum.name;
          } else {
            historicalUpdateString += `,${datum.name}`;
          }
        }
      });
      const liveUpdate: IControlUnitConfigUpdateDTO = {
        control_unit_id: controlUnit!.id!,
        configs: liveUpdateString,
      };
      const historicalUpdate: IControlUnitConfigUpdateDTO = {
        control_unit_id: controlUnit!.id!,
        configs: historicalUpdateString,
      };

      try {
        const token: string = await getToken();
        await axios
          .put(
            `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/config/${controlUnit?.control_unit_type?.model}-current`,
            liveUpdate,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            //intentionally left empty
          })
          .catch((err) => {
            setEditChartCBText(`${err.response.data.message}`);
            existingErrors = true;
          });
      } catch (error) {
        setEditChartCBText(
          "There was an issue while updating the Control Unit Configs."
        );
        existingErrors = true;
      }

      if (!existingErrors) {
        try {
          const token: string = await getToken();
          await axios
            .put(
              `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/config/${controlUnit?.control_unit_type?.model}-historical`,
              historicalUpdate,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              setEditChartCBText("The Control Unit Configs have been updated.");
            })
            .catch((err) => {
              setEditChartCBText(`${err.response.data.message}`);
              existingErrors = true;
            });
        } catch (error) {
          setEditChartCBText(
            "There was an issue while updating the Control Unit Configs."
          );
          existingErrors = true;
        }
        setAlerts();
      }
      setEditChartCBFinalDisplay(true);
    }
    //Set Control Unit again to update the View Button link
    setControlUnitFromAPI(parseInt(params.controlUnitId!), setLoading).then(
      (res: any) => {
        setControlUnit(res.data);
      }
    );
  }

  //This function runs on clicking OK after clicking OK after clicking Save in Edit Chart
  function completeEditChart() {
    setEditChartCBActive(false);
    setEditChartCBText("");
    setEditChartCBFinalDisplay(false);
  }

  const handleGenericUpdate = (
    field: string,
    input_index: number,
    newValue: any,
    arrayIndex: number
  ) => {
    let data: IGenericConfigDTO[] = [...genericChartData];

    switch (field) {
      case "input_420_span_max":
        data[arrayIndex].input_420_span_max = newValue;
        break;
      case "input_420_span_min":
        data[arrayIndex].input_420_span_min = newValue;
        break;
      case "input_420_units":
        data[arrayIndex].input_420_units = newValue;
        break;
      case "input_index":
        data[arrayIndex].input_index = newValue;
        break;
      case "input_mode":
        data[arrayIndex].input_mode = newValue;
        break;
      case "input_name":
        data[arrayIndex].input_name = newValue;
        break;
      case "input_temperature_units":
        data[arrayIndex].input_temperature_units = newValue;
        break;
      case "is_current_active":
        data[arrayIndex].is_current_active = newValue;
        break;
      case "is_historical_active":
        data[arrayIndex].is_historical_active = newValue;
        break;

      default:
        break;
    }

    setGenericChartData(data);
  };

  const handleToggle = (e: any, arrayLetter: string) => {
    if (
      userContext.permissions?.profire_manage_charts ||
      userContext.permissions?.external_manage_charts
    ) {
      let data: IPF2200SBFields[] =
        arrayLetter === "L"
          ? [...liveChartData]
          : "H" && [...historicalChartData];

      let index: any = data.findIndex(
        (datum) => datum.name === e.target.id.slice(0, -1)
      );

      data[index] = {
        name: data[index].name,
        active: !data[index].active,
      };

      arrayLetter === "L"
        ? setLiveChartData(data)
        : "H" && setHistoricalChartData(data);
    }
  };

  const handlePF2100Update = (
    field: string,
    input_index: number,
    newValue: any,
    arrayIndex: number
  ) => {
    let data: IPF2100ConfigDTO[] = [...pf2100ChartData];
    switch (field) {
      case "current_active":
        data[arrayIndex].current_active = newValue;
        break;
      case "historical_active":
        data[arrayIndex].historical_active = newValue;
        break;
      case "unit_of_measure":
        data[arrayIndex].unit_of_measure = newValue;
        break;

      default:
        break;
    }

    setPf2100ChartData(data);
  };

  const handleSWARMUpdate = (field: string, newValue: any) => {
    let data: ISWARMSettingsDTO = SWARMChartData?.settings!;
    switch (field) {
      case "input_1_units":
        data.input_1_units = newValue;
        break;
      case "input_1_span_min":
        data.input_1_span_min = newValue;
        break;
      case "input_1_span_max":
        data.input_1_span_max = newValue;
        break;
      case "input_2_units":
        data.input_2_units = newValue;
        break;
      case "input_2_span_min":
        data.input_2_span_min = newValue;
        break;
      case "input_2_span_max":
        data.input_2_span_max = newValue;
        break;
      case "temp_units":
        data.temp_units = newValue;
        break;
      case "pressure_units":
        data.pressure_units = newValue;
        break;
      case "level_units":
        data.level_units = newValue;
        break;

      default:
        break;
    }

    setSWARMChartData({
      current_configs: SWARMChartData?.current_configs!,
      historical_configs: SWARMChartData?.historical_configs!,
      settings: data,
    });
  };

  const handleSWARMToggle = (e: any, arrayLetter: string) => {
    if (
      userContext.permissions?.profire_manage_charts ||
      userContext.permissions?.external_manage_charts
    ) {
      let data: IPF2200SBFields[] =
        arrayLetter === "L"
          ? [...SWARMChartData?.current_configs!]
          : "H" && [...SWARMChartData?.historical_configs!];

      let index: any = data.findIndex(
        (datum) => datum.name === e.target.id.slice(0, -1)
      );

      data[index] = {
        name: data[index].name,
        active: !data[index].active,
      };

      arrayLetter === "L"
        ? setSWARMChartData({
            current_configs: data,
            historical_configs: SWARMChartData?.historical_configs!,
            settings: SWARMChartData?.settings!,
          })
        : "H" &&
          setSWARMChartData({
            current_configs: SWARMChartData?.current_configs!,
            historical_configs: data,
            settings: SWARMChartData?.settings!,
          });
    }
  };

  //Alerts Functions
  function setAlerts() {
    setGrafanaAlertsFromAPI(parseInt(params.controlUnitId!), setLoading).then(
      (res: any) => {
        setGrafanaAlerts(res.data);
      }
    );
  }

  const handleShowAlertHistory = (e: IGrafanaAlert) => {
    setAlertLogsFromAPI(e.alert_rule_id, setLoading).then((res: any) => {
      setGrafanaAlertHistory(res.data);
    });
    setShowAlertHistory(!showAlertHistory);
    setChosenAlertHistory(e);
  };

  const handleHideAlertHistory = () => {
    setShowAlertHistory(false);
    setGrafanaAlertHistory([]);
  };

  //Add Alert
  const [showAddAlert, setShowAddAlert] = useState<boolean>(false);
  const [addAlertCBActive, setAddAlertCBActive] = useState<boolean>(false);
  const [addAlertCBText, setAddAlertCBText] = useState<string>("");
  const [retainedNewAlert, setRetainedNewAlert] = useState<
    IGrafanaAlertCreateDTO | undefined
  >();

  function handleShowAddAlert() {
    if (controlUnit?.process_unit?.site?.company?.alert_contact_points) {
      setShowAddAlert(!showAddAlert);
    } else {
      handleShowContactPointError();
    }
  }

  //This function runs on clicking Save in Add Alert
  function handleAddAlert(e: IGrafanaAlertCreateDTO) {
    setShowAddAlert(false);
    setAddAlertCBActive(true);
    createNewAlert(e).then((res) => {
      if (res.status === 200 || res.status === 202) {
        setAddAlertCBText(`${e.alert_name} successfully added`);
        setRetainedNewAlert(undefined);
        setAlerts();
      } else {
        setAddAlertCBText(
          `The following issue was encountered while attempting to add ${e.alert_name}:\n\n${res.data.message}`
        );
        setRetainedNewAlert(e);
        setAlerts();
      }
    });
  }

  //This function runs on clicking OK after clicking Save in Add Alert
  function completeAddAlert() {
    setAddAlertCBActive(false);
    setAddAlertCBText("");

    if (retainedNewAlert !== undefined) {
      setShowAddAlert(true);
    }
  }

  //Edit Alert
  const [showEditAlert, setShowEditAlert] = useState<boolean>(false);
  const [editAlertCBActive, setEditAlertCBActive] = useState<boolean>(false);
  const [editAlertCBText, setEditAlertCBText] = useState<string>("");
  const [editAlertCBFinalDisplay, setEditAlertCBFinalDisplay] =
    useState<boolean>(false);
  //retainedEditAlert is the state that persists entered information that is used to repopulate the form in the event that the API Call errors out in the Confirmation Box
  const [retainedEditAlert, setRetainedEditAlert] = useState<
    IGrafanaAlert | undefined
  >();
  const [chosenEditAlert, setChosenEditAlert] = useState<IGrafanaAlert>();

  function handleShowEditAlert(e: any = undefined) {
    if (showEditAlert) {
      setShowEditAlert(false);
      setChosenEditAlert(undefined);
    } else {
      setShowEditAlert(!showEditAlert);
      setChosenEditAlert(e);
    }
  }

  //This function runs on clicking Save in Edit Alert
  function showEditAlertConfirmationBox(e: IGrafanaAlert) {
    setShowEditAlert(false);
    setEditAlertCBActive(true);
    setEditAlertCBText("Are you sure you want to save these changes?");
    setRetainedEditAlert(e);
  }

  //This function runs on clicking Cancel in Edit Alert
  function hideEditAlertConfirmationBox() {
    setShowEditAlert(false);
    setEditAlertCBActive(false);
    setEditAlertCBText("");
    setRetainedEditAlert(undefined);
  }

  //This function runs on clicking Yes after clicking Save in Edit Alert
  function handleEditAlert() {
    setEditAlertCBText("");

    editAlert(retainedEditAlert!).then((res) => {
      if (res.status === 200 || res.status === 202) {
        setEditAlertCBText(
          `${retainedEditAlert?.alert_name} successfully updated.`
        );
        setAlerts();
        setRetainedEditAlert(undefined);
      } else {
        setEditAlertCBText(
          `The following issue was encountered while attempting to edit ${chosenEditAlert?.alert_name}:\n\n${res.data.message}`
        );
        setAlerts();
      }
    });
    setEditAlertCBFinalDisplay(true);
  }

  //This function runs on clicking OK after clicking Yes after clicking Save in Edit Alert
  function completeEditAlert() {
    setEditAlertCBActive(false);
    setEditAlertCBText("");
    setEditAlertCBFinalDisplay(false);
    setChosenEditAlert(undefined);

    if (retainedEditAlert !== undefined) {
      setShowEditAlert(true);
    }
  }

  //Delete Alert
  const [deleteAlertCBActive, setDeleteAlertCBActive] =
    useState<boolean>(false);
  const [deleteAlertCBText, setDeleteAlertCBText] = useState<string>("");
  //finalDisplay is the bool that determines when it is time to show the single button when using a 2 button ConfirmationBox
  const [deleteAlertCBFinalDisplay, setDeleteAlertCBFinalDisplay] =
    useState<boolean>(false);
  const [chosenDeleteAlert, setChosenDeleteAlert] = useState<IGrafanaAlert>();

  //This function runs on clicking Save in Delete Alert
  function showDeleteAlertConfirmationBox(e: any) {
    setChosenDeleteAlert(e);
    setDeleteAlertCBActive(true);
    setDeleteAlertCBText(`Are you sure you want to delete ${e.alert_name}?`);
  }

  //This function runs on clicking Cancel in Delete Alert
  function hideDeleteAlertConfirmationBox(e: any) {
    setDeleteAlertCBActive(false);
    setDeleteAlertCBText("");
  }

  //This function runs on clicking Yes after clicking Save in Delete Alert
  function handleDeleteAlert() {
    setDeleteAlertCBText("");

    deleteAlert(chosenDeleteAlert?.alert_rule_id!).then((res) => {
      if (res.status === 200 || res.status === 202) {
        setDeleteAlertCBText(
          `${chosenDeleteAlert?.alert_name} successfully deleted.`
        );
        setAlerts();
      } else {
        setDeleteAlertCBText(
          `The following issue was encountered while attempting to delete ${chosenDeleteAlert?.alert_name}:\n\n${res.data.message}`
        );
        setAlerts();
      }
    });
    setDeleteAlertCBFinalDisplay(true);
  }

  //This function runs on clicking Ok after clicking Yes in Edit Alert
  function completeDeleteAlert(e: any) {
    setDeleteAlertCBActive(false);
    setDeleteAlertCBText("");
    setDeleteAlertCBFinalDisplay(false);
    setChosenDeleteAlert(undefined);
  }

  //Add Alert Contact Point Error
  const [contactPointErrorCBActive, setContactPointErrorCBActive] =
    useState<boolean>(false);
  const [contactPointErrorCBText, setContactPointErrorCBText] =
    useState<string>("");

  function handleShowContactPointError() {
    setContactPointErrorCBActive(true);
    setContactPointErrorCBText(
      "You have not created any Contact Points. \nPlease go to My Company and create a Contact Point before setting up an Alert."
    );
  }

  function handleCloseError() {
    setContactPointErrorCBActive(false);
    setContactPointErrorCBText("");
  }

  //Emissions Reports Functions
  useEffect(() => {
    if (controlUnitBelongsToUserCompany) {
      dailyAggregateContextRef.setDailyAggregates(
        params.controlUnitId,
        parseInt(chosenMonth),
        parseInt(chosenYear)
      );
    }
  }, [
    chosenMonth,
    chosenYear,
    dailyAggregateContextRef,
    params.controlUnitId,
    controlUnitBelongsToUserCompany,
  ]);

  function updateChosenYear(newYear: string) {
    setChosenYear(newYear);
  }

  function updateChosenMonth(newMonth: string) {
    setChosenMonth(newMonth);
  }

  const months: [string, string][] = [
    ["January", "1"],
    ["February", "2"],
    ["March", "3"],
    ["April", "4"],
    ["May", "5"],
    ["June", "6"],
    ["July", "7"],
    ["August", "8"],
    ["September", "9"],
    ["October", "10"],
    ["November", "11"],
    ["December", "12"],
  ];

  let years: [string, string][];
  years = [];

  if (controlUnit !== null && controlUnit !== undefined) {
    const maxYear: number = new Date().getFullYear();
    //populate years
    //company joined year set as lowest year to prevent unnecessary dropdown bloating
    for (
      let index = new Date(
        controlUnit?.process_unit?.site?.company?.date_joined!
      ).getFullYear();
      index <= maxYear;
      index++
    ) {
      years.push([index.toString(), index.toString()]);
    }
  }

  //Export No Data Error
  const [exportErrorCBActive, setExportErrorCBActive] =
    useState<boolean>(false);
  const [exportErrorCBText, setExportErrorCBText] = useState<string>("");

  function handleShowExportError() {
    setExportErrorCBActive(true);
    setExportErrorCBText("Nothing to export!");
  }

  function handleCloseExportError() {
    setExportErrorCBActive(false);
    setExportErrorCBText("");
  }

  //export messages to csv
  const ExportTableToCSV = (messages: IDailyAggregate[]) => {
    if (messages === null) {
      handleShowExportError();
    } else if (messages.length === 0 || messages === undefined) {
      handleShowExportError();
    } else {
      const options = {
        filename: `${controlUnit?.process_unit?.site?.facility_name}_${controlUnit?.tag}_${chosenYear}_${chosenMonth}`,
        fieldSeparator: ",",
        quotestrings: '"',
        decimalSeparator: ".",
        showLabels: true,
        showTitle: true,
        title:
          "Control Unit ID: " +
          controlUnit!.id +
          "," +
          "Comm Address: " +
          controlUnit!.comm_address +
          "," +
          "Tag: " +
          controlUnit!.tag +
          "," +
          "Report for: " +
          months[parseInt(chosenMonth) - 1][0] +
          " " +
          chosenYear,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: false,
        headers: [
          "Date (GMT)",
          "Comms Up Time Hours",
          "Duty Cycle Percent",
          "Firing Rate Average",
          "Run Time Hours",
          "Heating Time Hours",
          "Metric Tons CO2e",
        ],
      };

      const csvExporter = new ExportToCsv(options);

      let noIDs: IDailyAggregateNoIDs[];
      noIDs = [];

      messages.forEach((message) => {
        const temp: IDailyAggregateNoIDs = {
          date: message.date,
          comms_up_time_hours: message.comms_up_time_hours,
          duty_cycle_percent: message.duty_cycle_percent,
          average_firing_rate: message.average_firing_rate,
          daily_run_time_hours: message.daily_run_time_hours,
          heating_time_hours: message.heating_time_hours,
          metric_tons_co2e: message.metric_tons_co2e,
        };

        noIDs.push(temp);
      });

      csvExporter.generateCsv(noIDs);
    }
  };

  //raw data export
  const [rawDataExportFrom, setRawDataExportFrom] = useState<string>(
    dateToStringNoTime(new Date(new Date().setDate(new Date().getDate() - 30)))
  );
  const [rawDataExportTo, setRawDataExportTo] = useState<string>(
    dateToStringNoTime(new Date())
  );
  const [exportLoading, setExportLoading] = useState<boolean>(false);

  const [rawDataExportFromErrors, setRawDataExportFromErrors] =
    useState<string>("");
  const [rawDataExportToErrors, setRawDataExportToErrors] =
    useState<string>("");

  const handleRawDataExportFrom = (e: React.FormEvent<HTMLInputElement>) => {
    setRawDataExportFrom(e.currentTarget.value);
    setRawDataExportFromErrors("");
  };
  const handleRawDataExportTo = (e: React.FormEvent<HTMLInputElement>) => {
    setRawDataExportTo(e.currentTarget.value);
    setRawDataExportToErrors("");
  };

  //export raw data to csv
  const ExportRawToCSV = () => {
    setExportLoading(true);
    const test: IExportDataRequest = {
      control_unit_id: controlUnit?.id!,
      start_date: new Date(rawDataExportFrom),
      end_date: new Date(rawDataExportTo),
    };

    getRawDataByControlUnit(
      test,
      controlUnit?.control_unit_type?.model!,
      genericTypes
    ).then((res: any) => {
      if (res.data.length === 0) {
        handleShowExportError();
      } else if (res.data.length === 0 || res.data === undefined) {
        handleShowExportError();
      } else {
        const options = {
          filename: `${controlUnit?.process_unit?.site?.facility_name}_${controlUnit?.tag}_${rawDataExportFrom}_${rawDataExportTo}`,
          fieldSeparator: ",",
          quotestrings: '"',
          decimalSeparator: ".",
          showLabels: true,
          showTitle: true,
          title:
            "Control Unit ID: " +
            controlUnit!.id +
            "," +
            "Comm Address: " +
            controlUnit!.comm_address +
            "," +
            "Tag: " +
            controlUnit!.tag +
            "," +
            "Report for: " +
            dateToStringNoTime(new Date(rawDataExportFrom)) +
            " to " +
            dateToStringNoTime(new Date(rawDataExportTo)),
          useTextFile: false,
          useBom: true,
          useKeysAsHeaders: false,
          headers: Object.keys(res.data[0]),
        };

        const csvExporter = new ExportToCsv(options);

        csvExporter.generateCsv(res.data);
      }
      setExportLoading(false);
    });
  };

  //Alerts Table states
  const [alertsElements] = useState<string[]>(
    userContext.permissions?.profire_manage_alerts ||
      userContext.permissions?.external_manage_alerts
      ? ["StateMorph", "Duration", "Data", "Data", "History", "Edit", "Delete"]
      : ["StateMorph", "Duration", "Data", "Data", "History", "Space", "Space"]
  );

  const [alertsElementsInfo] = useState<string[]>([
    "Normal",
    "",
    "",
    "",
    "",
    "",
  ]);

  const [alertsHeaders] = useState<string[]>([
    "state",
    "alert_state_duration",
    "alert_name",
    "trip_condition",
    "",
    "",
    "",
  ]);

  const [alertsOverwriteHeaders] = useState<string[]>([
    "State",
    "State Duration",
    "Name",
    "Trip Condition",
    "",
    "",
    "",
  ]);

  const [alertsElementsOnClick] = useState<any[]>([
    null,
    null,
    null,
    null,
    handleShowAlertHistory,
    handleShowEditAlert,
    showDeleteAlertConfirmationBox,
  ]);

  //Emissions Table states
  const [emissionsElements] = useState<string[]>([
    "Date",
    "Data",
    "Data",
    "Data",
    "Data",
    "Data",
    "Data",
  ]);

  const [emissionsElementsInfo] = useState<string[]>([
    "Bold",
    "",
    "",
    "",
    "",
    "",
  ]);

  const [emissionsHeaders] = useState<string[]>([
    "date",
    "comms_up_time_hours",
    "duty_cycle_percent",
    "average_firing_rate",
    "daily_run_time_hours",
    "heating_time_hours",
    "metric_tons_co2e",
  ]);

  const [emissionsOverwriteHeaders] = useState<string[]>([
    "InfoButton",
    "Comms Up Time Hours",
    "Duty Cycle Percent",
    "Average Firing Rate",
    "Run Time Hours",
    "Heating Time Hours",
    "Metric Tons CO2e",
  ]);

  const [emissionsElementsOnClick] = useState<any[]>([
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ]);

  //Info Modal for Emissions Table
  const [emissionsInfoCBActive, setEmissionsInfoCBActive] =
    useState<boolean>(false);

  function handleShowEmissionsInfo() {
    setEmissionsInfoCBActive(!emissionsInfoCBActive);
  }

  if (
    userContext.loading ||
    controlUnitBelongsToUserCompany === null ||
    controlUnit === undefined ||
    loading ||
    useEffectLoading
  ) {
    return (
      <div className="fullPageLoadingContainer">
        <Loading dataTestname="single-control-unit-loading" />
      </div>
    );
  } else {
    return (
      <StyledSingleControlUnitDiv>
        {controlUnitBelongsToUserCompany && (
          <Breadcrumb
            dataTestname="single-control-unit-breadcrumbs"
            breadcrumbs={crumbs}
          />
        )}
        {controlUnitBelongsToUserCompany ? (
          <StyledMainDiv data-testid="main-div">
            <ConfirmationBox
              dataTestname="single-control-unit-edit-charts-confirmation-box"
              heading={"Information"}
              message={editChartCBText}
              active={editChartCBActive}
              onOk={completeEditChart}
              onYes={handleEditChart}
              onCancel={hideEditChartConfirmationBox}
              displayCancel={true}
              finalDisplay={editChartCBFinalDisplay}
            />
            <ConfirmationBox
              dataTestname="single-control-unit-add-alert-confirmation-box"
              heading={"Information"}
              message={addAlertCBText}
              active={addAlertCBActive}
              onOk={completeAddAlert}
              displayCancel={false}
            />
            <ConfirmationBox
              dataTestname="single-control-unit-edit-alert-confirmation-box"
              heading={"Information"}
              message={editAlertCBText}
              active={editAlertCBActive}
              onOk={completeEditAlert}
              onYes={handleEditAlert}
              onCancel={hideEditAlertConfirmationBox}
              displayCancel={true}
              finalDisplay={editAlertCBFinalDisplay}
            />
            <ConfirmationBox
              dataTestname="single-control-unit-delete-alert-confirmation-box"
              heading={"Information"}
              message={deleteAlertCBText}
              active={deleteAlertCBActive}
              onOk={completeDeleteAlert}
              onYes={handleDeleteAlert}
              onCancel={hideDeleteAlertConfirmationBox}
              displayCancel={true}
              finalDisplay={deleteAlertCBFinalDisplay}
            />
            <ConfirmationBox
              dataTestname="single-control-unit-contact-point-error-confirmation-box"
              heading={"Information"}
              message={contactPointErrorCBText}
              active={contactPointErrorCBActive}
              onOk={handleCloseError}
              displayCancel={false}
            />
            <ConfirmationBox
              dataTestname="single-control-unit-export-error-confirmation-box"
              heading={"Information"}
              message={exportErrorCBText}
              active={exportErrorCBActive}
              onOk={handleCloseExportError}
              displayCancel={false}
            />

            {controlUnit !== null && (
              <DefaultContainer
                dataTestname="single-control-unit-default-container"
                darkBackground={true}
                title={"Control Unit"}
              >
                <InfoDisplay
                  dataTestname="single-control-unit-info-display"
                  datum={controlUnit}
                  headers={[
                    "tag",
                    "control_unit_type.manufacturer",
                    "control_unit_type.model",
                    "comm_address",
                  ]}
                  headerWidth={"8.5%"}
                  colonWidth={"3%"}
                  infoWidth={"Calc(100% - 119px - 42px)"}
                  containerWidth={"100%"}
                  headerMinWidth={"119px"}
                  colonMinWidth={"21px"}
                  infoMinWidth={"120px"}
                  containerMinWidth={"100%"}
                  rightSpacerWidth={"0%"}
                />

                <StyledButtonsDiv data-testid="button-div">
                  {userContext.permissions?.profire_manage_control_units && (
                    <EditButton
                      dataTestname="single-control-unit-edit-button"
                      onClickFunction={handleShowEditControlUnit}
                    />
                  )}
                  {userContext.permissions?.profire_manage_control_units && (
                    <DeleteButton
                      dataTestname="single-control-unit-delete-button"
                      onClickFunction={showDeleteControlUnitConfirmationBox}
                    />
                  )}
                  {userContext.permissions?.external_manage_tags && (
                    <StyledEditButtonDiv data-testid="edit-tag-button-div">
                      <ProfireButton
                        dataTestname="single-control-unit-edit-tag-button"
                        text={showEditTag ? "Stop Editing" : "Edit Tag"}
                        onClickFunction={handleShowEditTag}
                        size={4}
                      />
                    </StyledEditButtonDiv>
                  )}
                </StyledButtonsDiv>
                <ConfirmationBox
                  dataTestname="single-control-unit-edit-confirmation-box"
                  heading={"Information"}
                  message={editControlUnitCBText}
                  active={editControlUnitCBActive}
                  onOk={completeEditControlUnit}
                  onYes={handleEditControlUnit}
                  onCancel={hideEditControlUnitConfirmationBox}
                  displayCancel={true}
                  finalDisplay={editControlUnitCBFinalDisplay}
                />
                <ConfirmationBox
                  dataTestname="single-control-unit-delete-confirmation-box"
                  heading={"Information"}
                  message={deleteControlUnitCBText}
                  active={deleteControlUnitCBActive}
                  onOk={completeDeleteControlUnit}
                  onYes={handleDeleteControlUnit}
                  onCancel={hideDeleteControlUnitConfirmationBox}
                  displayCancel={true}
                  finalDisplay={deleteControlUnitCBFinalDisplay}
                />
                <ConfirmationBox
                  dataTestname="single-control-unit-edit-tag-confirmation-box"
                  heading={"Information"}
                  message={editTagCBText}
                  active={editTagCBActive}
                  onOk={completeEditControlUnitTag}
                  onYes={handleEditTag}
                  onCancel={hideEditTagConfirmationBox}
                  displayCancel={true}
                  finalDisplay={editTagCBFinalDisplay}
                />
                {showEditControlUnit && (
                  <EditControlUnit
                    active={showEditControlUnit}
                    controlUnit={controlUnit!}
                    onCancel={handleShowEditControlUnit}
                    onEdit={showEditControlUnitConfirmationBox}
                    retainedData={retainedEditControlUnit}
                  />
                )}
                {showEditTag && (
                  <StyledEditTagDiv
                    data-testid="edit-tag-div"
                    className={showEditTag ? "ease" : ""}
                  >
                    <CommonInput
                      dataTestname="single-control-unit-tag-common-input"
                      type={"text"}
                      labelText={"Tag"}
                      required={true}
                      readOnly={false}
                      value={tag}
                      onChange={handleTag}
                      maxLength={50}
                      placeholder={"Tag"}
                      errors={tagErrors}
                    />
                    <StyledFormButtonDiv data-testid="save-tag-button-div">
                      <ProfireButton
                        dataTestname="single-control-unit-save-tag-button"
                        text="Save Tag"
                        onClickFunction={showEditTagConfirmationBox}
                        size={4}
                      />
                    </StyledFormButtonDiv>
                  </StyledEditTagDiv>
                )}
              </DefaultContainer>
            )}

            <CollapsibleDiv
              dataTestname="single-control-unit-charts-collapsible-div"
              title={"Charts"}
            >
              {supportedTypes.includes(
                controlUnit.control_unit_type?.model!
              ) ? (
                <StyledChartsInnerDiv data-testid="charts-inner-div">
                  {genericTypes.includes(
                    controlUnit.control_unit_type?.model!
                  ) ? (
                    genericChartData.map((genericChartDatum, index) => (
                      <StyledInputAreaInnerDiv key={index}>
                        <CollapsibleRow
                          dataTestname="single-control-unit-adam4118-chart-collapsible-row"
                          key={genericChartDatum.input_index}
                          title={
                            genericChartDatum.input_name ===
                            `Input ${genericChartDatum.input_index}`
                              ? `Input ${genericChartDatum.input_index}`
                              : `Input ${
                                  genericChartDatum.input_index
                                } (${controlUnit.control_unit_configs?.generic_configs?.find(
                                  (config) =>
                                    config.input_index ===
                                    genericChartDatum.input_index
                                )?.input_name!})`
                          }
                          type={
                            controlUnit.control_unit_configs?.generic_configs?.find(
                              (config) =>
                                config.input_index ===
                                genericChartDatum.input_index
                            )?.input_mode!
                          }
                        >
                          <GenericDeviceInputArea
                            dataTestname="single-control-unit-generic-device-input-area"
                            input_mode={genericChartDatum.input_mode}
                            input_index={genericChartDatum.input_index}
                            input_name={genericChartDatum.input_name}
                            input_temperature_units={
                              genericChartDatum.input_temperature_units
                            }
                            input_420_span_max={
                              genericChartDatum.input_420_span_max
                            }
                            input_420_span_min={
                              genericChartDatum.input_420_span_min
                            }
                            input_420_units={genericChartDatum.input_420_units}
                            is_current_active={
                              genericChartDatum.is_current_active
                            }
                            is_historical_active={
                              genericChartDatum.is_historical_active
                            }
                            handleChange={handleGenericUpdate}
                            arrayIndex={index}
                            readOnly={
                              !(
                                userContext.permissions
                                  ?.profire_manage_charts! ||
                                userContext.permissions?.external_manage_charts!
                              )
                            }
                            generic_modes={controlUnit.generic_modes}
                          />
                        </CollapsibleRow>
                      </StyledInputAreaInnerDiv>
                    ))
                  ) : controlUnit.control_unit_type?.model === "PF2200-SB" ? (
                    <StyledInputAreaInnerDiv>
                      <PF2200SBInputArea
                        liveChartData={liveChartData}
                        historicalChartData={historicalChartData}
                        handleToggle={handleToggle}
                        dataTestname={"single-control-unit-pf2200sb-input"}
                      />
                    </StyledInputAreaInnerDiv>
                  ) : controlUnit.control_unit_type?.model === "PF2100" ? (
                    controlUnit.control_unit_configs?.pf2100_configs!.map(
                      (pf2100ChartDatum, index) => (
                        <StyledInputAreaInnerDiv key={index}>
                          <CollapsibleRow
                            dataTestname="single-control-unit-adam4118-chart-collapsible-row"
                            key={pf2100ChartDatum.name}
                            title={pf2100ChartDatum.name}
                          >
                            <PF2100InputArea
                              dataTestname="single-control-unit-generic-device-input-area"
                              current_active={pf2100ChartDatum.current_active}
                              historical_active={
                                pf2100ChartDatum.historical_active
                              }
                              input_name={pf2100ChartDatum.name}
                              unit_of_measure={pf2100ChartDatum.unit_of_measure}
                              handleChange={handlePF2100Update}
                              arrayIndex={index}
                              readOnly={
                                !(
                                  userContext.permissions
                                    ?.profire_manage_charts! ||
                                  userContext.permissions
                                    ?.external_manage_charts!
                                )
                              }
                            />
                          </CollapsibleRow>
                        </StyledInputAreaInnerDiv>
                      )
                    )
                  ) : controlUnit.control_unit_type?.model ===
                      "PFCON-SAT-0000" ||
                    controlUnit.control_unit_type?.model === "PFCON-SAT-0001" ||
                    controlUnit.control_unit_type?.model ===
                      "PFCON-SAT-0002" ? (
                    <SWARMInputArea
                      dataTestname="single-control-unit-swarm-input-area"
                      settings={SWARMChartData?.settings!}
                      current_configs={SWARMChartData?.current_configs!}
                      historical_configs={SWARMChartData?.historical_configs!}
                      handleChange={handleSWARMUpdate}
                      handleCheckbox={handleSWARMToggle}
                      readOnly={
                        !(
                          userContext.permissions?.profire_manage_charts! ||
                          userContext.permissions?.external_manage_charts!
                        )
                      }
                      handleExistingErrors={setEditChartExistingErrors}
                      model={controlUnit.control_unit_type.model}
                    />
                  ) : (
                    <></>
                  )}

                  <StyledChartButtonRowContainer>
                    <StyledChartButtonRow
                      permissions={
                        userContext.permissions?.profire_manage_charts! ||
                        userContext.permissions?.external_manage_charts!
                      }
                      data-testid="chart-button-row-div"
                    >
                      {(userContext.permissions?.profire_manage_charts ||
                        userContext.permissions?.external_manage_charts) && (
                        <ProfireButton
                          dataTestname="single-control-unit-save-button"
                          text="Save"
                          onClickFunction={showEditChartConfirmationBox}
                          disabled={editChartExistingErrors}
                          size={2}
                        />
                      )}
                      <ProfireButton
                        dataTestname="single-control-unit-view-button"
                        text="View"
                        onClickFunction={() =>
                          openInNewTab(controlUnit!.dashboard_url!)
                        }
                        size={2}
                      />
                    </StyledChartButtonRow>
                  </StyledChartButtonRowContainer>

                  {/* RAW DATA EXPORT DIV */}
                  <StyledExportRawDataDiv>
                    <StyledConfigurationLabel>
                      Export Raw Data
                    </StyledConfigurationLabel>
                    <StyledDropdownsAndExportDiv>
                      <StyledDropdownsDiv>
                        <StyledExportFromDropdownDiv>
                          <StyledDropdownLabel>From</StyledDropdownLabel>
                          <CommonInput
                            dataTestname="single-control-unit-date-from-common-input"
                            type={"date"}
                            labelText={""}
                            required={false}
                            readOnly={false}
                            value={rawDataExportFrom}
                            onChange={handleRawDataExportFrom}
                            placeholder={""}
                            errors={rawDataExportFromErrors}
                            hideErrorsDiv={true}
                          />
                        </StyledExportFromDropdownDiv>
                        <StyledExportToDropdownDiv>
                          <StyledDropdownLabel>To</StyledDropdownLabel>
                          <CommonInput
                            dataTestname="single-control-unit-date-to-common-input"
                            type={"date"}
                            labelText={""}
                            required={false}
                            readOnly={false}
                            value={rawDataExportTo}
                            onChange={handleRawDataExportTo}
                            placeholder={""}
                            errors={rawDataExportToErrors}
                            hideErrorsDiv={true}
                          />
                        </StyledExportToDropdownDiv>
                      </StyledDropdownsDiv>
                      <StyledExportButtonDiv data-testid="export-button-div">
                        <ProfireButton
                          dataTestname="single-control-unit-export-button"
                          size={3}
                          text="Export"
                          onClickFunction={() => ExportRawToCSV()}
                          loading={exportLoading}
                        />
                      </StyledExportButtonDiv>
                    </StyledDropdownsAndExportDiv>
                    <StyledInstructionText>
                      Note: Dates will be returned in UTC
                    </StyledInstructionText>
                  </StyledExportRawDataDiv>
                </StyledChartsInnerDiv>
              ) : (
                <StyledCommonTableDiv>
                  <StyledInstructionText>
                    This unit type is not currently supported.
                  </StyledInstructionText>
                </StyledCommonTableDiv>
              )}
            </CollapsibleDiv>
            <CollapsibleDiv
              dataTestname="single-control-unit-alerts-collapsible-div"
              title="Alerts"
            >
              {grafanaAlertFieldsInvalid ? (
                <StyledCommonTableDiv>
                  <StyledInstructionText>
                    {grafanaAlertFieldsErrors}
                  </StyledInstructionText>
                </StyledCommonTableDiv>
              ) : (supportedTypes.includes(
                  controlUnit.control_unit_type?.model!
                ) &&
                  genericTypes.includes(
                    controlUnit.control_unit_type?.model!
                  ) &&
                  !allInputsDisabled) ||
                (supportedTypes.includes(
                  controlUnit.control_unit_type?.model!
                ) &&
                  !genericTypes.includes(
                    controlUnit.control_unit_type?.model!
                  )) ? (
                <>
                  <StyledCommonTableDiv>
                    {(userContext.permissions?.profire_manage_alerts ||
                      userContext.permissions?.external_manage_alerts) && (
                      <StyledAddButtonDiv data-testid="add-alert-button-div">
                        <ProfireButton
                          dataTestname="single-control-unit-add-alert-button"
                          onClickFunction={
                            controlUnit.process_unit?.site?.company
                              ?.alert_contact_points.length === 0
                              ? handleShowContactPointError
                              : handleShowAddAlert
                          }
                          text="Add Alert"
                          size={3}
                        />
                      </StyledAddButtonDiv>
                    )}
                    <CommonCustomizableTable
                      dataTestname="single-control-unit-common-customizable-table"
                      usageIdentifier="alert"
                      data={grafanaAlerts}
                      elements={alertsElements}
                      elementsInfo={alertsElementsInfo}
                      headers={alertsHeaders}
                      overwriteHeaders={alertsOverwriteHeaders}
                      tableWidth={"100%"}
                      tableMinWidth={"100%"}
                      tableMaxHeight={"400px"}
                      desktopColWidths={[
                        "120px",
                        "200px",
                        "22%",
                        "53.9%",
                        "2.3%",
                        "2.3%",
                        "2.3%",
                      ]}
                      desktopColMinWidths={[
                        "120px",
                        "200px",
                        "200px",
                        "301px",
                        "33px",
                        "33px",
                        "33px",
                      ]}
                      mobileColWidths={[
                        "10px",
                        "200px",
                        "81%",
                        "53.9%",
                        "2.3%",
                        "2.3%",
                        "2.3%",
                      ]}
                      mobileColMinWidths={[
                        "40px",
                        "200px",
                        "159px",
                        "301px",
                        "33px",
                        "33px",
                        "33px",
                      ]}
                      elementsOnClick={alertsElementsOnClick}
                      textAlign={[
                        "left",
                        "left",
                        "left",
                        "left",
                        "left",
                        "left",
                        "left",
                      ]}
                      tdHeight={"45px"}
                      tdHeightMobile={"33px"}
                      tdMinHeight={"45px"}
                      tdMinHeightMobile={"33px"}
                      colDisplayOnMobile={[
                        true,
                        false,
                        true,
                        false,
                        true,
                        true,
                        true,
                      ]}
                      colJustifyMobile={[
                        "center",
                        "left",
                        "left",
                        "left",
                        "left",
                        "left",
                      ]}
                      colJustify={[
                        "left",
                        "left",
                        "left",
                        "left",
                        "left",
                        "left",
                      ]}
                      colFlexDirection={[]}
                      addPaddingRightForScroll={true}
                    />
                    {showAddAlert && (
                      <AddAlert
                        dataTestname="single-control-unit-add-alert"
                        active={showAddAlert}
                        fieldData={grafanaAlertFields}
                        contactPoints={
                          controlUnit.process_unit?.site?.company
                            ?.alert_contact_points!
                        }
                        controlUnitID={parseInt(params.controlUnitId!)}
                        onCancel={handleShowAddAlert}
                        onAdd={handleAddAlert}
                        existingAlerts={grafanaAlerts}
                        genericDevice={
                          supportedTypes.includes(
                            controlUnit.control_unit_type?.model!
                          ) &&
                          genericTypes.includes(
                            controlUnit.control_unit_type?.model!
                          )
                            ? true
                            : false
                        }
                        genericData={
                          controlUnit.control_unit_configs?.generic_configs!
                        }
                        retainedData={retainedNewAlert}
                      />
                    )}
                    {showEditAlert && (
                      <EditAlert
                        dataTestname="single-control-unit-edit-alert"
                        active={showEditAlert}
                        fieldData={grafanaAlertFields}
                        contactPoints={
                          controlUnit.process_unit?.site?.company
                            ?.alert_contact_points!
                        }
                        controlUnitID={parseInt(params.controlUnitId!)}
                        onCancel={handleShowEditAlert}
                        onEdit={showEditAlertConfirmationBox}
                        existingAlerts={grafanaAlerts}
                        chosenAlert={chosenEditAlert!}
                        genericDevice={
                          supportedTypes.includes(
                            controlUnit.control_unit_type?.model!
                          ) &&
                          genericTypes.includes(
                            controlUnit.control_unit_type?.model!
                          )
                            ? true
                            : false
                        }
                        genericData={
                          controlUnit.control_unit_configs?.generic_configs!
                        }
                        retainedData={retainedEditAlert}
                      />
                    )}
                    {showAlertHistory && (
                      <AlertHistory
                        dataTestname="single-control-unit-alert-history"
                        active={showAlertHistory}
                        data={grafanaAlertHistory}
                        headers={["state", "timestamp"]}
                        alertName={chosenAlertHistory?.alert_name!}
                        showX={true}
                        xFunction={handleHideAlertHistory}
                        overflow={"scroll"}
                        maxHeight={"50%"}
                      />
                    )}
                  </StyledCommonTableDiv>
                </>
              ) : genericTypes.includes(
                  controlUnit.control_unit_type?.model!
                ) && allInputsDisabled ? (
                <StyledCommonTableDiv>
                  Please enable an Input and Save to Add Alerts
                </StyledCommonTableDiv>
              ) : (
                <StyledCommonTableDiv>
                  <StyledInstructionText>
                    This unit type is not currently supported.
                  </StyledInstructionText>
                </StyledCommonTableDiv>
              )}
              {(userContext.permissions?.profire_manage_alerts ||
                userContext.permissions?.external_manage_alerts) && (
                <StyledAddButtonMobileDiv
                  supportedTypes={supportedTypes}
                  genericTypes={genericTypes}
                  controlUnitModel={controlUnit.control_unit_type?.model!}
                  allInputsDisabled={allInputsDisabled}
                  grafanaAlertFieldsErrors={grafanaAlertFieldsErrors}
                  data-testid="add-alert-button-div"
                >
                  <ProfireButton
                    dataTestname="single-control-unit-add-alert-button"
                    onClickFunction={
                      controlUnit.process_unit?.site?.company
                        ?.alert_contact_points.length === 0
                        ? handleShowContactPointError
                        : handleShowAddAlert
                    }
                    text="Add Alert"
                    size={3}
                  />
                </StyledAddButtonMobileDiv>
              )}
            </CollapsibleDiv>
            {!genericTypes.includes(controlUnit.control_unit_type?.model!) && (
              <CollapsibleDiv
                dataTestname="single-control-unit-emissions-collapsible-div"
                title={"Emissions Reports"}
              >
                <StyledCommonTableDiv>
                  <StyledDropdownsAndExportDiv>
                    <StyledDropdownsDiv>
                      <StyledYearsDropdownDiv>
                        <StyledDropdownLabel>Year</StyledDropdownLabel>
                        <Dropdown
                          dataTestname="single-control-unit-year-dropdown"
                          columns={years}
                          onchange={updateChosenYear}
                          selected={chosenYear}
                          labelText=""
                          hideErrorsDiv={true}
                        />
                      </StyledYearsDropdownDiv>
                      <StyledMonthsDropdownDiv>
                        <StyledDropdownLabel>Month</StyledDropdownLabel>
                        <Dropdown
                          dataTestname="single-control-month-year-dropdown"
                          columns={months}
                          onchange={updateChosenMonth}
                          selected={chosenMonth}
                          labelText=""
                          hideErrorsDiv={true}
                        />
                      </StyledMonthsDropdownDiv>
                    </StyledDropdownsDiv>
                    <StyledExportButtonDiv data-testid="export-button-div">
                      <ProfireButton
                        dataTestname="single-control-unit-export-button"
                        size={3}
                        text="Export"
                        onClickFunction={() =>
                          ExportTableToCSV(
                            dailyAggregateContext.dailyAggregates
                          )
                        }
                      />
                    </StyledExportButtonDiv>
                  </StyledDropdownsAndExportDiv>

                  <EmissionsInfo
                    active={emissionsInfoCBActive}
                    onCancel={handleShowEmissionsInfo}
                  />

                  <CommonCustomizableTable
                    dataTestname="single-control-unit-common-customizable-table"
                    usageIdentifier="emissions"
                    data={dailyAggregateContext.dailyAggregates}
                    elements={emissionsElements}
                    elementsInfo={emissionsElementsInfo}
                    headers={emissionsHeaders}
                    overwriteHeaders={emissionsOverwriteHeaders}
                    tableWidth={"100%"}
                    tableMinWidth={"100%"}
                    tableMaxHeight={"400px"}
                    desktopColWidths={[
                      "100%",
                      "100%",
                      "100%",
                      "100%",
                      "100%",
                      "100%",
                      "100%",
                    ]}
                    desktopColMinWidths={[
                      "100px",
                      "100px",
                      "100px",
                      "100px",
                      "100px",
                      "100px",
                      "100px",
                    ]}
                    mobileColWidths={[
                      "100px",
                      "100px",
                      "100px",
                      "100px",
                      "100px",
                      "100px",
                      "100px",
                    ]}
                    mobileColMinWidths={[
                      "100px",
                      "100px",
                      "100px",
                      "100px",
                      "100px",
                      "100px",
                      "100px",
                    ]}
                    elementsOnClick={emissionsElementsOnClick}
                    textAlign={[
                      "left",
                      "right",
                      "right",
                      "right",
                      "right",
                      "right",
                      "right",
                    ]}
                    tdHeight={"39px"}
                    tdHeightMobile={"33px"}
                    tdMinHeight={"39px"}
                    tdMinHeightMobile={"33px"}
                    colDisplayOnMobile={[true, true, true, true, true, true]}
                    colJustify={[
                      "center",
                      "center",
                      "center",
                      "center",
                      "center",
                      "center",
                      "center",
                    ]}
                    colFlexDirection={[
                      "column",
                      "column",
                      "column",
                      "column",
                      "column",
                      "column",
                      "column",
                    ]}
                    infoButtonFunction={handleShowEmissionsInfo}
                    addPaddingRightForScroll={true}
                  />
                </StyledCommonTableDiv>
              </CollapsibleDiv>
            )}
          </StyledMainDiv>
        ) : (
          <StyledMainDiv data-testid="main-div">
            <StyledBannerDiv data-testid="banner-div">
              <Banner
                dataTestname="single-control-unit-banner"
                headingtext={headingText}
                text={text}
                redBox={true}
              />
            </StyledBannerDiv>
          </StyledMainDiv>
        )}
      </StyledSingleControlUnitDiv>
    );
  }
};

export default SingleControlUnit;

const StyledSingleControlUnitDiv = styled.div`
  width: Calc(100% - 32px);
  max-width: Calc(1200px - 40px);
  min-width: Calc(320px - 40px);
  margin: 60px 16px 20px 16px;
  height: Calc(100% - 80px);
  background-color: white;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    margin: 60px 20px 20px 20px;
    width: Calc(100% - 40px);
    background-color: ${(props) => props.theme.bgColor};
  }
`;

const StyledMainDiv = styled.div`
  height: calc(100% - 33px);
  width: 100%;
`;

const StyledBannerDiv = styled.div`
  min-width: Calc(300px - 32px);
  width: Calc(100% - 32px);
  padding: 20px 16px;
  margin-bottom: 25px;
  border-radius: 10px;
`;

const StyledDropdownsAndExportDiv = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    flex-direction: row;
    margin-bottom: 13px;
  }
`;

const StyledDropdownsDiv = styled.div`
  width: Calc(100%);

  margin-bottom: 10px;
  display: flex;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    width: Calc(100% - 105px);
  }
`;

const StyledExportFromDropdownDiv = styled.div`
  width: calc(50% - 2.5px);

  padding-right: 5px;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    width: Calc(30% - 5px);
  }
`;

const StyledExportToDropdownDiv = styled.div`
  width: calc(50% - 2.5px);

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    width: Calc(30%);
  }
`;

const StyledYearsDropdownDiv = styled.div`
  width: calc(50% - 2.5px);

  padding-right: 5px;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    width: Calc(30% - 5px);
  }
`;

const StyledMonthsDropdownDiv = styled.div`
  width: calc(50% - 2.5px);

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    width: Calc(30%);
  }
`;

const StyledDropdownLabel = styled.h3`
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.headingSecondaryWeight};
  margin-bottom: 6px;
  margin-top: 11px;
  color: #888a8d;
`;

const StyledButtonsDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

const StyledExportButtonDiv = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: end;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    flex-direction: column;
  }
`;

const StyledEditButtonDiv = styled.div`
  width: 100%;
  margin-top: 5px;
  text-align: right;
`;

const StyledEditTagDiv = styled.div`
  padding-top: 10px;
  padding-bottom: 5px;
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;

  input {
    text-align: left;
    font-size: ${(props) => props.theme.contentMainSize};
    font-weight: ${(props) => props.theme.contentMainWeight};
  }
`;

const StyledInstructionText = styled.p`
  text-align: left;
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
  margin: 0px;
  width: 100%;
`;

const StyledFormButtonDiv = styled.div`
  text-align: left;
  width: 100%;
`;

const StyledCommonTableDiv = styled.div`
  width: Calc(100%);
  background-color: #ffffff;
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};

  padding: 0px 0px 0px 0px;
  border-radius: 0px 0px 4px 4px;
  text-align: left;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    width: Calc(100% - 40px);
    padding: 0px 20px 21px 20px;
  }
`;

const StyledChartsInnerDiv = styled.div`
  width: 100%;

  padding: 8px 0px 0px 0px;
  border-radius: 0px 0px 4px 4px;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    padding: 8px 0px 21px 0px;
  }
`;

const StyledInputAreaInnerDiv = styled.div`
  width: 100%;

  padding: 0px;
  border-radius: 0px 0px 4px 4px;
`;

interface ButtonRowProps {
  permissions: boolean;
}

const StyledChartButtonRowContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

const StyledChartButtonRow = styled.div<ButtonRowProps>`
  width: 196px;

  text-align: right;
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.permissions ? "space-between" : "end")};
  margin-top: 12px;
  margin-right: 0px;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    margin-right: 20px;
  }
`;

const StyledAddButtonDiv = styled.div`
  width: 100%;
  margin-bottom: 15px;
  text-align: right;
  display: none;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    display: block;
  }
`;

interface AddButtonMobileDivProps {
  supportedTypes: string[];
  genericTypes: string[];
  controlUnitModel: string;
  allInputsDisabled: boolean;
  grafanaAlertFieldsErrors: string;
}

const StyledAddButtonMobileDiv = styled.div<AddButtonMobileDivProps>`
  width: 100%;
  margin-top: 12px;
  text-align: right;
  display: ${(props) =>
    props.genericTypes.includes(props.controlUnitModel) &&
    props.allInputsDisabled
      ? "none"
      : props.grafanaAlertFieldsErrors.length > 0
      ? "none"
      : props.supportedTypes.includes(props.controlUnitModel)
      ? "block"
      : "none"};

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    display: none;
  }
`;

const StyledConfigurationLabel = styled.label`
  font-size: ${(props) => props.theme.headingMainSize};
  font-weight: ${(props) => props.theme.headingMainWeight};
  color: #292929;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  margin-bottom: 19px;
`;

const StyledExportRawDataDiv = styled.div`
  width: Calc(100%);
  background-color: #ffffff;

  padding: 20px 0px 0px 0px;
  border-radius: 0px 0px 4px 4px;
  text-align: left;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    width: Calc(100% - 66px);
    padding: 20px 20px 21px 46px;
  }
`;
