import React from "react";
import styled from "styled-components";
import ProfireButton from "../common/buttons/ProfireButton";
import ModalBox from "../common/modals/ModalBox";
import SafeHyperlink from "../common/navigation/SafeHyperlink";

interface EmissionsInfoProps {
  active: boolean;
  onCancel?: () => void;
}

const EmissionsInfo: React.FC<EmissionsInfoProps> = ({ active, onCancel }) => {
  return (
    <ModalBox
      dataTestname="emissions-info-modal-box"
      active={active}
      desktopContainerMinWidth={"765px"}
      mobileContainerMinWidth={"320px"}
      containerTitle="Emissions Info"
      maxHeight={"50%"}
    >
      <StyledEmissionsInfoDiv>
        <StyledMainDiv data-testid="main-div">
          <StyledSectionDiv>
            Each row in the report represents the aggregation of a full day's
            worth of data (in UTC).
          </StyledSectionDiv>
          <table>
            <thead>
              <StyledRow>
                <th></th>
                <th></th>
              </StyledRow>
            </thead>
            <tbody>
              <StyledRow>
                <StyledCell>Comms Up Time Hours</StyledCell>
                <StyledCell>
                  The number of hours communication was active from the device.
                </StyledCell>
              </StyledRow>
              <StyledRow>
                <StyledCell>Duty Cycle Percent</StyledCell>
                <StyledCell>
                  The percentage of received data messages where the main valve
                  was energized to the total number of messages received.
                </StyledCell>
              </StyledRow>
              <StyledRow>
                <StyledCell>Average Firing Rate</StyledCell>
                <StyledCell>
                  The average firing rate of all received messages.
                </StyledCell>
              </StyledRow>
              <StyledRow>
                <StyledCell>Run Time Hours</StyledCell>
                <StyledCell>
                  The total amount of time where the device was in a running
                  state.
                </StyledCell>
              </StyledRow>
              <StyledRow>
                <StyledCell>Heating Time Hours</StyledCell>
                <StyledCell>
                  The total amount of time where the main valve was energized.
                </StyledCell>
              </StyledRow>
              <StyledRow>
                <StyledCell>Metric Tons CO2e</StyledCell>
                <StyledCell>
                  The industry standard estimated CO2 emissions for the
                  appliance. See the{" "}
                  <SafeHyperlink
                    dataTestname=""
                    displayText="EPA Direct Emissions from Stationary Combustion Sources"
                    url="https://www.epa.gov/sites/default/files/2016-03/documents/stationaryemissions_3_2016.pdf"
                    afterDisplayText=" for more info."
                  />
                </StyledCell>
              </StyledRow>
            </tbody>
          </table>
          <StyledButtonRow>
            <StyledCancelButtonHolder>
              <ProfireButton
                dataTestname="emissions-info-ok-button"
                text="OK"
                onClickFunction={onCancel}
              />
            </StyledCancelButtonHolder>
          </StyledButtonRow>
        </StyledMainDiv>
      </StyledEmissionsInfoDiv>
    </ModalBox>
  );
};

export default EmissionsInfo;

const StyledEmissionsInfoDiv = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const StyledMainDiv = styled.div`
  height: 100%;
  width: 100%;
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
`;

const StyledSectionDiv = styled.div`
  width: Calc(100%);
  background-color: #ffffff;
  border-radius: 10px;
  text-align: left;
  font-size: ${(props) => props.theme.contentMainSize};
`;

const StyledButtonRow = styled.div`
  width: 100%;
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

const StyledCancelButtonHolder = styled.div`
  margin-top: 10px;
`;

const StyledRow = styled.tr`
  font-size: ${(props) => props.theme.contentMainSize};
  td:first-child {
    font-weight: bold;
    width: 28%;
    justify-content: baseline;
    padding-left: 0px;
  }
`;

const StyledCell = styled.td`
  text-align: left;
  padding: 10px;
  vertical-align: top;
`;
