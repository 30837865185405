import { IProcessUnit } from "../../types/processUnits";
import { ActionTypes, IProcessUnitState } from "./types";

type Action =
  | { type: ActionTypes.SET_PROCESS_UNITS; payload: IProcessUnit[] }
  | { type: ActionTypes.SET_PROCESS_UNIT; payload: IProcessUnit | null }
  | {
      type: ActionTypes.SET_PROCESS_UNIT_BELONGS_TO_USER_COMPANY;
      payload: boolean;
    }
  | { type: ActionTypes.SENDING_REQUEST }
  | { type: ActionTypes.REQUEST_FINISHED };

const reducer = (state: IProcessUnitState, action: Action) => {
  switch (action.type) {
    case ActionTypes.SET_PROCESS_UNITS:
      return {
        ...state,
        processUnits: action.payload,
      };
    case ActionTypes.SET_PROCESS_UNIT:
      return {
        ...state,
        processUnit: action.payload,
      };
    case ActionTypes.SET_PROCESS_UNIT_BELONGS_TO_USER_COMPANY:
      return {
        ...state,
        processUnitBelongsToUserCompany: action.payload,
      };
    case ActionTypes.SENDING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.REQUEST_FINISHED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
