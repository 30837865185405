export interface IGrafanaAlert {
  alert_rule_id: number;
  control_unit_id?: number;
  alert_contact_point_id?: number;
  alert_name: string;
  field_name: string; // correct: bath_temp | incorrect: Bath Temp
  evaluation_operation: string; // example: "gt" | "lt" | ""
  evaluation_value: number; // can be decimal
  evaluation_to_value?: number; // can be decimal (used only for outside_range & within_range)
  evaluation_for_period_minutes: number; // in seconds
  trip_condition: string;
  alert_state: string;
  alert_state_duration: number;
}

export interface IGrafanaAlertCreateDTO {
  control_unit_id: number;
  alert_contact_point_id: number;
  alert_name: string;
  field_name: string; // correct: bath_temp | incorrect: Bath Temp
  evaluation_operation: string; // example: "gt" | "lt" | ""
  evaluation_value: number; // can be decimal
  evaluation_to_value?: number; // can be decimal (used only for outside_range & within_range)
  evaluation_for_period_minutes: number; // in seconds
  field_display_name: string;
  operation_display_name: string;
  unit_of_measure: string;
  alert_config: IAlertConfigEnum;
}

export enum IAlertConfigEnum {
  numeric = "numeric", //greater than, less than, within range, out of range
  chronological = "chronological", //always greater than [time lapsed]....for _ minutes
  is_digital_boolean = "is_digital_boolean", //True/False
  is_digital_energy = "is_digital_energy" //Energized/De-energized
}

export interface IGrafanaAlertFields {
  display_name: string;
  db_name: string;
  unit_of_measure: string;
  alert_config: IAlertConfigEnum;
}

export interface IGrafanaAlertLog {
  id: number;
  alert_rule_id: number;
  state: string;
  date: number;
}

export interface IGrafanaAlertEditDTO {
  id: number;
  control_unit_id: number;
  alert_contact_point_id: number;
  alert_name: string;
  field_name: string; // correct: bath_temp | incorrect: Bath Temp
  evaluation_operation: string; // example: "gt" | "lt" | ""
  evaluation_value: number; // can be decimal
  evaluation_to_value?: number; // can be decimal (used only for outside_range & within_range)
  evaluation_for_period_minutes: number; // in seconds
  field_display_name: string;
  operation_display_name: string;
  unit_of_measure: string;
  alert_config: IAlertConfigEnum;
}
