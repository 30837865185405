import { createContext } from "react";
import { IControlUnitConfigContext } from "./types";

const defaultControlUnitContext: IControlUnitConfigContext = {
  currentConfigs: [],
  historicalConfigs: [],
  loading: true,
  setCurrentConfigs: () => null,
  setHistoricalConfigs: () => null,
};

const ControlUnitContext = createContext<IControlUnitConfigContext>(
  defaultControlUnitContext
);

export default ControlUnitContext;
