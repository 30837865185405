import { createContext } from "react";
import { IProcessUnitContext } from "./types";

const defaultProcessUnitContext: IProcessUnitContext = {
  processUnits: [],
  processUnit: null,
  processUnitBelongsToUserCompany: null,
  loading: true,
  setProcessUnits: () => null,
  setProcessUnit: () => null,
  setProcessUnitsBySiteId: () => null,
  setProcessUnitBelongsToUserCompany: () => null,
  setProcessUnitsByCompany: () => null,
};

const ProcessUnitContext = createContext<IProcessUnitContext>(
  defaultProcessUnitContext
);

export default ProcessUnitContext;
