import axios from "axios";
import { ICompanyCreateDTO, ICompanyEditDTO } from "../../types/companies";
import { getToken } from "../msTokenFunc";

export const getCompanyData = async (
  companyId: number,
  loadingFunc: Function
) => {
  try {
    loadingFunc(true);
    const token = await getToken();
    const result = await axios.get(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/company/${companyId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    loadingFunc(false);
    return result;
  } catch (error: any) {
    loadingFunc(false);
    return error.response;
  }
};

export const addCompany = async (newCompany: ICompanyCreateDTO) => {
  try {
    const token = await getToken();
    const result = await axios.post(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/company`,
      newCompany,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return result;
  } catch (error: any) {
    return error.response;
  }
};

export const updateCompany = async (updatedCompany: ICompanyEditDTO) => {
  try {
    const token = await getToken();
    const result = await axios.put(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/company`,
      updatedCompany,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result;
  } catch (error: any) {
    return error.response;
  }
};

export const getCompanyByUserId = async (
  userId: string,
  loadingFunc: Function
) => {
  try {
    loadingFunc(true);
    const token = await getToken();
    const result = await axios.get(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/company/byuser${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    loadingFunc(false);
    return result;
  } catch (error: any) {
    loadingFunc(false);
    return error.response;
  }
};

export const getAllCompanies = async (loadingFunc: Function) => {
  try {
    loadingFunc(true);
    const token = await getToken();
    const result = await axios.get(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/company`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    loadingFunc(false);
    return result;
  } catch (error: any) {
    loadingFunc(false);
    return error.response;
  }
};

export const getReadyCompanies = async (loadingFunc: Function) => {
  try {
    loadingFunc(true);
    const token = await getToken();
    const result = await axios.get(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/company/ready`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    loadingFunc(false);
    return result;
  } catch (error: any) {
    loadingFunc(false);
    return error.response;
  }
};

export const UpdateCompanyAPIKey = async (
  companyId: number
) => {
  try {
    const token = await getToken();
    const result = await axios.put(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/company/newkey/${companyId}`,
      updateCompany,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result;
  } catch (error: any) {
    return error.response;
  }
};

export const deleteCompany = async (companyId: number) => {
  try {
    const token = await getToken();
    const result = await axios.delete(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/company/${companyId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result;
  } catch (error: any) {
    return error.response;
  }
};

export const testGrafana = async (teamName: string, loadingFunc: Function) => {
  try {
    loadingFunc(true);
    const token = await getToken();
    await axios.post(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/grafana/test/${teamName}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    loadingFunc(false);
  } catch (error) {
    loadingFunc(false);
  }
};

export const setCompanyByIdFromAPI = async (
  companyId: number,
  loadingFunc: Function
) => {
  try {
    const token = await getToken();
    const result = await axios.get(
      `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/company/${companyId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    loadingFunc(false);
    return result;
  } catch (error: any) {
    loadingFunc(false);
    return error.response;
  }
};
