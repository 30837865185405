import styled from "styled-components";
import Logo from "../assets/images/login/PFConnect_White.png";
import BottomLogo from "../assets/images/login/Primary_White.png";
import { useMsal } from "@azure/msal-react";
import { loginRequest, loginRedirectRequest } from "../utilities/authConfig";
import { useEffect, useState } from "react";

interface LoginProps {
  setLoading: Function;
}

const Login: React.FC<LoginProps> = ({ setLoading }) => {
  const { instance } = useMsal();

  const [width, setWidth] = useState<number>(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const isMobile = width <= 768;

  const handleLogin = async () => {
    //this setLoading(true) prevents the Unauthorized Page from briefly flashing on login
    await setLoading(true);
    if (isMobile) {
      await instance.loginRedirect(loginRedirectRequest).then((info) => {});

      //nothing happens here post redirect
    } else {
      await instance.loginPopup(loginRequest).catch((error) => {
        //this catch needs to be here to prevent a crash that occurs if the user opens the login popup, then clicks the X to close it without choosing an account
        setLoading(false);
      });
    }
  };

  return (
    <BackgroundContainer>
      <SignInContainer>
        <SignInDiv>
          <TitleWrapper>
            <LogoImg data-testid="landing-img" />
          </TitleWrapper>
          <ButtonWrapper>
            <SignInButton onClick={handleLogin} data-testid="login-button">
              SIGN IN
            </SignInButton>
          </ButtonWrapper>
          <ContactContainer>
            <InterestedDiv data-testid="interested">INTERESTED?</InterestedDiv>
            <ContactUsDiv
              href="https://profireenergy.com/contact.html"
              data-testid="contact-us"
            >
              CONTACT US
            </ContactUsDiv>
          </ContactContainer>
        </SignInDiv>
        <ProfireLogoContainer>
          <ProfireLogoDiv>
            <BottomLogoImg data-testid="landing-img" />
          </ProfireLogoDiv>
        </ProfireLogoContainer>
      </SignInContainer>
    </BackgroundContainer>
  );
};

export default Login;

const SmallBreakpoint = "991px";

const BackgroundContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #1a1a1a;
  min-height: 100vh;
  min-height: 100dvh;
  @media (max-width: ${(props) => props.theme.tabletMaxBreakpoint}) {
    background: none;
    background-color: #000100;
  }
`;

const SignInContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const LogoImg = styled.img`
  content: url(${Logo});
  width: 284px;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    width: 534px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const SignInButton = styled.button`
  background-color: #9c1b30;
  font-size: ${(props) => props.theme.headingSecondarySize};
  font-weight: ${(props) => props.theme.headingSecondaryWeight};
  color: #fff;
  height: 34px;
  border: none;
  margin: 0;
  width: 279px;
  border-radius: 4px;
  justify-content: left;
  align-items: center;
  overflow: hidden;
  :hover {
    cursor: pointer;
    background-color: #b21f37;
  }
  outline: none;
  margin-top: 18px;
  margin-bottom: 5px;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    margin-top: 36px;
  }
`;

const ContactContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0px;
  @media (max-width: ${SmallBreakpoint}) {
    margin: 0px;
  }
  margin-top: 11px;
`;

const InterestedDiv = styled.div`
  color: white;
  text-align: center;
  margin-right: 20px;
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
`;

const ContactUsDiv = styled.a`
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.headingSecondaryWeight};
  color: white;
  text-align: center;
  text-decoration: none;
`;

const ProfireLogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const ProfireLogoDiv = styled.div`
  width: 142px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const SignInDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: Calc(100% - 172px - 52px);
`;

const BottomLogoImg = styled.img`
  content: url(${BottomLogo});
  width: 142px;
`;
