import React from "react";
import styled from "styled-components";
import CollapsibleLinkTableHeader from "./CollapsibleLinkTableHeader";
import CollapsibleLinkTableRow from "./CollapsibleLinkTableRow";

// Collapsible Link Table Component Instructions
// 1. Encase <CollapsibleLinkTable /> in a Styled div
// 2. Carefully read below to understand what each of the props do:
//  - headers: Ensure your headers exactly match the properties listed in the types file that your data uses
//    If you need to access object data *within* the data being passed, you can use objectName.propertyName as a header
//    Example: to access model in controlUnit, I can use control_unit_type.model as a header, and model will be extracted and displayed!
//  - overwriteHeaders: If you wish to use custom titles for your headers instead of the default right-most property name, enter the same amount of
//    custom names here as you have headers.
//  - data: this is the data needed to display in the main rows
//  - collapseHeaders: operate the same as "headers" above, but this is for the collapsible rows that appear when a row is clicked on
//  - collapseOverwriteHeaders: operate the same as overHeaders, but for the collapsible rows
//  - collapseData: the data to be displayed in the collapsible rows
//  - viewButtonUrl: this is the base url that the main rows will link to. do NOT include the unique key in this url. ex: "/sites/details/"
//  - viewButtonUrlAppendProperty: this is the name of the property that will be appended to the end of the viewButtonUrl (usually "id")
//    this will result in something like "/sites/details/43" as the url that the View Button on the main row will navigate to.
//  - collapseDatumFieldToMatchAppendProperty: this is an array of properties that must be drilled down into in order to access a property that matches viewButtonUrlAppendProperty
//    this match ensures that only data which is a child of the main row will be populated in the collapsible rows
// 3. Please do not touch the styling in this file. If you *do* need to change styling, please talk to TJ.
// 4. Currently, there isn't an option to set left/right/center text-align on individual columns without affecting other tables.
// 5. If there are any issues, please talk to TJ.

interface CollapsibleLinkTableProps {
  dataTestname: string;
  headers: string[];
  overwriteHeaders?: string[];
  data: any[];
  collapseHeaders: string[];
  collapseOverwriteHeaders?: string[];
  collapseCUs: any[];
  collapseMUs: any[];
  collapsePUs: any[];
  grafanaUrlProperty: string;
  viewButtonUrl: string;
  viewButtonUrlAppendProperty: string;
  collapseDatumFieldToMatchAppendProperty: string[];
}

const CollapsibleLinkTable: React.FC<CollapsibleLinkTableProps> = ({
  dataTestname,
  headers,
  overwriteHeaders,
  data,
  collapseHeaders,
  collapseOverwriteHeaders,
  collapseCUs,
  collapseMUs,
  collapsePUs,
  grafanaUrlProperty,
  viewButtonUrl,
  viewButtonUrlAppendProperty,
  collapseDatumFieldToMatchAppendProperty,
}) => {
  return (
    <StyledCollapsibleLinkTableDiv data-testname={dataTestname}>
      <StyledCollapsibleLinkTable
        data-testid="collapsible-table"
        className="table"
      >
        <thead>
          <CollapsibleLinkTableHeader
            dataTestname="collapsible-link-table-collapsible-link-table-header"
            headers={headers}
            overwriteHeaders={overwriteHeaders}
          />
        </thead>
        <tbody>
          {data.map((datum, dataIndex) => (
            <CollapsibleLinkTableRow
              dataTestname={`collapsible-link-table-collapsible-link-table-row-${datum.id}`}
              key={datum.id + dataIndex}
              datum={datum}
              dataIndex={dataIndex}
              headers={headers}
              collapseCUs={collapseCUs === undefined ? [] : collapseCUs}
              collapseMUs={collapseMUs === undefined ? [] : collapseMUs}
              collapsePUs={collapsePUs === undefined ? [] : collapsePUs}
              collapseHeaders={collapseHeaders}
              collapseOverwriteHeaders={collapseOverwriteHeaders}
              grafanaUrlProperty={grafanaUrlProperty}
              viewButtonUrl={viewButtonUrl}
              viewButtonUrlAppendProperty={viewButtonUrlAppendProperty}
              collapseDatumFieldToMatchAppendProperty={
                collapseDatumFieldToMatchAppendProperty
              }
            />
          ))}
        </tbody>
      </StyledCollapsibleLinkTable>
    </StyledCollapsibleLinkTableDiv>
  );
};

export default CollapsibleLinkTable;

const StyledCollapsibleLinkTableDiv = styled.div`
  overflow: auto;
  max-height: 600px;
  width: 100%;
  display: block;
  background-color: white;
`;

const StyledCollapsibleLinkTable = styled.table`
  table-layout: fixed;
  border-collapse: collapse;
  /* if you need to change the width value of the table, adjust min-width below, NOT width. */
  //though not necessary, 288px min-width will work on phones down to 320px width
  min-width: 288px;
  width: calc(100% - 10px);

  thead {
    position: sticky;
    top: 0;
    z-index: 2;
    background: white;
  }

  thead th {
    position: sticky;
    top: 0;
    z-index: 1;
    background: white;
  }

  tbody th {
    position: sticky;
  }

  thead th:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
  }

  tbody th {
    position: sticky;
    left: 0;
    z-index: 1;
  }

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    min-width: Calc(768px - 40px);
  }
`;
