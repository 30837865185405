import { IAlertConfigEnum } from "../types/alerts";
import { IControlUnit, IControlUnitAllInclusive } from "../types/controlUnits";
import { IMonitorUnit } from "../types/monitorUnits";
import { IProcessUnit } from "../types/processUnits";
import { ISite } from "../types/sites";

export const numeric: [string, string][] = [
    ["IS ABOVE", "gt"],
    ["IS BELOW", "lt"],
    ["IS OUTSIDE RANGE", "outside_range"],
    ["IS WITHIN RANGE", "within_range"],
  ];

export const chronological: [string, string][] = [
    ["IS GREATER THAN", "gt"],
];

export const digitalEnergy: [string, string][] = [
    ["IS ENERGIZED", "gt"],
    ["IS DE-ENERGIZED", "lt"],
];

export const digitalBoolean: [string, string][] = [
    ["IS TRUE", "gt"],
    ["IS FALSE", "lt"],
];

export const updateAlertConditionsArray = (switchField: IAlertConfigEnum) => {
    let arrayToReturn: [string, string][];

    switch (switchField) {
        case IAlertConfigEnum.chronological:
            arrayToReturn = chronological;
          break;
        case IAlertConfigEnum.numeric:
            arrayToReturn = numeric;
          break;
        case IAlertConfigEnum.is_digital_boolean:
            arrayToReturn = digitalBoolean;
          break;
        case IAlertConfigEnum.is_digital_energy:
            arrayToReturn = digitalEnergy;
          break;
        default:
            arrayToReturn = []
          break;
    }

    return arrayToReturn;
};


export const temperatureUnitsArray: [string, string][] = [
  ["Celcius", "°C"],
  ["Fahrenheit", "°F"],
];

export const fourTwentyPressureUnitsArray: [string, string][] = [
  ["PSI", "PSI"],
  ["kPa", "kPa"],
  ["inWC", "inWC"],
  ["oz", "oz"],
  ["ksc", "ksc"],
];

export const fourTwentyLevelUnitsArray: [string, string][] = [
  ["%", "%"],
  ["m3", "m3"],
  ["BBL", "BBL"],
  ["GAL", "GAL"],
  ["L", "L"],
];

export const updatePF2100UnitsArray = (switchField: string) => {
    let arrayToReturn: [string, string][];

    switch (switchField) {
      case "Aux Temp":
        arrayToReturn = temperatureUnitsArray;
        break;
      case "Process Temp":
        arrayToReturn = temperatureUnitsArray;
        break;
      case "4-20 Level":
        arrayToReturn = fourTwentyLevelUnitsArray;
        break;
      case "4-20 Pressure":
        arrayToReturn = fourTwentyPressureUnitsArray;
        break;
      default:
        arrayToReturn = []
      break;
    }

    return arrayToReturn;
};

export const blankSite: ISite = {
  id: 0,
  facility_name: "",
  facility_address: "",
  company_id: 0,
}

export const blankMonitorUnit: IMonitorUnit = {
  id: 0,
  monitor_unit_type_id: 0,
  site_id: 0,
  tag: "",
}

export const blankProcessUnit: IProcessUnit = {
  id: 0,
  burner_high_fire_pressure: null,
  burner_low_fire_pressure: null,
  burner_manufacturer: null,
  burner_model: null,
  burner_orifice_diameter: null,
  burner_rated_max_btu: null,
  fuel_gas_btu: null,
  fuel_gas_type_id: 0,
  high_fire_net_btu_per_hour: null,
  last_service_date: null,
  low_fire_net_btu_per_hour: null,
  process_unit_type_id: 0,
  site_id: 0,
  tag: "",
  vessel_manufacturer: null,
  vessel_model: null,
  vessel_rated_max_btu: null,
}

export const blankControlUnit: IControlUnit = {
  id: 0, 
  comm_address: "", 
  control_unit_type_id: 0, 
  monitor_unit_id: 0, 
  process_unit_id: 0, 
  tag: ""
}

export const blankControlUnitAllInclusive: IControlUnitAllInclusive = {
  id: 0, 
  comm_address: "", 
  control_unit_type_id: 0, 
  monitor_unit_id: 0, 
  process_unit_id: 0, 
  tag: "", 
  generic_modes: [], 
  control_unit_type: {
    id: 0,
    manufacturer: "", 
    model: ""
  }
}

export const backendErrorCodes: number[] = [400, 404, 500];