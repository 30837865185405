import { IRole } from "../../types/roles";
import { ActionTypes, IRoleState } from "./types";

type Action =
  | { type: ActionTypes.SET_ROLES; payload: IRole[] }
  | { type: ActionTypes.SENDING_REQUEST }
  | { type: ActionTypes.REQUEST_FINISHED };

const reducer = (state: IRoleState, action: Action) => {
  switch (action.type) {
    case ActionTypes.SET_ROLES:
      return {
        ...state,
        roles: action.payload,
      };
    case ActionTypes.SENDING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.REQUEST_FINISHED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
