import React, { useReducer } from "react";
import { ActionTypes, IMonitorUnitContext, IMonitorUnitState } from "./types";
import MonitorUnitReducer from "./MonitorUnitReducer";
import MonitorUnitContext from "./MonitorUnitContext";
import axios from "axios";
import { getToken } from "../../utilities/msTokenFunc";

const MonitorUnitProvider: React.FC<IMonitorUnitContext> = (props) => {
  const initialState: IMonitorUnitState = {
    monitorUnits: [],
    monitorUnit: null,
    monitorUnitBelongsToUserCompany: null,
    loading: true,
  };

  const [state, dispatch] = useReducer(MonitorUnitReducer, initialState);

  const setMonitorUnits = async () => {
    try {
      dispatch({ type: ActionTypes.SENDING_REQUEST });
      const token: string = await getToken();
      await axios
        .get(`${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/monitorunit`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) =>
          dispatch({ type: ActionTypes.SET_MONITOR_UNITS, payload: res.data })
        );
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    } catch (error) {
      dispatch({ type: ActionTypes.SET_MONITOR_UNITS, payload: [] });
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    }
  };

  const setMonitorUnit = async (id: number) => {
    try {
      dispatch({ type: ActionTypes.SENDING_REQUEST });
      const token: string = await getToken();
      await axios
        .get(
          `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/monitorunit/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) =>
          dispatch({ type: ActionTypes.SET_MONITOR_UNIT, payload: res.data })
        );
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    } catch (error) {
      dispatch({ type: ActionTypes.SET_MONITOR_UNIT, payload: null });
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    }
  };

  const setMonitorUnitsBySiteId = async (siteId: number) => {
    try {
      dispatch({ type: ActionTypes.SENDING_REQUEST });
      const token: string = await getToken();
      await axios
        .get(
          `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/monitorunit/bysite/${siteId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) =>
          dispatch({ type: ActionTypes.SET_MONITOR_UNITS, payload: res.data })
        );
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    } catch (error) {
      dispatch({ type: ActionTypes.SET_MONITOR_UNITS, payload: [] });
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    }
  };

  const setMonitorUnitBelongsToUserCompany = async (
    monitorUnitId: number,
    azureId: string,
    isProfireUser: boolean
  ) => {
    try {
      dispatch({ type: ActionTypes.SENDING_REQUEST });

      if (isProfireUser) {
        dispatch({
          type: ActionTypes.SET_MONITOR_UNIT_BELONGS_TO_USER_COMPANY,
          payload: true,
        });
      } else {
        const token: string = await getToken();
        await axios
          .get(
            `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/user/verifyusermonitorunit/${monitorUnitId}/${azureId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            dispatch({
              type: ActionTypes.SET_MONITOR_UNIT_BELONGS_TO_USER_COMPANY,
              payload: res.data,
            });
          });
      }

      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    } catch (error) {
      dispatch({
        type: ActionTypes.SET_MONITOR_UNIT_BELONGS_TO_USER_COMPANY,
        payload: false,
      });
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    }
  };

  const setMonitorUnitsByCompany = async (companyId: number) => {
    try {
      dispatch({ type: ActionTypes.SENDING_REQUEST });
      const token: string = await getToken();
      await axios
        .get(
          `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/monitorunit/bycompany/${companyId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) =>
          dispatch({ type: ActionTypes.SET_MONITOR_UNITS, payload: res.data })
        );
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    } catch (error) {
      dispatch({ type: ActionTypes.SET_MONITOR_UNITS, payload: [] });
      dispatch({ type: ActionTypes.REQUEST_FINISHED });
    }
  };

  return (
    <MonitorUnitContext.Provider
      value={{
        monitorUnits: state.monitorUnits,
        monitorUnit: state.monitorUnit,
        monitorUnitBelongsToUserCompany: state.monitorUnitBelongsToUserCompany,
        loading: state.loading,
        setMonitorUnits: setMonitorUnits,
        setMonitorUnit: setMonitorUnit,
        setMonitorUnitsBySiteId: setMonitorUnitsBySiteId,
        setMonitorUnitBelongsToUserCompany: setMonitorUnitBelongsToUserCompany,
        setMonitorUnitsByCompany: setMonitorUnitsByCompany,
      }}
    >
      {props.children}
    </MonitorUnitContext.Provider>
  );
};

export default MonitorUnitProvider;
