import { Configuration, PublicClientApplication } from "@azure/msal-browser";

export const getToken = async (): Promise<string> => {
  const cfg: Configuration = {
    auth: {
      clientId: process.env.REACT_APP_CLIENT_ID!,
      authority:
        process.env.REACT_APP_AUTHORITY!,
      redirectUri: process.env.REACT_APP_PORTAL_URL,
      postLogoutRedirectUri: process.env.REACT_APP_PORTAL_URL,
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: false,
    },
  };

  const pca = new PublicClientApplication(cfg);
  const accounts = pca.getAllAccounts();
  const account = accounts[0];

  const resp = await pca.acquireTokenSilent({
    scopes: [process.env.REACT_APP_SCOPE!],
    account,
  });

  return resp.accessToken;
};
