import { ISite } from "../../types/sites";

export interface ISiteContext {
  sites: ISite[];
  site: ISite | null;
  siteBelongsToUserCompany: boolean | null;
  loading: boolean;
  setSites: Function;
  setSite: Function;
  setSitesByCompanyId: Function;
  setSiteBelongsToUserCompany: Function;
  children?: React.ReactNode;
}

export interface ISiteState {
  sites: ISite[];
  site: ISite | null;
  siteBelongsToUserCompany: boolean | null;
  loading: boolean;
}

export enum ActionTypes {
  SET_SITES = "SET_SITES",
  SET_SITE = "SET_SITE",
  SET_SITE_BELONGS_TO_USER_COMPANY = "SET_SITE_BELONGS_TO_USER_COMPANY",
  SENDING_REQUEST = "SENDING_REQUEST",
  REQUEST_FINISHED = "REQUEST_FINISHED",
}

export interface SiteAction {
  type: ActionTypes;
  payload: number | string | boolean | ISite | ISite[];
}
