import { handleDateDisplay } from "./timeFunctions";
import { validateDateString } from "./validationFunctions";

export const PropertyAccess = (datum: any, header: string) => {
  let current = datum;
  let accessArray = header.split(".");
  accessArray.forEach((property) => {
    current = current[property];
  });
  return current;
};

export const HeaderAccess = (header: string) => {
  let accessArray = header.split(".");

  return accessArray[accessArray.length - 1];
};

export const FormatProperty = (datum: string) => {
  return datum === null || datum === undefined
    ? "N/A"
    : validateDateString(datum)
    ? handleDateDisplay(new Date(datum.toString()))
    : datum;
};
