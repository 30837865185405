import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Loading from "../components/common/modals/Loading";
import SiteContext from "../stores/sites/SiteContext";
import UserContext from "../stores/users/UserContext";
import Banner from "../components/common/headers/Banner";
import Tabs from "../components/common/tabs/Tabs";
import Tab from "../components/common/tabs/Tab";
import Breadcrumb from "../components/common/navigation/Breadcrumbs";
import { IBreadcrumbItem } from "../types/breadcrumb";
import InfoDisplay from "../components/common/tables/InfoDisplay";
import DefaultContainer from "../components/common/containers/DefaultContainer";
import EditButton from "../components/common/buttons/EditButton";
import DeleteButton from "../components/common/buttons/DeleteButton";
import ProfireButton from "../components/common/buttons/ProfireButton";
import EditSite from "../components/sites/EditSite";
import {
  deleteSite,
  editSite,
  setSiteBelongsToUserCompanyFromAPI,
  setSiteFromAPI,
} from "../utilities/apiCalls/sites";
import { ISite } from "../types/sites";
import { IProcessUnit, IProcessUnitCreateDTO } from "../types/processUnits";
import {
  addNewProcessUnit,
  setProcessUnitsBySiteFromAPI,
} from "../utilities/apiCalls/processUnits";
import { IMonitorUnit, IMonitorUnitCreateDTO } from "../types/monitorUnits";
import AddProcessUnit from "../components/processUnits/AddProcessUnit";
import AddMonitorUnit from "../components/monitorUnits/AddMonitorUnit";
import {
  addNewMonitorUnit,
  setMonitorUnitsBySiteFromAPI,
} from "../utilities/apiCalls/monitorUnits";
import ConfirmationBox from "../components/common/modals/ConfirmationBox";
import CommonCustomizableTable from "../components/common/tables/CommonCustomizableTable";
import { backendErrorCodes, blankSite } from "../utilities/staticObjects";

interface SingleSiteProps {}

const SingleSite: React.FC<SingleSiteProps> = () => {
  const userContext = useContext(UserContext);
  const userContextRef = useRef(userContext).current;
  const siteContext = useContext(SiteContext);
  const siteContextRef = useRef(siteContext).current;
  const params = useParams();

  const [loading, setLoading] = useState<boolean>(false);
  const [siteBelongsToUserCompany, setSiteBelongsToUserCompany] = useState<
    boolean | null
  >(null);
  const [site, setSite] = useState<ISite>();
  const [processUnits, setProcessUnits] = useState<IProcessUnit[]>([]);
  const [monitorUnits, setMonitorUnits] = useState<IMonitorUnit[]>([]);

  let headingText: string = "Invalid Site";
  let text: string =
    "This Site ID is invalid. If you believe this is an error, please contact technical support.";

  useEffect(() => {
    setSiteBelongsToUserCompanyFromAPI(
      parseInt(params.siteId!),
      userContextRef.user?.azure_id!,
      userContextRef.permissions?.profire_select_company!
    ).then((res: any) => {
      if (res.data === true) {
        setSiteBelongsToUserCompany(true);

        setSiteFromAPI(parseInt(params.siteId!), setLoading).then(
          (res: any) => {
            if (backendErrorCodes.includes(res.status)) {
              setSiteBelongsToUserCompany(false);
              setSite(blankSite);
            } else {
              setSite(res.data);
            }
          }
        );

        setProcessUnitsBySiteFromAPI(parseInt(params.siteId!), setLoading).then(
          (res: any) => {
            setProcessUnits(res.data);
          }
        );

        setMonitorUnitsBySiteFromAPI(parseInt(params.siteId!), setLoading).then(
          (res: any) => {
            setMonitorUnits(res.data);
          }
        );
      } else {
        //the site does not belong to the user's company
        setSiteBelongsToUserCompany(false);
        //fill site with empty data to prevent warnings
        setSite(blankSite);
      }
    });
  }, [userContextRef, siteContextRef, params.siteId]);
  const navigate = useNavigate();

  const crumb: IBreadcrumbItem[] = userContext.permissions
    ?.profire_select_company
    ? [
        {
          id: site?.company_id!,
          title: site?.company?.name!,
          url: userContext.permissions?.profire_select_company
            ? `/sites/${site?.company_id}`
            : `/sites`,
          prefix: "Company",
        },
      ]
    : [];

  //Edit Site
  //showEditSite is the bool that determines whether or not to display the Edit Site Modal
  const [showEditSite, setShowEditSite] = useState<boolean>(false);
  //editSiteCBActive is the bool that determines whether or not to display the Confirmation Box after clicking Save in Edit Site
  const [editSiteCBActive, setEditSiteCBActive] = useState<boolean>(false);
  //editSiteCBText is the string that determines what message to display in the Confirmation Box
  const [editSiteCBText, setEditSiteCBText] = useState<string>("");
  //editSiteCBFinalDisplay is the bool that determines when it is time to show the single button when using a 2 button ConfirmationBox
  const [editSiteCBFinalDisplay, setEditSiteCBFinalDisplay] =
    useState<boolean>(false);
  //retainedEditSite is the state that persists entered information that is used to repopulate the form in the event that the API Call errors out in the Confirmation Box
  const [retainedEditSite, setRetainedEditSite] = useState<ISite | undefined>();

  //toggles whether or not Edit Site is displayed
  function handleShowEditSite() {
    setShowEditSite(!showEditSite);
  }

  //This function runs on clicking Save in Edit Site
  function showEditSiteConfirmationBox(e: any) {
    setShowEditSite(false);
    setEditSiteCBActive(true);
    setEditSiteCBText("Are you sure you want to save these changes?");
    setRetainedEditSite(e);
  }

  //This function runs on clicking Cancel in Edit Site
  function hideEditSiteConfirmationBox(e: any) {
    setEditSiteCBActive(false);
    setEditSiteCBText("");
    setRetainedEditSite(undefined);
  }

  //This function runs on clicking Yes after clicking Save in Edit Site
  function handleEditSite() {
    setEditSiteCBText("");

    editSite(retainedEditSite!).then((res) => {
      if (res.status === 200 || res.status === 202) {
        setEditSiteCBText(
          `${retainedEditSite!.facility_name} successfully edited`
        );
        setSiteAfterEdit();
        setRetainedEditSite(undefined);
      } else {
        setEditSiteCBText(
          `The following issue was encountered while attempting to edit ${
            retainedEditSite!.facility_name
          }:\n\n${res.data.message}`
        );
      }
    });
    setEditSiteCBFinalDisplay(true);
  }

  //This function runs on clicking Ok after clicking Yes in Edit Site
  function completeEditSite(e: any) {
    setEditSiteCBActive(false);
    setEditSiteCBText("");
    setEditSiteCBFinalDisplay(false);

    if (retainedEditSite !== undefined) {
      setShowEditSite(true);
    }
  }

  //This function will get the latest information for the Site when called
  function setSiteAfterEdit() {
    setSiteFromAPI(parseInt(params.siteId!), setLoading).then((res: any) => {
      setSite(res.data);
    });
  }

  //Delete Site
  const [deleteSiteCBActive, setDeleteSiteCBActive] = useState<boolean>(false);
  const [deleteSiteCBText, setDeleteSiteCBText] = useState<string>("");
  //finalDisplay is the bool that determines when it is time to show the single button when using a 2 button ConfirmationBox
  const [deleteSiteCBFinalDisplay, setDeleteSiteCBFinalDisplay] =
    useState<boolean>(false);

  //This function runs on clicking Save in Delete Site
  function showDeleteSiteConfirmationBox(e: any) {
    setDeleteSiteCBActive(true);
    setDeleteSiteCBText(
      `Are you sure you want to delete ${site?.facility_name}?`
    );
  }

  //This function runs on clicking Cancel in Delete Site
  function hideDeleteSiteConfirmationBox(e: any) {
    setDeleteSiteCBActive(false);
    setDeleteSiteCBText("");
  }

  //This function runs on clicking Yes after clicking Save in Delete Site
  function handleDeleteSite() {
    setDeleteSiteCBText("");

    deleteSite(site?.id!).then((res) => {
      if (res.status === 200 || res.status === 202) {
        setDeleteSiteCBText(
          `${site?.facility_name} successfully deleted. You will now be redirected to the Sites page.`
        );
      } else {
        setDeleteSiteCBText(
          `The following issue was encountered while attempting to delete ${site?.facility_name}:\n\n${res.data.message}`
        );
      }
    });
    setDeleteSiteCBFinalDisplay(true);
  }

  //This function runs on clicking Ok after clicking Yes in Edit Site
  function completeDeleteSite(e: any) {
    setDeleteSiteCBActive(false);
    setDeleteSiteCBText("");
    setDeleteSiteCBFinalDisplay(false);
    navigate(`/sites/${site?.company?.id}`);
  }

  //Appliance Table states
  const [appliancesElements] = useState<string[]>([
    "Data",
    "Data",
    "Data",
    "Data",
    "RouteButton",
  ]);

  const [appliancesElementsInfo] = useState<string[]>(["", "", "", "", "View"]);

  const [appliancesHeaders] = useState<string[]>([
    "tag",
    "process_unit_type.type",
    "fuel_gas_type.type",
    "last_service_date",
    "",
  ]);

  const [appliancesOverwriteHeaders] = useState<string[]>([
    "Tag",
    "Type",
    "Fuel Type",
    "Fuel Service Date",
    "",
  ]);

  const viewApplianceOnClick = (appliance: any) => {
    navigate(`/processunits/details/${appliance?.id}`);
  };

  const [appliancesElementsOnClick] = useState<any[]>([
    null,
    null,
    null,
    null,
    viewApplianceOnClick,
  ]);

  //Add ProcessUnit
  const [showAddProcessUnit, setShowAddProcessUnit] = useState<boolean>(false);
  const [addProcessUnitCBActive, setAddProcessUnitCBActive] =
    useState<boolean>(false);
  const [addProcessUnitCBText, setAddProcessUnitCBText] = useState<string>("");
  const [retainedNewProcessUnit, setRetainedNewProcessUnit] = useState<
    IProcessUnitCreateDTO | undefined
  >();

  //this function toggles whether Add Process Unit modal is shown
  function handleShowAddProcessUnit() {
    if (showAddProcessUnit) {
      setRetainedNewProcessUnit(undefined);
    }
    setShowAddProcessUnit(!showAddProcessUnit);
  }

  //This function runs on clicking Save in Add ProcessUnit
  function handleAddProcessUnit(e: any) {
    setShowAddProcessUnit(false);
    setAddProcessUnitCBActive(true);
    addNewProcessUnit(e).then((res) => {
      if (res.status === 200 || res.status === 202) {
        setAddProcessUnitCBText(`${e.tag} successfully added`);
        setRetainedNewProcessUnit(undefined);
        setProcessUnitsAfterAdd();
      } else {
        setAddProcessUnitCBText(
          `The following issue was encountered while attempting to add ${e.tag}:\n\n${res.data.message}`
        );
        setRetainedNewProcessUnit(e);
      }
    });
  }

  //This function runs on clicking OK after clicking Save in Add Process Unit
  function completeAddProcessUnit() {
    setAddProcessUnitCBActive(false);
    setAddProcessUnitCBText("");

    if (retainedNewProcessUnit !== undefined) {
      setShowAddProcessUnit(true);
    }
  }

  //This function updates the list of process units after adding a new one
  function setProcessUnitsAfterAdd() {
    setProcessUnitsBySiteFromAPI(parseInt(params.siteId!), setLoading).then(
      (res: any) => {
        setProcessUnits(res.data);
      }
    );
  }

  //MonitorUnit Table states
  const [monitorUnitsElements] = useState<string[]>([
    "Data",
    "Data",
    "Data",
    "RouteButton",
  ]);

  const [monitorUnitsElementsInfo] = useState<string[]>(["", "", "", "View"]);

  const [monitorUnitsHeaders] = useState<string[]>([
    "tag",
    "monitor_unit_type.manufacturer",
    "monitor_unit_type.model",
    "",
  ]);

  const [monitorUnitsOverwriteHeaders] = useState<string[]>([
    "Tag",
    "Manufacturer",
    "Model",
    "",
  ]);

  const viewMonitorUnitOnClick = (monitorUnit: any) => {
    navigate(`/monitorunits/details/${monitorUnit?.id}`);
  };

  const [monitorUnitsElementsOnClick] = useState<any[]>([
    null,
    null,
    null,
    viewMonitorUnitOnClick,
  ]);

  //Add MonitorUnit
  const [showAddMonitorUnit, setShowAddMonitorUnit] = useState<boolean>(false);
  const [addMonitorUnitCBActive, setAddMonitorUnitCBActive] =
    useState<boolean>(false);
  const [addMonitorUnitCBText, setAddMonitorUnitCBText] = useState<string>("");
  const [retainedNewMonitorUnit, setRetainedNewMonitorUnit] = useState<
    IMonitorUnitCreateDTO | undefined
  >();

  function handleShowAddMonitorUnit() {
    if (showAddMonitorUnit) {
      setRetainedNewMonitorUnit(undefined);
    }
    setShowAddMonitorUnit(!showAddMonitorUnit);
  }

  //This function runs on clicking Save in Add MonitorUnit
  function handleAddMonitorUnit(e: IMonitorUnit) {
    setShowAddMonitorUnit(false);
    setAddMonitorUnitCBActive(true);
    addNewMonitorUnit(e).then((res) => {
      if (res.status === 200 || res.status === 202) {
        setAddMonitorUnitCBText(`${e.tag} successfully added`);
        setRetainedNewMonitorUnit(undefined);
        setMonitorUnitsAfterAdd();
      } else {
        setAddMonitorUnitCBText(
          `The following issue was encountered while attempting to add ${e.tag}:\n\n${res.data.message}`
        );
        setRetainedNewMonitorUnit(e);
      }
    });
  }

  //This function runs on clicking OK after clicking Save in Add Alert
  function completeAddMonitorUnit() {
    setAddMonitorUnitCBActive(false);
    setAddMonitorUnitCBText("");

    if (retainedNewMonitorUnit !== undefined) {
      setShowAddMonitorUnit(true);
    }
  }

  function setMonitorUnitsAfterAdd() {
    setMonitorUnitsBySiteFromAPI(parseInt(params.siteId!), setLoading).then(
      (res: any) => {
        setMonitorUnits(res.data);
      }
    );
  }

  if (
    loading ||
    userContext.loading ||
    siteBelongsToUserCompany === null ||
    site === undefined
  )
    return (
      <div className="fullPageLoadingContainer">
        <Loading dataTestname="single-site-loading" />
      </div>
    );
  return (
    <StyledSingleSiteDiv>
      {siteBelongsToUserCompany && (
        <Breadcrumb
          dataTestname="single-site-breadcrumbs"
          breadcrumbs={crumb}
        />
      )}
      {siteBelongsToUserCompany ? (
        <StyledMainDiv data-testid="main-div">
          <ConfirmationBox
            dataTestname="single-site-delete-confirmation-box"
            heading={"Information"}
            message={deleteSiteCBText}
            active={deleteSiteCBActive}
            onOk={completeDeleteSite}
            onYes={handleDeleteSite}
            onCancel={hideDeleteSiteConfirmationBox}
            displayCancel={true}
            finalDisplay={deleteSiteCBFinalDisplay}
          />
          {site !== undefined && (
            <DefaultContainer
              dataTestname="single-site-dark-default-container"
              darkBackground={true}
              title={"Site"}
            >
              <InfoDisplay
                dataTestname="single-site-info-display"
                datum={site}
                headers={[
                  "facility_name",
                  "facility_address",
                  "longitude",
                  "latitude",
                ]}
                headerWidth={"8.5%"}
                colonWidth={"3%"}
                infoWidth={"Calc(100% - 121px - 42px)"}
                containerWidth={"100%"}
                headerMinWidth={"121px"}
                colonMinWidth={"21px"}
                infoMinWidth={"118px"}
                containerMinWidth={"100%"}
                rightSpacerWidth={"0%"}
              />

              <StyledButtonsDiv data-testid="edit-delete-button">
                {userContext.permissions?.profire_manage_sites && (
                  <EditButton
                    dataTestname="single-site-edit-button"
                    onClickFunction={handleShowEditSite}
                  />
                )}
                {userContext.permissions?.profire_manage_sites && (
                  <DeleteButton
                    dataTestname="single-site-delete-button"
                    onClickFunction={showDeleteSiteConfirmationBox}
                  />
                )}
              </StyledButtonsDiv>
              <ConfirmationBox
                dataTestname="single-site-edit-confirmation-box"
                heading={"Information"}
                message={editSiteCBText}
                active={editSiteCBActive}
                onOk={completeEditSite}
                onYes={handleEditSite}
                onCancel={hideEditSiteConfirmationBox}
                displayCancel={true}
                finalDisplay={editSiteCBFinalDisplay}
              />
              {showEditSite && (
                <EditSite
                  active={showEditSite}
                  site={site!}
                  onCancel={handleShowEditSite}
                  onEdit={showEditSiteConfirmationBox}
                  retainedData={retainedEditSite}
                />
              )}
            </DefaultContainer>
          )}
          <Tabs dataTestname="=single-site-tabs">
            <Tab dataTestname="single-site-appliances-tab" title="Appliances">
              <StyledCommonTableDiv>
                <ConfirmationBox
                  dataTestname="single-site-add-process-unit-confirmation-box"
                  heading={"Information"}
                  message={addProcessUnitCBText}
                  active={addProcessUnitCBActive}
                  onOk={completeAddProcessUnit}
                  displayCancel={false}
                />
                {userContext.permissions?.profire_manage_process_units && (
                  <StyledAddButtonDiv data-testid="add-process-unit-button-div">
                    <ProfireButton
                      dataTestname="single-site-add-appliance-button"
                      onClickFunction={handleShowAddProcessUnit}
                      text="Add Appliance"
                      size={6}
                    />
                    {showAddProcessUnit && (
                      <AddProcessUnit
                        active={showAddProcessUnit}
                        siteId={site.id}
                        onCancel={handleShowAddProcessUnit}
                        onAdd={handleAddProcessUnit}
                        retainedData={retainedNewProcessUnit}
                      />
                    )}
                  </StyledAddButtonDiv>
                )}
                <CommonCustomizableTable
                  dataTestname="single-site-appliance-common-customizable-table"
                  usageIdentifier="appliance"
                  data={processUnits}
                  elements={appliancesElements}
                  elementsInfo={appliancesElementsInfo}
                  headers={appliancesHeaders}
                  overwriteHeaders={appliancesOverwriteHeaders}
                  tableWidth={"100%"}
                  tableMinWidth={"100%"}
                  tableMaxHeight={"400px"}
                  desktopColWidths={["20%", "20%", "20%", "20%", "20%"]}
                  desktopColMinWidths={["20%", "20%", "20%", "20%", "20%"]}
                  mobileColWidths={["50%", "50%", "0px", "0px", "0px"]}
                  mobileColMinWidths={["50%", "50%", "0px", "0px", "0px"]}
                  elementsOnClick={appliancesElementsOnClick}
                  textAlign={["left", "left", "left", "left", "right"]}
                  textAlignMobile={["left", "right", "left", "left", "right"]}
                  tdHeight={"45px"}
                  tdHeightMobile={"33px"}
                  tdMinHeight={"45px"}
                  tdMinHeightMobile={"33px"}
                  addPaddingRightForScroll={true}
                  colDisplayOnMobile={[true, true, false, false, false]}
                  colJustify={["start", "start", "start", "start", "end"]}
                  colFlexDirection={[
                    "column",
                    "column",
                    "column",
                    "column",
                    "row",
                  ]}
                  mobileRowOnClick={viewApplianceOnClick}
                />
                {userContext.permissions?.profire_manage_process_units && (
                  <StyledAddButtonMobileDiv data-testid="add-process-unit-button-div">
                    <ProfireButton
                      dataTestname="single-site-add-appliance-button"
                      onClickFunction={handleShowAddProcessUnit}
                      text="Add Appliance"
                      size={6}
                    />
                    {showAddProcessUnit && (
                      <AddProcessUnit
                        active={showAddProcessUnit}
                        siteId={site.id}
                        onCancel={handleShowAddProcessUnit}
                        onAdd={handleAddProcessUnit}
                        retainedData={retainedNewProcessUnit}
                      />
                    )}
                  </StyledAddButtonMobileDiv>
                )}
              </StyledCommonTableDiv>
            </Tab>
            <Tab
              dataTestname="single-site-monitor-units-tab"
              title="Monitor Units"
            >
              <StyledCommonTableDiv>
                <ConfirmationBox
                  dataTestname="single-site-add-monitor-unit-confirmation-box"
                  heading={"Information"}
                  message={addMonitorUnitCBText}
                  active={addMonitorUnitCBActive}
                  onOk={completeAddMonitorUnit}
                  displayCancel={false}
                />
                {userContext.permissions?.profire_manage_monitor_units && (
                  <StyledAddButtonDiv data-testid="add-monitor-unit-button-div">
                    <ProfireButton
                      dataTestname="single-site-add-monitor-unit-button"
                      onClickFunction={handleShowAddMonitorUnit}
                      text="Add Monitor Unit"
                      size={6}
                    />
                    {showAddMonitorUnit && (
                      <AddMonitorUnit
                        active={showAddMonitorUnit}
                        siteId={site.id}
                        onCancel={handleShowAddMonitorUnit}
                        onAdd={handleAddMonitorUnit}
                        retainedData={retainedNewMonitorUnit}
                      />
                    )}
                  </StyledAddButtonDiv>
                )}
                <CommonCustomizableTable
                  dataTestname="single-site-monitor-unit-common-customizable-table"
                  usageIdentifier="monitorUnit"
                  data={monitorUnits}
                  elements={monitorUnitsElements}
                  elementsInfo={monitorUnitsElementsInfo}
                  headers={monitorUnitsHeaders}
                  overwriteHeaders={monitorUnitsOverwriteHeaders}
                  tableWidth={"100%"}
                  tableMinWidth={"100%"}
                  tableMaxHeight={"400px"}
                  desktopColWidths={["25%", "25%", "25%", "25%"]}
                  desktopColMinWidths={["25%", "25%", "25%", "25%"]}
                  mobileColWidths={["50%", "50%", "0px", "0px"]}
                  mobileColMinWidths={["50%", "0px", "50%", "0px"]}
                  elementsOnClick={monitorUnitsElementsOnClick}
                  textAlign={["left", "left", "left", "right"]}
                  textAlignMobile={["left", "left", "right", "right"]}
                  tdHeight={"45px"}
                  tdHeightMobile={"33px"}
                  tdMinHeight={"45px"}
                  tdMinHeightMobile={"33px"}
                  addPaddingRightForScroll={true}
                  colDisplayOnMobile={[true, false, true, false]}
                  colJustify={["start", "start", "start", "end"]}
                  colFlexDirection={["column", "column", "column", "row"]}
                  mobileRowOnClick={viewMonitorUnitOnClick}
                />
                {userContext.permissions?.profire_manage_monitor_units && (
                  <StyledAddButtonMobileDiv data-testid="add-monitor-unit-button-div">
                    <ProfireButton
                      dataTestname="single-site-add-monitor-unit-button"
                      onClickFunction={handleShowAddMonitorUnit}
                      text="Add Monitor Unit"
                      size={6}
                    />
                    {showAddMonitorUnit && (
                      <AddMonitorUnit
                        active={showAddMonitorUnit}
                        siteId={site.id}
                        onCancel={handleShowAddMonitorUnit}
                        onAdd={handleAddMonitorUnit}
                        retainedData={retainedNewMonitorUnit}
                      />
                    )}
                  </StyledAddButtonMobileDiv>
                )}
              </StyledCommonTableDiv>
            </Tab>
          </Tabs>
        </StyledMainDiv>
      ) : (
        <StyledMainDiv data-testid="main-div">
          <StyledBannerDiv data-testid="banner-div">
            <Banner
              dataTestname="single-site-banner"
              headingtext={headingText}
              text={text}
              redBox={true}
            />
          </StyledBannerDiv>
        </StyledMainDiv>
      )}
    </StyledSingleSiteDiv>
  );
};

export default SingleSite;

const StyledSingleSiteDiv = styled.div`
  width: Calc(100% - 32px);
  max-width: Calc(1200px - 40px);
  min-width: Calc(320px - 40px);
  margin: 60px 16px 20px 16px;
  height: Calc(100% - 80px);
  background-color: white;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    margin: 60px 20px 20px 20px;
    width: Calc(100% - 40px);
    background-color: ${(props) => props.theme.bgColor};
  }
`;

const StyledMainDiv = styled.div`
  height: calc(100% - 33px);
  width: 100%;
`;

const StyledBannerDiv = styled.div`
  min-width: Calc(300px - 32px);
  width: Calc(100% - 32px);
  padding: 20px 16px;
  margin-bottom: 25px;
  border-radius: 10px;
`;

const StyledAddButtonDiv = styled.div`
  width: 100%;
  margin-bottom: 15px;
  text-align: right;
  display: none;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    display: block;
  }
`;

const StyledAddButtonMobileDiv = styled.div`
  width: 100%;
  margin-top: 12px;
  text-align: right;
  display: block;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    display: none;
  }
`;

const StyledCommonTableDiv = styled.div`
  width: Calc(100% - 40px);
  background-color: #ffffff;
  padding: 20px;
  border-radius: 4px;
  text-align: left;
`;

const StyledButtonsDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;
