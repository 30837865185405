import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Dropdown from "../common/formInputs/Dropdown";
import MonitorUnitContext from "../../stores/monitorUnits/MonitorUnitContext";
import { IControlUnit } from "../../types/controlUnits";
import LookupContext from "../../stores/lookup/LookupContext";
import { validateTextString } from "../../utilities/validationFunctions";
import Banner from "../common/headers/Banner";
import UserContext from "../../stores/users/UserContext";
import CommonInput from "../common/formInputs/CommonInput";
import ProfireButton from "../common/buttons/ProfireButton";
import ModalBox from "../common/modals/ModalBox";

interface EditControlUnitProps {
  active: boolean;
  controlUnit: IControlUnit;
  onCancel?: () => void;
  onEdit: Function;
  retainedData: IControlUnit | undefined;
}

let existingErrors: Boolean = false;

const EditControlUnit: React.FC<EditControlUnitProps> = ({
  active,
  controlUnit,
  onCancel,
  onEdit,
  retainedData,
}) => {
  const userContext = useContext(UserContext);
  const lookupContext = useContext(LookupContext);
  const lookupContextRef = useRef(lookupContext).current;
  const monitorUnitContext = useContext(MonitorUnitContext);
  const monitorUnitContextRef = useRef(monitorUnitContext).current;

  let headingText: string = "Insufficient Access";
  let text: string =
    "You do not have sufficient access to use this page. If you believe this is an error, please contact technical support.";
  useEffect(() => {
    lookupContextRef.setControlUnitTypes();
    monitorUnitContextRef.setMonitorUnitsBySiteId(
      controlUnit.process_unit?.site?.id
    );
  }, [
    monitorUnitContextRef,
    lookupContextRef,
    controlUnit.process_unit?.site?.id,
  ]);

  const [chosenMonitorUnit, setChosenMonitorUnit] = useState<number>(
    retainedData === undefined
      ? controlUnit?.monitor_unit_id === undefined ||
        controlUnit.monitor_unit_id === null
        ? 0
        : controlUnit?.monitor_unit_id
      : retainedData.monitor_unit_id === null
      ? 0
      : retainedData.monitor_unit_id
  );
  function updateChosenMonitorUnit(newChosen: string) {
    setChosenMonitorUnit(parseInt(newChosen));
    setChosenMonitorUnitErrors("");
  }
  let monitorUnitsArray: [string, string][] = [];
  //populate monitorUnitsArray for dropdown
  monitorUnitsArray.push(["Select a monitor unit...", "-1"]);
  monitorUnitsArray.push(["None", "0"]);
  for (let index = 0; index < monitorUnitContext.monitorUnits.length; index++) {
    monitorUnitsArray.push([
      monitorUnitContext.monitorUnits[index].tag,
      monitorUnitContext.monitorUnits[index].id.toString(),
    ]);
  }

  const [chosenMonitorUnitErrors, setChosenMonitorUnitErrors] =
    useState<string>("");

  const [tag, setTag] = useState<string>(
    retainedData === undefined
      ? controlUnit?.tag === undefined
        ? ""
        : controlUnit?.tag
      : retainedData.tag
  );
  const [tagErrors, setTagErrors] = useState<string>("");

  const handleTag = (e: React.FormEvent<HTMLInputElement>) => {
    if (
      !validateTextString(e.currentTarget.value) &&
      e.currentTarget.value !== ""
    ) {
      setTag(e.currentTarget.value);
      setTagErrors(
        "First character must be alphanumeric and only alphanumeric, spaces, and the following are supported after: | _ : , . # - / '"
      );
    } else {
      setTag(e.currentTarget.value);
      setTagErrors("");
    }
  };

  const [commAddress, setCommAddress] = useState<string>(
    retainedData === undefined
      ? controlUnit?.comm_address === undefined
        ? ""
        : controlUnit?.comm_address
      : retainedData.comm_address
  );
  const [commAddressErrors, setCommAddressErrors] = useState<string>("");

  const handleCommAddress = (e: React.FormEvent<HTMLInputElement>) => {
    if (
      !validateTextString(e.currentTarget.value) &&
      e.currentTarget.value !== ""
    ) {
      setCommAddress(e.currentTarget.value);
      setCommAddressErrors(
        "First character must be alphanumeric and only alphanumeric, spaces, and the following are supported after: | _ : , . # - / '"
      );
    } else {
      setCommAddress(e.currentTarget.value);
      setCommAddressErrors("");
    }
  };

  function handleCancel() {
    existingErrors = false;
    onCancel!();
  }

  function handleExistingErrors(errors: Boolean) {
    existingErrors = errors;
  }

  function clearErrors() {
    setChosenMonitorUnitErrors("");
    setCommAddressErrors("");
    setTagErrors("");

    handleExistingErrors(false);
  }

  function ErrorHandler() {
    clearErrors();

    if (chosenMonitorUnit === -1) {
      setChosenMonitorUnitErrors("Monitor unit is required.\n");
      handleExistingErrors(true);
    }
    if (chosenMonitorUnit > 0 && !commAddress) {
      setCommAddressErrors(
        "Comm Address is required while a Monitor Unit is selected.\n"
      );
      handleExistingErrors(true);
    }
    if (commAddress) {
      if (!validateTextString(commAddress)) {
        setCommAddressErrors(
          "First character must be alphanumeric and only alphanumeric, spaces, and the following are supported after: | _ : , . # - / '"
        );
        handleExistingErrors(true);
      }
    }
    if (!tag) {
      setTagErrors("Tag is required.\n");
      handleExistingErrors(true);
    }
    if (tag) {
      if (!validateTextString(tag)) {
        setTagErrors(
          "First character must be alphanumeric and only alphanumeric, spaces, and the following are supported after: | _ : , . # - / '"
        );
        handleExistingErrors(true);
      }
    }
  }

  function onSubmit(e: any) {
    e.preventDefault();

    if (!existingErrors) {
      const updatedControlUnit: IControlUnit = {
        id: controlUnit?.id!,
        control_unit_type_id: controlUnit.control_unit_type_id,
        monitor_unit_id: chosenMonitorUnit === 0 ? null : chosenMonitorUnit,
        process_unit_id: controlUnit?.process_unit_id!,
        comm_address: commAddress,
        tag: tag,
      };

      onEdit(updatedControlUnit);
    }
  }

  return (
    <ModalBox
      dataTestname="edit-control-unit-modal-box"
      active={active}
      desktopContainerMinWidth={"765px"}
      mobileContainerMinWidth={"320px"}
      containerTitle="Edit Control Unit"
      maxHeight={"50%"}
    >
      <StyledEditControlUnitDiv>
        {userContext.permissions?.profire_manage_control_units ? (
          <StyledMainDiv data-testid="main-div">
            <StyledForm onSubmit={onSubmit}>
              <StyledSectionDiv>
                <CommonInput
                  dataTestname="edit-control-unit-appliance-common-input"
                  type={"text"}
                  labelText={"Appliance"}
                  required={false}
                  readOnly={true}
                  value={controlUnit?.process_unit?.tag!}
                  placeholder={"Appliance"}
                />
                <Dropdown
                  dataTestname="edit-control-unit-monitor-unit-dropdown"
                  selected={
                    chosenMonitorUnit === null
                      ? "0"
                      : chosenMonitorUnit.toString()
                  }
                  onchange={updateChosenMonitorUnit}
                  columns={monitorUnitsArray}
                  labelText={"Monitor Unit"}
                  required={false}
                  errors={chosenMonitorUnitErrors}
                  autoFocus={true}
                />
                <CommonInput
                  dataTestname="edit-control-unit-control-unit-type-common-input"
                  type={"text"}
                  labelText={"Control Unit Type"}
                  required={false}
                  readOnly={true}
                  value={controlUnit?.control_unit_type?.model!}
                  placeholder={"Control Unit Type"}
                />
                <CommonInput
                  dataTestname="edit-control-unit-comm-address-common-input"
                  type={"text"}
                  labelText={
                    "Comm Address (required if Monitor Unit is chosen)"
                  }
                  required={false}
                  readOnly={false}
                  value={commAddress === null ? "" : commAddress}
                  onChange={handleCommAddress}
                  maxLength={50}
                  placeholder={"Comm Address"}
                  errors={commAddressErrors}
                />
                <CommonInput
                  dataTestname="edit-control-unit-tag-common-input"
                  type={"text"}
                  labelText={"Tag"}
                  required={true}
                  readOnly={false}
                  value={tag}
                  onChange={handleTag}
                  maxLength={50}
                  placeholder={"Tag"}
                  errors={tagErrors}
                />
                {existingErrors && (
                  <StyledErrorMessage data-testid="submit-errors">
                    {"Please fix above errors and resubmit."}
                  </StyledErrorMessage>
                )}
                <StyledButtonRow>
                  <StyledCancelButtonHolder>
                    <ProfireButton
                      dataTestname="add-control-unit-cancel-button"
                      text="Cancel"
                      onClickFunction={handleCancel}
                    />
                  </StyledCancelButtonHolder>
                  <StyledSaveButtonHolder>
                    <ProfireButton
                      dataTestname="add-control-unit-save-button"
                      text="Save"
                      onClickFunction={ErrorHandler}
                      defaultButton={true}
                    />
                  </StyledSaveButtonHolder>
                </StyledButtonRow>
              </StyledSectionDiv>
            </StyledForm>
          </StyledMainDiv>
        ) : (
          <StyledMainDiv data-testid="main-div">
            <StyledBannerDiv
              allowed={userContext.permissions?.profire_manage_control_units!}
              data-testid="banner-div"
            >
              <Banner
                dataTestname="edit-control-unit-banner"
                headingtext={headingText}
                text={text}
              />
            </StyledBannerDiv>
          </StyledMainDiv>
        )}
      </StyledEditControlUnitDiv>
    </ModalBox>
  );
};

export default EditControlUnit;

const StyledEditControlUnitDiv = styled.div`
  width: calc(100% - 10px);
  height: 100%;
  overflow-y: auto;
  padding-right: 10px;
`;

const StyledMainDiv = styled.div`
  height: 100%;
  width: 100%;
`;

interface AllowedProps {
  allowed: boolean;
}

const StyledBannerDiv = styled.div<AllowedProps>`
  min-width: Calc(300px - 32px);
  width: Calc(100% - 32px);
  background-color: ${(props) => (props.allowed ? "#ffffff" : "#9c1b30")};
  padding: 20px 16px;
  margin-bottom: 25px;
  border-radius: 10px;
`;

const StyledErrorMessage = styled.div`
  text-align: left;
  color: #9c1b30;
  font-size: ${(props) => props.theme.contentErrorSize};
  font-weight: ${(props) => props.theme.contentErrorWeight};

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
`;

const StyledSectionDiv = styled.div`
  width: Calc(100%);
  background-color: #ffffff;
  border-radius: 10px;
  text-align: left;
`;

const StyledForm = styled.form``;

const StyledButtonRow = styled.div`
  width: 100%;
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

const StyledCancelButtonHolder = styled.div`
  margin-right: 20px;
`;

const StyledSaveButtonHolder = styled.div``;
