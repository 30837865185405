import React from "react";
import styled from "styled-components";
import {
  FormatProperty,
  HeaderAccess,
  PropertyAccess,
} from "../../../utilities/propertyAccess";
import { FormatHeader } from "../../../utilities/tableHeaderFix";

interface InfoProps {
  dataTestname: string;
  datum: any;
  headers: string[];
  overwriteHeaders?: string[];
  containerWidth: string;
  containerMinWidth: string;
  headerWidth: string;
  headerMinWidth: string;
  colonWidth: string;
  colonMinWidth: string;
  infoWidth: string;
  infoMinWidth: string;
  greyBoxTitle?: string;
  greyBoxHeaders?: string[];
  greyBoxOverwriteHeaders?: string[];
  greyBoxContainerWidth?: string;
  greyBoxContainerMinWidth?: string;
  greyBoxHeaderWidth?: string;
  greyBoxHeaderMinWidth?: string;
  greyBoxColonWidth?: string;
  greyBoxColonMinWidth?: string;
  greyBoxInfoWidth?: string;
  greyBoxInfoMinWidth?: string;
  rightSpacerWidth?: string;
  rightSpacerMinWidth?: string;
}

const InfoDisplay: React.FC<InfoProps> = ({
  dataTestname,
  datum,
  headers,
  overwriteHeaders,
  containerWidth,
  containerMinWidth,
  greyBoxTitle,
  greyBoxHeaders,
  greyBoxOverwriteHeaders,
  greyBoxContainerWidth,
  greyBoxContainerMinWidth,
  headerWidth,
  headerMinWidth,
  colonWidth,
  colonMinWidth,
  infoWidth,
  infoMinWidth,
  greyBoxHeaderWidth,
  greyBoxHeaderMinWidth,
  greyBoxColonWidth,
  greyBoxColonMinWidth,
  greyBoxInfoWidth,
  greyBoxInfoMinWidth,
  rightSpacerWidth,
  rightSpacerMinWidth,
}) => {
  return (
    <StyledFullWidthDiv>
      <StyledInfoContainer
        data-testname={dataTestname}
        data-testid="info-container"
        greyBoxExists={
          greyBoxHeaders === undefined || greyBoxHeaders.length === 0
            ? false
            : true
        }
        width={containerWidth}
        minWidth={containerMinWidth}
      >
        {headers.map((header, index) =>
          header.includes(".") ? (
            <StyledInfoRow key={index}>
              {overwriteHeaders ? (
                <StyledHeading
                  data-testid="info-heading"
                  greyBoxExists={
                    greyBoxHeaders === undefined || greyBoxHeaders.length === 0
                      ? false
                      : true
                  }
                  width={headerWidth}
                  minWidth={headerMinWidth}
                >
                  {overwriteHeaders[index]}
                </StyledHeading>
              ) : (
                <StyledHeading
                  data-testid="info-heading"
                  greyBoxExists={
                    greyBoxHeaders === undefined || greyBoxHeaders.length === 0
                      ? false
                      : true
                  }
                  width={headerWidth}
                  minWidth={headerMinWidth}
                >
                  {FormatHeader(HeaderAccess(header))}
                </StyledHeading>
              )}
              <StyledColon
                greyBoxExists={
                  greyBoxHeaders === undefined || greyBoxHeaders.length === 0
                    ? false
                    : true
                }
                width={colonWidth}
                minWidth={colonMinWidth}
                data-testid="info-colon"
              >
                :
              </StyledColon>
              <StyledInfo
                data-testid="info-data"
                greyBoxExists={
                  greyBoxHeaders === undefined || greyBoxHeaders.length === 0
                    ? false
                    : true
                }
                width={infoWidth}
                minWidth={infoMinWidth}
              >
                {PropertyAccess(datum, header)}
              </StyledInfo>
            </StyledInfoRow>
          ) : (
            <StyledInfoRow key={index}>
              {overwriteHeaders ? (
                <StyledHeading
                  data-testid="info-heading"
                  greyBoxExists={
                    greyBoxHeaders === undefined || greyBoxHeaders.length === 0
                      ? false
                      : true
                  }
                  width={headerWidth}
                  minWidth={headerMinWidth}
                >
                  {overwriteHeaders[index]}
                </StyledHeading>
              ) : (
                <StyledHeading
                  data-testid="info-heading"
                  greyBoxExists={
                    greyBoxHeaders === undefined || greyBoxHeaders.length === 0
                      ? false
                      : true
                  }
                  width={headerWidth}
                  minWidth={headerMinWidth}
                >
                  {FormatHeader(header)}
                </StyledHeading>
              )}
              <StyledColon
                greyBoxExists={
                  greyBoxHeaders === undefined || greyBoxHeaders.length === 0
                    ? false
                    : true
                }
                width={colonWidth}
                minWidth={colonMinWidth}
                data-testid="info-colon"
              >
                :
              </StyledColon>
              <StyledInfo
                data-testid="info-data"
                greyBoxExists={
                  greyBoxHeaders === undefined || greyBoxHeaders.length === 0
                    ? false
                    : true
                }
                width={infoWidth}
                minWidth={infoMinWidth}
              >
                {FormatProperty(datum[header])}
              </StyledInfo>
            </StyledInfoRow>
          )
        )}
      </StyledInfoContainer>
      {greyBoxHeaders && (
        <StyledGreyBoxContainer
          width={greyBoxContainerWidth!}
          minWidth={greyBoxContainerMinWidth!}
          data-testid="grey-container"
        >
          <StyledGreyBoxInfoContainer>
            <StyledGreyBoxTitle data-testid="grey-title">
              {greyBoxTitle}
            </StyledGreyBoxTitle>
            {greyBoxHeaders.map((header, index) =>
              header.includes(".") ? (
                <StyledInfoRow key={index}>
                  {greyBoxOverwriteHeaders ? (
                    <StyledGreyBoxHeading
                      width={greyBoxHeaderWidth!}
                      minWidth={greyBoxHeaderMinWidth!}
                      data-testid="grey-heading"
                      infoHeaderWidth={headerWidth}
                      infoHeaderMinWidth={headerMinWidth}
                    >
                      {greyBoxOverwriteHeaders[index]}
                    </StyledGreyBoxHeading>
                  ) : (
                    <StyledGreyBoxHeading
                      width={greyBoxHeaderWidth!}
                      minWidth={greyBoxHeaderMinWidth!}
                      data-testid="grey-heading"
                      infoHeaderWidth={headerWidth}
                      infoHeaderMinWidth={headerMinWidth}
                    >
                      {FormatHeader(HeaderAccess(header))}
                    </StyledGreyBoxHeading>
                  )}
                  <StyledGreyBoxColon
                    width={greyBoxColonWidth!}
                    minWidth={greyBoxColonMinWidth!}
                    data-testid="grey-colon"
                  >
                    :
                  </StyledGreyBoxColon>
                  <StyledGreyBoxInfo
                    width={greyBoxInfoWidth!}
                    minWidth={greyBoxInfoMinWidth!}
                    data-testid="grey-data"
                  >
                    {FormatProperty(PropertyAccess(datum, header))}
                  </StyledGreyBoxInfo>
                </StyledInfoRow>
              ) : (
                <StyledInfoRow key={index}>
                  {greyBoxOverwriteHeaders ? (
                    <StyledGreyBoxHeading
                      width={greyBoxHeaderWidth!}
                      minWidth={greyBoxHeaderMinWidth!}
                      data-testid="grey-heading"
                      infoHeaderWidth={headerWidth}
                      infoHeaderMinWidth={headerMinWidth}
                    >
                      {greyBoxOverwriteHeaders[index]}
                    </StyledGreyBoxHeading>
                  ) : (
                    <StyledGreyBoxHeading
                      width={greyBoxHeaderWidth!}
                      minWidth={greyBoxHeaderMinWidth!}
                      data-testid="grey-heading"
                      infoHeaderWidth={headerWidth}
                      infoHeaderMinWidth={headerMinWidth}
                    >
                      {FormatHeader(header)}
                    </StyledGreyBoxHeading>
                  )}
                  <StyledGreyBoxColon
                    width={greyBoxColonWidth!}
                    minWidth={greyBoxColonMinWidth!}
                    data-testid="grey-colon"
                  >
                    :
                  </StyledGreyBoxColon>
                  <StyledGreyBoxInfo
                    width={greyBoxInfoWidth!}
                    minWidth={greyBoxInfoMinWidth!}
                    data-testid="grey-data"
                  >
                    {FormatProperty(datum[header])}
                  </StyledGreyBoxInfo>
                </StyledInfoRow>
              )
            )}
          </StyledGreyBoxInfoContainer>
        </StyledGreyBoxContainer>
      )}
      <StyledGreyBoxRightSpacer
        width={rightSpacerWidth!}
        minWidth={rightSpacerMinWidth!}
      />
    </StyledFullWidthDiv>
  );
};

export default InfoDisplay;

const StyledFullWidthDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    display: flex;
    flex-direction: row;
    justify-content: start;
  }
`;

interface InfoContainerProps {
  greyBoxExists: boolean;
  width: string;
  minWidth: string;
}

interface GreyBoxProps {
  width: string;
  minWidth: string;
}

interface GreyBoxHeaderProps {
  infoHeaderWidth: string;
  infoHeaderMinWidth: string;
  width: string;
  minWidth: string;
}

const StyledInfoContainer = styled.div<InfoContainerProps>`
  text-align: left;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 0px;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    margin-top: 12px;
    width: ${(props) => props.width};
    min-width: ${(props) => props.minWidth};
  }
`;

const StyledInfoRow = styled.div`
  width: 100%;
  display: flex;
`;

const StyledHeading = styled.h2<InfoContainerProps>`
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.headingMainWeight};
  margin-bottom: 6px;
  margin-top: 0px;
  width: ${(props) => props.width};
  min-width: ${(props) => props.minWidth};
`;

const StyledColon = styled.h2<InfoContainerProps>`
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
  margin-bottom: 6px;
  margin-top: 0px;
  width: ${(props) => props.width};
  min-width: ${(props) => props.minWidth};
  text-align: center;
`;

const StyledInfo = styled.div<InfoContainerProps>`
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
  margin-bottom: 6px;
  margin-top: 0px;
  font-weight: normal;
  width: ${(props) => props.width};
  min-width: ${(props) => props.minWidth};
`;

const StyledGreyBoxInfoContainer = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0;
  border-radius: 4px;
  margin-top: 20px;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    background-color: #e8e8e8;
    padding: 11px 22px 10px 22px;
    margin-top: 0px;
  }
`;

const StyledGreyBoxContainer = styled.div<GreyBoxProps>`
  text-align: left;
  display: flex;
  flex-direction: column;
  flex-basis: ${(props) => props.width};
  min-width: ${(props) => props.minWidth};
  margin-bottom: 0px;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    margin-bottom: 12px;
  }
`;

const StyledGreyBoxTitle = styled.h2`
  font-size: ${(props) => props.theme.headingSecondarySize};
  font-weight: ${(props) => props.theme.headingSecondaryWeight};
  margin-bottom: 14px;
  margin-top: 0px;
  width: 100%;
`;

const StyledGreyBoxHeading = styled.h2<GreyBoxHeaderProps>`
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.headingMainWeight};
  margin-bottom: 6px;
  margin-top: 0px;
  width: ${(props) => props.infoHeaderWidth};
  min-width: ${(props) => props.infoHeaderMinWidth};

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    width: ${(props) => props.width};
    min-width: ${(props) => props.minWidth};
  }
`;

const StyledGreyBoxColon = styled.h2<GreyBoxProps>`
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
  margin-bottom: 6px;
  margin-top: 0px;
  width: ${(props) => props.width};
  min-width: ${(props) => props.minWidth};
  text-align: center;
`;

const StyledGreyBoxInfo = styled.div<GreyBoxProps>`
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
  margin-bottom: 6px;
  margin-top: 0px;
  font-weight: normal;
  width: ${(props) => props.width};
  min-width: ${(props) => props.minWidth};
`;

const StyledGreyBoxRightSpacer = styled.div<GreyBoxProps>`
  width: ${(props) => props.width};
  min-width: ${(props) => props.minWidth};
  background-color: white;
`;
