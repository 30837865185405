import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import ProfireButton from "../common/buttons/ProfireButton";
import CommonInput from "../common/formInputs/CommonInput";
import Dropdown from "../common/formInputs/Dropdown";
import {
  IGrafanaAlert,
  IGrafanaAlertEditDTO,
  IGrafanaAlertFields,
} from "../../types/alerts";
import ModalBox from "../common/modals/ModalBox";
import { IContactPoint } from "../../types/contactPoints";
import { IGenericConfigDTO } from "../../types/controlUnitConfig";
import { validateWholeNumberString } from "../../utilities/validationFunctions";
import { updateAlertConditionsArray } from "../../utilities/staticObjects";
import InfoButton from "../common/buttons/InfoButton";
import StyleContext from "../../stores/styles/StyleContext";

interface EditAlertProps {
  dataTestname: string;
  active: boolean;
  fieldData: IGrafanaAlertFields[];
  contactPoints: IContactPoint[];
  controlUnitID: number;
  onCancel?: () => void;
  onEdit: Function;
  existingAlerts: IGrafanaAlert[];
  chosenAlert: IGrafanaAlert;
  genericDevice: boolean;
  genericData: IGenericConfigDTO[] | null;
  retainedData: IGrafanaAlert | undefined;
}

let existingErrors: Boolean = false;

const EditAlert: React.FC<EditAlertProps> = ({
  dataTestname,
  active,
  fieldData,
  contactPoints,
  controlUnitID,
  onCancel,
  onEdit,
  existingAlerts,
  chosenAlert,
  genericDevice,
  genericData,
  retainedData,
}) => {
  const styleContext = useContext(StyleContext);

  const infoHoverText: string =
    "Once condition is breached, alert will go into pending state. If it is pending for longer than the “for” value, it will become a tripped alert.";

  const [chosenField, setChosenField] = useState<string>(
    retainedData === undefined
      ? chosenAlert.field_name
      : retainedData.field_name
  );
  const [conditionsArray, setConditionsArray] = useState<[string, string][]>(
    updateAlertConditionsArray(
      fieldData.find((chosen) => chosen.db_name === chosenField)?.alert_config!
    )
  );
  const [chosenCondition, setChosenCondition] = useState<string>(
    retainedData === undefined
      ? chosenAlert.evaluation_operation
      : retainedData.evaluation_operation
  );
  const [chosenContactPoint, setChosenContactPoint] = useState<number>(
    retainedData === undefined
      ? chosenAlert.alert_contact_point_id!
      : retainedData.alert_contact_point_id!
  );
  const [evaluationValue, setEvaluationValue] = useState<string>(
    retainedData === undefined
      ? chosenAlert.evaluation_value.toString()
      : retainedData.evaluation_value.toString()
  );
  const [evaluationToValue, setEvaluationToValue] = useState<string>(
    retainedData === undefined
      ? chosenAlert.evaluation_to_value
        ? chosenAlert.evaluation_to_value.toString()
        : "0"
      : retainedData.evaluation_to_value === undefined
      ? "0"
      : retainedData.evaluation_to_value.toString()
  );
  const [minutes, setMinutes] = useState<number>(
    retainedData === undefined
      ? chosenAlert.evaluation_for_period_minutes
      : retainedData.evaluation_for_period_minutes
  );
  const [alertName, setAlertName] = useState<string>(
    retainedData === undefined
      ? chosenAlert.alert_name
      : retainedData.alert_name
  );
  const [resultMessage, setResultMessage] = useState<string>("");
  const [result, setResult] = useState<boolean>(false);

  function updateChosenField(newChosen: string) {
    setChosenField(newChosen);

    updateChosenCondition(newChosen);
    setConditionsArray(
      updateAlertConditionsArray(
        fieldData.find((a) => a.db_name === newChosen)?.alert_config!
      )
    );

    //if alert config is changed from not chronological to chronological AND the value is out of range, display error
    //ELSE IF alert config is changed from chronological to not chronological AND the error is "Value must be between 0 and 100,000", remove error
    if (
      fieldData.find((a) => a.db_name === chosenField)?.alert_config !==
        "chronological" &&
      fieldData.find((a) => a.db_name === newChosen)?.alert_config ===
        "chronological" &&
      (parseInt(evaluationValue) < 0 || parseInt(evaluationValue) > 100000)
    ) {
      setEvaluationValueErrors("Value must be between 0 and 100,000");
    } else if (
      fieldData.find((a) => a.db_name === chosenField)?.alert_config ===
        "chronological" &&
      fieldData.find((a) => a.db_name === newChosen)?.alert_config !==
        "chronological" &&
      evaluationValueErrors === "Value must be between 0 and 100,000"
    ) {
      setEvaluationValueErrors("");
    }
  }

  function updateChosenCondition(newChosen: string) {
    setChosenCondition(newChosen);

    if (
      genericDevice ||
      fieldData.find((a) => a.db_name === chosenField)?.alert_config ===
        "is_digital_boolean" ||
      fieldData.find((a) => a.db_name === chosenField)?.alert_config ===
        "is_digital_energy"
    ) {
      if (
        genericData?.find(
          (datum) =>
            datum.input_name ===
            fieldData.find((field) => field.db_name === chosenField)
              ?.display_name
        )?.input_mode === "Digital" ||
        fieldData.find((a) => a.db_name === chosenField)?.alert_config ===
          "is_digital_boolean" ||
        fieldData.find((a) => a.db_name === chosenField)?.alert_config ===
          "is_digital_energy"
      ) {
        newChosen === "lt"
          ? setEvaluationValue("1")
          : newChosen === "gt" && setEvaluationValue("0");
      }
    }
  }

  function updateChosenContactPoint(newChosen: string) {
    setChosenContactPoint(parseInt(newChosen));
  }

  function updateEvaluationValue(e: React.FormEvent<HTMLInputElement>) {
    if (!validateWholeNumberString(e.currentTarget.value)) {
      setEvaluationValue(e.currentTarget.value);
      setEvaluationValueErrors("Only whole numbers are accepted");
    } else if (
      fieldData.find((a) => a.db_name === chosenField)?.alert_config ===
        "chronological" &&
      (parseInt(e.currentTarget.value) < 0 ||
        parseInt(e.currentTarget.value) > 100000)
    ) {
      setEvaluationValue(e.currentTarget.value);
      setEvaluationValueErrors("Value must be between 0 and 100,000");
    } else {
      setEvaluationValue(e.currentTarget.value);
      setEvaluationValueErrors("");
    }
  }

  function updateEvaluationToValue(e: React.FormEvent<HTMLInputElement>) {
    if (!validateWholeNumberString(e.currentTarget.value)) {
      setEvaluationToValue(e.currentTarget.value);
      setEvaluationValueErrors("Only whole numbers are accepted");
    } else {
      setEvaluationToValue(e.currentTarget.value);
      setEvaluationValueErrors("");
    }
  }

  function updateMinutes(e: React.FormEvent<HTMLInputElement>) {
    setMinutes(e.currentTarget.valueAsNumber);
    setMinutesErrors("");
  }
  function updateAlertName(e: React.FormEvent<HTMLInputElement>) {
    setAlertName(e.currentTarget.value);
    setAlertNameErrors("");
  }

  const [evaluationValueErrors, setEvaluationValueErrors] =
    useState<string>("");
  const [minutesErrors, setMinutesErrors] = useState<string>("");
  const [alertNameErrors, setAlertNameErrors] = useState<string>("");

  let alertFields: [string, string][] = [];
  if (fieldData.length === 0) {
    alertFields.push(["Failed to fetch fields", "0"]);
  }
  for (let index = 0; index < fieldData.length; index++) {
    alertFields.push([fieldData[index].display_name, fieldData[index].db_name]);
  }

  let contactPointArray: [string, string][] = [];
  if (contactPoints.length === 0) {
    contactPointArray.push(["Add Contact Points in My Company", "0"]);
  }
  for (let index = 0; index < contactPoints.length; index++) {
    contactPointArray.push([
      contactPoints[index].name,
      contactPoints[index].id.toString(),
    ]);
  }

  function SaveEdits(e: any) {
    e.preventDefault();

    if (!existingErrors) {
      const editedAlert: IGrafanaAlertEditDTO = {
        id: chosenAlert.alert_rule_id,
        control_unit_id: controlUnitID,
        alert_contact_point_id: chosenContactPoint,
        alert_name: alertName,
        field_name: chosenField,
        evaluation_operation: chosenCondition,
        evaluation_value: parseInt(evaluationValue),
        evaluation_to_value: parseInt(evaluationToValue),
        evaluation_for_period_minutes: minutes,
        field_display_name: alertFields.find(
          (field) => field[1] === chosenField
        )?.[0]!,
        operation_display_name: conditionsArray.find(
          (condition) => condition[1] === chosenCondition
        )?.[0]!,
        unit_of_measure: fieldData.find((e) => e.db_name === chosenField)!
          ?.unit_of_measure,
        alert_config: fieldData.find((e) => e.db_name === chosenField)!
          ?.alert_config,
      };
      onEdit(editedAlert);
    } else {
      setResultMessage(`Please fix the above errors.`);
      setResult(false);
    }
  }

  function ErrorHandler() {
    existingErrors = false;
    setResultMessage("");

    setEvaluationValueErrors("");
    setAlertNameErrors("");

    if (
      chosenCondition === "outside_range" ||
      chosenCondition === "within_range"
    ) {
      if (parseInt(evaluationValue) >= parseInt(evaluationToValue)) {
        setEvaluationValueErrors("Left value must be lower than right value");
        existingErrors = true;
      }
    }
    if (
      isNaN(parseInt(evaluationValue)) &&
      (chosenCondition === "outside_range" ||
        chosenCondition === "within_range")
    ) {
      setEvaluationValueErrors("Lower bound is required");
      existingErrors = true;
    }
    if (
      isNaN(parseInt(evaluationToValue)) &&
      (chosenCondition === "outside_range" ||
        chosenCondition === "within_range")
    ) {
      setEvaluationValueErrors("Upper bound is required");
      existingErrors = true;
    }
    if (
      isNaN(parseInt(evaluationValue)) &&
      (chosenCondition === "gt" || chosenCondition === "lt")
    ) {
      setEvaluationValueErrors("Value is required");
      existingErrors = true;
    }
    if (!validateWholeNumberString(evaluationValue)) {
      setEvaluationValueErrors("Only whole numbers are accepted");
      existingErrors = true;
    }
    if (!validateWholeNumberString(evaluationToValue)) {
      setEvaluationValueErrors("Only whole numbers are accepted");
      existingErrors = true;
    }
    if (isNaN(minutes)) {
      setMinutesErrors("Minutes is required");
      existingErrors = true;
    }
    if (minutes < 6) {
      setMinutesErrors("Minutes cannot be less than 6");
      existingErrors = true;
    }
    if (minutes > 10000) {
      setMinutesErrors("Minutes cannot be greater than 10000");
      existingErrors = true;
    }
    if (alertName === "") {
      setAlertNameErrors("Name is required");
      existingErrors = true;
    }
    if (
      existingAlerts.find((e) => e.alert_name === alertName)
        ? existingAlerts.find((e) => e.alert_name === alertName)
            ?.alert_rule_id !== chosenAlert.alert_rule_id
        : false
    ) {
      setAlertNameErrors("Name in use by another alert");
      existingErrors = true;
    }
  }

  const [mobileForTextActive, setMobileForTextActive] =
    useState<boolean>(false);

  function showForText() {
    setMobileForTextActive(!mobileForTextActive);
  }

  const forRef = useRef<any>(null);
  const infoButtonRef = useRef<any>(null);

  const handleOutsideOfBoxClick = useCallback(
    (e: any) => {
      if (
        forRef.current &&
        !forRef.current?.contains(e.target) &&
        !infoButtonRef.current?.contains(e.target)
      ) {
        setMobileForTextActive(false);
      }
    },
    [setMobileForTextActive, forRef, infoButtonRef]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideOfBoxClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideOfBoxClick);
    };
  }, [handleOutsideOfBoxClick]);

  return (
    <ModalBox
      dataTestname="edit-alert-modal-box"
      active={active}
      desktopContainerMinWidth={
        genericDevice ||
        fieldData.find((a) => a.db_name === chosenField)?.alert_config ===
          "is_digital_boolean" ||
        fieldData.find((a) => a.db_name === chosenField)?.alert_config ===
          "is_digital_energy"
          ? genericData?.find(
              (datum) =>
                datum.input_name ===
                fieldData.find((field) => field.db_name === chosenField)
                  ?.display_name
            )?.input_mode === "Digital" ||
            fieldData.find((a) => a.db_name === chosenField)?.alert_config ===
              "is_digital_boolean" ||
            fieldData.find((a) => a.db_name === chosenField)?.alert_config ===
              "is_digital_energy"
            ? "589px"
            : chosenCondition === "outside_range" ||
              chosenCondition === "within_range"
            ? "832px"
            : "765px"
          : "765px"
      }
      mobileContainerMinWidth={"320px"}
      containerTitle="Edit Alert"
    >
      <StyledForm data-testname={dataTestname} onSubmit={SaveEdits}>
        <StyledH2>Trigger Condition</StyledH2>
        <StyledRow>
          <StyledWhenFieldMobileRow>
            <StyledLabelCenteringContainer>
              <StyledWhen condition={chosenCondition}>WHEN</StyledWhen>{" "}
            </StyledLabelCenteringContainer>
            <StyledFieldsHolder>
              <Dropdown
                dataTestname="edit-alert-when-condition-dropdown"
                columns={alertFields}
                onchange={updateChosenField}
                labelText=""
                selected={chosenField.toString()}
                hideErrorsDiv={true}
                autoFocus={true}
              />
            </StyledFieldsHolder>
          </StyledWhenFieldMobileRow>
          <StyledConditionsHolder>
            <Dropdown
              dataTestname="edit-alert-condition-dropdown"
              columns={conditionsArray}
              onchange={updateChosenCondition}
              labelText=""
              selected={chosenCondition.toString()}
              hideErrorsDiv={true}
            />
          </StyledConditionsHolder>

          <StyledEvaluationMobileRow
            condition={chosenCondition}
            display={
              genericData?.find(
                (datum) =>
                  datum.input_name ===
                  fieldData.find((field) => field.db_name === chosenField)
                    ?.display_name
              )?.input_mode === "Digital" ||
              fieldData.find((a) => a.db_name === chosenField)?.alert_config ===
                "is_digital_boolean" ||
              fieldData.find((a) => a.db_name === chosenField)?.alert_config ===
                "is_digital_energy"
                ? "none"
                : "flex"
            }
          >
            <StyledEvaluationHolder
              condition={chosenCondition}
              display={
                genericData?.find(
                  (datum) =>
                    datum.input_name ===
                    fieldData.find((field) => field.db_name === chosenField)
                      ?.display_name
                )?.input_mode === "Digital" ||
                fieldData.find((a) => a.db_name === chosenField)
                  ?.alert_config === "is_digital_boolean" ||
                fieldData.find((a) => a.db_name === chosenField)
                  ?.alert_config === "is_digital_energy"
                  ? "none"
                  : "initial"
              }
            >
              <CommonInput
                dataTestname="edit-alert-evaluation-common-input"
                type="text"
                value={evaluationValue.toString()}
                onChange={updateEvaluationValue}
                hideErrorsDiv={true}
              />
            </StyledEvaluationHolder>
            {chosenCondition === "outside_range" ||
            chosenCondition === "within_range" ? (
              <StyledLabelCenteringContainer>
                <StyledTo>TO</StyledTo>
              </StyledLabelCenteringContainer>
            ) : (
              <></>
            )}
            {chosenCondition === "outside_range" ||
            chosenCondition === "within_range" ? (
              <StyledEvaluationToHolder condition={chosenCondition}>
                <CommonInput
                  dataTestname="edit-alert-evaluation-to-common-input"
                  type="text"
                  value={evaluationToValue.toString()}
                  onChange={updateEvaluationToValue}
                  hideErrorsDiv={true}
                />
              </StyledEvaluationToHolder>
            ) : (
              <></>
            )}
          </StyledEvaluationMobileRow>
          <StyledDegrees>
            {fieldData.find((e) => e.db_name === chosenField)
              ?.unit_of_measure === undefined ||
            fieldData.find((e) => e.db_name === chosenField)
              ?.unit_of_measure === null
              ? ""
              : fieldData.find((e) => e.db_name === chosenField)
                  ?.unit_of_measure}
          </StyledDegrees>
          {styleContext.isMobile && (
            <StyledEvaluationErrorHolder>
              <StyledEvaluationErrorHolderInner>
                <span>{evaluationValueErrors}</span>
              </StyledEvaluationErrorHolderInner>
            </StyledEvaluationErrorHolder>
          )}
        </StyledRow>
        <StyledForRow>
          <StyledForRowInner>
            <StyledFor>FOR</StyledFor>
            <StyledInfo>
              {mobileForTextActive && (
                <StyledInfoButtonDiv
                  hoverWidthsMobile={["150px", "212px", "340px"]}
                  hoverPositionMobile={["218px", "0px", "unset", "66px"]}
                  ref={forRef}
                >
                  {infoHoverText}
                </StyledInfoButtonDiv>
              )}
              <InfoButton
                dataTestname="edit-alert-info-button"
                hoverText={infoHoverText}
                hoverWidthsMobile={["150px", "300px", "340px"]}
                hoverPositionMobile={["28px", "unset", "unset", "-36px"]}
                onClickFunction={showForText}
                mobileHoverTextRef={infoButtonRef}
              />
            </StyledInfo>
            <StyledMinutesHolder>
              <CommonInput
                dataTestname="edit-alert-for-common-input"
                type="number"
                value={minutes.toString()}
                onChange={updateMinutes}
                hideErrorsDiv={true}
              />
            </StyledMinutesHolder>
            <StyledMin>min</StyledMin>
          </StyledForRowInner>
          {!styleContext.isMobile && (
            <StyledEvaluationErrorHolder>
              <StyledEvaluationErrorHolderInner>
                <span>{evaluationValueErrors}&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <StyledErrorUnitOfMeasure>{`${
                  fieldData.find((e) => e.db_name === chosenField)
                    ?.unit_of_measure
                }`}</StyledErrorUnitOfMeasure>
              </StyledEvaluationErrorHolderInner>
            </StyledEvaluationErrorHolder>
          )}
        </StyledForRow>
        <StyledMinutesErrorHolder>
          {minutesErrors ? minutesErrors : <>&nbsp;</>}
        </StyledMinutesErrorHolder>
        <StyledNameAndContactPointRow>
          <StyledNameHolder>
            <CommonInput
              dataTestname="edit-alert-name-common-input"
              type="string"
              labelText="Name"
              value={alertName}
              onChange={updateAlertName}
              hideErrorsDiv={true}
            />
          </StyledNameHolder>
        </StyledNameAndContactPointRow>
        <StyledNameErrorHolder>
          {alertNameErrors ? alertNameErrors : <>&nbsp;</>}
        </StyledNameErrorHolder>
        <StyledNameAndContactPointRow>
          <StyledNameRowInner>
            <StyledContactPointHolder>
              <Dropdown
                dataTestname="edit-alert-contact-point-dropdown"
                columns={contactPointArray}
                onchange={updateChosenContactPoint}
                labelText="Contact Point"
                selected={chosenContactPoint.toString()}
              />
            </StyledContactPointHolder>
          </StyledNameRowInner>
          <StyledResultMessage success={result}>
            {resultMessage}
          </StyledResultMessage>
        </StyledNameAndContactPointRow>
        <StyledButtonRow>
          <StyledCancelButtonHolder>
            <ProfireButton
              dataTestname="edit-alert-cancel-button"
              text="Cancel"
              onClickFunction={onCancel}
            />
          </StyledCancelButtonHolder>
          <StyledSaveButtonHolder>
            <ProfireButton
              dataTestname="edit-alert-save-button"
              text="Save"
              onClickFunction={ErrorHandler}
              defaultButton={true}
            />
          </StyledSaveButtonHolder>
        </StyledButtonRow>
      </StyledForm>
    </ModalBox>
  );
};

export default EditAlert;

const StyledForm = styled.form`
  width: calc(100% - 10px);
  height: 100%;
  overflow-y: auto;
  padding-right: 10px;
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  width: 100%;

  > label {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    flex-direction: row;
  }
`;

const StyledWhenFieldMobileRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  width: 100%;
  margin-bottom: 5px;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    margin-bottom: 0px;
  }
`;

const StyledEvaluationMobileRow = styled.div<EvaluationValueProps>`
  display: ${(props) => props.display};
  flex-direction: row;
  flex-basis: 100%;
  justify-content: space-between;
  width: 100%;
  margin-top: 5px;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    margin-top: 0px;
    justify-content: initial;
  }
`;

const StyledForRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  justify-content: space-between;
  width: 100%;

  * {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const StyledForRowInner = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
`;

const StyledNameAndContactPointRow = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  justify-content: space-between;
  width: 100%;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    flex-direction: row;
  }
`;

const StyledNameRowInner = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledFieldsHolder = styled.div`
  width: calc(100% - 69.5667px);
  min-width: 241px;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    margin-right: 20px;
  }
`;

const StyledConditionsHolder = styled.div`
  width: calc(100% - 69.5667px);
  min-width: 241px;
  margin-left: 69.5667px;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    width: 198px;
    min-width: 198px;
    max-width: 198px;
    margin-right: 20px;
    margin-left: 0px;
  }
`;

interface EvaluationValueProps {
  condition: string;
  display: string;
}

const StyledEvaluationHolder = styled.div<EvaluationValueProps>`
  display: ${(props) => props.display};
  width: ${(props) =>
    props.condition === "outside_range" || props.condition === "within_range"
      ? "13.8%"
      : "calc(100% - 69.5667px)"};
  max-width: ${(props) =>
    props.condition === "outside_range" || props.condition === "within_range"
      ? "87px"
      : "calc(100% - 69.5667px)"};
  min-width: ${(props) =>
    props.condition === "outside_range" || props.condition === "within_range"
      ? "87px"
      : "calc(100% - 69.5667px)"};
  margin-left: 69.5667px;

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
  }

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    width: ${(props) =>
      props.condition === "outside_range" || props.condition === "within_range"
        ? "13.8%"
        : "18.8%"};
    max-width: ${(props) =>
      props.condition === "outside_range" || props.condition === "within_range"
        ? "87px"
        : "144px"};
    min-width: ${(props) =>
      props.condition === "outside_range" || props.condition === "within_range"
        ? "87px"
        : "144px"};
    margin-right: 10px;
    margin-left: 0px;
  }
`;

interface EvaluationToValueProps {
  condition: string;
}

const StyledEvaluationToHolder = styled.div<EvaluationToValueProps>`
  width: ${(props) =>
    props.condition === "outside_range" || props.condition === "within_range"
      ? "13.8%"
      : "18.8%"};
  max-width: ${(props) =>
    props.condition === "outside_range" || props.condition === "within_range"
      ? "87px"
      : "144px"};
  min-width: ${(props) =>
    props.condition === "outside_range" || props.condition === "within_range"
      ? "87px"
      : "144px"};

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
  }

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    width: ${(props) =>
      props.condition === "outside_range" || props.condition === "within_range"
        ? "13.8%"
        : "18.8%"};
    max-width: ${(props) =>
      props.condition === "outside_range" || props.condition === "within_range"
        ? "87px"
        : "144px"};
    min-width: ${(props) =>
      props.condition === "outside_range" || props.condition === "within_range"
        ? "87px"
        : "144px"};
    margin-right: 10px;
  }
`;

const StyledTo = styled.label`
  margin: 0;
  padding: 0;
  width: 67px;
  display: flex;
  justify-content: center;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    width: unset;
    margin: 0;
    padding: 0;
    margin-right: 10px;
    display: block;
  }
`;

const StyledMinutesHolder = styled.div`
  width: 84px;
  max-width: 84px;
  margin-right: 7px;

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
  }
`;

const StyledNameHolder = styled.div`
  width: 310px;
  max-width: 310px;
  display: flex;
  flex-direction: column;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    width: 316px;
    max-width: 316px;
  }
`;

const StyledContactPointHolder = styled.div`
  width: 310px;
  max-width: 310px;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    width: 316px;
    max-width: 316px;
    margin-bottom: 31px;
  }
`;

const StyledH2 = styled.h2`
  margin: 0;
  padding: 0;
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
  width: 100%;
  margin-bottom: 28px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledLabelCenteringContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledWhen = styled.label<EvaluationToValueProps>`
  margin: 0;
  padding: 0;
  margin-right: ${(props) =>
    props.condition === "outside_range" || props.condition === "within_range"
      ? "28px"
      : "28px"};
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
`;

const StyledDegrees = styled.label`
  margin: 0;
  padding: 0;
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};

  width: calc(100% - 69.5667px - 13px);
  min-width: unset;
  margin-left: calc(69.5667px + 13px);

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    margin: 0;
    padding: 0;
    width: max-content;
    max-width: unset;
    font-size: ${(props) => props.theme.contentMainSize};
    font-weight: ${(props) => props.theme.contentMainWeight};
  }
`;

const StyledFor = styled.label`
  margin: 0;
  padding: 0;
  margin-right: 9px;
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    margin-right: 10px;
  }
`;

const StyledMin = styled.label`
  margin: 0;
  padding: 0;
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
`;

const StyledEvaluationErrorHolder = styled.div`
  margin: 0;
  padding: 0;
  color: #9c1b30;
  display: flex;
  flex-direction: row;
  justify-content: start;
  width: calc(100% - 69.5667px - 13px);
  margin-left: calc(69.5667px + 13px);
  font-size: ${(props) => props.theme.contentErrorSize};
  font-weight: ${(props) => props.theme.contentErrorWeight};

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    flex-direction: column;
    width: unset;
    margin-left: unset;
  }
`;

const StyledEvaluationErrorHolderInner = styled.div`
  margin: 0;
  padding: 0;
  color: #9c1b30;
  display: flex;
  flex-direction: row;
  justify-content: start;
`;

const StyledErrorUnitOfMeasure = styled.span`
  color: #ffffff;
`;

const StyledMinutesErrorHolder = styled.div`
  margin: 0;
  padding: 0;
  color: #9c1b30;
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin-bottom: 12px;
  font-size: ${(props) => props.theme.contentErrorSize};
  font-weight: ${(props) => props.theme.contentErrorWeight};
`;

const StyledNameErrorHolder = styled.div`
  margin: 0;
  padding: 0;
  color: #9c1b30;
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin-bottom: 5px;
  font-size: ${(props) => props.theme.contentErrorSize};
  font-weight: ${(props) => props.theme.contentErrorWeight};
`;

const StyledInfo = styled.div`
  margin-right: 14px;
  width: 20px;
`;

const StyledButtonRow = styled.div`
  width: 100%;
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

const StyledCancelButtonHolder = styled.div`
  margin-right: 20px;
`;

const StyledSaveButtonHolder = styled.div``;

interface ResultProps {
  success: boolean;
}

const StyledResultMessage = styled.div<ResultProps>`
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
  color: ${(props) => (props.success ? "green" : "#9c1b30")};
  display: flex;
  flex-direction: row;
  justify-content: end;
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    flex-direction: column;
  }
`;

interface MobileInfoButtonProps {
  hoverText?: string;
  hoverPositionMobile: string[];
  hoverWidthsMobile: string[];
}

const StyledInfoButtonDiv = styled.div<MobileInfoButtonProps>`
  content: "${(props) => props.hoverText}";
  visibility: visible;
  opacity: 1;
  min-width: ${(props) => props.hoverWidthsMobile[0]};
  width: ${(props) => props.hoverWidthsMobile[1]};
  background-color: #aaa;
  color: #fff;
  text-align: center;
  border-radius: 3px;
  padding: 10px;
  transition: opacity 0.5s ease-in-out;
  text-align: left;
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};

  position: absolute;
  z-index: 130;
  margin-top: 148px;
`;
